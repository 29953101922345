.math-captcha-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.math-captcha-block label{
  display: block;
  width: 100%;
  padding: 10px 1.7% 0 0;
  margin-bottom: 10px;
}
.captcha-container{
  float: left;
  width: 70px;
}
.captcha-input{
  float: left;
  margin-left: 10px;
  flex: 1;
}
