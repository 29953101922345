.class_group_intern{
    .gallery {
        margin-bottom: 35px;
        display:flex;
        @media (max-width: 1279px){
            display:block;
        }
        .vignette-wrapper{
            width:25%;
            @media (max-width: 1279px){
                width: 100%;
            }
            .vignette{
                width:100%;
                padding-bottom:150%;
                .gallery-actions{
					position: absolute;
					z-index: 2;
				}
				a{
					display: block;
					position: absolute;
					z-index: 1;
					width: 100%;
					height: 100%;
				}
				&:hover{
					.favorites-actions button,
					a:before{
						opacity: 1;
					}
				}
            }
        }
    }
    .bloc-button-gallerie{
		height:121px;
		margin:0 auto;
		width:100%;
		text-align:center;
		margin-top: 6px;
		/*&:before{
			content: '';
		    display: inline-block;
		    height: 100%;
		    vertical-align: middle;
		    margin-right: -0.25em;
		}*/
		.callToAction--picto-light-wide{
			vertical-align: middle;
			margin-bottom: 0px;
			color:#1D1D1E;
			border-color:#1D1D1E;
			.svg-picto-archive .st0{
				fill:$default-light-font-color;
			}
			&:hover,
			&:active,
			&:focus,
			&:hover .svg-picto-archive .st0,
			&:active .svg-picto-archive .st0,
			&:focus .svg-picto-archive .st0{
				color:$default-hover-color;
				border-color:$default-hover-color;
				fill:$default-hover-color;
			}
		}
    }
    @media (max-width: 1279px){
        .gallery--artips-4 .vignette .encart {
            width: 57.813%!important;
            left: 21.094%;
            height: 340px;
            bottom: 130px;
            padding: 23.5px 20px 0 23.5px;
        }
    }
    @media (max-width:639px){
        .gallery--artips-4 .vignette .encart {
            height: 170px;
            bottom: 75px;
        }

        .bloc-button-gallerie{
    		padding:0 27.5px;
    		height: 82.5px;
    		.callToAction--picto-light-wide{
	    		margin-top: 0px;
	    	}
    	}
        .callToAction--picto-light-wide {
            font-size: 14px;
            height: auto;
            min-height: 42.5px;
            padding-top: 9px;
        }
    }

    
}