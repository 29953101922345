
.modale-masque{
	bottom: 0;
    left: 0;
    overflow-y: scroll;
    position: fixed;
    right: 0;
    top: 0;
	width:100%;
	height:100%;
	display:block;
	/*-webkit-overflow-scrolling:touch;*/
}
.overlay-nav {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index:9999999;
	nav {
		text-align: center;
		position: relative;
		top: 67px;
		height: 80%;
		/*-webkit-transform: translateY(-50%);
		transform: translateY(-50%);*/
	}
	.recherche-bloc{
		position: relative;
		top: 25%;
		width:100%;
		padding:0 8.3% 40px;
		text-align:left;
	}
	.icon-more{
	    font-size: 30px;
	    width: 40px;
	    height: 40px;
	    position: absolute;
	    right: 0px;
	    top: 0px;
	}
	.callToAction--dark{
		@include buttonBuilder(225px, 50px, 'dark',true);
		margin-bottom:0px;
		margin-left:20px;
		background-color:$default-light-font-color;
	}
	.sous-titre-page{
		font-family: $font-biko-bold;
		font-size:19px;
		font-size: 1.9rem;
	}
	.share-title{
		font-family: $font-lovelo-black !important;
		font-size: 37px !important;
		font-size: 3.7rem !important;
		margin-top:0px !important;
	}
	.detail{
		margin-top: 25px;
	}
	.socials{
		width:100%;
		.icon{
			font-size: 60px;
			font-size:6rem;
			color:$default-hover-color;
		}
		.icon-send{
			font-size:40px;
			font-size: 4rem;
		}
		div{
			display:inline-block;
			width:80px;
		}
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 0 auto;
		display: inline-block;
		//height: 180px;
		position: relative;
		width:100%;
		margin-top: 35px;
		li {
			display: block;
			height: 20%;
			min-height: 54px;
			/*padding-top: 30px;*/
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			width:100%;
			a {
				color: #2d4010;
			    display: block;
			    font-family: "open_sanssemibold";
			    transition: all 0.2s ease 0s;
				letter-spacing: 0;
				font-size:27px;
				font-size:2.7rem;
				width:100%;
				line-height:1em;
				padding:25px 0;
				/*height:85px;*/
			}
		}
	}
} 
/* Effects */
.overlay-scale {
	visibility: hidden;
	opacity: 0;
	/*-webkit-transform: scale(0.9);
	transform: scale(0.9);*/
	transition: -webkit-transform 0.3s, opacity 0.3s, visibility 0s 0.3s;
	transition: transform 0.3s, opacity 0.3s, visibility 0s 0.3s;
	text-align:center;
}

.overlay-scale.open {
	visibility: visible;
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);	
	transition: -webkit-transform 0.3s, opacity 0.3s;
	transition: transform 0.3s, opacity 0.3s;
	/*overflow: auto;
	 overflow-y: scroll;*/
	 min-width: 320px;
	 background-color: rgba(255, 255, 255, 0.9);
	/* -webkit-overflow-scrolling : touch;*/
}
.overlay-nav .overlay-close {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 40px;
    top: 40px;
    overflow: hidden;
    border: medium none;
    background: transparent url("../images/header/croix-menu.png") no-repeat scroll center center;
    text-indent: 200%;
    //color: transparent;
    z-index: 100;
    text-shadow: none;
    padding: 0px;
    transition: opacity 0.2s ease 0s;
    &::after{
    	content: "\e920";
	    position: absolute;
	    font-family: "pba";
	    top: -1px;
	    left: 0px;
	    font-size: 3.2rem;
	    color: #77787B;
	    transform: rotate(45deg);
	}
}
.overlay-nav ul li a:hover, .overlay-nav ul li a:active, .overlay-nav ul li a:focus {
    color: #121c04;
	text-decoration:none;
	transition: all 0.2s ease 0s;
	background-color:#6fab25;
}

@media screen and (max-height: 30.5em) {
	.overlay-nav nav {
		height: 70%;
	}
	.overlay-nav ul li {
		min-height: 34px;
	}
}
/*
.overlay-close:focus, .overlay-close:hover, .overlay-close:active{
	outline:1px dotted #FFF;
}*/
.overlay-close:focus, .overlay-close:hover, .overlay-close:active{
	opacity: 0.7;
    transition: opacity 0.2s ease 0s;
}

/*Menu bandeau partage*/
.partage-bloc{
	margin: 0 auto;
    max-width: 700px;
    padding-bottom: 20px;
    position: relative;
    top: 50%;
	margin-top: -100px;
}

#menu-search-full.overlay-nav .overlay-close, #share-full.overlay-nav .overlay-close {
	background: url(../images/icons/picto-cross.png) no-repeat center center;
}
.partage-bloc .wrap-center {
    padding: 0px;
    max-width: 700px;
}
.tac {
    text-align: center;
    margin-bottom: 30px;
}
.partage-bloc .sous-titre-page::before {
    content:"\e937";
    font-family: "pba";
    font-size: 20px;
    font-size: 2rem;
    margin-right: 20px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 3px;
}
#reseaux-sociaux{
	margin-top:10px;
}
#reseaux-sociaux-share > li{
	display:inline-block;
	margin-right:10px;
	width:auto;
	height:auto;
	max-height:none;
}
#reseaux-sociaux-share > li:last-child{
	margin:0px;
}
#reseaux-sociaux li a, #reseaux-sociaux-share li a{
	height:50px;
	width:50px;
	display:block;
	background-repeat:no-repeat;
	background-color:#ececec;
	background-position:0px 0px;
	transition: all 0.3s ease;
	padding:0px;
}
#reseaux-sociaux-share li a{
	background-color:#a6b1b7;
}
#reseaux-sociaux li a:hover,#reseaux-sociaux li a:active,#reseaux-sociaux li a:focus,#reseaux-sociaux-share li a:hover,#reseaux-sociaux-share li a:active,#reseaux-sociaux-share li a:focus {
	background-color:#f6f6f6;
	background-position:0px -50px;
}
#reseaux-sociaux-share li a:hover,#reseaux-sociaux-share li a:active,#reseaux-sociaux-share li a:focus {
	background-color:#6f7a80;
}
#reseaux-sociaux li.twitter a{
	background-image:url('../images/icons/twitter-sprite.png');
}
#reseaux-sociaux-share li.twitter a{
	background-image:url('../images/icons/twitter-sprite-share.png');
}
#reseaux-sociaux li.facebook a{
	background-image:url('../images/icons/facebook-sprite.png');
}
#reseaux-sociaux-share li.facebook a{
	background-image:url('../images/icons/facebook-sprite-share.png');
}
#reseaux-sociaux li.googlep a{
	background-image:url('../images/icons/googlep-sprite.png');
}
#reseaux-sociaux-share li.googlep a{
	background-image:url('../images/icons/googlep-sprite-share.png');
}
#reseaux-sociaux li.pinterest a{
	background-image:url('../images/icons/pinterest-sprite.png');
}
#reseaux-sociaux-share li.pinterest a{
	background-image:url('../images/icons/pinterest-sprite-share.png');
}
#reseaux-sociaux li.linkedin a{
	background-image:url('../images/icons/linkedin-sprite.png');
}
#reseaux-sociaux-share li.linkedin a{
	background-image:url('../images/icons/linkedin-sprite-share.png');
}
#reseaux-sociaux > li:last-child, #partenaires ul > li:last-child{
	margin-right:0px;
}
#embed-link {
    background-color: #F9F9F9;
    border: 1px solid #A6B1B7;
    color: #8A8D8E;
    line-height: 50px;
    height: 50px;
    width: 100%;
    padding-left: 20px;
    font-size: 1.7rem;
}
/*#clipboard-btn {
    height: 50px;
    width: 175px;
    margin-left: 30px;
}*/
.partage-bloc .parent-centre {
    margin-top: 48px;
}
.parent-centre {
    display: table;
    width: 100%;
}
.partage-bloc .enfant-centre {
    width: 100%;
}
.enfant-centre {
    display:table-cell;
    vertical-align: middle;
}