footer {
  .socials {
    .icon {
      font-size: 30px;
      font-size: 3rem;
    }
    .icon-instagram {
      font-size: 24px;
      font-size: 2.4rem;
      text-align: left;
      margin-left: 4px;
      margin-top: 4px;
    }
    .icon-svg-linkedin {
      height: 30px;
      width: 30px;

      &:before,
      &:after {
        height: 30px;
        width: 30px;
      }

      &:before {
        background-image: url(svg-bg-uri($svg-snapchat, #fff));
      }

      &:after {
        background-image: url(svg-bg-uri($svg-snapchat, $default-hover-color));
      }

      &:hover,
      &:focus {
        &:before {
          opacity: 0;
        }
        &:after {
          opacity: 1;
        }
      }
    }
    .icon-svg-linkedin {
      height: 30px;
      width: 30px;
      border: 1px solid #fff;
      border-radius: 50%;
      transition: 0.25s;

      &:before,
      &:after {
        height: 22px;
        width: 22px;
        background-position: center;
        background-repeat: no-repeat;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-size: contain;
      }

      &:before {
        background-image: url(svg-bg-uri($svg-linkedin, #fff));
      }

      &:after {
        background-image: url(svg-bg-uri($svg-linkedin, $default-hover-color));
      }

      @media (min-width: 640px) and (max-width: 1279px) {
        height: 60px;
        width: 60px;
        border: 2px solid #fff;

        &:before,
        &:after {
          height: 40px;
          width: 40px;
        }
      }

      &:hover,
      &:focus {
        border-color: $default-hover-color;
        &:before {
          opacity: 0;
        }
        &:after {
          opacity: 1;
        }
      }
    }

    .icon-svg-tiktok {
      height: 30px;
      width: 30px;
      border: 1px solid #fff;
      border-radius: 50%;
      transition: 0.25s;

      &:before,
      &:after {
        height: 15px;
        width: 15px;
        background-position: center;
        background-repeat: no-repeat;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-size: contain;
      }

      &:before {
        background-image: url(svg-bg-uri($svg-tiktok, #fff));
      }

      &:after {
        background-image: url(svg-bg-uri($svg-tiktok, $default-hover-color));
      }

      @media (min-width: 640px) and (max-width: 1279px) {
        height: 60px;
        width: 60px;
        border: 2px solid #fff;

        &:before,
        &:after {
          height: 30px;
          width: 30px;
        }
      }

      &:hover,
      &:focus {
        border-color: $default-hover-color;
        &:before {
          opacity: 0;
        }
        &:after {
          opacity: 1;
        }
      }
    }
  }
}
footer {
  width: 100%;
  //margin-top:70px;
  padding: 85px 90px 0px;
  background-color: $default-dark-font-color;
  color: $default-light-font-color;

  .footerDesktop {
    display: block;
    width: 1102px;
    margin: 0 auto;

    .xDistribution {
      div {
        vertical-align: top;
      }
    }
  }
  .footerRWD {
    display: none;
  }
  .icon-infos {
    font-size: 48px;
    font-size: 4.8rem;
  }
  .icon-musee-prive {
    font-size: 50px;
    font-size: 5rem;
  }
  .icon-presse {
    font-size: 38px;
    font-size: 3.8rem;
  }
  .icon-share-circled {
    font-size: 47px;
    font-size: 4.7rem;
  }
  ul,
  ol {
    list-style-type: disc;
    padding: 20px;
  }
  li {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .pictoFooter {
    height: 50px;
    vertical-align: bottom;
    display: block;
    margin-bottom: 29px;
    margin-left: 12px;
  }
  p {
    font-family: $font-novecento-widebook;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.6em;
  }
  .strong {
    font-weight: bold;
  }
  .fakeH2 {
    font-family: $font-lovelo-black;
    font-size: 28px !important;
    font-size: 2.8rem !important;
    letter-spacing: 0.07em;
    margin-bottom: 15px !important;
    margin-top: 0px !important;

    @media (max-width: 639px) {
      font-size: 14px !important;
      font-size: 1.4rem !important;
      margin-bottom: 7.5px !important;
    }
  }
  hr {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  ul {
    margin-top: 0px;
    padding: 15px 0 0 20px !important;
    font-family: $font-novecento-widebook;
    font-size: 12px;
    font-size: 1.2rem;
    a {
      @include transition(all, 0.3s, ease, 0s);
      &:hover,
      &:active,
      &:focus {
        @include transition(all, 0.3s, ease, 0s);
        color: $default-secondary-color;
      }
    }
  }
  li {
    margin: 4px 0 0 0 !important;
  }
  .retour {
    display: block;
    line-height: 18px;
    line-height: 1.8rem;

    strong {
      font-weight: bold;
      font-family: $font-novecento-widebook;
    }
  }
  .place {
    margin-top: 10px;
  }
  .infosPratiques {
    vertical-align: top;
    span:last-child {
      margin-top: 10px;
    }
  }
  .museePrivee {
    vertical-align: top;
  }
  .presse {
    vertical-align: top;
  }
  .pictoFooter::before {
    content: "";
    display: inline-block;
    height: 100%;
    margin-right: -0.25em;
  }
  .suivezNous {
    vertical-align: top;
    min-width: 230px;
    p {
      margin-bottom: 10px;
    }
    .picto-twitter {
      @include transition(all, 0.3s, ease, 0s);
      &:hover .st0,
      &:active .st0,
      &:focus .st0 {
        @include transition(all, 0.3s, ease, 0s);
        fill: $default-secondary-color;
      }
    }
    .picto-facebook {
      @include transition(all, 0.3s, ease, 0s);
      &:hover .st0,
      &:active .st0,
      &:focus .st0 {
        @include transition(all, 0.3s, ease, 0s);
        fill: $default-secondary-color;
      }
    }
    .picto-instagram {
      @include transition(all, 0.3s, ease, 0s);
      &:hover .st0,
      &:active .st0,
      &:focus .st0 {
        @include transition(all, 0.3s, ease, 0s);
        fill: $default-secondary-color;
      }
    }
    .picto-youtube {
      @include transition(all, 0.3s, ease, 0s);
      &:hover .st0,
      &:active .st0,
      &:focus .st0 {
        @include transition(all, 0.3s, ease, 0s);
        fill: $default-secondary-color;
      }
    }
    .picto-vimeo {
      @include transition(all, 0.3s, ease, 0s);
      &:hover .st0,
      &:active .st0,
      &:focus .st0 {
        @include transition(all, 0.3s, ease, 0s);
        fill: $default-secondary-color;
      }
    }
  }
  .mecenat {
    text-align: right;
    color: $main-bright-grey;
    margin-top: 35px;
    padding-bottom: 25px;
    p {
      display: inline-block;
      margin-top: 14px;
      vertical-align: top;
      margin-right: 35px;
      font-size: 9px;
      font-size: 0.9rem;
    }
    a {
      vertical-align: middle;
      display: inline-block;
    }
  }
  a {
    @include transition(all, 0.3s, ease, 0s);
    &:hover,
    &:active,
    &:focus {
      @include transition(all, 0.3s, ease, 0s);
      color: $default-secondary-color;
    }
  }
  .st0 {
    @include transition(all, 0.3s, ease, 0s);
    &:hover,
    &:active,
    &:focus {
      @include transition(all, 0.3s, ease, 0s);
      color: $default-secondary-color;
    }
  }
  .icon-send,
  .icon-smile {
    font-size: 24px;
    font-size: 2.4rem;
    margin-right: 17px;
  }
  .icon-team {
    font-size: 32px;
    font-size: 3.2rem;
    margin-right: 10px;
  }

  .lineFooter {
    p {
      font-family: $font-biko-bold;
      font-size: 18px;
      font-size: 1.8rem;
    }
    .mentionsLegales {
      margin-left: 0px;
    }
    .liens {
      margin-right: 152px;
    }
  }
}
@media (min-width: 640px) and (max-width: 1279px) {
  footer {
    .footerDesktop {
      display: none;
    }
    .pictoFooter {
      margin-bottom: 0px;
    }
    .icon-clock {
      font-size: 48px;
      font-size: 4.8rem;
      margin-top: -6px;
    }
    .icon-marker {
      font-size: 54px;
      font-size: 5.4rem;
      margin-top: 3px;
    }
    .icon-ticket {
      font-size: 34px;
      font-size: 3.4rem;
      margin-top: 1px;
    }
    .icon-share-circled {
      font-size: 47px;
      font-size: 4.7rem;
    }
    .icon-musee-prive {
      font-size: 54px;
      font-size: 5.4rem;
      margin-top: -13px;
    }
    .icon-presse {
      margin-top: -3px;
      font-size: 37px;
      font-size: 3.7rem;
    }
    .icon-send {
      font-size: 35px;
      font-size: 3.5rem;
      margin-top: -3px;
      margin-right: 0px;
    }
    h2 {
      font-size: 36px !important;
      font-size: 3.6rem !important;
    }
    p {
      line-height: 2.8rem;
      font-size: 24px;
      font-size: 2.4rem;
    }
    .retour {
      line-height: 2.8rem;
      font-size: 24px;
      font-size: 2.4rem;
      display: inline-block;
    }
    .strong {
      line-height: 2.8rem;
      font-size: 24px;
      font-size: 2.4rem;
      display: inline;
    }
    .asdasd {
      width: 100%;
      margin: 0 auto;
    }
    .center {
      margin: 0 auto;
      width: 444px;
    }
    .footerRWD {
      display: inline-block;
      width: 100%;
      .line {
        display: block;
        width: 100%;
        margin-bottom: 65px;
      }
      .line2 {
        display: block;
        width: 100%;
        margin-bottom: 40px;
      }
      .pictoFooter {
        display: inline-block;
        vertical-align: top;
        text-align: center;
        width: 50px;
        .icon {
          vertical-align: top;

          &-clock {
            font-size: 42px;
            font-size: 4.2rem;
          }
          &-marker {
            font-size: 49px;
            font-size: 4.9rem;
          }
          &-ticket {
            font-size: 30px;
            font-size: 3rem;
          }
          &-musee-prive {
            font-size: 50px;
            font-size: 5rem;
          }
          &-presse {
            font-size: 32px;
            font-size: 3.2rem;
          }
          &-send {
            font-size: 30px;
            font-size: 3rem;
          }
        }
      }
      .col2 {
        display: inline-block;
        margin-left: 50px;
        max-width: 327px;
      }
    }
    .socials {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 120px;
      margin-bottom: 60px;

      &:after {
        content: none;
      }

      .icon {
        font-size: 60px;
        font-size: 6rem;
      }
      .icon-instagram {
        font-size: 50px;
        font-size: 5rem;
      }
    }
    .picto-twitter {
      width: 60px;
      height: 60px;
    }
    .picto-facebook {
      width: 60px;
      height: 60px;
    }
    .picto-instagram {
      width: 60px;
      height: 60px;
    }
    .picto-youtube {
      width: 60px;
      height: 60px;
    }
    .picto-vimeo {
      width: 60px;
      height: 60px;
    }
  }
}
@media (max-width: 639px) {
  footer {
    padding: 42px 45px 0px;
    .footerDesktop {
      display: none;
    }
    .pictoFooter {
      margin-bottom: 0px;
    }
    .icon-clock {
      font-size: 24px;
      font-size: 2.4rem;
      //margin-top: -3px;
    }
    .icon-marker {
      font-size: 27px;
      font-size: 2.7rem;
      //margin-top: 1.5px;
    }
    .icon-ticket {
      font-size: 17px;
      font-size: 1.7rem;
      //margin-top: 0.5px;
    }
    .icon-share-circled {
      font-size: 23.5px;
      font-size: 2.35rem;
    }
    .icon-musee-prive {
      font-size: 27px;
      font-size: 2.7rem;
      //margin-top: -6.5px;
    }
    .icon-presse {
      //margin-top: -1.5px;
      font-size: 18.5px;
      font-size: 1.85rem;
    }
    .icon-send {
      font-size: 17.5px;
      font-size: 1.75rem;
      //margin-top: -1.5px;
      margin-right: 0px;
    }
    h2 {
      font-size: 18px !important;
      font-size: 1.8rem !important;
      margin-bottom: 10px;
    }
    p {
      line-height: 1.4rem;
      font-size: 12px;
      font-size: 1.2rem;
    }
    .retour {
      line-height: 1.4rem;
      font-size: 12px;
      font-size: 1.2rem;
      display: inline-block;
    }
    .strong {
      line-height: 1.4rem;
      font-size: 12px;
      font-size: 1.2rem;
      display: inline;
    }
    .asdasd {
      width: 100%;
      margin: 0 auto;
    }
    .center {
      margin: 0 auto;
      width: 222px;
    }
    .footerRWD {
      display: inline-block;
      width: 100%;
      .line {
        display: block;
        width: 101%;
        margin-bottom: 32.5px;
      }
      .line2 {
        display: block;
        width: 100%;
        margin-bottom: 20px;
      }
      .pictoFooter {
        display: inline-block;
        vertical-align: top;
        text-align: center;
        width: auto;
        margin-bottom: 0px;
        height: 25px;
        .icon {
          vertical-align: top;
          &-clock {
            font-size: 21px;
            font-size: 2.1rem;
          }
          &-marker {
            font-size: 24.5px;
            font-size: 2.45rem;
          }
          &-ticket {
            font-size: 15px;
            font-size: 1.5rem;
          }
          &-musee-prive {
            font-size: 25px;
            font-size: 2.5rem;
          }
          &-presse {
            font-size: 16px;
            font-size: 1.6rem;
          }
          &-send {
            font-size: 15px;
            font-size: 1.5rem;
          }
        }
      }
      .col2 {
        display: inline-block;
        margin-left: 25px;
        max-width: 174px;
      }
    }
    // .picto-twitter{
    // 	width:30px;
    // 	height:30px;
    // }
    // .picto-facebook{
    // 	width:30px;
    // 	height:30px;
    // }
    // .picto-instagram{
    // 	width:30px;
    // 	height:30px;
    // }
    // .picto-youtube{
    // 	width:30px;
    // 	height:30px;
    // }
    // .picto-vimeo{
    // 	width:30px;
    // 	height:30px;
    // }
    .socials {
      margin-top: 60px;
      margin-bottom: 30px;
      .icon {
        font-size: 30px;
        font-size: 3rem;
      }
      .icon-instagram {
        font-size: 25px;
        font-size: 2.5rem;
      }
    }
  }
}
