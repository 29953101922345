#resa_atelier {
  width: 50%;
  margin: 0 auto;
  background: #fff;
  padding: 50px 50px 100px;
  
  .errors {
    color: red;
    font-weight: bold;
    
    ul {
      margin: 20px 0 20px 40px;
      
      li {
        list-style: disc;
      }
    }
  }

  h2 {
    text-align: center;
  }

  .infos {
    display: block;
    text-align: center;
    font-weight: bold;
    color: red;
    margin-top: 20px;
  }

  form {
    margin-top: 50px;

    .required {
      color: #FF0000;
    }

    .infos {
      margin-top: 5px;
      display: block;
      font-size: 12px;
      font-style: italic;
    }

    .form-group {
      margin-bottom: 30px;
    }

    .g-recaptcha {
      float: right;
    }

    .cnil {
      text-align: center;
      font-size: 10px;
      line-height: 12px;
      margin-top: 30px;
    }

    .callToAction--pictoAfter:before {
      content: none;
    }
  }

  .valid_form {
    text-align: center;
    margin-bottom: 30px;
  }

  .return_atelier {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .callToAction--pictoAfter:before {
    content: none;
  }
}