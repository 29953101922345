$frise-width: 100%;
$frise-height: 600;
$frise-top-part: $frise-height*0.55;
$frise-bot-part: $frise-height*0.45;
$item-top-height: $frise-top-part*0.85;
$item-bot-height: $frise-height*0.45;
$item-center-height: $frise-height*0.3;
.page-frise{
	// background-color: #2c2c2e;
	#frise{
		// User
		height: #{$frise-height}px;
		width: 925px;
		// background-color: #2c2c2e;
		color: white;
		overflow: visible;
		display: block;
		margin: 0 auto;
		position: relative;
 
		.custom-controls{
			position: absolute;
			width: 100%;
			font-size: 48px;
			font-size: 4.8rem;
			.left{
				position: absolute;
				left: -60px;
				@include transition(all, 0.3s, ease, 0s);
				&:hover,
				&:active,
				&:focus{
					color: $default-hover-color;
				}
			}
			.right{
				position: absolute;
				right: -60px;
				@include transition(all, 0.3s, ease, 0s);
				&:hover,
				&:active,
				&:focus{
					color: $default-hover-color;
				}
			}
		}
		.frise-item{
			a{
				&:hover,
				&:focus,
				&:active{
					.visu{
						img{
							border-color: $default-hover-color;
						}
					}
				}
			}
			.date{
				font-family: $font-biko-bold;
				font-size: 18px;
				font-size: 1.8rem;
			}
			.description{
				font-family: $font-opensans-regular;
				font-size: 12px;
				font-size: 1.2rem;
				display: inline-block;
			}
			.mark{
				width: 2px;
				padding: 0;
				position: relative;
				left: 50%;
				margin-left: -1px;
			}
			&--top{
				.date{
					margin-bottom: 7px;
				}
				.visu{
					height: 130px;
					text-align: center;
					// overflow: hidden;
					margin-bottom: 30px;
					height : 100%;
					position: relative;
					
					img{
						height: 100%;
						width : auto;
						border: solid 6px #FFF;
						@include transition (all, 0.3s, ease, 0s);
					}
					
					&:before{
						content: '';
						display: none;
						width: 30px;
						height: 30px;
						background-color: #ffffff;
						transform:  translateX(-50%) translateY(100%) rotate(45deg);
						position: absolute;
						bottom: 0px;
						left: 50%;
					}
				}
				.description{
					padding-bottom: 15px;
				}
				.innerContent{
				}
				.mark{
					bottom: 0;
				}
				&:hover, &:focus{
					&.has-popup{
						.visu:before{
							display: block;
						}
						.item-popup{
							display: block;
						}
					}
				}
			}
			&--bot{
				&:hover,
				&:active,
				&:focus{
					.visu{
						color: $default-hover-color;
					}
				}

				.innerContent{
				}
				.date{
					margin-top: 7px;
				}
				.description{
					padding-top: 15px;
					height: 39px;
				}
				.visu{
					display: block;
					font-size: 48px;
					font-size: 4.8rem;
					margin-top: 20px;
					@include transition(all, 0.3s, ease, 0s);
					position: relative;
				}
				.mark{
					top: 0;
					height: 50px;
				}
			}
		}

		.item-popup{
			padding: 20px 30px;
			position: absolute;
			top: 50%;
			left: 50%;
			display: none;
			width: 400px;
			background-color: #FFFFFF;
			color: #0b132b;
			text-align: left;
			z-index: 100;

			&-border{
				height: 30px;
				width: 100%;
				border-top: solid 1px #b81231;
				position: relative;
				&:after{
					position: absolute;
					top: 0;
					right: 0;
					height: 30px;
					width: 30px;
					border-right: solid 13px #b81231;
					border-top: solid 12px #b81231;
					content: '';
				}
				margin-bottom: 5px;
			}
			&-date{
				font-size: 28px;
				text-transform: uppercase;
				margin-bottom: 10px;
				font-family: $font-novecento-sans-wide-book;
				line-height: 1.2em;
			}
			&-title{
				color: #0b132b;
				font-size: 13px;
				margin-bottom: 20px;
				font-family: $font-opensans-bold;
				line-height: 1.2em;
			}
			&-description{
				font-size: 13px;
				color: #0b132b;
				font-family: $font-opensans-light;
				line-height: 1.2em;
			}
		}

	

		// Plug
		.frise-window{
			height: 100%;
			overflow: hidden;
		}
		.frise-viewport{
			height: 100%;
			position: relative;
		}
		.frise-item{
			width: 200px;
			text-align: center;
			position: absolute;
			display: inline-block;

			&--top{
				bottom: #{$frise-bot-part}px;
				height: #{$item-top-height}px;
			}
			&--bot{
				top: #{$frise-top-part}px;
				height: #{$item-bot-height}px;
			}
			&--center{
				width: 115px;
				.visu-marker{
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;
					height: 69px;
					margin-bottom: 20px;
					width: 57px;
					display: inline-block;
				}
				.date{
					font-size: 28px;
					font-size: 2.8rem;
				}
				height: 115px;
				top: 254px;
			}
		}
		.frise-line{
			height: 2px;
			background-color: #FFF;
			position: absolute;
			top: 55%;
			margin-top: -1px;

			&:after{
				content: '';
				display: inline-block;
				width: 2px;
				height: 20px;
				background-color: #FFF;
				position: absolute;
				left: 0px;
				top: 50%;
				margin-top: -10px;
			}
			&:before{
				content: '';
				display: inline-block;
				width: 2px;
				height: 20px;
				background-color: #FFF;
				position: absolute;
				right: 0px;
				top: 50%;
				margin-top: -10px;
			}
		}
		// Controleurs par défaut
		.default-controls{

		}
		// Placement controls
		.frise-controls{

			.control-left{
			}
			.control-right{
			}
		}

	}
} 
.page-frise--oeuvre{
	.module--oeuvre{
		height: 600px;
	}
	.frise-left{
		width: 1075px;
		display: inline-block;
	}
	#frise{
		width: 83%;
		display: block;
	}
}