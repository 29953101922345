.videotheque {
  background-color: #fff;
  padding: 50px 0;

  &__main-container {
    max-width: 1280px;
    margin: 0 auto;

    h2 {
      margin-bottom: 50px;
    }

  }

  &__videos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 980px;
    margin: 0 auto;
  }

  &__video {
    width: calc(100% / 3);
    text-align: center;

    @include RWD(tablet) {
      width: calc(100% / 2);
    }

    @include RWD(mobile) {
      width: 100%;
    }

    h4 {
      width: 80%;
      margin: 20px auto;
      font-family: "bikobold";
      font-size: 16px;
      font-size: 1.6rem;
      text-transform: lowercase;
      letter-spacing: 0.031em;
    }

  }

  .pagination--light {
    margin: 45px auto;
  }

}