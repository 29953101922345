@mixin arianneBuilder($gabarit2,$controls-oeuvre,$title,$picto-title,$select,$uppercase){
	background-color: $default-dark-font-color;
	h1{
		display: inline;
	}

	@if	$gabarit2{
		background-color: transparent;
		position: absolute;
		width: 100%;
		left: 0;
		z-index: 2;
		.profil{
			text-transform: uppercase;
			display: inline;
		}
	}
	@if $controls-oeuvre{
		.prev-oeuvre,.next-oeuvre{
			padding-top: 35px;
			font-size: 30px;
			font-size: 3.0rem;
		}
		.prev-oeuvre{
			position: absolute;
			left: 395px;
		}
		.next-oeuvre{
			position: absolute;
			left: 860px;
		}
	}

	@if $title{
		.title{
			font-size: 24px;
			font-size: 2.4rem;
			width: 395px;
			letter-spacing: 0.05em;
			vertical-align: top;
			padding-top: 40px;

			.text{
				vertical-align: middle;
			}

			sup{
				font-size: 14px;
				font-size: 1.4rem;
			}
		}
	}

	@if $uppercase{
		.title{
			text-transform: uppercase;
		}
	}

	@if $picto-title{
		.title{
			.icon{
				vertical-align: middle;
				margin-right: 35px;
			}
		}
	}

	@if $select{

	}
}


.arianne{
	height: 105px;
	color: white;
	font-family: $font-biko-bold;
	@media(max-width: 1279px){
		display: none;
	}

	.center-line{
		padding: 0 25px;
		position: relative;
		width: 1280px;
		margin: 0 auto;
		height: 100%;

		&:before{
			height: 100%;
			display: inline-block;
			vertical-align: middle;
			content: '';
		}
		&>*{
			display: inline-block;
			vertical-align: middle;
		}
	}

	a{
		@include transition(all,0.3s, ease, 0s);
		&:hover,
		&:active,
		&:focus{
			color: $default-hover-color;
		}
	}

	// Back To
	.back-to{
		padding-top: 40px;
		font-size: 19px;
		font-size: 1.9rem;
		position: absolute;
		left: 25px;
		max-width: 340px;
		.text{
			vertical-align: top;
			margin-left: 20px;
		}
		/*svg{
			position: relative;
			// top: 3px;
			margin-right: 10px;
			vertical-align: middle;
			float: left;
		}*/
	}

	// Share
	.share{
		position: absolute;
		right: 40px;
		margin-top: 42px;
		font-size: 20px;
		font-size: 2.0rem;
		@include transition(all, 0.3s, ease, 0s);
		&:hover,
		&:focus,
		&:active{
			color: $default-hover-color;
		}
		&:before{
			height: 100%;
			display: inline-block;
			vertical-align: middle;
			content: '';
		}
	}

	//Billeterie
	.billeterie{
		position: absolute;
		right: 145px;
		top: 50%;
		margin-top: -16px;
		border: 4px solid #FFF;
		padding: 8px 14px 5px;
		text-transform: uppercase;
		font-size: 14px;
		font-size: 1.4rem;
		font-family: $font-lovelo-black;
		@include transition(all, 0.3, ease, 0s);

		&:hover,
		&:focus,
		&:active{
			border-color: $default-hover-color;
		}
	}
	&.gabarit-1{
		@include arianneBuilder(true,true, false, false, true,false);
	}
	&.gabarit-2{
		@include arianneBuilder(true,true, true, true, true,false);
	}
	&.applications{
		@include arianneBuilder(false,false,true,true,false,false);
		.title {
		    font-size: 2.4rem;
		    width: 506px;
		    letter-spacing: 0.05em;
		    vertical-align: top;
		    padding-top: 28px;
		    display: inline-flex;
		    display: -moz-inline-flex;
		    display: -webkit-inline-flex;
		    display: -ms-inline-flex;
		    align-items: center;
		    -moz-align-items: center;
		    -ms-align-items: center;
		    -webkit-align-items: center;
		    .icon {
			    vertical-align: middle;
			    margin-right: 35px;
			    font-size: 58px;
			}
		}
	}
	&.collections-liste{
		@include arianneBuilder(false,false,true,false,true,true);

		.select-contain{
			position: absolute;
			right: 80px;
			top: 50%;
			margin-top: -16px;
			text-align: left;

			.icon{
				float: right;
				padding-left: 5px;
			}
		}
		.collection-select{
			width: 300px;
			// Remettre si width auto
			// max-width: 350px;
			// width: auto;
			// text-align: left; 
			text-transform: none;
			font-size: 16px;
			font-size: 1.6rem;
			padding: 7px 10px 7px 15px;
			text-align: center;
			border: 4px solid #FFF;
			@include transition(all, 0.3s, ease, 0s);


			&:hover,
			&:focus,
			&:active{
				// border-color: $default-hover-color;

				.icon{
					color: $default-hover-color;
				}
			}
		}
		.collection-list{
			z-index: 3;
			display: none;
			text-align: left;
			position: absolute;
			top: 28px;
			// left: -4px;
			border: solid 4px #FFF;
			border-top: none;
			background-color: $default-dark-font-color;
			width: 100%;
			// box-sizing: padding-box;
			li{
				padding: 5px 10px 5px 15px;
				&.collection-active{
					opacity: 0.3;
				}
			}
		}
	}
	/* Arianne recherche avancée */
	&.collections-liste-oeuvre{
		@include arianneBuilder(false,false,true,false,true,true);

		.select-contain{
			position: absolute;
			right: 80px;
			top: 50%;
			margin-top: -16px;
			text-align: left;
			background-color: $default-dark-font-color;

			.icon{
				float: right;
				font-size: 12px;
				font-size: 1.2rem;
				padding-top: 2px;
				position: absolute;
				right: 15px;
			}
		}
		.collection-select{
			width: 240px;
			text-transform: none;
			// font-family: $font-lovelo-black;
			font-size: 16px;
			font-size: 1.6rem;
			padding: 7px 10px 7px 0px;
			text-align: center;
			border: 4px solid #FFF;
			height: 45px;
			@include transition(all, 0.3s, ease, 0s);

			.active{
				display: inline-block;
			}

			&:hover,
			&:focus,
			&:active{
				// border-color: $default-hover-color;

				.icon{
					color: $default-hover-color;
				}
			}
		}
		.collection-list{
			z-index: 3;
			display: none;
			text-align: left;
			position: absolute;
			top: 43px;
			// left: -4px;
			border: solid 4px #FFF;
			border-top: none;
			background-color: $default-dark-font-color;
			width: 240px;
			// box-sizing: padding-box;

			%triangle{
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				width: 0;
				height: 0;
				display: inline-block;
				position: relative;
				top: -3px;
				margin-right: 10px;
			}
			.triangle-up{
				@extend %triangle;
				border-bottom: 5px solid #FFF;
			}
			.triangle-down{
				@extend %triangle;
				border-top: 5px solid #FFF;
			}
			li{
				padding: 5px 10px 10px 30px;
				&:first-child{
					padding-top: 20px;
				}
				&:nth-last-child(2){
					padding-bottom: 20px;
				}
				&.collection-active{
					opacity: 0.3;
				}
				&.advance{
					text-align: center;
					height: 45px;
					border-top: solid 2px #FFF;
					padding-top: 14px;
					padding-left: 10px;
					button{
						display: inline-block;
						@include transition(all, 0.3s, ease, 0s);
						&:hover,
						&:active,
						&:focus{
							color: $default-hover-color;
						}
					}
				}
			}
		}
		.collection-advance{
			position: absolute;
			left: 0;
			top: 100px;
			width: 100%;
			border: solid 4px #FFF;
			background-color: $default-dark-font-color;
			z-index: 3;
			padding: 55px 70px 55px 70px;
			text-align: left;
			display: none;

			.form-group{
				display: inline-block;
				vertical-align: top;

				&.select{
					margin-right: 50px;
					margin-bottom: 35px;

					&:nth-child(3n){
						margin-right: 0;
					}

					.fancy-select{
						border: none; 
						width: 340px;
						.trigger{
							height: 30px;
							padding-top: 9px;
							text-align: center;
							// width: 340px;

							&:after{
								font-size: 13px;
								font-size: 1.3rem;
								padding-top: 8px;
								right: 5px;
							}
						}
						ul.options{
							width: 340px;
							left: 0;
							max-height: 200px;
							top: 30px;
							bottom: auto;

							li{
								padding-top: 10px;
								padding-bottom: 10px;
								&:after{
									display: none;
								}
							}
						}
					}
				}
				&.input{
					position: relative;

					.icon{
						color: $default-dark-font-color;
						position: absolute;
						right: 10px;
						font-size: 17px;
						font-size: 1.7rem;
						top: 6px;
					}

					input{
						width: 340px;
						height: 30px;
						color: $default-dark-font-color;
						padding-left: 25px;
						font-family: $font-atcoverlook-regular;
					}

				}
				
			}
			.buttons{
				width: 100%;
				margin-top: 25px;
			}
			.reset{
				display: inline-block;
				margin-bottom: 0;

			}
			.send{
				display: inline-block;
				float: right;
				margin-bottom: 0;
			}
		}
		.has-advance &{
			.collection-select{
				height: 67px;
				border-bottom: none;
			}
			.select-contain{
				z-index: 5;
			}
		}
	}
	&.oeuvre{
		@include arianneBuilder(false,true,true,false,false,true);
	}
	&.plan-musee{
		@include arianneBuilder(false,false,true,true,false,false);
	}
	&.contact{
		@include arianneBuilder(false,false,true,true,false,true);
	}
	&.presse{
		@include arianneBuilder(false,false,true,true,false,true);
	}
	&.organisez-evenement{
		@include arianneBuilder(false,false,false,false,false,false);
	}
}

.has-advance{
	.page--oeuvre--liste{
		opacity: 0.2;
	}
}

// arianneBuilder($gabarit2,$controls-oeuvre,$title,$picto-title,$select,$uppercase)