/* Mise en gorme page nomenclature à supprimer */
.nomenclature{
	background-color: white;

	h1{
		font-size: 30px;
		font-weight: 600;
	}
	h2{
		font-size: 24px;
		margin-bottom: 40px;

	}
	h3{
		font-weight: 600;
		margin-top: 10px;
	}
	ul, ol{
		list-style-type: disc;
		padding: 20px;
	}
	li{
		margin-bottom: 20px;
		margin-top: 20px;
	}
	em{
		color: blue;
		font-style: italic;
	}
	i{
		font-style: italic;
		font-size: 12px;
		margin-left: 10px;
		display: inline-block;
	}
	font-family: Arial;
	font-size: 12px;
}

.button{
	&--dark{
		@include buttonBuilder(200px, 35px, 'dark', 'basic');
	}
	&--light{
		@include buttonBuilder(200px, 35px, 'light',  'basic');
	}
}
.callToAction{
	&--light{
		@include buttonBuilder(550px, 85px, 'light','callToAction');
	}
	&--dark{
		@include buttonBuilder(550px, 85px, 'dark','callToAction');
	}
	&--textRed{
		@include buttonBuilder(500px, 85px, 'light','callToAction');
		color:$default-hover-color;
	}
	&--picto-archive{
		@include buttonBuilder(550px, 85px, 'dark','callToAction');
		@include buttonPictoBuilder('archive', 18px, 'dark');
	}
	&--picto-agenda{
		@include buttonBuilder(500px, 85px, 'light','callToAction');
		@include buttonPictoBuilder('agenda', 18px, 'dark');
	}
	&--pictoAfter{
		@include buttonBuilder(auto, 30px, 'dark','more');
		@include buttonPictoBuilder('more', 18px, 'dark');
	}
}
.scrollTo{
	&--agenda{
		@include buttonBuilder(420px, 100px, 'dark', 'scrollTo');
		@include buttonPictoBuilder('agenda', 18px, 'dark');
		.svg-picto-agenda{
			top: 30px;
		}
	}
}
.overPicture{
	@include buttonBuilder(600px, 85px, 'light', 'overPicture');
}

.bandeauApp{
	position:relative;
	min-height: 150px;
	width:100%;
	background-color:$default-secondary-color;
	color:$default-light-font-color;
	text-align:center;
	@include transition(all, 0.3s, ease, 0s);
	&:hover,
	&:active,
	&:focus{
		@include transition(all, 0.3s, ease, 0s);
		background-color:$default-light-font-color;
		color:$default-secondary-color;
	}
	&:hover .st0,
	&:hover .st1,
	&:active .st0,
	&:active .st1,
	&:focus .st0,
	&:focus .st1{
		@include transition(all, 0.3s, ease, 0s);
		stroke:$default-secondary-color;
	}
	&:hover .st2,
	&:active .st2,
	&:focus .st2{
		@include transition(all, 0.3s, ease, 0s);
		fill:$default-secondary-color;
	}
	.imgDevices{
		display: inline-block;
		position: absolute;
		top: 35px;
		right: 60%;
		.svg-tablette{
			.st0, .st1, .st2{
				@include transition(all, 0.3s, ease, 0s);
				color:$default-light-font-color;
				&:hover,
				&:active,
				&:focus{
					@include transition(all, 0.3s, ease, 0s);
					color:$default-secondary-color;
				}
			}
		}
	}
	p{
		font-family:$font-novecento-sans-wide-book;
		font-size: 24px; 
		font-size:2.4rem;
		margin-left:42%;
		vertical-align: middle;
		padding-top: 51px;
		text-align:left;
		padding-bottom: 35px;
		&::before{
			content:"";
			height:100%;
			width:300px;
		}
		span{
			font-family:$font-lovelo-black; 
			display:block;
		}
	}
}


@media(min-width: 640px) and (max-width: 1279px){
	.nomenclature{
		.logo2{
			margin-left:21px;
		}
		.blocLogo{
			display:none;
		}
		.buttonMenu{
			display:block;
		}
		.search-icon-mobile{
			display:inline-block;
		}
	}
}
@media(max-width: 639px){
	.nomenclature{

	}
}


@media(min-width: 640px) and (max-width: 1279px){
	.bandeauApp{
		&:hover span,
		&:active span,
		&:focus span{
			border-color:$default-secondary-color;;
		}
		.imgDevices{
			display: block;
			position: relative;
			text-align:center;
			top:0px;
			left:0px;
			padding-top:75px;
			.svg-tablette{
				height:auto;
				width:325px;
				.st0, .st1, .st2{
					@include transition(all, 0.3s, ease, 0s);
					color:$default-light-font-color;
					&:hover,
					&:active,
					&:focus{
						@include transition(all, 0.3s, ease, 0s);
						stroke:$default-secondary-color;
					}
				}
			}
		}
		p{
			position:relative;
			width:100%;
			padding-bottom:265px;
			margin-left:0px;
			text-align:center;
			font-size:37px;
			font-size:3.7rem;
			padding-top: 55px;
			span{
				position:absolute;
				border:4px solid $default-light-font-color;
				line-height: 116px;
				top:150px;
				width: 100%;
				font-size:28px;
				font-size:2.8rem;
				&:hover,
				&:active,
				&:focus{
					border-color:$default-secondary-color;
				}
			}
		}
	}
}
@media(max-width: 639px){
	.bandeauApp{
		&:hover span,
		&:active span,
		&:focus span{
			border-color:$default-secondary-color;;
		}
		.imgDevices{
			display: block;
			position: relative;
			text-align:center;
			top:0px;
			left:0px;
			padding-top:37.5px;
			.svg-tablette{
				height:auto;
				width:162.5px;
				.st0, .st1, .st2{
					@include transition(all, 0.3s, ease, 0s);
					color:$default-light-font-color;
					&:hover,
					&:active,
					&:focus{
						@include transition(all, 0.3s, ease, 0s);
						stroke:$default-secondary-color;
					}
				}
				.st2{
					@include transition(all, 0.3s, ease, 0s);
					color:$default-light-font-color;
					&:hover,
					&:active,
					&:focus{
						@include transition(all, 0.3s, ease, 0s);
						stroke:$default-secondary-color;
					}
				}
			}
		}
		p{
			position:relative;
			width:100%;
			padding-bottom:132.5px;
			margin-left:0px;
			text-align:center;
			font-size:18.5px;
			font-size:1.85rem;
			padding-top: 22.5px;
			span{
				position:absolute;
				border:2px solid $default-light-font-color;
				line-height: 58px;
				top:75px;
				width: 100%;
				font-size:14px;
				font-size:1.4rem;
				&:hover,
				&:active,
				&:focus{
					border-color:$default-secondary-color;
				}
			}
		}
	}
}