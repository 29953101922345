%molecule-audio-player{
    display: flex;
    //width: 655px;
    max-width: 100%;
    margin: 0 auto;
    width: 100%;
 
    audio{
        display: none;
    }

    // Partie droite infos
    .audio-infos{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 20px;
    }

    .audio-title{
        font-weight: 600;
        font-size: 24px;
        font-size: 2.4rem;
        color: #1c1c1c;
        margin-bottom: 10px;
    }
    .audio-artist{
        font-weight: 400;
        font-size: 16px;
        font-size: 1.6rem;
        color: #1C1C1C;
        margin-bottom: 5px;

    }

    // Lecteur audio
    .audio-player{
        display: flex;
        width: 910px;
        max-width: 100%;
        margin-right: 30px;

        // Bouton playpause
        .player-control{
            cursor: pointer;
            margin-right: 80px;
            height: 25px;
            width: 25px;
            transition: all, 0.5s;
            .flexbox{
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .btn-play{
                @extend %icon-play;
            }
            .btn-pause{
                @extend %icon-pause;
                margin: 0 auto;
                display: none;
            }
            .hidden{
                display: none;
            }

            &.playing{
                .btn-play{
                    display: none;
                }
                .btn-pause{
                    display: flex;
                }
            }
            &:hover, &:focus{
                opacity: 0.5;
            }
        }

        // Vue bar + temps
        .player-view{
            flex: 1;
            .player-timing{
                display: flex;  
                justify-content: space-between;
                margin-bottom: 5px;

                .player-time-current, .player-time-total{
                    position: relative;
                    top: 11px;
                    font-size: 11px;
                    font-size: 1.1rem;
                    color: #fff;
                }
                .player-time-current{
                    left: -30px;
                }
                .player-time-total{
                    right: -30px;
                }
            }
            .player-progress-bar{
                height: 2px;
                width: 100%;
                background-color: #fff;
                position: relative;
                border-radius: 5px;
                //overflow: hidden;

                input[type="range"]{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    margin: 0;
                    transform: translateY(-50%);
                    opacity: 0;
                    cursor: pointer;
                    z-index: 10;
                }
                .player-running-bar{
                    background-color: #d83335;
                    position: absolute;
                    top: 0;
                    left: 0; 
                    height: 100%;
                    width: 0%; 
                    &:after{
                        content:'';
                        width: 10px;
                        height: 10px;
                        display: block;
                        background-color: #d83335;
                        position:absolute;
                        top: -4px;
                        right: -5px;
                        border-radius: 5px;
                    }
                }
            }
        }
    }
}

%icon-play{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 13px 0 13px 22px;
    border-color: transparent transparent transparent #fff;
    display: block;

}
%icon-pause{
    justify-content: space-between;
    height: 26px;
    width: 14px;
    &:before, &:after{
        content: "";
        height: 100%;
        width: 4px;
        background-color: #fff;
        display: block;
    }
}