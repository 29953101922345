.class_group_content .page--collections--master{

	.banner--wide{
		padding-bottom: 0;
		@media(max-width: 1279px){
			min-height: 1140px;
		}
		@media(max-width: 639px){
			min-height: 570px;
		}

		.container--wide {
			max-width: 900px;
		}

	}
	.anchor-contain{
		top: 70px;
		@media(max-width: 1279px){
			padding-top: 0 !important;
		}
		@media(max-width: 639px){
			top: 35px;
		}
	}
	// BG sections
	.chef-oeuvre, .musee, .collections{
		background-color: $default-dark-font-color;
	}
	.parcours, .secrets{
		background-color: #FFF;
	}
	.icon.has-scroll.icon-chevron-down{
		color: white;
	}

	.callToAction{
		// margin-top: 45px;
	}
	//Section 1
	section{
		// padding-bottom: 40px;
	}
	.chef-oeuvre{
		// padding-bottom: 40px;
	}
	@media(max-width:1279px){
		.hidden-frise{
			display: none !important;
		}
	}
	.banner--anchor-6{
		.scrollTo--picto{
			// padding-left: 20px;
			padding-right: 30px;
			.subtitle{
				font-family: $font-atcoverlook-regular;
				font-size: 18px;
				font-size: 1.8rem;
				.icon-artips-text{
					font-size: 20px;
					font-size: 2.0rem;
					display: inline-block;
					margin: 0;
					width: auto;					
					position: relative; 
					left: -2px;
					top: -2px; 
				}

				@media(max-width: 639px){
					font-size: 9px;
					font-size: 0.9rem; 
					.icon-artips-text{
						font-size: 12px !important;
						font-size: 1.2rem !important;
						vertical-align: bottom;
					}
				}
			}
		}
	}

	.sliderGabarit{
		.prevPage{
			left: 50px;
			color: white;
		}
		.nextPage{
			right: 50px;
			color: white;
		}
		.pagerSquare{
			right: 50px;
		}
	}
	@media(max-width: 1279px){
		.sliderGabarit{
			.prevPage{
				display: none;
			}
			.nextPage{
				display: none;
			}
			.pagerSquare{
				right: 0;
			}
		}
	}
}