.class_group_content {
  .page--agenda {
    .sliderGabarit {
      .nextPage {
        right: 50px;
        color: #fff;
      }
      .prevPage {
        color: #fff;
        left: 50px;
      }
      .callToAction--light {
        margin-top: 45px;
        max-width: 100%;
        width: 550px;
      }
      .has-scroll {
        position: absolute;
        left: 50%;
        margin-left: -14px;
        bottom: 45px;
        width: 40px;
      }
      .pagerSquare {
        right: 50px;
      }
    }
    .section-agenda {
      background-color: #fff;
    }

    @media (max-width: 1279px) {
      .sliderGabarit {
        .nextPage,
        .prevPage {
          display: none;
        }
        .pagerSquare {
          right: 0px;
        }
      }
    }
    @media (max-width: 639px) {
      .callToAction--light {
        width: 275px;
      }
    }
    .select-contain {
      // position: absolute;
      // right: 80px;
      // top: 50%;
      // margin-top: -16px;
      text-align: left;

      .icon {
        float: right;
        display: inline-block;
        font-size: 16px;
        font-size: 1.6rem;
        margin-bottom: 0;
      }
    }
    .collection-select {
      width: 240px;
      text-transform: none;
      font-family: $font-lovelo-black;
      font-size: 16px;
      font-size: 1.6rem;
      padding: 7px 10px 7px 15px;
      text-align: left;
      border: 4px solid $default-dark-font-color;
      @include transition(all, 0.3s, ease, 0s);

      &:hover,
      &:focus,
      &:active {
        // border-color: $default-hover-color;

        .icon {
          color: $default-hover-color;
        }
      }
    }
    .collection-list {
      z-index: 3;
      display: none;
      text-align: left;
      position: absolute;
      font-family: $font-lovelo-black;
      top: 30px;
      // left: -4px;
      border: solid 4px $default-dark-font-color;
      border-top: none;
      background-color: #fff;
      width: 240px;
      color: $default-dark-font-color;
      // box-sizing: padding-box;
      li {
        padding: 5px 10px 5px 15px;
        font-size: 15px;
        font-size: 1.5rem;
        &.collection-active {
          opacity: 0.3;
        }
        a {
          @include transition(all, 0.3s, ease, 0s);
          &:hover,
          &:focus,
          &:active {
            color: $default-hover-color;
          }
        }
      }
    }
    // .h2-builder {
    //   display: flex;
    //   justify-content: space-between;
    // }
    @media (max-width: 1279px) {
      .h2-builder {
        .innerContent {
          // display: none;
        }
      }
      .select-contain {
        position: relative;
        right: auto;
        top: auto;
        margin-top: 20px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 240px;

        .icon {
          width: 20px;
        }
        .collection-list {
          top: auto;
        }
      }
    }
  }
}
.mac.class_group_content .page--agenda .collection-select .collection span {
  display: inline-block;
  margin-top: 3px;
}
