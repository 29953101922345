.chapo em {
  font-style: italic;
}

.container-1-col-ext.content-atelier {
  min-height: 250px;

  .list_atelier {
    margin-top: 25px;
    margin-bottom: 100px;

    .age {
      text-transform: uppercase;
    }

    .atelier {
      margin-top: 20px;
      margin-left: 20px;

      &.no-cat {
        margin-left: 0;
        margin-top: 40px;
      }

      .contain--learn-more {
        text-align: right;
      }
    }

    .text {
      margin: 15px 0 25px 10px;
    }

    .dates {
      margin-left: 10px;
    }

    .resa {
      display: flex;
      align-items: center;
      margin: 10px 0 30px;

      .full-bar {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        position: relative;
        height: 30px;
        border: 1px solid #D1D3D4;
        border-radius: 5px;
        margin-right: 20px;

        .count {
          position: relative;
          z-index: 2;
          font-style: italic;
        }

        .pourcent {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          background-color: #D1D3D4;
        }
      }

      .contain--learn-more {
        .callToAction--pictoAfter {
          margin-bottom: 0;
        }
      }
    }
  }
}