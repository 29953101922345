html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
}

html {
  line-height: 1;
}

ol, ul { 
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}
q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}
button{
  padding: 0px;
  border-width:0px;
  background-color: transparent;
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    padding-bottom: 0px;
  }
}
button::-moz-focus-inner { 
  border: 0; 
  padding: 0; 
  margin-top:-2px; 
  margin-bottom: -2px; 
}
// Box-sizing
a{
  box-sizing: border-box;
}
// AntiBootstrap
  // .form-control{
  //   color: inherit;
  // }
  .form-horizontal .form-group {
    margin-right: 0px;
    margin-left: 0px;
  }
  .form-group{
    margin-bottom:0px;
  }
  .form-control::-moz-placeholder {
    color: inherit;
    opacity: 1;
  }
  a{
    color: inherit;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus{
      color:inherit;
      text-decoration: none;
    }
  }
  // a,
  // a:active,
  // a:focus,
  // a:hover{
  //   color: inherit;
  //   text-decoration: none;
  // }
  // button,
  // button:active,
  // button:hover,
  // button:focus{
  //   box-shadow: none;
  // }
  // input,
  // input:active,
  // input:focus,
  // input:hover{
  //   box-shadow: none;
  // }
  // button{
  //   border: none;
  // }
  // .form-control:focus,
  // .form-control:hover,
  // .form-control:active{
  //   border: none;
  //   box-shadow: none;
  // }
  ::-webkit-input-placeholder {
    opacity: 1 !important;
}
:-moz-placeholder{ 
    opacity: 1 !important;
}
::-moz-placeholder { 
    opacity: 1 !important;
}
:-ms-input-placeholder {  
   opacity: 1 !important;
}