// * Custom checkboxes
.checkbox-custom{

    input[type="checkbox"]{
        position:absolute;
        left: -99999px;
    }
    // Checkboxes gray border red center
    .label-checkbox{
        padding-left: 25px;
        position: relative;
        line-height: normal;
        display: inline-block;
        &:before, &:after{
            content:'';
            position: absolute;
            top: 3px;
            left: 0px;
            width: 14px;
            height: 14px;
        }
        &:before{
            border: 2px solid #bcbec0;
            background: #fff;
        }   
        &:after{
            font-family:none;
            background: url(../images/icons/check.png) center no-repeat;
            transform: none;
            display:none;
        }                    
    }

    input[type="checkbox"]:checked + .label-checkbox:after{
        display:block;
    }
    input[type="checkbox"]:focus + .label-checkbox:before{
        border-width: 4px;
    }


    // Checkboxes "Switch" 
    .label-switch{
        display: inline-block;
	    padding-left: 50px;
	    // font-size:16px; 
        // color:#fff; 
        // font-family: Arial;
	    line-height:22px;
        height:22px;
        position:relative;
        cursor: pointer;

        &:before{
            content:'';
            background: #fff;
            position: absolute;
            top: 2px;
            left: 2px;
            height: 18px;
            width: 18px;
            border-radius: 2px;
            z-index: -1;
        }

        &:after{
            content:'';
            background: $default-secondary-color;
            position:absolute;
            top:0;
            left:0;
            width:40px;
            height:22px;
            border-radius:3px;
            border-color: $grey-line;
            z-index:-2;
            transition: all 0.5s cubic-bezier(.95, .25, .30, .65);
        }

    }

    input[type=checkbox]:checked ~ .label-switch:before{
        animation: switch 0.5s cubic-bezier(.95, .25, .30, .65) forwards;
    }

    input[type=checkbox]:not(:checked) ~ .label-switch:before{
        animation: switchOff 0.5s cubic-bezier(.95, .25, .30, .65) forwards;
    }

    input[type=checkbox]:checked ~ .label-switch:after {
    background: $default-green-color;}

    @-webkit-keyframes switch{
        50%  {width:22px;}
        100% {width:18px; transform: translate3D(18px, 0, 0);}
    }

    @keyframes switch{
        50% {width:22px;}
        100% {width:18px; transform: translate3D(18px, 0, 0);}
    }

    @-webkit-keyframes switchOff{
        0% {transform: translate3D(18px, 0, 0);}
        50%  {width:22px;}
        100% {width:18px;transform: translate3D(0, 0, 0);}
    }

    @keyframes switchOff{
        0% {transform: translate3D(18px, 0, 0);}
        50% {width:22px;}
        100% {width:18px;transform: translate3D(0, 0, 0);}
    }

}

// * Custom radio inputs
.radio-custom{
    .radio{
        margin:0 20px 20px 0;
        padding-top: 0;
        display: inline-block;
        vertical-align: middle;
        position:relative;
        &:nth-last-of-type(1){
            margin-right:0px;
        } 
        [type="radio"]:not(:checked),
        [type="radio"]:checked {
            position:absolute;
            left: -99999px;
        }
        label{
            display:inline-block;
            margin: 0;
            min-height: auto;
            padding: 0;
            padding-left:20px;
            color:#000;
            font-family: "atc_overlookregular";
            &:before, &:after{
                content: "";
                position: absolute;
                left: 0px; top: 2px;
                width: 13px;
                height: 13px;
                border-radius:50%;
            }
            &:before{
                border: 3px solid #bcbec0;
                background: transparent;
                z-index:2;
            }
        }
        [type="radio"]:focus + label{
            color:#d8383f;
        }
        [type="radio"]:checked + label{
            &:after{
                background: #d8383f;
                z-index:1;
            }
        }
    }
}

//* Custom select boxes
.select-custom{
    position:relative;
    display: inline-block;
    &:after{
        width: 35px;
        height: auto;
        display: block;
        padding-top: 1px;
        padding-left: 10px;
        content: "\e93d";
        font-family: "pba";
        font-size: 18px;
        font-size: 1.8rem;
        position: absolute;
        right: 20px;
        top: 16px;
        color: #BCBEC0;
        z-index:1;
    }

    select{
        background:transparent;
        position:relative;
        z-index:2;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding-right:0 !important;

        // MS pseudo class - remove dropdown arrow
        &::-ms-expand{
            display: none;
        }

        option{
            font-size:18px;
            &:disabled{
                color:#ddd;
            }
        }
    }
}