.infos {
    background-color: #fff;
    .bg-image {
        height: 510px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        box-shadow: 0px 0px 0px 1000px rgba(0, 0, 0, 0.2) inset;
        overflow: auto;
    }
    .container {
        width: 800px;
        height: 335px;
        margin: 0px auto;
        vertical-align: middle;
        position: relative;
        margin-top: 85px;
        background-size: 102% auto;
        background-position: -9px 79px;
    }
    .deco-left {
        position: absolute;
        left: 0px;
        top: 0px;
        border-left: 16px solid $default-light-font-color;
        border-top: 16px solid $default-light-font-color;
        border-bottom: 16px solid $default-light-font-color;
        height: 100%;
        width: 30px;
    }
    .deco-right {
        position: absolute;
        right: 0px;
        top: 0px;
        border-right: 16px solid $default-light-font-color;
        border-top: 16px solid $default-light-font-color;
        border-bottom: 16px solid $default-light-font-color;
        height: 100%;
        width: 30px;
    }
    .inner-container {
        font-family: $font-novecento-sans-wide-bold;
        color: $default-light-font-color;
        font-size: 21px;
        font-size: 2.1rem;
        text-align: center;
        .picto-vert {
            width: 50px;
            height: 50px;
            margin: 0px auto;
            margin-top: 110px;
            margin-bottom: 40px;
        }
        p {
            margin-bottom: 30px;
            letter-spacing: 0.07em;
        }
        .bloc-green {
            //background-color:$default-green-color;
            border: 4px solid $default-light-font-color;
            color: $default-light-font-color;
            font-family: $font-lovelo-black;
            font-size: 24px;
            font-size: 2.4rem;
            width: auto;
            margin-left: 90px;
            margin-right: 90px;
            height: 62px;
            padding-top: 6px;
            //padding-top: 15px;
            //display: none;
            vertical-align: middle;

            #compte_a_rebours {
                font-size: 2rem;
            }

            .vAlign {
                &:before {
                    content: '';
                    display: inline-block;
                    height: 100%;
                    vertical-align: middle;
                    margin-right: -0.25em;
                }
            }
            .numbers {
                font-family: $font-novecento-wide-bold;
                font-size: 22px;
                font-size: 2.2rem;
            }
            .letters {
                font-family: $font-novecento-widenormal;
                font-size: 22px;
                font-size: 2.2rem;
            }
        }
        .ferme {
            background-color: $default-hover-color;
            .museeOuvert {
                display: none;
            }
            .museeFerme {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
            }
        }
        .ouvert {
            background-color: $default-green-color;
            .museeOuvert {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
            }
            .museeFerme {
                display: none;
            }
        }
    }
    .main-container-info {
        width: 1280px;
        margin: 0 auto;
        padding: 90px 150px;
        a {
            color: $default-hover-color;
        }
        .container-info {
            width: 440px;
            min-height: 130px;
            vertical-align: top;
            .icon {
                display: inline-block;
                width: 85px;
                vertical-align: top;
                font-size: 40px;
                font-size: 4.0rem;
            }
            .icon-clock {
                font-size: 47px;
                font-size: 4.7rem;
            }
            .icon-marker {
                font-size: 54px; 
                font-size: 5.4rem;
            }
            .icon-credit-card {
                font-size: 37px;
                font-size: 3.7rem;
            }
            .icon-ticket {
                font-size: 35px;
                font-size: 3.5rem;
            }
            .icon-pmr {
                font-size: 50px;
                font-size: 5rem;
            }
            .icon-parcours {
                font-size: 45px;
                font-size: 4.5rem;
            }
            .icon-mobile-pba {
                font-size: 60px;
                font-size: 6rem;
            }
            .icon-plan {
                font-size: 42px;
                font-size: 4.2rem;
            }
            .icon-bed {
                font-size: 38px;
                font-size: 3.8rem;
            }
            .icon-bed {
                font-size: 38px;
                font-size: 3.8rem;
            }
            .icon-team {
                font-size: 61px;
                font-size: 6.1rem;
            }
            .icon-send {
                font-size: 37px;
                font-size: 3.7rem;
            }
            .icon-share-circled {
                font-size: 47px;
                font-size: 4.7rem;
            }
            .icon-clock {
                font-size: 47px;
                font-size: 4.7rem;
            }
            .inner-right {
                display: inline-block;
                width: 350px;
            }
            p {
                font-family: $font-opensans-light;
                font-size: 15px;
                font-size: 1.5rem;
                margin-bottom: 15px;
                .bold {
                    font-family: $font-opensans-bold;
                    font-size: 15px;
                    font-size: 1.5rem;
                }
                .uppercase {
                    text-transform: uppercase;
                }
            }
            a {
                &:hover,
                &:active,
                &:focus {
                    text-decoration: underline;
                    color: $default-hover-color;
                }
            }
            .socials {
                width: 100%;
                .icon {
                    font-size: 31px;
                    font-size: 3.1rem;
                    color: $default-hover-color;
                }
                .icon-instagram {
                    font-size: 24px;
                    font-size: 2.4rem;
                    text-align: left;
                    margin-left: 4px;
                    margin-top: 4px;
                }
                div {
                    display: inline-block;
                    width: 40px;
                }
            }
            h2 {
                font-family: $font-lovelo-black;
                font-size: 21px;
                font-size: 2.1rem;
                margin-top: 15px;
                margin-bottom: 15px;
                margin-left: 4px;
            }
            span.info-detail {
                display: block;
                color: $default-hover-color;
                font-family: $font-opensans-bold;
                font-size: 15px;
                font-size: 1.5rem;
            }
            .detail {
                margin-top: 25px;
            }
        }
        .line {
            margin-bottom: 50px;
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
    .headband-pba {
        width: 100%;
        background-color: $default-hover-color;
        height: 160px;
        .blocLogo {
            vertical-align: middle;
        }
        .logo {
            width: 100%;
            padding-top: 46px;
            padding-bottom: 46px;
            .logo-content {
                width: auto;
                display: block;
                color: white;
                font-size: 30px;
                font-size: 3.0rem;
                letter-spacing: 0.13em;
                text-align: center;
                margin-bottom: 15px;
                @include transition(all, 0.3s, ease, 0s);
                span {
                    &.need-mr {
                        margin-right: 12px;
                    }
                    &.need-tiret {
                        margin-right: 20px;
                        position: relative;
                        &:after {
                            display: inline-block;
                            content: '';
                            width: 10px;
                            height: 1.5px;
                            background-color: #FFF;
                            position: absolute;
                            top: 50%;
                            right: -12px;
                            @include transition(all, 0.3s, ease, 0s);
                        }
                    }
                }
            }
        }
        p {
            font-family: $font-novecento-sans-wide-bold;
            color: $default-light-font-color;
            font-size: 21px;
            font-size: 2.1rem;
            text-align: center;
            letter-spacing: 0.07em;
        }
    }
    .bg-plan-triptyque {
        background-color: #FBFBFB;
        width: 100%;
    }
    .plan-triptyque {
        height: 500px;
        width: 1280px;
        margin: 0px auto;
    }
}

@media (min-width: 640px) and (max-width: 1279px) {
    .infos {
        background-color: #fff;
        .bg-image {
            height: 510px;
            width: 100%;
            background-position: center center;
            background-size: cover;
        }
        .container {
            background-image: url("") !important;
            width: 100%;
            height: 420px;
            margin: 0px auto;
            vertical-align: middle;
            position: relative;
            padding-top: 105px;
            padding-left: 0;
            padding-right: 0;
            background-size: 102% auto;
            background-position: -9px 79px;
        }
        .inner-container {
            font-family: $font-novecento-sans-wide-bold;
            color: $default-light-font-color;
            font-size: 31px;
            font-size: 3.1rem;
            text-align: center;
            .picto-vert {
                width: 64px;
                height: 74px;
                margin: 0px auto;
                margin-top: 0px;
                margin-bottom: 40px;
            }
            .bloc-green {
                margin: 0 auto;
                border: medium none #FFF;
                //background-color:$default-green-color;
                width: 580px;            
            }
            .ferme {
                background-color: $default-hover-color !important;
                .museeOuvert {
                    display: none;
                }
                .museeFerme {
                    display: inline-block;
                }
            }
            .ouvert {
                background-color: $default-green-color;
                .museeOuvert {
                    display: inline-block;
                }
                .museeFerme {
                    display: none;
                }
            }
            p {
                width: 420px;
                margin: 0 auto;
                margin-bottom: 85px;
            }
        }
        .main-container-info {
            width: 100%;
            margin: 0 auto;
            padding: 70px 55px;
            .line {
                margin-bottom: 0px;
            }
            .container-info {
                width: 100%;
                min-height: 130px;
                vertical-align: top;
                .icon {
                    display: block;
                    text-align: center;
                    width: 100%;
                    margin-bottom: 45px;
                }
                .inner-right {
                    display: inline-block;
                    width: 350px;
                }
                .inner-right {
                    width: 100%;
                    margin-bottom: 85px;
                    h2 {
                        text-align: center;
                        font-size: 36px;
                        font-size: 3.6rem;
                        margin-top: 0px;
                    }
                    p {
                        font-size: 26px;
                        font-size: 2.6rem;
                        .bold {
                            font-size: 26px;
                            font-size: 2.6rem;
                        }
                    }
                }
                span.info-detail {
                    font-size: 26px;
                    font-size: 2.6rem;
                    text-align: center;
                    border: 3px solid $default-dark-font-color;
                    padding: 12px 28px;
                    width: 90%;
                    margin: 0 auto;
                    margin-top: 20px;
                }
                a {
                    &:hover,
                    &:active,
                    &:focus {
                        text-decoration: none;
                        color: $default-hover-color;
                        border-color: $default-hover-color;
                    }
                    @include transition(all, 0.3s, ease, 0s);
                    &:hover .info-detail,
                    &:active .info-detail,
                    &:focus .info-detail {
                        text-decoration: none;
                        color: $default-hover-color;
                        border-color: $default-hover-color;
                        @include transition(all, 0.3s, ease, 0s);
                    }
                }
                .socials {
                    text-align: justify;
                    -ms-text-justify: distribute-all-lines;
                    text-justify: distribute-all-lines;
                    font-size: 0px;
                    line-height: 0;
                    & > * {
                        display: inline-block;
                    }
                    &::after {
                        content: "";
                        width: 100%;
                        display: inline-block;
                    }
                    .icon {
                        font-size: 60px;
                        font-size: 6rem;
                    }
                }
            }
        }
        .headband-pba {
            display: none;
        }
        .plan-triptyque {
            display: none;
        }
    }
}

@media (max-width: 639px) {
    .infos {
        background-color: #fff;
        .bg-image {
            height: 255px;
            width: 100%;
            background-position: center center;
            background-size: cover;
        }
        .container {
            background-image: url("") !important;
            width: 100%;
            height: 210px;
            margin: 0px auto;
            vertical-align: middle;
            position: relative;
            padding-top: 52.5px;
            padding-left: 0;
            padding-right: 0;
            background-size: 102% auto;
            background-position: -4.5px 39.5px;
        }
        .inner-container {
            font-family: $font-novecento-sans-wide-bold;
            color: $default-light-font-color;
            font-size: 15.5px;
            font-size: 1.55rem;
            text-align: center;
            .picto-vert {
                width: 32px;
                height: 37px;
                margin: 0px auto;
                margin-top: 0px;
                margin-bottom: 20px;
            }
            .bloc-green {
                margin: 0 auto;
                border: medium none #FFF;
                //background-color:$default-green-color;
                width: 290px;
                //padding-top: 10px;
                font-size: 12px;
                font-size: 1.2rem;
                height: 31px;
            }
            .ferme {
                background-color: $default-hover-color !important;
                .museeOuvert {
                    display: none;
                }
                .museeFerme {
                    display: inline-block;
                }
            }
            .ouvert {
                background-color: $default-green-color;
                .museeOuvert {
                    display: inline-block;
                }
                .museeFerme {
                    display: none;
                }
            }
            p {
                width: 210px;
                margin: 0 auto;
                margin-bottom: 42.5px;
            }
        }
        .main-container-info {
            width: 100%;
            margin: 0 auto;
            padding: 35px 27.5px;
            .line {
                margin-bottom: 0px;
            }
            .container-info {
                width: 100%;
                min-height: 65px;
                vertical-align: top;
                .icon {
                    display: block;
                    text-align: center;
                    width: 100%;
                    margin-bottom: 22.5px;
                }
                .icon-clock {
                    font-size: 23.5px;
                    font-size: 2.35rem;
                }
                .icon-marker {
                    font-size: 27px;
                    font-size: 2.7rem;
                }
                .icon-credit-card {
                    font-size: 18.5px;
                    font-size: 1.85rem;
                }
                .icon-ticket {
                    font-size: 17.5px;
                    font-size: 1.75rem;
                }
                .icon-pmr {
                    font-size: 25px;
                    font-size: 2.5rem;
                }
                .icon-parcours {
                    font-size: 22.5px;
                    font-size: 2.25rem;
                }
                .icon-mobile-pba {
                    font-size: 30px;
                    font-size: 3rem;
                }
                .icon-plan {
                    font-size: 21px;
                    font-size: 2.1rem;
                }
                .icon-bed {
                    font-size: 19px;
                    font-size: 1.9rem;
                }
                .icon-bed {
                    font-size: 19px;
                    font-size: 1.9rem;
                }
                .icon-team {
                    font-size: 30.5px;
                    font-size: 3.05rem;
                }
                .icon-send {
                    font-size: 18.5px;
                    font-size: 1.85rem;
                }
                .icon-share-circled {
                    font-size: 23.5px;
                    font-size: 2.35rem;
                }
                .icon-clock {
                    font-size: 23.5px;
                    font-size: 2.35rem;
                }
                .inner-right {
                    width: 100%;
                    margin-bottom: 42.5px;
                    h2 {
                        text-align: center;
                        font-size: 18px;
                        font-size: 1.8rem;
                        margin-top: 0px;
                    }
                    p {
                        font-size: 13px;
                        font-size: 1.3rem;
                        .bold {
                            font-size: 13px;
                            font-size: 1.3rem;
                        }
                    }
                }
                span.info-detail {
                    font-size: 13px;
                    font-size: 1.3rem;
                    text-align: center;
                    border: 1.5px solid $default-dark-font-color;
                    padding: 6px 14px;
                    width: 90%;
                    margin: 0 auto;
                    margin-top: 10px;
                }
                a {
                    &:hover,
                    &:active,
                    &:focus {
                        text-decoration: none;
                        color: $default-hover-color;
                        border-color: $default-hover-color;
                    }
                    @include transition(all, 0.3s, ease, 0s);
                    &:hover .info-detail,
                    &:active .info-detail,
                    &:focus .info-detail {
                        text-decoration: none;
                        color: $default-hover-color;
                        border-color: $default-hover-color;
                        @include transition(all, 0.3s, ease, 0s);
                    }
                }
                .socials {
                    text-align: justify;
                    -ms-text-justify: distribute-all-lines;
                    text-justify: distribute-all-lines;
                    font-size: 0px;
                    line-height: 0;
                    & > * {
                        display: inline-block;
                    }
                    &::after {
                        content: "";
                        width: 100%;
                        display: inline-block;
                    }
                    .icon {
                        font-size: 30px;
                        font-size: 3rem;
                    }
                }
            }
        }
        .headband-pba {
            display: none;
        }
        .plan-triptyque {
            display: none;
        }
    }
}