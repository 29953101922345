.presse-content {
  background-color: #fff;
  overflow: auto;
  .bg-image {
    height: 387px;
    //background:linear-gradient(rgba(44, 44, 46, 0.5), rgba(44, 44, 46, 0.5));
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 55px;
    .btn-presse {
      text-align: center;
      width: 100%;
    }
    a {
      display: block;
      height: 87px;
      width: 600px;
      background-color: $default-light-font-color;
      font-family: $font-biko-bold;
      font-size: 24px;
      font-size: 2.4rem;
    }
    .valign {
      vertical-align: middle;
      height: 100%;
      padding-top: 89px;
    }
    div.overPicture {
      border: solid 3px;
      text-align: center;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
      cursor: auto;
      padding: 0 10px;
      -webkit-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      min-height: 85px;
      width: 600px;
      border-color: #fff;
      background-color: #fff;
      color: #1d1d1e;
      font-family: "bikobold";
      font-size: 24px;
      margin-bottom: 33px;
    }
    a.overPicture {
      @include buttonBuilder(600px, 85px, "light", "overPicture");
      margin-bottom: 0;
      // &:last-child{
      // 	margin-bottom:0px;
      // }
      // &.tel{
      // 	&:hover, &:focus, &:active{
      // 		color: #1D1D1E !important;
      // 		background-color: #FFF !important;
      // 		border-color: #FFF !important;
      // 	}
      // }
    }
  }
  .download-presse {
    .press-folder {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 1220px;
      margin: 0 auto;
      max-width: calc(100% - 80px);

      .dossier {
        width: 270px;
        height: 240px;
        border: solid 2px #7b7c7e;
        text-align: center;
        @include border-radius(10px);
        font-family: $font-biko-bold;
        font-size: 18px;
        font-size: 1.8rem;
        vertical-align: top;
        margin-right: 34px;
        @include transition(all, 0.3s, ease, 0s);
        margin-bottom: 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem 1rem 2rem 1rem;

        .title {
          margin-bottom: 0;
          height: 54px;
          margin-top: auto;
        }
        .date {
			margin-top: 1.5rem;
          	margin-bottom: 0;
        }

        &:last-child {
          // margin-right: 0px;
        }
        .icon {
          font-size: 34px;
          font-size: 3.4rem;
          margin-top: auto;
        }

        &:hover,
        &:focus,
        &:active {
          color: #fff;
          background-color: $main-bright-grey;
        }
        @media (min-width: 1262px) {
          &:nth-child(4),
          &:nth-child(9),
          &:nth-child(8) {
            // margin-right: 0;
          }
        }

        &.hasImage {
          .dossier-image {
            width: 100px;
            height: 100px;
            margin-bottom: 0.25rem;

            > img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
      @media (max-width: 1261px) and (min-width: 958px) {
        .dossier {
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:nth-child(n + 3) {
            // display: none;
          }
        }
      }
      @media (max-width: 687px) {
        max-width: calc(100% - 40px);
        .dossier {
          margin-right: auto;
          margin-left: auto;
          // margin-bottom: 0px;

          &:nth-child(n + 3) {
            // display: none;
          }
          &:nth-child(n + 2) {
            // display: none;
          }
        }
      }
    }
    p {
      margin-bottom: 45px;
    }
    i {
      font-size: 50px;
      font-size: 5rem;
      margin-right: 120px;
      margin-left: -120px;
    }
    .download--wide-4 {
      margin-bottom: 40px;
    }
    .buttonCall {
      margin-bottom: 100px;
    }
  }
  .miniTitre {
    font-family: $font-biko-bold;
    font-size: 24px;
    font-size: 2.4rem;
    vertical-align: middle;
  }
  .col-left,
  .col-right {
    width: 425px;
    vertical-align: top;
  }
  .acces-phototheque {
    padding-bottom: 140px;
    p.center-line {
      margin-bottom: 65px;
    }
    .icon-mountain {
      font-size: 41px;
      font-size: 4.1rem;
      margin-left: -45px;
      margin-right: 45px;
    }
    .mBottom {
      margin-bottom: 30px;
    }
    input {
      height: 65px;
      width: 100%;
      margin-bottom: 10px;
      font-family: $font-atcoverlook-regular;
      font-size: 24px;
      font-size: 2.4rem;
      border: 4px solid $lighter-bright-grey;
      color: $lighter-bright-grey;
      padding-left: 30px;
      text-align: center;
    }
    .form-group {
      &:last-child {
        margin-bottom: 19px;
      }
    }
    button,
    .callToAction--dark {
      border: 4px solid $lighter-dark-font-color;
      color: $lighter-dark-font-color;
      height: 85px;
      width: 100%;
      font-family: $font-lovelo-black;
      font-size: 24px;
      font-size: 2.4rem;
      text-transform: uppercase;
      //margin-bottom:30px;
      @include transition(all, 0.3s, ease, 0s);
      &:hover,
      &:active,
      &:focus {
        color: $default-hover-color;
        border-color: $default-hover-color;
        @include transition(all, 0.3s, ease, 0s);
      }
    }
    p {
      text-align: center;
      font-family: $font-opensans-light;
      color: $lighter-dark-font-color;
      font-size: 16px;
      font-size: 1.6rem;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 50px;
      }
    }
    .fyi p {
      color: $default-hover-color;
      margin-bottom: 10px;
      text-align: left;
    }
    .sous-titre {
      font-family: $font-biko-bold;
      font-size: 24px;
      font-size: 2.4rem;
      margin-bottom: 30px;
      color: $default-dark-font-color;
    }
    .passwordLost {
      margin-top: 10px;
      margin-bottom: 0px;
    }
    .inner-container {
      width: 430px;
      margin: 0 auto;
      p {
        text-align: left;
      }
    }
    .form1 a {
      //text-align:center;
      //font-family: $font-opensans-light;
      //font-size: 16px;
      //font-size: 1.6rem;
      //color:$default-dark-font-color;
      text-decoration: underline;
      &:hover,
      &:active,
      &:focus {
        color: $default-hover-color;
      }
    }
  }
  .container--phototheque {
    width: 1180px;
    margin: 0 auto;
    p.center-line {
      margin-bottom: 65px;
    }
    p {
      text-align: center;
      font-family: $font-opensans-light;
      color: $lighter-dark-font-color;
      font-size: 16px;
      font-size: 1.6rem;
    }
    .icon-mountain {
      font-size: 41px;
      font-size: 4.1rem;
      margin-left: -45px;
      margin-right: 45px;
    }
  }
  .phototheque {
    a:not(.callToAction--dark) {
      @include transition(all, 0.3s, ease, 0s);
      display: inline-block;
      width: 25%;
      padding: 0 12px;
      &:hover .nommagePhoto,
      &:focus .nommagePhoto,
      &:active .nommagePhoto {
        color: $default-hover-color;
        @include transition(all, 0.3s, ease, 0s);
      }
      vertical-align: top;
      @media (max-width: 1279px) {
        width: 50%;
      }
      @media (max-width: 639px) {
        width: 100%;
      }
    }
    .line {
      text-align: center;
    }
    .blocPhoto {
      margin-bottom: 20px;
      .thumbnail-presse {
        margin-bottom: 13px;
        width: 270px;
        height: 240px;
        border-radius: 5px;
        background-size: cover;
        background-repeat: no-repeat;
      }
      .nommagePhoto {
        font-family: $font-biko-bold;
        font-size: 18px;
        font-size: 1.8rem;
        color: $default-dark-font-color;
        display: inline-block;
        width: 200px;
      }
      p {
        margin-bottom: 0px;
      }
    }
    .line {
      &:last-child {
        margin-bottom: 20px;
      }
    }
    .buttonCall {
      padding-bottom: 45px;
      .callToAction--dark {
        margin-bottom: 0px;
      }
    }
  }
}

@media (min-width: 640px) and (max-width: 1279px) {
  .presse-content {
    .mBottom {
      margin-bottom: 55px;
    }
    .bg-image {
      height: 387px;
      margin-bottom: 55px;
      background: none;
      background-image: none !important;
      .btn-presse {
        text-align: center;
        width: 100%;
      }
      a {
        display: block;
        height: 87px;
        width: 600px;
        background-color: $default-light-font-color;
        font-family: $font-biko-bold;
        font-size: 24px;
        font-size: 2.4rem;
      }
      .valign {
        height: 100%;
        padding-top: 89px;
      }
      .overPicture {
        @include buttonBuilder(600px, 85px, "light", "overPicture");
        margin-bottom: 33px;
        border: 4px solid $default-dark-font-color;
        background-color: transparent;
        font-size: 36px;
        font-size: 3.6rem;
        text-transform: unset;
        width: 525px;
        margin-right: auto;
        margin-left: auto;
      }
    }
    .download-presse {
      p {
        margin-bottom: 45px;
      }
      i {
        font-size: 75px;
        font-size: 7.5rem;
        display: block;
        margin: 125px auto 30px;
      }
      .download--wide-4 {
        margin-bottom: 40px;
      }
      .buttonCall {
        margin-bottom: 100px;
        margin-left: auto;
        margin-right: auto;
        width: 525px;
        .callToAction--dark {
          text-transform: unset !important;
        }
      }
    }
    .miniTitre {
      font-size: 36px;
      font-size: 3.6rem;
      vertical-align: middle;
    }
    .col-left,
    .col-right {
      width: 425px;
      vertical-align: top;
      display: block;
      margin: 0 auto;
    }
    .acces-phototheque {
      padding-bottom: 0px;
      p.center-line {
        margin-bottom: 65px;
      }
      .icon-mountain {
        font-size: 65px;
        font-size: 6.5rem;
        display: block;
        margin: 125px auto 30px;
      }
      .mBottom {
        margin-bottom: 30px;
      }
      input {
        height: 65px;
        width: 100%;
        margin-bottom: 10px;
        font-size: 20px;
        font-size: 2rem;
        border: 4px solid $lighter-bright-grey;
        color: $lighter-bright-grey;
        padding-left: 30px;
        text-align: center;
      }
      button,
      .callToAction--dark {
        font-size: 20px;
        padding-top: 0px;
      } /*
			p{
				text-align:center;
				font-family:$font-opensans-light;
				color:$lighter-dark-font-color;
				font-size: 16px;
				font-size:1.6rem;
				margin-bottom:20px;
				&:last-child{
					margin-bottom:50px;
				}
			}
			.fyi p{
				color: $default-hover-color;
				margin-bottom: 10px;
				text-align: left;
			}
			.sous-titre{
				font-family:$font-biko-bold;
				font-size: 24px;
				font-size: 2.4rem;
				margin-bottom:30px;
				color:$default-dark-font-color;
			}
			.passwordLost{
				margin-top: 10px;
				margin-bottom:0px;
			}*/
      .inner-container {
        width: 100%;
        margin: 0 auto;
        p {
          text-align: left;
        }
      } /*
			.form1 a{
				//text-align:center;
				//font-family: $font-opensans-light;
				//font-size: 16px;
				//font-size: 1.6rem;
				//color:$default-dark-font-color;
				text-decoration: underline;
				&:hover,
				&:active,
				&:focus{
					color:$default-hover-color;
				}
			}*/
    }
    .container--phototheque {
      width: 100%;
      margin: 0 auto;
      p.center-line {
        margin-bottom: 65px;
      }
      p {
        text-align: center;
        font-family: $font-opensans-light;
        color: $lighter-dark-font-color;
        font-size: 16px;
        font-size: 1.6rem;
      }
      .icon-mountain {
        font-size: 65px;
        font-size: 6.5rem;
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-bottom: 35px;
      }
    }
    .phototheque {
      a {
        width: 50%;
        vertical-align: top;
        @include transition(all, 0.3s, ease, 0s);
        &:hover .nommagePhoto,
        &:focus .nommagePhoto,
        &:active .nommagePhoto {
          color: $default-hover-color;
          @include transition(all, 0.3s, ease, 0s);
        }
      }
      .blocPhoto {
        margin-bottom: 20px;
        .thumbnail-presse {
          //margin-bottom:13px;
          width: 270px;
          height: 240px;
          border-radius: 5px;
          background-size: cover;
          background-repeat: no-repeat;
          margin: 0 auto 13px;
        }
        .nommagePhoto {
          font-family: $font-biko-bold;
          font-size: 18px;
          font-size: 1.8rem;
          color: $default-dark-font-color;
        }
        p {
          margin-bottom: 0px;
        }
      }
      .line {
        &:last-child {
          margin-bottom: 20px;
        }
      }
      .buttonCall {
        padding-bottom: 45px;
        .callToAction--dark {
          width: 575px;
          margin-bottom: 0px;
        }
      }
    }
  }
}

@media (max-width: 639px) {
  .presse-content {
    .mBottom {
      margin-bottom: 22.5px;
    }
    .bg-image {
      height: 250px;
      margin-bottom: 22.5px;
      background: none;
      background-image: none !important;
      .btn-presse {
        text-align: center;
        width: 100%;
      }
      a {
        display: block;
        height: 43.5px;
        width: 300px;
        background-color: $default-light-font-color;
        font-family: $font-biko-bold;
        font-size: 12px;
        font-size: 1.2rem;
      }
      .valign {
        height: 100%;
        padding-top: 44.5px;
      }
      .overPicture {
        @include buttonBuilder(600px, 42.5px, "light", "overPicture");
        margin-bottom: 16.5px;
        border: 3px solid $default-dark-font-color;
        background-color: transparent;
        font-size: 18px;
        font-size: 1.8rem;
        text-transform: unset;
        width: 262.5px;
        margin-right: auto;
        margin-left: auto;
      }
    }
    .download-presse {
      p {
        margin-bottom: 22.5px;
      }
      i {
        font-size: 37.5px;
        font-size: 3.75rem;
        display: block;
        margin: 62.5px auto 15px;
      }
      .download--wide-4 {
        margin-bottom: 20px;
      }
      .buttonCall {
        margin-bottom: 50px;
        margin-left: auto;
        margin-right: auto;
        width: 262.5px;
        .callToAction--dark {
          text-transform: unset !important;
          height: 42.5px;
          padding-top: 10px;
        }
      }
    }
    .miniTitre {
      font-size: 18px;
      font-size: 1.8rem;
      vertical-align: middle;
    }
    .col-left,
    .col-right {
      width: 100%;
      vertical-align: top;
      display: block;
      margin: 0 auto;
    }
    .acces-phototheque {
      padding-bottom: 0px;
      p.center-line {
        margin-bottom: 32.5px;
      }
      p:last-child {
        margin-bottom: 20px;
      }
      .icon-mountain {
        font-size: 32.5px;
        font-size: 3.25rem;
        display: block;
        margin: 62.5px auto 15px;
      }
      .mBottom {
        margin-bottom: 15px;
      }

      input {
        height: 65px;
        width: 100%;
        margin-bottom: 10px;
        font-size: 20px;
        font-size: 2rem;
        border: 4px solid $lighter-bright-grey;
        color: $lighter-bright-grey;
        padding-left: 30px;
        text-align: center;
      }
      button,
      .callToAction--dark {
        border: 3px solid $lighter-dark-font-color;
        height: 42.5px;
        width: 100%;
        font-size: 12px;
        font-size: 1.2rem;
      }
      .col-right {
        .callToAction--dark {
          padding-top: 10px;
        }
      }
      .inner-container {
        width: 100%;
        margin: 0 auto;
        p {
          text-align: left;
        }
      }
    }
    .container--phototheque {
      width: 100%;
      margin: 0 auto;
      p.center-line {
        margin-bottom: 65px;
      }
      p {
        text-align: center;
        font-family: $font-opensans-light;
        color: $lighter-dark-font-color;
        font-size: 16px;
        font-size: 1.6rem;
      }
      .icon-mountain {
        font-size: 32.5px;
        font-size: 3.25rem;
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-bottom: 17.5px;
      }
    }
    .phototheque {
      a {
        width: 100%;
        @include transition(all, 0.3s, ease, 0s);
        &:hover .nommagePhoto,
        &:focus .nommagePhoto,
        &:active .nommagePhoto {
          color: $default-hover-color;
          @include transition(all, 0.3s, ease, 0s);
        }
      }
      .blocPhoto {
        margin-bottom: 20px;
        .thumbnail-presse {
          width: 270px;
          height: 240px;
          border-radius: 5px;
          background-size: cover;
          background-repeat: no-repeat;
          margin: 0 auto 13px;
        }
        .nommagePhoto {
          font-family: $font-biko-bold;
          font-size: 18px;
          font-size: 1.8rem;
          color: $default-dark-font-color;
        }
        p {
          margin-bottom: 0px;
        }
      }
      .line {
        &:last-child {
          margin-bottom: 20px;
        }
      }
      .buttonCall {
        padding-bottom: 45px;
        .callToAction--dark {
          width: 90%;
          margin-bottom: 0px;
          height: 42.5px;
          padding-top: 10px;
        }
      }
    }
  }
}

.white-board {
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 20px;
}
.presse-full {
  display: block;
  margin: 0 auto;
  text-align: center;

  img {
    max-width: 100%;
  }

  figcaption {
    padding: 20px;
    font-family: $font-biko-bold;
    font-size: 18px;
    letter-spacing: 0.025em;
  }
}
