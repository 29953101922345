/* 
@media(max-width: 1279px){

}
@media(max-width: 1279px) and (min-width: 640px){

}
@media(max-width: 639px){

}
 */

// Constructeur
@mixin galleryBuilder($nombre-vignette,$encart,$type){

	@media(max-width: 639px){
		margin-bottom: 22.5px;
	}
	// Général
	.vignette{
		width: 100%/$nombre-vignette;
		background-position: center center;
		background-size: cover;
		padding-bottom: 150%/$nombre-vignette;
		display: inline-block;
		position: relative;
		vertical-align: top;
		@include transition(all, 0.3s, ease, 0s);
		color: $default-dark-font-color;

		.title{
			text-transform: uppercase;
			font-family: $font-lovelo-black;
			font-size: 27px;
			font-size: 2.7rem;
		}
		.author{
			font-family: $font-biko-bold;
			font-size: 18px;
			font-size: 1.8rem;
			margin-top: 10px;
		}

		@media(max-width: 1279px){
			width: 100%;
			padding-bottom: 101.563%;
		}
	}

	// Spécifique

	@if $encart {
		.encart{
			background-color: #FFF;
			position: absolute;
			text-align: left;
		}
		.vignette{
			&:hover,
			&:focus,
			&:active{
				color: $default-hover-color;
			}
		}
	}
	@if $type == 'artips'{
		.vignette{
			.encart{
				width: 76.5%;
				height: 227px;
				left: 11.75%;
				bottom: 70px;
				padding: 47px 40px 0 47px;
			}
			@media(max-width: 1279px){
				.encart{
					width: 57.813%;
					left: 21.094%;
					height: 340px;
					bottom: 130px;
					padding: 23.5px 20px 0 23.5px;
				}
				.title{
					font-size: 40px;
					font-size: 4rem;
				}
				.author{
					font-size: 26px;
					font-size: 2.6rem;
				}
			}
			@media(max-width: 1279px) and (min-width: 640px){
				.encart{
					height: 340px;
					bottom: 130px;
				}
				.title{
					font-size: 40px;
					font-size: 4rem;
				}
				.author{
					font-size: 26px;
					font-size: 2.6rem;
				}
			}
			@media(max-width: 639px){
				.encart{
					height: 170px;
					bottom: 75px;
				}
				.title{
					font-size: 20px;
					font-size: 2rem;
				}
				.author{
					font-size: 13px;
					font-size: 1.3rem;
				}
			}
		}
		.icon-artips{
			position: absolute;
			bottom: 17px;
			right: 22px;
			font-size: 33px;
			font-size: 3.3rem;

			@media(max-width: 639px){
				font-size: 25px;
				font-size: 2.5rem;
			}
		}
	}

	@if $type == "collection"{
		.vignette{
			.encart{
				width: 70.31%;
				left: 14.845%;
				height: 160px;
				bottom: 133px;
				padding: 30px 25px 0 25px;

				sup{
					font-size: 16px;
					font-size: 1.6rem;
				}
			}
			@media(max-width: 1279px){
				padding-bottom: 48.473%;
				.encart{
					width: 100%;
					left: 0;
					bottom: 0;

					sup{
						vertical-align: middle;
					}
				}
			}
			@media(max-width: 1279px) and (min-width: 640px){
				.encart{
					height: 125px;
					padding: 25px 25px 0 25px;
				}
				.title{
					font-size: 41px;
					font-size: 4.1rem;
				}
			}
			@media(max-width: 639px){
				.encart{
					height: 62.5px;
					padding: 12.5px 12.5px 0 12.5px;
				}
				.title{
					font-size: 20px;
					font-size: 2.0rem;
				}
			}
		}
	}
	@if $type == "profil"{
		background-size: cover;
		background-position: center center;
		overflow: hidden;
		.vignette{
			padding-bottom: 200%/$nombre-vignette;
			vertical-align: top;
			.encart{
				width: 76.172%;
				left: 11.914%;
				height: 139px;
				bottom: 183px;
				padding: 40px 22px 0 22px;
				background-color: rgba(255,255,255,0.9);

				sup{
					font-size: 16px;
					font-size: 1.6rem;
				}
			}
			.profil{
				font-family: $font-atcoverlook-regular;
				text-transform: uppercase;
				font-size: 18px;
				font-size: 1.8rem;
			}
		}
		@media(max-width: 1279px){
			.vignette{
				padding-bottom: 0;
				height: auto;
				background-image: none !important;
				.encart{
					width: 100%;
					position: relative;
					bottom: 0;
					left: 0;
					text-align: center;
				}
				.profil{
					display: inline-block;
				}
				.title{
					display: inline-block;
				}
			}
		}
		@media(max-width: 1279px) and (min-width: 640px){
			padding: 70px 55px 70px 55px;
			.vignette{
				.encart{
					height: 125px;
					padding: 25px 25px 0 25px;
					margin-bottom: 45px;
					padding-top: 0;
					line-height: 125px;
				}
				.title{
					font-size: 42px;
					font-size: 4.2rem;
				}
				.profil{
					font-size: 28px;
					font-size: 2.8rem;
					margin-right: 10px;
				}
			}
		}
		@media(max-width: 639px){
			padding: 35px 27.5px 35px 27.5px;
			.vignette{
				.encart{
					height: 62.5px;
					padding: 12.5px 12.5px 0 12.5px;
					margin-bottom: 22.5px;
					padding-top: 0;
					line-height: 62.5px;
				}
				.title{
					font-size: 21px;
					font-size: 2.1rem;
				}
				.profil{
					font-size: 14px;
					font-size: 1.4rem;
					margin-right: 5px;
				}
			}
		}
	}

	@if $type == "collection-locked"{
		.vignette{
			.encart{
				width: 70.31%;
				left: 14.845%;
				height: 160px;
				bottom: 133px;
				padding: 30px 25px 0 25px;

				sup{
					font-size: 16px;
					font-size: 1.6rem;
				}
			}
		}
		// Chevrons
		.chevron{
			position: absolute;
			border-color: #FFF;
			border-width: 15px;
			height: 35px;
			width: 35px;
			z-index: 2;
		}
		.bot-right{
			border-bottom: solid 15px #FFF;
			border-right: solid 15px #FFF;
			bottom: 0;
			right: 0;
		}
		.bot-left{
			border-bottom: solid 15px #FFF;
			border-left: solid 15px #FFF;
			bottom: 0;
			left: 0;
		}
		.top-left{
			border-top: solid 15px #FFF;
			border-left: solid 15px #FFF;
			top: 0;
			left: 0;
		}
		.top-right{
			border-top: solid 15px #FFF;
			border-right: solid 15px #FFF;
			top: 0;
			right: 0;
		}
	}
	@if $type == "parcours"{
		.vignette{
			.encart{
				width: 70.31%;
				left: 14.845%;
				min-height: 139px;
				bottom: 133px;
				padding: 30px 25px 0 25px;
			}
			.title{
				height: 65px;
			}
			.temps-parcours{
				font-family: $font-opensans-regular;
				font-size: 14px;
				font-size: 1.4rem;
				color: #868687;
				@include transition (all, 0.3s, ease, 0s);
			}
			.time{
				font-family: $font-lovelo-black;
				font-size: 21px;
				font-size: 2.1rem;
			}
			.minute{
				font-family: $font-biko-bold;
				font-size: 21px;
				font-size: 2.1rem;
			}
			.icon-clock{
				position: relative;
				top: -4px;
				margin-right: 1px;
				left: -6px;
				font-size: 20px;
				font-size: 2.0rem;
			}
			&:hover,
			&:active,
			&:focus{
				.temps-parcours{
					color: inherit;
				}
			}
			@media(max-width: 1279px){
				.encart{
					width: 65.625%;
					left: 17.188%;
				}
			}
			@media(max-width: 1279px) and (min-width: 640px){
				.encart{
					height: 260px;
					padding: 47px 47px 0 47px;
				}
				.title{
					font-size: 52px;
					font-size: 5.2rem;
					height: 150px;
				}
				.temps-parcours{
					font-size: 24px;
					font-size: 2.4rem;
				}
				.time{
					font-size: 37px;
					font-size: 3.7rem;
				}
				.minute{
					font-size: 37px;
					font-size: 3.7rem;
				}
			}
			@media(max-width: 639px){
				.encart{
					height: 130px;
					padding: 26.5px 26.5px 0 26.5px;
				}
				.title{
					font-size: 26px;
					font-size: 2.6rem;
				}
				.temps-parcours{
					font-size: 12px;
					font-size: 1.2rem;
				}
				.time{
					font-size: 18.5px;
					font-size: 1.85rem;
				}
				.minute{
					font-size: 18.5px;
					font-size: 1.85rem;
				}
			}
		}
	}
	@if $type == "oeuvre"{
		.vignette{
			height: auto;
			vertical-align: top;
			padding-bottom: 0;
			.description{
				color: $default-light-font-color;
				background-color: $lighter-dark-font-color;
				height: 100px;
				padding: 18px 5px 0px 30px;
				@include transition(all, 0.3s, ease, 0s);
			}
			.title{
				text-transform: none;
				font-family: $font-biko-regular;
				font-size: 18px;
				font-size: 1.8rem;
				margin-bottom: 5px;
				letter-spacing: 0.05em;
			}
			.date{
				font-family: $font-opensans-light;
				font-size: 16px;
				font-size: 1.6rem;
			}
			.visu{
				width: 100%;
				background-position: center center;
				background-size: cover;
				display: inline-block;
				padding-bottom: 150%;
				vertical-align: top;
			}

			&:hover,
			&:focus,
			&:active{
				.description{
					color: $default-hover-color;
				}
			}
			@media(max-width: 1279px){
				width: 50%;
			}
			@media(max-width: 1279px) and (min-width: 640px){
				.description{
					height: 140px;
					padding: 20px 10px 0px 20px;
				}
				.title{
					font-size: 28px;
					font-size: 2.8rem;
				}
				.date{
					font-size: 24px;
					font-size: 2.4rem;
				}
			}
			@media(max-width: 639px){
				.description{
					height: 70px;
					padding: 10px 5px 0px 10px;
				}
				.title{
					font-size: 14px;
					font-size: 1.4rem;
				}
				.date{
					font-size: 12px;
					font-size: 1.2rem;
				}
			}
		}
	}
	@if $type == "plan"{
		.vignette{
			height: auto;
			vertical-align: top;
			padding-bottom: 0;
			.description{
				color: $default-light-font-color;
				background-color: $lighter-dark-font-color;
				height: 100px;
				padding: 18px 5px 0px 30px;
				@include transition(all, 0.3s, ease, 0s);
			}
			.title{
				text-transform: none;
				font-family: $font-biko-regular;
				font-size: 18px;
				font-size: 1.8rem;
				margin-bottom: 5px;
				letter-spacing: 0.05em;
			}
			.date{
				font-family: $font-opensans-light;
				font-size: 16px;
				font-size: 1.6rem;
			}
			.visu{
				width: 100%;
				background-position: center center;
				background-size: cover;
				display: inline-block;
				padding-bottom: 150%;
				vertical-align: top;
			}
			.indice{
				color: $default-light-font-color;
				border: solid 2px #FFF;
				@include border-radius(50px);
				display: inline-block;
				height: 22px;
				width: 22px;
				font-family: $font-lovelo-black;
				font-size: 14px;
				font-size: 1.4rem;
				line-height: 1.4em;
				text-align: center;
				position: absolute;
				top: 10px;
				left: 10px;
			}

			&:hover,
			&:focus,
			&:active{
				.description{
					color: $default-hover-color;
				}
			}
			@media(max-width: 1279px){
				width: 100%;
				&:hover,
				&:focus,
				&:active{
					color: $default-dark-font-color;
				}
				.caption{

				}
				.indice-rwd{
					display: inline-block;
					font-family: $font-lovelo-black;
					border: solid 1px $default-dark-font-color;
					@include border-radius(50px);
					text-align: center;
				}
				.collection{
					display: inline-block;
					font-family: $font-opensans-regular;
					vertical-align: middle;
				}
			}
			@media(max-width: 1279px) and (min-width: 640px){
				.caption{
					margin-left: 42px;
					margin-bottom: 25px;
				}
				.indice-rwd{
					font-size: 32px;
					font-size: 3.2rem;
					line-height: 50px;
					width: 50px;
					height: 50px;
					margin-right: 20px;
				}
				.collection{
					font-size: 26px;
					font-size: 2.6rem;
					max-width: calc(100% - 80px);
					max-width: -moz-calc(100% - 80px);
					max-width: -webkit-calc(100% - 80px);
				}
			}
			@media(max-width: 639px){
				.caption{
					margin-left: 21px;
					margin-bottom: 17.5px;
				}
				.indice-rwd{
					font-size: 16px;
					font-size: 1.6rem;
					line-height: 25px;
					width: 25px;
					height: 25px;
					margin-right: 10px;
				}
				.collection{
					font-size: 13px;
					font-size: 1.3rem;
					max-width: calc(100% - 40px);
					max-width: -moz-calc(100% - 40px);
					max-width: -webkit-calc(100% - 40px);
				}
			}
		}
	}
	@if $type == "incontournable"{
		@media(min-width: 1280px){
			text-align: center;
			padding: 0 75px;
			.vignette{
				margin-right: 83px;
				&:last-child{
					margin-right: 0;
				}
			}
		}
		.vignette{
			.encart{
				width: 192px;
				min-height: 150px;
				font-family: $font-opensans-regular;
				font-size: 14px;
				font-size: 1.4rem;
				@include transition (all, 0.3s, ease, 0s);
				position: absolute;
				left: 0;
				top: 50%;
				margin-top: -75px;
			}
			.icon-clock{
				position: absolute;
				top: 20px;
				left: -10px;
				font-size: 18px;
				font-size: 1.8rem;
			}
			.temps-parcours{
				text-align: right;
				padding-right: 20px;
				position: absolute;
				bottom: 10px;
				right: 0;
			}
			.time{
				font-family: $font-lovelo-black;
				font-size: 48px;
				font-size: 4.8rem;
				display: inline-block;
			}
			.minute{
				font-family: $font-biko-bold;
				font-size: 48px;
				font-size: 4.8rem;
			}	
			.parcours{
				display: block;
			}
			.count{
				display: block;
			}
		}
		@media(max-width: 1279px){
			.vignette{
				position: relative;
			}
		}
		@media(max-width: 1279px) and (min-width: 640px){
			.vignette{
				.encart{
					height: 260px;
					width: 65%;
					padding: 47px 47px 0 47px;
					top: 50%;
					margin-top: -130px;
					left: 17.5%;
				}
				.icon-clock{
					left: 47px;
					top: 47px;
					font-size: 40px;
				}
				.temps-parcours{
					font-size: 24px;
					font-size: 2.4rem;
					bottom: 47px;
					right: 47px;
				}
				.time{
					font-size: 82px;
					font-size: 8.2rem;
				}
				.minute{
					font-size: 74px;
					font-size: 7.4rem;
				}
			}
		}
		@media(max-width: 639px){
			.vignette{
				.encart{
					height: 130px;
					width: 65%;
					padding: 23px 23px 0 23px;
					top: 50%;
					margin-top: -65px;
					left: 17.5%;
				}
				.icon-clock{
					left: 23px;
					top: 23px;
				}
				.temps-parcours{
					font-size: 12px;
					font-size: 1.2rem;
					bottom: 23px;
					right: 23px;
				}
				.time{
					font-size: 41px;
					font-size: 4.1rem;
				}
				.minute{
					font-size: 37px;
					font-size: 3.7rem;
				}
			}
		}
	}
}

@mixin downloadBuilder($type){
	text-align: center;
	.dossier{
		display: inline-block;
		width: 270px;
		height: 240px;
		border: solid 2px #7B7C7E;
		text-align: center;
		@include border-radius(10px);
		font-family: $font-biko-bold;
		font-size: 18px;
		font-size: 1.8rem;
		padding: 70px 30px 0 30px;
		vertical-align: top;
		margin-right: 34px;
		@include transition(all,0.3s,ease,0s);


		.title{
			margin-bottom: 20px;
			height: 54px;
		}
		.date{
			margin-bottom: 20px;
		}

		&:last-child{
			margin-right: 0px;
		}
		.icon{
			font-size: 34px;
			font-size: 3.4rem;
		}

		&:hover,
		&:focus,
		&:active{
			color: #FFF;
			background-color: $main-bright-grey;
		}
	}
	@media(max-width: 1279px){
		.dossier{
			&:nth-child(2n){
				margin-right: 0;
			}
			&:nth-child(n+3){
				display: none;
			}
		}
	}
	@media(max-width: 1279px) and (min-width: 640px){
		
	}
	@media(max-width: 639px){
		.dossier{
			margin-right: 0;
			margin-bottom: 0px;

			&:nth-child(n+3){
				display: none;
			}
			&:nth-child(n+2){
				display: none;
			}
		}
	}
}

.gallery{
	margin-bottom: 45px;

	@media(max-width: 639px){
		margin-bottom: 22.5px;
	}

	& + &{
		margin-top: -45px;
		@media(max-width: 639px){
			margin-top: -22.5px;
		}
	}

	&--artips-4{
		position: relative;
		@include galleryBuilder(4, true, 'artips');
	}
	&--artips-6{
		position: relative;
		@include galleryBuilder(6, true, 'artips');		
	}
	&--collection-4{
		position: relative;
		@include galleryBuilder(4, true, 'collection');		
	}
	&--collection-locked-4{
		position: relative;
		@include galleryBuilder(4, true, 'collection-locked');		
	}
	&--parcours-4{
		position: relative;
		@include galleryBuilder(4,true, 'parcours');		
	}
	&--profils-5{
		position: relative;
		@include galleryBuilder(5,true, 'profil');		
	}
	&--oeuvre-4{
		@include galleryBuilder(4,true, 'oeuvre');		
	}
	&--oeuvre-6{
		@include galleryBuilder(6,true, 'oeuvre');		
	}
	&--plan-6{
		@include galleryBuilder(6,true,'plan');		
	}
	&--incontournable-3{
		@include galleryBuilder(4,true,'incontournable');		
	}
}

.download--thin-2{
	@include downloadBuilder('basic');
}

.download--wide-4{
	@include downloadBuilder('basic');
}
.download--presse-4{
	@include downloadBuilder('basic');
}