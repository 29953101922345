.mac{
	@media(max-width:1279px){
		.bandeauApp p span{
			height: 116px;
			line-height: 120.5px;
		}
		.Home-page .expander-light .text-headband-light{
			height:126px;
			line-height: 132.5px;
		}
	}
	@media(max-width:639px){
		.bandeauApp p span{
			height: 58px;
			line-height: 60.5px;
		}
		.Home-page .expander-light .text-headband-light{
			height:57px;
			line-height: 60.5px;
		}
	}
}