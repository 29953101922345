.class_identifier_golden_book{

    .arianne{
        height: auto;
        padding-bottom: 20px;
    }
    .center-line{
        h1{
            word-wrap: break-word;
            font-family: loveloblack;
            vertical-align: middle;
            padding-top: 44px;
        }
        p{
            display: block;
            font-family: bikobold;
            font-size: 1.57rem;
            text-align: center;
            text-transform: none;
        }
        .triangle{
            width: 0px;
            height: 0px;
            display: inline-block;
            margin-top: 30px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid rgb(255, 255, 255);
        }
    }

    section.infos{
        overflow: hidden;
        h2{
            padding-top: 70px;
            margin-bottom: 20px;
        }
        .triangle{
            border-top-color: rgb(0, 0, 0);
            margin-top: 0;
            margin-bottom: 35px;
        }
    }

    .page--goldenbook{

        .links-goldenbook{
            padding: 30px 0 60px;
            background-color:#f7f7f7;            
            .wrapper{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                max-width: 1168px;
                width:100%;
                margin: 0 auto;
                @media(max-width:1279px){
                    padding: 0 27px;
                }
            }

            @extend %cadre-btn;

            a span, 
            button span{display:inline-block; width: 100%; text-align:center;}
        }

        .xDistribution--comment{
            width: 100%;
            overflow: hidden;
        }

        .comment-wrapper{
            //float:left;
            margin: 7px;
            width: calc(25% - 15px);
            background-color: #f5f5f5;
            font-family: $font-biko-bold;

            .commentContent{
                position: relative;
                font-size: 2rem;
                font-size: 20px;
                line-height: 24px;

                a.readmore{
                    display: block;
                    text-align: right;
                    padding-top: 20px;
                    &:before{
                        display: inline-block;
                        content: "\e940";
                        margin-right: 10px;
                        font-family: "pba";
                        font-size: 16px;
                        font-size: 1.6rem;
                        transition: all 0.3s;
                    }

                    &:hover:before{
                        transform: translate3D(0, 0, 0) translateX(5px);
                    }
                }

                &:after{
                    position: absolute;
                    width:42px;
                    height:38px;
                    bottom: -38px;
                    left: 90px;
                }
            }

            .commentAuthor{
                font-size: 2.4rem;
                font-size: 24px;
                text-transform: uppercase;
                text-align:right;
            }

            &.short-comment, &.normal-comment, &.long-comment{
                // JS purpose
                .commentContent{ margin-bottom:35px}
            }

            &.short-comment{ 
                padding: 60px 40px;
            }
            &.normal-comment, &.long-comment{
                padding:40px 7px;
                color:#fff;
                .commentContent{
                    border: 4px solid #fff;
                    padding: 18px;
                }
                .commentAuthor{
                    height: 87px;
                    padding: 60px 20px 0 110px;
                    background: transparent url(svg-bg-uri($svg-comment-author, #fff)) no-repeat 30px 0;
                    background-size:62px 87px;
                    line-height: 27px;
                }
            }
            &.normal-comment{
                background-color: $default-dark-font-color;
                .commentContent:after{
                    content: url(svg-bg-uri($svg-comment-arrow, $default-dark-font-color));
                }
            }
            &.long-comment{
                background-color: $default-secondary-color;
                .commentContent:after{
                    content: url(svg-bg-uri($svg-comment-arrow, $default-secondary-color));
                }
            }

        }

        .showPagination{
            padding: 55px 0 10px;
            text-align:center;
            button{
                @extend %bones-btn;
                @extend %skin-btn-black;
                padding: 30px 100px;
                font-size: 24px;
                height: 92px;
                &:hover, &:active, &:focus{
                    @extend %animation-btn-red;
                }

                .svg-refresh{
                    width: 30px;
                    height: 30px;
                    position: relative;
                    top: -3px;
                    &:before{
                        content:'';
                        width: 30px;
                        height: 30px;
                        display: inline-block;
                        background: transparent url(svg-bg-uri($svg-refresh, #000)) no-repeat 50%;
                        background-size: 25px;
                        animation: spinSvgRefresh 2s infinite linear;
                    }
                }
            }
        }




        .scrollTop{
            text-align: center;
            margin: 20px 0;
            button{
                font-size: 30px;
            }
        }
    }
}

@media(min-width:1280px){
    .class_identifier_golden_book .page--goldenbook--comment{
            width: calc(33.3333333332% - 15px);
        }
}
@media(min-width:640px) and (max-width: 1279px){
    .class_identifier_golden_book .page--goldenbook--comment{
            width: calc(50% - 15px);
        }
}
@media(max-width: 639px){
    .class_identifier_golden_book {
        .page--goldenbook--comment{
            width: calc(100% - 15px);
        }
        .page--goldenbook .showPagination button{
            padding: 30px 50px;
            max-width: calc(100% - 20px );
            margin: 0 10px;
        }
    }
}

#popup-goldenbook{
    p{
        margin-bottom: 15px;
        line-height: 20px;
        &:last-child{
            margin-bottom:0;
        }
    }
}