.class_group_content.class_identifier_page_wide {
	.collections-parcours-visite{
		background-color:#fff;
		.background-1-col{
			height:510px;
			width:100%;
			background-color: #D1D3D4;
		}
		.hidden-desktop{
			display:none;
		}
		.container-1-col-ext-bg{
			margin:0 auto;
			background-color:$default-light-font-color;
			width:712px;
			margin-top:-467px;
		}
		.container-1-col-ext{
			margin:0 auto;
			background-color:$default-light-font-color;
			width:712px;
			margin-top:0px;
		}
		.container-1-col-int-bg{
			padding:80px 105px 0;
			color:$lighter-dark-font-color;
			min-height: 468px; 
		}
		.container-1-col-int{
			padding:0px 105px 0;
			color:$lighter-dark-font-color;
		}
		h2{
			text-align:center;
			margin-bottom:30px;
			margin-top: 0;
		}
		p{
			margin-bottom:30px;
			font-family: $font-opensans-regular;
			font-size: 19px;
			font-size: 1.9rem;
			line-height: normal;
		}
		.plus-info-unique{
			text-align:right;
			font-family:$font-biko-bold;
			font-size: 17px;
			font-size: 1.7rem;
			button{
				@include transition(all,0.3s,ease,0s);
			    &:hover,
				&:active,
				&:focus{
					color:$default-hover-color;
					@include transition(all,0.3s,ease,0s);
				}
			}			
			.icon{
				margin-left:10px;
				font-size:27px;;
				font-size: 2.7rem;
				height: 30px;
			}
		}
		.container-button{
			padding-left:75px;
			padding-right:75px;
		}
		.container-2-col-ext{
			width:900px;
			margin:0 auto;
			//margin-bottom:70px;
		}
		.plus-info-toggle{
			display:none;
		}
		.rte-col-left {
		    width: 48%;
		    background-color: #FFF;
		    display: inline-block;
		}
		.rte-col-right {
		    width: 48%;
		    background-color: #FFF;
		    display: inline-block;
		}
		.container-1-col-ext{
			margin:0 auto;
			background-color:$default-light-font-color;
			width:712px;
			margin-top:0px;
		}
		.yosemite-rte:first-child {
		    margin-bottom: 30px;
		    margin-top: 0px;
		}
		.bloc-titre-gallerie{
			height:auto;
			text-align:center;
			color:$default-dark-font-color;
			p{
				font-family:$font-lovelo-black;
				font-size: 24px;
				font-size: 2.4rem;
				vertical-align: middle;
				padding-top: 0px;
				margin-bottom:0px;
			}
			.triangleBG {
			    width: 0px;
			    height: 0px;
			    border-left: 10px solid transparent;
			    border-right: 10px solid transparent;
			    border-top: 10px solid $default-dark-font-color;
			    display: inline-block;
			    margin-top: 20px;
			    margin-bottom:30px;
			}
		}	    
		.callToAction{
			&--dark{
				@include buttonBuilder(100%, 85px, 'dark',true);
				@include buttonPictoBuilder('agenda', 18px, 'dark'); 
				margin-bottom:60px;
				min-width: 540px;
				.icon{
					font-size:41px;
					font-size:4.1rem;
				}
			}
		}
		.gallery:last-child {
		    margin-bottom: 0px;
		}
	}
}

@media (min-width:640px) and (max-width:1279px){
	.class_group_content.class_identifier_page_wide {
		.collections-parcours-visite{
			.hidden-desktop{
				display:inline-block;
				margin-bottom:40px;
			}
			.background-1-col {
    			height: 340px;
    			position: relative;
    		}
    		.pChapo {
			   display:none;
			}
			.plus-info-unique{
			   display:none;	
			}
    		.triangle-mobile{
    			width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 15px 15px 15px;
				border-color: transparent transparent $default-light-font-color transparent;
				position: absolute;
				bottom: 0px;
				left: 50%;
				margin-left: -15px;
    		}
			.container-2-col-ext {
			    width: 100%;
			    //margin: 0px auto;
			}
			.plus-info-toggle {
			    display: block !important;
			}
			.container-1-col-ext {
			    width: 100%;
			}
			.container-1-col-ext-bg {
			    margin: 0px auto 0px;
			    background-color: #FFF;
			    width: 100%;
			}
			.container-1-col-int-bg {
			    padding: 40px 55px 0px;
			    .icon{
			    	font-size:30px;
			    	font-size: 3rem;
			    	text-align: center;
					width: 100%;
					display:inline;
			    }
			    .icon-parcours{
	    			width: 100%;
					text-align: center;
					font-size: 6rem;
					margin-bottom: 40px;
					display: block;
	    		}
			}
			h2{
				margin-top:0px;
				margin-bottom: 10px;
			}
			h3{
				text-align:center;
			}
			.collections-parcours-visite p {
			    margin-bottom: 10px;
			}
			.container-button {
				display: none;
			    /*padding-left: 55px;
			    padding-right: 55px;
			    margin-bottom:60px;*/
			}
			.rte-col-right {
			    width: 100%;
			    background-color: #FFF;
			    display: block;
			}
			.rte-col-left {
			    width: 100%;
			    background-color: #FFF;
			    display: block;
			}
			.rte-contain {
				margin-bottom:0px;
			}
			.bloc-titre-gallerie{
				.icon{
			    	font-size:60px;
			    	font-size: 6rem;
			    	text-align: center;
					width: 100%;
			    }
			}
			.callToAction--dark {
				margin-bottom: 0px;
			}
		}
	}
}
@media (max-width:639px){
	.class_group_content.class_identifier_page_wide {
		.collections-parcours-visite{
			.hidden-desktop{
				display:inline-block;
				margin-bottom:20px;
			}
			.background-1-col {
    			height: 170px;
    			position: relative;
    		}
    		.pChapo {
			   display:none;
			}
			.plus-info-unique{
			   display:none;	
			}
    		.triangle-mobile{
    			width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 7.5px 7.5px 7.5px;
				border-color: transparent transparent $default-light-font-color transparent;
				position: absolute;
				bottom: 0px;
				left: 50%;
				margin-left: -7.5px;
    		}
			.container-2-col-ext {
			    width: 100%;
			    //margin: 0px auto;
			}
			.plus-info-toggle {
			    display: block !important;
			}
			.container-1-col-ext {
			    width: 100%;
			}
			.container-1-col-ext-bg {
			    margin: 0px auto 0px;
			    background-color: #FFF;
			    width: 100%;
			}
			.container-1-col-int-bg {
			    padding: 20px 22.5px 0px;
			    .icon{
			    	font-size:15px;
			    	font-size: 1.5rem;
			    	text-align: center;
					width: 100%;
					display:inline;
			    }
			    .icon-parcours{
	    			width: 100%;
					text-align: center;
					font-size: 3rem;
					margin-bottom: 20px;
					display: block;
	    		}
			}
			h2{
				margin-top:0px;
				margin-bottom: 10px;
			}
			h3{
				text-align:center;
			}
			.collections-parcours-visite p {
			    margin-bottom: 5px;
			    line-height:1em;
			}
			.container-button {
				display: none;
			    /*padding-left: 55px;
			    padding-right: 55px;
			    margin-bottom:60px;*/
			}
			.rte-col-right {
			    width: 100%;
			    background-color: #FFF;
			    display: block;
			}
			.rte-col-left {
			    width: 100%;
			    background-color: #FFF;
			    display: block;
			}
			.rte-contain {
				margin-bottom:0px;
			}
			.bloc-titre-gallerie{
				.icon{
			    	font-size:30px;
			    	font-size: 3rem;
			    	text-align: center;
					width: 100%;
			    }
			}
			.callToAction--dark {
				margin-bottom: 0px;
			}
		}
	}
}