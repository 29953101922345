.class_group_content.class_identifier_page_wide {
    .page--autres-sites {
        background-color: #fff;
        figure {
            text-align: center;
        }
        .container-ext {
            width: 1090px;
            margin: 0 auto;
            padding-top: 100px;
        }
        .container-int { 
            padding: 100px 150px;
        }
        .line1 {
            width: 100%;
            margin-bottom: 140px;
            text-align: center;
        }
        h1 {
            font-family: "loveloblack";
            font-size: 28px;
            font-size: 2.8rem;
            margin-top: 40px;
            color: #fff;
            text-transform: uppercase;
            letter-spacing: 0.031em;
            @media (max-width: 639px) {
                font-size: 14px;
                font-size: 1.4rem;
                margin-top: 20px;
            }
        }
        .line2 {
            display: table;
            width: 100%;
            margin-bottom: 140px;
            margin-left: 20px;
            div {
                display: table-cell;
            }
            /*&:last-child{
             margin-bottom:0px;
            }*/
        }
    }
}

@media (min-width: 640px) and (max-width: 1280px) {
    .class_group_content.class_identifier_page_wide {
        .page--autres-sites {
            img{
              max-width: 250px;
              height: auto;
            }
            .line1 {
                display: block;
                margin-bottom: 35px;
                margin-top: 35px;
                width: 100%;
                text-align: center;
                div {
                    display: inline-block;
                    width: 350px;
                    vertical-align: middle;
                    &:first-child {
                        margin-right: 20px;
                    }
                }
            }
            .line2 {
                display: block;
                margin-bottom: 0px;
                text-align: center;
                margin-bottom: 35px;
                margin-top: 35px;
                width: 100%;
                margin-left: 0px;
                div {
                    display: inline-block;
                    width: 350px;
                    vertical-align: middle;
                    &:first-child {
                        margin-right: 20px;
                    }
                }
            }
            .line3 {
                div {
                    display: inline-block;
                    width: 350px;
                    vertical-align: middle;
                    margin-bottom: 35px;
                    margin-top: 35px;
                    &:first-child {
                        margin-right: 20px;
                    }
                }
            }
            #deplacement-js-mobile {
                display: none;
            }
            .section--wide-2 {
                text-align: center;
            }
        }
    }
}

@media (max-width: 719px) {
    // Rush, pas beau
    .class_group_content.class_identifier_page_wide {
        .page--autres-sites {
            .line1 {
              div{
                margin-bottom: 35px;
              }
            }
            .line2 {
              div{
                margin-bottom: 35px;
              }
            }
            .line3 {
              div{
                margin-bottom: 35px;
              }
            }
        }
    }
}

@media (max-width: 639px) {
    .class_group_content.class_identifier_page_wide {
        .page--autres-sites {
            img{
              max-width: 250px;
              height: auto;
            }
            .line1 {
                display: block;
                margin-bottom: 35px;
                margin-top: 35px;
                width: 100%;
                text-align: center;
                margin-bottom: 0px;
                div {
                    display: block;
                    text-align: center;
                    &:first-child {
                        margin-right: 20px;
                    }
                }
            }
            .line2 {
                display: block;
                margin-bottom: 0px;
                text-align: center;
                width: 100%;
                margin-left: 0px;
                div {
                    display: block;
                    text-align: center;
                    &:first-child {
                        margin-right: 20px;
                    }
                }
            }
            .line3 {
                text-align: center;
                width: 100%;
                div {
                    display: block;
                    text-align: center;
                    &:first-child {
                        margin-right: 20px;
                    }
                }
            }
            .section--wide-2 {
                text-align: center;
            }
        }
    }
}