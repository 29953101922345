.class_identifier_oeuvre .page--collections--oeuvre-carte{
	.module--oeuvre{
		height: auto;
	}
	.carto{
		background-color: #FFF;
		display: inline-block;
		width: 1075px;
		height: 650px;
		position: relative;
	}
	.map-visu{
		position: absolute;
		top: 135px;
		left: 50%;
		margin-left: -72.5px;
		width: 145px;
		height: 100px;
		background-position: center;
		background-size: cover;
		border: solid 9px $default-dark-font-color;
		z-index: 1;
	}
	.infobox{
		text-align: center;
		background-color: transparent !important; 
		border: none !important;

		a{
			display: inline-block;
			border: solid 9px #1D1D1E;

			img{
				vertical-align: top;
			}
		}
	}
}