#audio-player-overlay{
    position: relative;
    height: 60px;
    width:100%;
    margin-top: 15px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2c2c2e;
    &.hidden{
        display: none;
    }
    .svg-refresh{
        width: 30px;
        height: 30px;
        &:before{
            content:'';
            width: 30px;
            height: 30px;
            display: block;
            background: transparent url(svg-bg-uri($svg-refresh, #fff)) no-repeat 50%;
            background-size: 25px;
            animation: spinSvgRefresh 2s infinite linear;
        }
    }
    button.close {
        width: 30px;
        height: 30px;
        content: '';
        display: block;
        position: absolute;
        right: 15px;
        top: 15px;
        opacity: 1;
        z-index: 1; 

        &:after, 
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            height: 3px;
            background-color: #FFF;
            width: 20px;
            -webkit-transition: all, 0.5s;
            transition: all, 0.5s; }
        &:after{
            -webkit-transform: translate(-50%, -50%) rotate(45deg);
                    transform: translate(-50%, -50%) rotate(45deg); }
        &:before {
            -webkit-transform: translate(-50%, -50%) rotate(-45deg);
                    transform: translate(-50%, -50%) rotate(-45deg); }
    }

    .media-audio-player{
        @extend %molecule-audio-player;
        width:100%;
        .audio-infos{
            max-width: 100%;
        }
        .audio-player{
            margin-right: 0;
            padding-right: 30px;
            .player-control{
                margin-right: 10px;
            }
            .player-view{
                margin: 0 35px;
                position: relative;
                .player-timing{
                    position: absolute;
                    width: 100%;
                    top:3px;
                    @media(max-width: 639px){
                        top:7px;
                    }
                    .player-time-current,
                    .player-time-total{
                        top:0;
                    }
                }
                .player-progress-bar{
                    top: 13px;
                }
            }
        } 
    }

}
