#ui-datepicker-div {
  padding: 10px;
  .ui-datepicker-header {
    display: flex;
    background: none;
    background-color: #2c2c2e;
    border-color: #2c2c2e;
    border-radius: 0;
    padding: 10px;
    margin: -10px;
    margin-bottom: 10px;
    // border: solid 3px #2c2c2e;

    .ui-datepicker-prev {
      order: 1;
      margin-right: 10px;
      cursor: pointer;
    }
    .ui-datepicker-next {
      order: 3;
      margin-left: 10px;
      cursor: pointer;
    }
    .ui-datepicker-title {
      order: 2;
    }
    .ui-state-hover {
      border: none;
      background: none;
      font-weight: bold;
      color: #d8383f;
    }
    .ui-state-hover .ui-icon,
    .ui-state-focus .ui-icon {
      background-image: url("jquery-ui/images/ui-icons_ffffff_256x240.png");
    }
  }

  .ui-datepicker-header {
    // padding: .3em 0;
    // color: #fff;
    // font-family: "DroidSans", sans-serif;
    // font-size: 13px;
    // text-shadow: 0 -1px 0 rgba(0,0,0,.8);
    // border: none;
    // border-radius: 0;
    // -moz-border-radius: 2px 2px 0 0;
    // -webkit-border-radius: 2px 2px 0 0;
    // border-radius: 2px 2px 0 0;
    // -moz-background-clip: padding;
    // -webkit-background-clip: padding-box;
    // background-clip: padding-box;
    // background-color: #b73c37;
    // -moz-box-shadow: 0 1px 1px rgba(0,0,0,.2);
    // -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.2);
    // box-shadow: 0 1px 1px rgba(0,0,0,.2);
    // background-image: -moz-linear-gradient(bottom, rgba(0,0,0,.25) 0%, rgba(255,255,255,.25) 100%);
    // background-image: -o-linear-gradient(bottom, rgba(0,0,0,.25) 0%, rgba(255,255,255,.25) 100%);
    // background-image: -webkit-linear-gradient(bottom, rgba(0,0,0,.25) 0%, rgba(255,255,255,.25) 100%);
    // background-image: linear-gradient(bottom, rgba(0,0,0,.25) 0%, rgba(255,255,255,.25) 100%);
  }

  .ui-datepicker-header .ui-state-hover {
    background: transparent;
    border-color: transparent;
    cursor: pointer;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
  }

  .ui-datepicker .ui-datepicker-next span,
  .ui-datepicker .ui-datepicker-prev span {
    background-image: url("jquery-ui/images/ui-icons_ffffff_256x240.png");
    background-position: -32px -16px;
  }

  .ui-datepicker .ui-datepicker-prev span {
    background-position: -96px -16px;
  }

  .ui-datepicker-calendar {
    margin: 0;
    width: 100%;
  }

  .ui-datepicker th {
    padding: 0.5em 0.3em;
    color: #707586;
    font-size: 10px;
    font-weight: bold;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.75);
    text-transform: uppercase;
    border: none;
    border-bottom: 1px solid #d5d9e2;
    background: #f2f6fe;
  }

  .ui-datepicker td {
    border: none;
    padding: 0;
  }

  td .ui-state-default {
    background: transparent;
    border: none;
    text-align: center;
    padding: 0.7em 0;
    margin: 0;
    font-weight: normal;
    color: #4d4d4d;
    font-size: 11px;
  }

  td .ui-state-active,
  td .ui-state-hover {
    font-weight: bold;
    opacity: 0.7;
    text-decoration: underline;
  }
}
