// COMMUN OEUVRE
		$oeuvre-height: 575px;
.class_identifier_oeuvre{
	.module--oeuvre{
		min-height: $oeuvre-height;
		background-color: transparent;
		color: white;
	}
	.oeuvre-menu{
		display: inline-block;
		width: 205px;
		vertical-align: top;
	}
	.emplacement-contain{
		display: inline-block;
		width: calc(100% - 215px);
		margin-left: 10px;
	}
	.menu-item{
		width: 100%;
		height: ($oeuvre-height /5);
		display: block;
		text-align: center; 
		border-bottom: solid 1px #474A4B;
		position: relative;

		&.active{  
			color: $default-hover-color;
		}

		&:last-child{
			border-bottom: none;
			&:after{
				display: none;
			}
		}
		&:after{
			display: inline-block;
			content: '';
			width: 10px;
			height: 10px;
			border-bottom: 5px solid #474A4B;
			border-right: 5px solid #474A4B;
			position: absolute;
			bottom: 0;
			right: 0;
		}
		&:before{
			display: inline-block;
			content: '';
			height: 100%;
			vertical-align: middle;
		}
		.innerContent{
			display: inline-block;
			vertical-align: middle;
			width: 96%;
		}
		.title{
			font-family: $font-biko-bold;
			font-size: 17px;
			font-size: 1.7rem;
			margin-bottom: 9px;
			@include transition(all,0.3s,ease,0s);
		}
		.subtitle{
			font-family: $font-opensans-regular;
			font-size: 14px;
			font-size: 1.4rem;
			@include transition(all,0.3s,ease,0s);
		}
		.icon{
			font-size: 24px;
			font-size: 2.4rem;
			margin-bottom: 12px;
		}
		&.item-map{
			.subtitle{
				font-size: 13px;
				font-size: 1.3rem;
				font-family: $font-opensans-light;
			}
		}
		&.item-artips{
			.subtitle{
				font-size: 13px;
				font-size: 1.3rem;
				font-family: $font-opensans-light;
				color: $main-darker-grey;
			}
			.icon{
				color: $main-darker-grey;
				font-size: 28px;
			}
		}
		
	}
	a.menu-item{
		@include transition(all,0.3s,ease,0s);
		&:hover,
		&:focus,
		&:active{
			.icon, .subtitle, .title{
				@include transition(all,0.3s,ease,0s);
				color: $default-hover-color;
			}
		}
	}
	// Slider Général
	.sliderFicheOeuvre{
		&.wide{
			margin-bottom: 45px;
			@media(max-width: 639px){
				margin-bottom: 22.5px;
			}
		}
		display: inline-block;
		width: 730px;
		position:relative;
		max-width: 100%;
		.bxSlider{
			margin-bottom:0px;
		}
		.bx-wrapper{
			margin-bottom:0px;
			.bx-viewport{
				height:290px;
				@media(max-width:1279px){

				}
				border: 0px solid #FFF;
				left: 0px;
				box-shadow: none;
				background-color:transparent;
				.oeuvre{
					text-align: center;
					height: 100%;
					img{
						display: inline-block;
						min-height: 100%;
						min-width: 100%;
					}
				}
			}
		}

		.pagerSquare{
			position:absolute;
			bottom:10px;
			right:12px;
			
			a{
				margin-right:6px;
				&:last-child{
					margin-right:0px;
				}
			}
			.pager-square{
				width:24px;
				height:24px;
				background-color:transparent;
				border:4px solid $default-light-font-color;
				display: inline-block;
			}
			a.active{
				display: inline-block;
				.pager-square{
					background-color: $default-light-font-color;
				}
			}
		}
		@media(max-width: 1279px){
			.pagerSquare{
				width:100%;
				text-align: center;
			}
		}
		.bxslider{
			ul{
				margin-top: 0px;
				margin-left:0px;
			}
			li{
				height:290px;
				width:510px;
				margin-top: 0px;
				margin-left:0px;
				background-size: cover;
				background-position: center center;
			}
		}
		.arrowSlider{
			position:absolute;
		}
		.prevPage{
			left:-110px;
			top:50%;
			font-size:47px;
			font-size:4.7rem;
			.icon{
				&:hover,
				&:active,
				&:focus{
					color: $default-hover-color;
				}
			}
		}
		.nextPage{
			right:-110px;
			top:50%;
			font-size:47px;
			font-size:4.7rem;
			.icon{
				&:hover,
				&:active,
				&:focus{
					color: $default-hover-color;
				}
			}
		}

		// Responsive
		@media(max-width: 1279px){
			.prevPage{
				left: 30px;
				color: white;
			}
			.nextPage{
				right: 30px;
				color: white;
			}
		}
		@media(max-width: 639px){
			.prevPage{
				left: 15px;
			}
			.nextPage{
				right: 15px;
			}
		}
	}
 
	// Slider Caption
	.slider-caption{
		margin-top: 20px;
		margin-bottom: 60px;
		text-align: center;

		@media(max-width: 639px){
			margin-top: 10px;
			margin-bottom: 30px;
		}
	}
	.oeuvre-infos.yosemite-rte{
		position: relative;
		.infos-title{
			font-family: $font-biko-bold;
			font-size: 23px;
			font-size: 2.3rem;
			line-height: 27.6px;
			margin-bottom: 27px;
		}
		.infos-description{
			font-family: $font-opensans-light;
			font-size: 16px;
			font-size: 1.6rem;
			line-height: 19.2px;
		}
	}
	@media(max-width: 1279px){
		footer{
			display: none !important;
		}
		.module--oeuvre{
			height: auto;
		}
		.sliderFicheOeuvre{
			width: 100%;
		}
		.oeuvre-menu{
			display: none;
		}
		.oeuvre-infos{
			display: none !important;
		}
		.sliderFicheOeuvre{

			height: auto;
			.oeuvre-visu{
				display: none !important;
			}
			.oeuvreControls{
				position: absolute;
  				right: 0;
			}
			.pagerSquare{
				position: static;
				padding: 40px 12px 0 0;
				text-align: right;

			}
			.oeuvre{
				width: 100%;
				.visu{
					width: 100%;
					padding-bottom: 78.13%;
					background-position: center;
					background-size: cover;
					background-repeat: no-repeat;
				}
			}
		}
		// Fake Arianne
		.fake-arianne{
			margin: 30px 0;
			.icon-more{
				font-size: 35px;
				font-size: 3.5rem;
				margin-bottom: 20px;
				@include transition(all,0.3s,ease,0s);
			}
			a:hover,
			a:focus,
			a:active{
				color: $default-hover-color;
				@include transition(all,0.3s,ease,0s);
			}
			.arianne-part{
				text-align: center;
				position: relative;
				&:before{
					content: '';
					display: inline-block;
					vertical-align: middle;
					height: 100px;
				}

			}
			.icon-chevron-left{
				position: absolute;
				top: 50%;
				margin-top: -15px;
				left: 25px;
				font-size: 30px;
				font-size: 3.0rem;
				@include transition(all,0.3s,ease,0s);
			}
			.icon-chevron-right{
				position: absolute;
				top: 50%;
				margin-top: -15px;
				right: 25px;
				font-size: 30px;
				font-size: 3.0rem;
				@include transition(all,0.3s,ease,0s);
			}
			.innerContent{
				display: inline-block;
				vertical-align: middle;
				color: white;
				position: relative;
				padding-left: 50px;
				padding-right: 50px;
			}
			.title{
				font-family: $font-biko-bold;
				font-size: 36px;
				font-size: 3.6rem;
				letter-spacing: 0.05em;
				line-height: 43.2px;
			}
			.artist{
				font-family: $font-opensans-regular;
				font-size: 29px;
				font-size: 2.9rem;
				display: inline-block;
				width: 80%;
			}
			.date{
				font-family: $font-biko-bold;
				font-size: 26px;
				font-size: 2.6rem;
				color: #7B7C7E;
				// position: absolute;
				right: 0px;
				bottom: -1px;
			}
		}
	}
	@media(max-width: 639px){
		.sliderFicheOeuvre{
			.pagerSquare{
				top: 20px;
				a{
					margin-right: 1px;
				}
				.pager-square{
					width: 12px;
					height: 12px;
					border-width: 2px;
				}
			}
		}
		.fake-arianne{
			margin-top: 40px;
			.icon-more{
				font-size: 17.5px;
				font-size: 1.75rem;
				margin-bottom: 10px;
			}
			.arianne-part{
				&:before{
					height: 50px;
				}

			}
			.icon-chevron-left{
				margin-top: -7.5px;
				left: 12.5px;
				font-size: 15px;
				font-size: 1.5rem;
				z-index: 1;
			}
			.icon-chevron-right{
				margin-top: -7.5px;
				right: 12.5px;
				font-size: 15px;
				font-size: 1.5rem;
			}
			.title{
				font-size: 18px;
				font-size: 1.8rem;
				letter-spacing: 0.05em;
				line-height: 21.6px;
			}
			.artist{
				font-size: 14.5px;
				font-size: 1.45rem;
				width: 80%;
			}
			.date{
				font-size: 13px;
				font-size: 1.3rem;
				right: 0px;
				bottom: -1px;
			}
		}
	}
}


// SPE OEUVER DEFAUT
.class_identifier_oeuvre{
	.page--collections--oeuvre{
		section{
			overflow: hidden;
		}
		.h2-oeuvre{
			color: white; 
			height: 130px;

			&:before{
				height: 100%;
				display: inline-block;
				content: '';
				vertical-align: middle;
			}
			.innerContent{
				display: inline-block;
				vertical-align: middle;
			}
			.suptitle{
				font-family: $font-biko-bold;
				font-size: 20px;
				font-size: 2.0rem;
				line-height: 24px;
			}
			h2{
				line-height: 33.59px;
			}
			sup{
				font-size: 16px;
				font-size: 1.6rem;
			}
		}


		.oeuvre-infos{
			width: 340px;
			display: inline-block;
			height: 100%;
			padding-left: 35px;
			padding-right: 35px;
			vertical-align: top;

			.infos-description{
				margin-bottom: 30px;
			}
			.btn-audio{
				line-height:25px;
				font-family: $font-biko-bold;
				font-size: 1.7rem;
				font-size: 17px;
				.icon{
					vertical-align: top;
				}
				&:hover, &:active, &:focus{
					transition: all 0.3s;
					color: $default-hover-color;
				}
			}

		}
		.sliderFicheOeuvre{
			display: inline-block;

			.sliderOeuvre{
				@media(min-width: 1280px){
					height: 575px;
				}
			}
		}
		.learn-more{
			text-align: right;
			display: block;
			margin-top: 20px;
			color: white;
			@include transition(all,0.3s,ease,0s);
			&:hover,
			&:focus,
			&:active{
				@include transition(all,0.3s,ease,0s);
				color: $default-hover-color;
			}
			.texte{
				font-family: $font-biko-bold;
				font-size: 17px;
				font-size: 1.7rem;
				display: inline-block;
			}
			.icon{
				display: inline-block;
				vertical-align: middle;
				font-size: 25px;
				font-size: 2.5rem;
			}
		}
		.gigapixel-action{
			text-align: right;
			padding-top: 30px;
			.btn-gigapixel{
				font-family: $font-biko-bold;
				font-size: 17px;
				font-size: 1.7rem;

				.icon{
					height: 25px;
					width: 25px;
					vertical-align: text-bottom;
					&:before,
					&:after{
						height: 25px;
						width: 25px;
					}
				}
				&:hover, &:active, &:focus{
					transition: all 0.3s;
					color: $default-hover-color;
				}					
			}
			
			@media(max-width: 1279px){
				padding:20px 0 15px 12px;
				text-align: left;
			}
		}

		.infos-gallery{
			// position: absolute;
			bottom: 0;
			text-align: right;
			right: 20px;
			margin-top: 20px;

			.gallery-picto{
				height: 60px;
				width: 60px;
				display: inline-block;
				background-position: center center;
				background-size: cover;
				margin-left: 7px;
			}

		}
		.oeuvre-visu{
			width: 735px !important;
			background-position: center center;
			background-size: cover;
			display: inline-block;
			height: 100%;
			text-align: center;

			// &:before{
			// 	content: '';
			// 	display: inline-block;
			// 	vertical-align: middle;
			// 	height: 100%;
			// 	margin-right: -0.25em;
			// }
			// img{
			// 	display: inline-block;
			// 	vertical-align: middle;
			// }

			&.visible-desktop{ 
				// height: 575px;
			}
		}
		.image-full{
			text-align: center;
		}

		#audio-player-btn-2{
			padding-left: 12px;
			font-family: $font-biko-bold;
			font-size: 17px;
			font-size: 1.7rem;
			&:hover, &:active, &:focus{
				transition: all 0.3s;
				color: $default-hover-color;
			}					

		}
	}
}

// EVO 12 2017: liseret orange
.class_identifier_oeuvre .module--oeuvre-famille{
	.menu-item{
		border-color: #FE921B;
		&:after{
			border-color: #FE921B;
		} 
	}
	.oeuvre-visu,
	.infos-visus{
		img{
			border: solid 10px #FE921B;
		}
	}
}
// Image en mobile
.class_identifier_oeuvre .module--oeuvre-famille .sliderFicheOeuvre .bx-wrapper .bx-viewport .oeuvre img{
	border: solid 10px #FE921B;
}