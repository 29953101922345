.page--visiter-master{
	section{
		overflow: hidden;
	}
	.parcours-visiteurs{
		.bloc-button-gallerie .public-gallery-link{
			@extend %skin-btn-white;
			&:hover, &:active, &:focus{
				@extend %animation-btn-red;
			}
		}
		.gallery{
			padding: 0 75px;
  			text-align: center;
		}
		.vignette{
			width: 25%;
			background-position: center center;
			background-size: cover;
			padding-bottom: 37.5%; 
			margin:0 4.165% 60px;
			display: inline-block;
			position: relative;
			vertical-align: top;
			-webkit-transition: all 0.3s ease 0s;
			transition: all 0.3s ease 0s;
			color: #fff;
			&:hover {
				@extend %animation-btn-red;
			}
			.encart{
				background-color:#1d1d1e;
				width: 192px;
				min-height: 150px;
				font-family: $font-opensans-regular;
				font-size: 14px;
				font-size: 1.4rem;
				position: absolute;
				left: 0;
				top: 50%;
				margin-top: -75px;
			}
			.icon-eye{
				position: absolute;
				top: 20px;
				left: -10px;
				font-size: 18px;
				font-size: 1.8rem;
			}
			.public-parcours{
				text-align: right;
				padding-right: 20px;
				position: absolute;
				bottom: 10px;
				right: 0;
			}
			.title-parcours{
				font-family: $font-biko-bold;
				font-size: 2.7rem;
				font-size: 27px;
				letter-spacing: 0.075em;
				display: inline-block;
			}
			.username{
				font-family: "open_sansregular";
				font-size: 15px;
				font-size: 1.5rem;
				display: block;
			}	
		}

		@media (max-width: 1279px){
			.gallery{
				padding:0;
			}
			.vignette {
				width: 100%;
				position: relative;
				padding-bottom: 101.563%;
				margin:0;
			}
		}
		@media(max-width: 1279px) and (min-width: 640px){
			.vignette{
				margin-left: 0;
				margin-right: 0;
				.encart{
					height: 260px;
					width: 65%;
					padding: 47px 47px 0 47px;
					top: 50%;
					margin-top: -130px;
					left: 17.5%;
				}
				.icon-eye{
					left: 47px;
					top: 47px;
					font-size: 40px;
				}
				.public-parcours{
					font-size: 24px;
					font-size: 2.4rem;
					bottom: 40px;
					right: 40px;
					padding-left: 100px;
				}
				.title-parcours{
					font-size: 60px;
					font-size: 6rem;
					line-height: 50px;
  					margin-bottom: 10px;
				}
				.username{
					font-size: 36px;
					font-size: 3.6rem;
				}
			}
		}
		@media(max-width: 639px){
			.vignette{
				.encart{
					height: 130px;
					width: 65%;
					padding: 23px 23px 0 23px;
					top: 50%;
					margin-top: -65px;
					left: 17.5%;
				}
				.icon-eye{
					left: 23px;
					top: 23px;
				}
				.public-parcours{
					font-size: 12px;
					font-size: 1.2rem;
					bottom: 23px;
					right: 23px;
					padding-left: 70px;
				}
				.title-parcours{
					font-size: 41px;
					font-size: 4.1rem;
				}
				.username{
					font-size: 25px;
					font-size: 2.5rem;
				}
			}
		}

	}
	// .h2-builder{
	// 	@include titleBuilder('picto-left','','container-dark',140px,'picto-triangle');
	// }
	.h2-builder{
		.triangle{
			// margin-top: 5px;
		}
	}
	h3{
		font-family: $font-lovelo-black;
		font-size: 24px;
		font-size: 2.4rem;
		text-align: center;
		margin-bottom: 40px;
		letter-spacing: 0.05em;
	}
	.incontournable{
		padding: 50px 0 70px 0;
		background-color: #FFF;

		@media(max-width: 1279px){
			padding-bottom: 0;
			.gallery{
				margin-bottom: 0;
			}
		}
	}
}
.yosemite-hat{
	p:first-child{
		text-align: center;
	}
}
.mac{
	.gallery--profils-5 .vignette .profil {
		line-height:30px;
	}
}