/* line 168, ../sass/_nomenclature.scss */
header .searchInput .buttonInput{
	color: $default-light-font-color;
	font-size: 15px;
	font-size: 1.5rem;
	top: 10px;
	&:hover,
	&:focus,
	&:active{
		color: $default-hover-color;
	}
}

/* line 851, ../sass/_nomenclature.scss */
footer .pictoFooter {
  font-size: 48px;
  font-size: 4.8rem;

}

/* line 913, ../sass/_nomenclature.scss */
footer .pictoFooter::before {
  margin-right: 0;
}
