.arianne.recherche{
	background-color: #FFF;
	color: $default-dark-font-color;

	.searchEngine{
		.field{
			border: solid 4px #231F20;
			padding-left: 20px;
			height: 30px;
			margin-right: 15px;
			vertical-align: top;
			font-family: $font-atcoverlook-regular;
			font-size: 14px;
			font-size: 1.4rem;
			width: 310px;
			@include transition(all, 0.3s, ease, 0s);

			&:hover,
			&:active,
			&:focus{
				border-color: $default-hover-color;
			}
		}
		.send{
			width: 140px;
			background-color: #231F20;
			color: #FFF;
			text-align: center;
			display: inline-block;
			height: 30px;
			vertical-align: top;
			text-transform: uppercase;
			font-family: $font-lovelo-black;
			font-size: 14px;
			font-size: 1.4rem;
			border: solid 4px #231F20;
			padding-top: 2px;


			@include transition(all, 0.3s, ease,0s);

			&:focus,
			&:hover,
			&:active{
				color: #231F20;
				background-color: #FFF;
				border-color: $default-hover-color;
				color: $default-hover-color;
			}
		}
	}
}
.page--recherche{
	background-color: #FFF;
	overflow: auto;

	.results{
		margin-bottom: 30px;
		margin-top: 30px;
	}
	.actu-list--gabarit{
		.head{
			margin-bottom: 30px;

			.categorie{
				margin: 0;
			}

			.tiret{
				margin-left: 10px;
				margin-right: 10px;
			}
		}
	}
	@media(min-width: 1280px){
		.actu-list--gabarit{
			.results{
				margin-top: 30px;
			}
			.head{
				.date{
				}
				.tiret{
				}
				.categorie{
				}
			}
			.right{
				border-bottom: solid 1px $default-dark-font-color;
			}
			.contain--learn-more{
				float: right;
			}

			&:last-child{
				.right{
					border-bottom: none;
				}
			}
		}
	}
	@media(max-width: 639px){
		.actu-list--gabarit{
			.head{
				margin-bottom: 15px;
				.tiret{
					margin-left: 5px;
				}
				.categorie{
					margin-left: 0px;
				}
			}
		}
	}
}