#popup-first-connect{
    background-color: #fff;
    @extend %bones-popup;
    // display: block;

    form{
        p:nth-of-type(1){
            color:#d8383f;
            margin-bottom:10px;
        }
        p, label{
            font-family: "open_sanslight";
            font-size: 16px; font-size: 1.6rem;
        }
        label{
            margin-bottom:12px;
        }
        .date{
            position:relative;
            width:425px;
            margin-bottom:10px;
            margin-bottom: 10px;
            input{
                margin-bottom: 0;
            }
            &:before{
                content:'';
                position:absolute;
                top:50%; right:40px;
                transform: translateY(-50%);
                width:24px; height:24px;
                background: url(../images/icons/birthday.png) center no-repeat;
            }    
            @media(max-width:1279px){
                width:100%;
            }    
        }        
        .delete_avatar{
            width: 14px;
            height: 14px;
            display: inline-block;
            vertical-align: middle;
            opacity: 1;
            transition: opacity 0.3s;
            position: relative;
            &:before, &:after{
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                height: 3px;
                background-color: #535353;
                width: 14px;
                -webkit-transition: all, 0.5s;
                transition: all, 0.5s;
            }
            &:before{
                transform: translate(-50%, -50%) rotate(-45deg);
            }
            &:after{
                transform: translate(-50%, -50%) rotate(45deg);
            }
            &:hover, &:active, &:focus{
                opacity: 0.5;
            }
        }
        ul{
            font-size:0;
            padding:5px;
            background-color:#1d1d1e;
            max-height:270px;
            overflow:auto;
            margin-bottom:20px;
            li{
                width:89px;
                height:120px;
                margin:5px;
                display: inline-block;
                vertical-align: top;
                background-size:cover;
                background-repeat:no-repeat;
                background-position:center;
                [type="radio"]{
                    display: none;
                }
                [type="radio"]:checked{
                    & + label{
                        background-color:rgba(216, 56, 63, 0.86);
                        background-repeat:no-repeat;
                        background-position:center;          
                        background-image:url(../images/icons/check_avatar.png);              
                    }
                }
                label{
                    width:100%; height:100%;
                    padding:0; margin:0;
                    &:before, &:after{
                        content:none;
                    }
                }
            }
        }
        button[type="submit"]{
            font-size:24px; font-size:2.4rem;
        }
    }
}