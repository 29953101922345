.pop-up{
    @extend %bones-popup;

    %radio_red-back-white-tick{ // radio button display for #account-banner & #account-avatar
        input[type="radio"], input[type="checkbox"]{
            position: absolute;
            left:-99999px;
        }
        label{
            position:absolute;
            left:0;
            top: 0;
            height: 100%;
            width: 100%;
            background-color:transparent;

        }
        input[type="radio"]:checked + label, input[type="checkbox"]:checked + label{
            background-color:rgba(216, 56, 63, 0.86);
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBzdHlsZT0iZmlsbDogI2ZmZjsiIGQ9Ik00MzUgOThsLTM5LTM5Yy01LTUtMTItOC0xOS04IC04IDAtMTQgMy0xOSA4TDE3MCAyNDdsLTg0LTg0Yy01LTUtMTItOC0xOS04IC04IDAtMTQgMy0xOSA4TDggMjAyQzMgMjA3IDAgMjE0IDAgMjIxYzAgOCAzIDE0IDggMTlsMTAzIDEwMyAzOSAzOWM1IDUgMTIgOCAxOSA4IDggMCAxNC0zIDE5LThsMzktMzlMNDM1IDEzN2M1LTUgOC0xMiA4LTE5QzQ0MyAxMTAgNDQwIDEwNCA0MzUgOTh6Ii8+PC9zdmc+");
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: 40px;
        }

        #artwork-selection input[type="radio"]:checked + label, input[type="checkbox"]:checked + label{
            background-color:rgba(121,165,6, 0.86);
        }
    }

    background-color: #fff;

    .errors-wrapper{
        color:$default-secondary-color;
        font-family: $font-opensans-light;
        width: 425px;

        span{
            display: inline-block;
            margin-bottom:10px;
        }
    }


    form{
        max-width:100%;
        label{
            display: none;
        }
        button[type="submit"], input[type="submit"]{
            font-size:24px; font-size:2.4rem;min-height:55px;height:auto;
            background: transparent;
            @media(max-width:1279px){
                font-size:20px; font-size:2rem;

            }
        }

        &#account_create{  
            p:nth-of-type(1){
            color:#d8383f;
            margin-bottom:10px;
            }
            label{
                display: none;
            }
            .accept-cgu{
                label{ 
                    display: block;
                }
            }
            #accept-error{
                margin-top:10px;
            }
            select{
                @extend %input;
            }
            button[type="submit"]{
                margin-top:20px;
                font-size:24px; font-size:2.4rem;
            }
        }

        &#account-changepwd{
            input[type="password"]{
                font-size:19px;
                font-size:1.9rem
            }
        }

        &#account-delete{
            .checkbox-custom{
                padding: 10px 0 20px;
            }
        }

        &#account-avatar, &#first-connect{
            width: 430px;

            label{
                display: block;
                margin-bottom:9px;
            }

            ul{
                display:flex;
                flex-flow: row wrap;
                justify-content: space-between;
                background-color:#000;
                padding: 10px 0 0 10px;
                margin-bottom: 30px;
                height: 270px;
                overflow: auto;

                li{
                    width:90px;
                    height: 120px;
                    margin:0 10px 10px 0;
                    position:relative;
                    overflow:hidden;
                    background-size: cover;

                    &:last-child{
                        margin-right: auto;
                    }
                    
                    @extend %radio_red-back-white-tick;

                }
            }

            .birth_date-group{
                display: flex;
                %birthInputs{
                    padding:0;
                    text-align:center;
                    margin-right:10px;
                }
                #birth_date_DD, #birth_date_MM{
                    @extend %birthInputs;
                    max-width:80px;
                    flex:1;
                }
                #birth_date_YYYY{
                    @extend %birthInputs;
                    max-width:160px;
                    flex:2;
                    margin-right: 0;
                }
            }
        }

        &#first-connect{
            width: 435px;
            .form-group{
                margin-bottom:10px;
                .radio{
                    margin-bottom:0;
                }
                select{
                    @extend %input;
                    padding-right: 30px!important;
                }
            }
        }

        &#account-banner, &#artwork-selection{

            width:515px;
            
            label{
                display: block;
                margin-bottom:9px;
            }

            ul{
                // display:flex;
                // flex-flow: row wrap;
                // justify-content: space-between;
                background-color:#000;
                padding: 10px 0 0 10px;
                margin-bottom: 30px;
                height: 400px;
                overflow: auto;

                li{
                    width:calc( 50% - 10px);
                    height: 120px;
                    margin:0 10px 10px 0;
                    position:relative;
                    overflow:hidden;
                    background-size: cover;
                    display: block;
                    float:left;

                    &:last-child{
                        margin-right: auto;
                    }

                    @extend %radio_red-back-white-tick;

                    @media(max-width:639px){
                        width:100%;
                    }

                }
            }
        }

        &#artwork-selection, &#album-edit, &#goldenbook-add-comment, &.goldenbook-send-ecard{
            .select-custom{
                width:100%;
            }
            select{
                @extend %input;
            }
            textarea{
                @extend %input;
                height:150px;
                padding-top: 10px;
            }
            .label-switch{
                margin-bottom:30px;
            }
        }

        &#goldenbook-add-comment{
            width: 435px;
            .label-switch{
                margin-bottom:15px;
            }
            hr{
                border-color: #bcbec0;
                margin:10px -75px 20px;
            }
            p{
                margin-bottom:15px; 
                &:last-of-type{
                    margin-bottom: 30px;
                }
            }
        }

        &.goldenbook-send-ecard{
            width:525px;
            margin-top: 0;

            ul{
                display:flex;
                flex-flow: row wrap;
                justify-content: space-between;
                background-color:#000;
                padding: 10px 0 0 10px;
                margin-bottom: 30px;
                height: 400px;
                overflow: auto;

                li{
                    width:235px;
                    height: 190px;
                    // margin:0 10px 10px 0;
                    margin : 0 auto 10px;
                    position:relative;
                    overflow:hidden;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: 50%;
                    background-color: #1a1a1a;

                    &:last-child:nth-child(2n+1){
                        margin-right: auto;
                    }

                    label{
                        display: block;
                        margin-bottom:9px;
                    }
                    
                    @extend %radio_red-back-white-tick;

                }
            }
        }

    }
    
    .goldenbook-send-ecard-wrapper{
        width: 525px;
        max-width : 95vw;

        #tabs--ecard{
            display: flex;
            justify-content: space-between;
            text-align: center;
            padding: 4px;
            background-color:#000;
            margin-bottom: 35px;

            li{
                color: #fff;
                font-size: 16px;
                font-family: $font-biko-bold;
                width: calc(50% - 2px);
                height: 49px;
                line-height: 49px;

                button{
                    width: 100%;
                    &.active{
                        color:#000;
                        background-color:#fff;
                    }
                    &:not(.active):hover{
                        background-color: rgba(255, 255, 255, 0.1);
                    }
                }
            }
        }

        .tabs--fb{
            p{
                margin-bottom: 25px;
            }
            #linkfb{
                @extend %bones-btn;
                @extend %skin-btn-black;
                text-decoration: none;
                font-size: 24px;
                font-size: 2.4rem;
                min-height: 55px;
                line-height: 55px;
                height: auto;
                text-align: center;
                &:hover, &:active, &:focus{
                    @extend %animation-btn-red;
                }
            }
        }
        .tabs--mail{
            form{
                p{
                    margin-bottom: 15px;
                }
                > p:not(:first-of-type){
                    padding-top: 20px;
                }

                .text-content{
                    margin-bottom: 30px;
                }
            }
        }

    }
    
    &.diaporama-wrapper{
        width: 100%;
        height: 100%;
        max-height: unset;
        background-color: transparent;
        overflow: hidden;

        .diaporama-controls{
            position: absolute;
            z-index: 3;
            width:100%;
            top:50%;
            left:0;
            padding:0 100px;
            transform: translateY(-50%);
            display: flex;
            justify-content: space-between;

            button{
                font-size:30px;
                color:#fff;
                cursor: pointer;

                &:disabled{
                    opacity:0.2;
                    cursor: default;
                }
            } 
        }

        .diaporama-content-wrapper{
            height: 100%;
            width:100%;
            display:block;
            position:relative;
            z-index:2;

            .diaporama-title{
                color:#fff;
                text-align:left;
            }
            .diaporama{
                height: 100%;
                overflow: hidden;
                display: flex;
                transition: transform 0.5s;
                transition: -ms-transform 0.5s;
                -webkit-transition: -webkit-transform 0.5s;
                -moz-transition: -moz-transform 0.5s;

                li{
                    display: block;
                    height: 100%;
                    text-align: center;

                    figure{
                        display: inline-block;
                        height: 100%;
                        .item-visu{
                            height:auto;
                            max-height:calc(100% - 130px);
                        }
                        figcaption{
                            height:90px;
                            color:#fff;
                        }
                    }
                }
            }
        }
    }

    #albumDiaporama{}

}