/* IE 11 */
_:-ms-fullscreen .select-custom:after, :root .select-custom:after{ 
	display:none
}

/* IE >= 9 */
@media screen and (min-width:0\0) {
	.Home-page .logo-pba-mobile-hp a {
		background-position:-239px 1px;
		background-size:993.5px;
	}
	header .searchInput .buttonInput{
		top:4px;
	}
	header .headerRWD .blocLogo .logo p:first-child{
		letter-spacing: 0.15em;
	}
	.infos .headband-pba .logo .logo-content{
		background-size:200% auto;
		background-position: -197px 0;
	}
	.infos .container{
		background-position:-9px 40px;
	}
	@media (min-width:640px) and (max-width:1279px){
		.applications .mobile-separator,
		.applications .mobile-separator-white{
			left:50%;
			margin-left:-230px;
		}
	}
	@media (max-width:639px) {
		header .headerRWD .headerUp button {
			margin-top:-4px;
		}
		.Home-page .right .deco-tablette-bottom{
			background-position:-84px -17px !important;
			background-size:165% auto;
		}
		.Home-page .deco-tablette-bottom{
			background-size:167% auto;	
		}
		.Home-page .logo-pba-mobile-hp a{
			background-position:-113px 1px;
			background-size:479.5px;
		}
		.applications .mobile-separator,
		.applications .mobile-separator-white{
			left:50%;
			margin-left:-115px;
		}
	}
}
/*
@media screen\0 {
	.Home-page .logo-pba-mobile-hp a {
		background-position:-239px 1px;
		background-size:993.5px;
	}
	header .searchInput .buttonInput{
		top:4px;
	}
	header .headerRWD .blocLogo .logo p:first-child{
		letter-spacing: 0.15em;
	}
	.infos .headband-pba .logo .logo-content{
		background-size:200% auto;
		background-position: -197px 0;
	}
	.infos .container{
		background-position:-9px 40px;
	}
	@media (min-width:640px) and (max-width:1279px){
		.applications .mobile-separator,
		.applications .mobile-separator-white{
			left:50%;
			margin-left:-230px;
		}
	}
	@media (max-width:639px) {
		.Home-page .right .deco-tablette-bottom{
			background-position:-84px -17px !important;
			background-size:165% auto;
		}
		.Home-page .deco-tablette-bottom{
			background-size:167% auto;	
		}
		.Home-page .logo-pba-mobile-hp a{
			background-position:-113px 1px;
			background-size:479.5px;
		}
		.applications .mobile-separator,
		.applications .mobile-separator-white{
			left:50%;
			margin-left:-115px;
		}
	}
}

@media all and (-ms-high-contrast:none){
	.Home-page .logo-pba-mobile-hp a {
		background-position:-239px 1px;
		background-size:993.5px;
	}
	header .searchInput .buttonInput{
		top:4px;
	}
	header .headerRWD .blocLogo .logo p:first-child{
		letter-spacing: 0.15em;
	}
	.infos .headband-pba .logo .logo-content{
		background-size:200% auto;
		background-position: -197px 0;
	}
	.infos .container{
		background-position:-9px 40px;
	}
	@media (min-width:640px) and (max-width:1279px){
		.applications .mobile-separator,
		.applications .mobile-separator-white{
			left:50%;
			margin-left:-230px;
		}
	}
	@media (max-width:639px) {
		.Home-page .right .deco-tablette-bottom{
			background-position:-84px -17px !important;
			background-size:165% auto;
		}
		.Home-page .deco-tablette-bottom{
			background-size:167% auto;	
		}
		.Home-page .logo-pba-mobile-hp a{
			background-position:-113px 1px;
			background-size:479.5px;
		}
		.applications .mobile-separator,
		.applications .mobile-separator-white{
			left:50%;
			margin-left:-115px;
		}
	}
}*/