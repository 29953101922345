#tags{
    margin-top:30px;
    margin-bottom:30px;
    width: 625px;
    @media(max-width:1279px){
        width:100%;
    }
    ul{
        display: flex;
        align-items:center;
        @media (max-width:1279px){
            display:block;
        }
        li{
            margin-right:5px;
            margin-bottom:0px;
            &:before{
                content:none;
            }
            a{
                font-family: 'open_sansregular';
                font-weight: 400;
                color:#1d1d1e;
                font-size:16px; font-size:1.6rem;
                height:30px;
                padding:0px 5px;
                display: flex;
                align-items: center;
                border:3px solid #1d1d1e;
                transition: all 0.3s;
                &:nth-last-of-type(1){
                    margin-right:0px;
                }
                &:hover, &:active, &:focus{
                    background-color:#1d1d1e;
                    color:#fff;
                }
            }
        }
    }
}