/* ACCES RAPIDES (gabarit 2) */
.acces-rapides{
	.svg-picto-empty{
		height: 48px;
		width: 48px;
		margin-bottom: 40px;
	}
	.item{
		&:hover,
		&:focus,
		&:active{
			color: $default-hover-color;
			.svg-picto-empty{
				.st0{
					
				}
			}
		}
	}
}

// Slider Général
.sliderGabarit{
	&.wide{
		margin-bottom: 45px;
		@media(max-width: 639px){
			margin-bottom: 22.5px;
		}
	}
	position:relative;
	max-width: 100%;
	.bxSlider{
		margin-bottom:0px;
	}
	.bx-wrapper{
		margin-bottom:0px;
		.bx-viewport{
			height:290px;	
			border: 0px solid #FFF;
			left: 0px;
			box-shadow: none;
		}
	}

	.pagerSquare{
		position:absolute;
		bottom:10px;
		right:12px;
		a{
			margin-right:6px;
			display: inline-block;
			&:last-child{
				margin-right:0px;
			}
		}
		.pager-square{
			width:24px;
			height:24px;
			background-color:transparent;
			border:4px solid $default-light-font-color;
			display: inline-block;
			vertical-align:top;
		}
		a.active{
			background-color:$default-light-font-color;
			display: inline-block;
			height: 22px;
		}
	}
	@media(max-width: 1279px){
		.arrowSlider{
			display:none;
		}
		.pagerSquare{
			width:100%;
			text-align: center;
			right: 0;
		}
	}
	@media(max-width: 639px){
		.pagerSquare{
			position:absolute;
			bottom:10px;
			right:0px;
			a{
				margin-right:6px;
				display: inline-block;
				&:last-child{
					margin-right:0px;
				}
			}
			.pager-square{
				width:12px;
				height:12px;
				background-color:transparent;
				border:2px solid $default-light-font-color;
				display: inline-block;
				vertical-align:top;
			}
			a.active{
				background-color:$default-light-font-color;
				display: inline-block;
				height: 11px;
			}
		}
	}
	.bxslider{
		ul{
			margin-top: 0px;
			margin-left:0px;
		}
		li{
			height:290px;
			width:510px;
			margin-top: 0px;
			margin-left:0px;
			background-size: cover;
			background-position: center center;
		}
	}
	.arrowSlider{
		position:absolute;
	}
	.prevPage{
		left:-110px;
		top:50%;
		font-size:47px;
		font-size:4.7rem;
		.icon{
			&:hover,
			&:active,
			&:focus{
				color: $default-hover-color;
			}
		}
	}
	.nextPage{
		right:-110px;
		top:50%;
		font-size:47px;
		font-size:4.7rem;
		.icon{
			&:hover,
			&:active,
			&:focus{
				color: $default-hover-color;
			}
		}
	}

	// Responsive
	@media(max-width: 1279px){
		.prevPage{
			left: 30px;
			color: white;
		}
		.nextPage{
			right: 30px;
			color: white;
		}
	}
	@media(max-width: 639px){
		.prevPage{
			left: 15px;
		}
		.nextPage{
			right: 15px;
		}
	}
}
 
// Slider Caption
.slider-caption{
	margin-top: 20px;
	margin-bottom: 60px;
	text-align: center;

	@media(max-width: 639px){
		margin-top: 10px;
		margin-bottom: 30px;
	}
} 

// Scroll TO
.icon.has-scroll{
	font-size: 20px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	width: 50px;
	clear: both;
	@media(max-width: 1279px){
		display: none !important; 
	}
	&.icon-chevron-down{
		@include transition(all, 0.3s, ease, 0s);
	}
	&.icon-chevron-up{
		@include transition(all, 0.3s, ease, 0s);
	}
}

// ACtus

.actu-list--gabarit{
	display: block;
	margin-left: auto;
	margin-right: auto;
	@include actuBuilder('gabarit');
	overflow: auto;
} 

// Mini actu avec relative
.miniActu{
	@include actuBuilder('miniActu');
}
// Actu Agenda
.actu-list--agenda{
	@include actuBuilder('agenda');
}

// ANCHORDS

.banner--anchor-4{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;

	.scrollTo--picto{
		margin-left: 0;
		margin-right: 0;

		@media(min-width: 1280px){
			margin-bottom: 65px;

			&:nth-child(7){
				width: 100%;
			}
			.innerContent{
				width: 275px;
				word-wrap: break-word;
			}
		}
		@media(max-width: 1279px){
			padding-left: 0;

			.icon{
				margin-left: 45px;
			}
			.innerContent{
				width: calc(100% - 130px);
				width: -moz-calc(100% - 130px);
				width: -webkit-calc(100% - 130px);
			}
		}
		@media(max-width: 1279px){
			.icon{
				margin-left: 22.5px;
			}
			.innerContent{
				width: calc(100% - 130px);
				width: -moz-calc(100% - 130px);
				width: -webkit-calc(100% - 130px);
				font-size: 36px;
				font-size: 3.6rem;
			}
		}
		@media(max-width: 639px){
			margin-bottom: 23px;
			.icon{
				margin-left: 5px;
				margin-right: 11.25px;
				width: 42.5px;
				font-size: 25px !important;
				font-size: 2.5rem !important;

				&.icon-key{
					position: relative;
					font-size: 20px;
					font-size: 2.0rem;
				}
			}
			.innerContent{
				width: calc(100% - 80px);
				width: -moz-calc(100% - 80px);
				width: -webkit-calc(100% - 80px);
				font-size: 18px;
				font-size: 1.8rem;
			}
		}
	}
	.icon{
		font-size: 36px;
		font-size: 3.6rem;
		vertical-align: middle;
		width: 85px;
		text-align: center;
		&.icon-key{
			font-size: 30px;
			font-size: 3.0rem;
		}
		&.icon-chef-oeuvre{
			font-size: 37px;
			font-size: 3.7rem;
		}
		&.icon-parcours{
			font-size: 38px;
			font-size: 3.8rem;
		}
		&.icon-musee-prive{
			font-size: 43px;
			font-size: 4.3rem;
		}
		&.icon-frise{
			font-size: 35px;
			font-size: 3.5rem;	
		}
		&.icon-collection{
			font-size: 39px;
			font-size: 3.9rem;
		}
		&.icon-workshop{
			font-size: 48px;
			font-size: 4.8rem;
		}
		&.icon-parent{
			font-size: 50px;
			font-size: 5.0rem;
		}
		&.icon-visio{
			font-size: 52px;
			font-size: 5.2rem;
		}
		&.icon-tablets{
			font-size: 44px;
			font-size: 4.4rem;
		}
		&.icon-projects{
			font-size: 44px;
			font-size: 4.4rem;
		}
		&.icon-architecture{
			font-size: 26px;
			font-size: 2.6rem;
		}
	}
}
.banner--anchor-6{
	.scrollTo--picto{
		@media(min-width: 1280px){
			margin-bottom: 25px;
		}
	}
}


// Titres H2
.page--collections--master{
	.chef-oeuvre, .musee, .collections{
		.h2-builder{
			@include titleBuilder('','picto-top','container-dark',280px,'picto-triangle');
		}
	}
	.parcours, .secrets{
		.h2-builder{
			@include titleBuilder('','picto-top','container-light',280px,'picto-triangle');
		}
	}
}
.page--visiter-master{
	.h2-builder{
		@include titleBuilder('picto-left','','container-dark',140px,'picto-triangle');
	}
}
.page--collections--oeuvre{
	.h2-builder{
		@include titleBuilder('picto-left','','container-dark',135px,'picto-triangle');
	}
}
.page--agenda{
	.h2-builder{
		@include titleBuilder('','picto-top','container-light',260px,'picto-triangle');
	}
}
.gabarit-1.artips{
	.h2-builder{
		@include titleBuilder('','picto-top','container-light',280px,'picto-triangle');
	}
}
	// H2 secrets
	.h2-secrets{
		.subtitle{
			font-size: 18px;
			font-size: 1.8rem;
			font-family: $font-atcoverlook-regular;
			text-transform: none;
			margin-top: 3px;

			.icon-artips{
				display: inline-block !important;
				font-size: 18px !important;
				font-size: 1.8rem !important;
				margin-bottom: 0 !important;
				margin-left: 5px;
				width: auto !important;
			}
		}
	}

// CalltoActions
.callToAction{
	// Sans Picto
	&--light{
		@include buttonBuilder(560px, 85px, 'light','callToAction');

		&-wide{
			@include buttonBuilder(560px, 85px, 'light','callToAction');	
		}
	}
	&--dark{
		@include buttonBuilder(560px, 85px, 'dark','callToAction');

		&-wide{
			@include buttonBuilder(560px, 85px, 'dark','callToAction');
		}
	}

	// Avec Picto
	&--picto-light{
		@include buttonBuilder(550px, 85px, 'light','callToAction');
		@include buttonPictoBuilder('agenda', 18px, 'dark');

		&-wide{
			@include buttonBuilder(560px, 85px, 'light','callToAction');
			@include buttonPictoBuilder('agenda', 18px, 'dark');
		}
	}
	&--picto-dark{
		@include buttonBuilder(550px, 85px, 'dark','callToAction');
		@include buttonPictoBuilder('agenda', 18px, 'dark');

		&-wide{
			@include buttonBuilder(560px, 85px, 'dark','callToAction');
			@include buttonPictoBuilder('agenda', 18px, 'light');
		}
	}
}

// LOCKED PICTURES (Sliderable)
.page--collections--master{
	.musee, .collections{
		.locked-picture{
			@include lockedPictureBuilder(495px, 660px);
		}
	}
}
.page--agenda{
	.locked-picture{
		@include lockedPictureBuilder(510px, 795px);
	}
}

// LOCK BAR
.page--collections--master{
	.musee{
	}
}


// Pagination
.pagination{
	&--dark{
		@include paginationBuilder('dark');
	}
	&--light{
		@include paginationBuilder('light');
	}
}






section > *:last-child{
	&.gallery{
		margin-bottom: 0px !important;
	}
	&.slider{
		margin-bottom: 0px !important;
	}
	&.stand-alone{
		margin-bottom: 0px !important;
		
	}
}
section{
	overflow: auto;
}