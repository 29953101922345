/* Couleurs */

$default-dark-font-color: #1D1D1E;
$lighter-dark-font-color: #2C2C2E;
$default-light-font-color: #FFF;
$default-hover-color: #D8383F;
$default-secondary-color: #D8383F;
$main-bright-grey: #7B7C7E;
$main-darker-grey: #77787B;
$lighter-bright-grey: #BCBEC0;
$grey-line:#E6E7E8;
$default-green-color:#00B899;

// Couleurs Actus
$color-expositions: rgba(184,18,49,1);
$color-visite: rgba(147,82,151,1);
$color-invite: rgba(181,209,121,1);
$color-evenements: rgba(211,51,108,1);
$color-actu: rgba(0,177,190,1);
$color-conference: rgba(111,188,154,1);
$color-jeune: rgba(121,165,6,1);
$color-nocturne: rgba(28,116,188,1);
$color-atelier: rgba(243,101,35,1);

/* Boutons */
$default-button-picto-padding: 35px;
$default-button-border-width: 3px;
$button-archives-picto-url: '../images/stamp.png';
$button-more-picto-url: '../images/more.svg';

/* Fonts */
$font-opensans-regular : 'open_sansregular'; // Corps de texte
$font-opensans-light: 'open_sanslight';
$font-opensans-light-italic: 'open_sanslight_italic';
$font-opensans-bold: 'open_sansbold';
$font-lovelo-black: 'loveloblack';	// Gros titres et Boutons
$font-biko-bold: 'bikobold'; // Petits titres et boutons
$font-biko-regular: 'bikoregular'; //Titre des oeuvres dans collection
$font-novecentowide-regular: 'novecento_widenormal'; // Sous titre  description
$font-novecento-sans-wide-bold: 'novecento_sans_wide_bookbold';
$font-novecento-sans-wide-book: 'novecento_sans_widebook';
$font-novecento-wide-bold: 'novecento_wide_boldbold';
$font-novecento-widebook: 'novecento_widebook'; //Texte footer  AJOUT
$font-novecento-widenormal: 'novecento_widenormal';//Sous-Titre gabarit 1 AJOUT
$font-atcoverlook-regular: 'atc_overlookregular'; // page visiter : Vous êtes
$font-baron-neue-normal50: 'baron_neuenormal_50';// sous-titre hp

/* RWD */
$break-small: 767;
$break-large: 1200;