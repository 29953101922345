.class_group_content.class_identifier_page_wide .page--collections-musee{
	.banner--anchor-4{
		.scrollTo--picto{
			.icon-architecture{
				font-size: 27px;
				font-size: 2.7rem; 
			}
			&:nth-child(n){
				@media(min-width:1280px){
					text-align: left;
					padding-left: 40px;
					.innerContent{
						margin-left: 20px;
						width: 78%;
						text-align: left;
					}
					.icon{
						width: auto;
					}
				}
			}
			&:nth-child(-n+2){
				.innerContent{
					width: 80%;
					letter-spacing: normal;
				}
				@media(max-width: 1279px){
					.innerContent{
						width: 75%;
					}
				}
			}
			&:nth-child(n+3){
				.innerContent{
					text-align: center;
				}
			}
			.subtitle{
				font-family: $font-atcoverlook-regular;
				font-size: 18px;
				font-size: 1.8rem;

				@media(max-width: 639px){
					font-size: 9px;
					font-size: 0.9rem;
				}
				.icon-artips-text{
					font-size: 20px !important;
					font-size: 2.0rem !important;
					display: inline-block;
					margin-bottom: 0;
					@media(max-width: 1199px){
						width: auto;
						margin: 0;
					}
				}
			}
		}
	}
	.title-icon{
		&.icon-architecture{
			font-size: 63px;
			font-size: 6.3rem;
		}
		.subtitle{
			margin-top: 10px;
		}
		.innerContent{
			display: inline-block;
			vertical-align: middle;
			color: white;
			font-family: $font-atcoverlook-regular;
			font-size: 18px;
			font-size: 1.8rem;
			margin-right: 3px; 
		}
		.icon-artips-text{
			font-size: 20px;
			font-size: 2.0rem;
			color: $default-dark-font-color;
		}
		.has-background--white{
			position: relative;
				content: '';
				width: 100%;
				height:30px; 
				background-color: #FFF;
				display: inline-block;
				width: 28px;
				left: 0;
		}
	}
	.section--wide-1{
		.title-icon{
			.innerContent{
				color: $default-dark-font-color;
			}
		}
	}
	.container--wide-2{
		top: 75px;
	}
	.callToAction--dark{
		margin-bottom: 40px;
	}
}
#scroll2 .subtitle .icon-artips-text{
	color: white !important;

	.gabarit-1.artips &{
		color: inherit !important;
	}
}