html{
	font-size: 10px;
}
body{
	color: $default-dark-font-color;
	background-color: $default-dark-font-color;
	font-family: $font-opensans-regular;
	font-size: 16px;
	font-size: 1.6rem;
	overflow-x: hidden;
}
strong{
	font-family: $font-opensans-bold;
}
button{
	&:focus{
		outline: dotted 1px $default-hover-color;
	}
}
/* Container */
.global-container{
	width: 1280px;
	margin: 0 auto;
}
.container{
	width: 1280px;
	margin: 0 auto;
	padding: 0;
}
.container--full-mobile{
	width: 1280px;
	margin: 0 auto;
	padding: 0;
}
.container--wide--padding-free{
	width: 1090px;
	margin: 0 auto;
}
.container-thin{
	width: 715px;
	padding: 0px 105px 0px 105px;
	margin: 0 auto;
}
/* Titre Niveau 2 */
h2{
	font-family: $font-biko-bold;
	font-size: 24px;
	font-size: 2.4rem;
	text-transform: uppercase;
	letter-spacing: 0.031em;

}

/* YOSEMITE RTE */
.yosemite-rte{
	font-family: $font-opensans-regular;
	font-size: 16px;
	font-size: 1.6rem;
	line-height: 1.2em;
	h2{
		font-family: $font-lovelo-black;
		font-size:28px;
		font-size: 2.8rem;
		//letter-spacing: 0.1em;
		margin-bottom:15px;
	}
	h3{
		font-family: $font-biko-bold;
		font-size: 20px;
		font-size: 2.0rem;
		margin-bottom: 25px;
		margin-top: 40px;
	}
	p{ 
		margin-bottom: 15px;
		font-family:$font-opensans-regular;
	}
	strong{
		font-family: $font-opensans-bold;
	}
	li{
		&:before{
			content: '\2022';
			display: inline-block;
			font-family: $font-opensans-bold;
			margin-right: 3px;
		}
		margin-bottom: 23px;
		list-style-type: none;
		font-family: $font-opensans-regular;
	}
	.mailto{
		color: $default-hover-color;
		display: block;
	}
	.button--rte{
		@include buttonBuilder(278px, 30px, 'dark', 'rte');
	}
	@media(max-width: 1279px) and (min-width: 640px){
		font-size: 26px;
		font-size: 2.6rem;
		h2{
			font-size: 37px;
			font-size:3.7rem;
		}
		h3{
			font-size: 26px;
			font-size: 2.6rem;
		}
	}
	@media(max-width: 639px){
		font-size: 13px;
		font-size: 1.3rem;

		p{
			margin-bottom: 7.5px;
		}
		h2{
			font-size: 18.5px;
			font-size:1.85rem;
		}
		h3{
			margin-top: 20px;
			margin-bottom: 12.5px;
		}
		li{
			margin-bottom: 16.5px;
		}
	}
}
.rte--small{
	font-size: 14px !important;
	font-size: 1.4rem !important;
}

.has-scroll.icon-chevron-down, .has-scroll.icon-chevron-up{
	&:hover,
	&:focus{
		color: $default-hover-color;
	}
}



//Liens d'écitements
.hidden-tablet, .hidden-tablet{
	display:inline-block;
}
@media(max-width: 1279px) and (min-width: 640px){
	.hidden-tablet{
		display: none;
	}
}

@media(max-width: 639px){
	.hidden-phone{
		display:none;
	}
}