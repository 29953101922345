
.scrollTo{
	&--picto{
		@include buttonBuilder(420px, 100px, 'dark', 'scrollTo');
		@include buttonPictoBuilder('picto', 18px, 'dark');

	}
}

.anchor-contain{
	position: relative;
	top: 120px;

	@media(max-width:1279px){
		top: 70px;
	}
	@media(max-width:639px){
		top: 35px;
	}
}
.container--wide{
	width: 985px;
	margin: 0 auto;
	@media(max-width: 1279px){
		width: 100%;
		padding-left: 55px;
		padding-right: 55px;
		// padding-top: 85px !important;
	}
	@media(max-width: 639px){
		width: 100%;
		padding-left: 27.5px;
		padding-right: 27.5px;
		// padding-top: 42.5px !important;
	}
}
.banner--wide{
	background-position: center center;
	background-size: cover;
	padding-bottom: 185px;
	min-height: 620px;
	position: relative;
	#scroll0{
		.icon-chevron-down{
			color: white;
		}
	}
	@media(max-width: 1279px){
		min-height: 1015px;
	}
	@media(max-width: 639px){
		min-height: 507.5px;
	}
}

.class_group_content.class_identifier_page_wide, .class_group_content.class_identifier_page_thin {
	background-color: white;
	.icon-chevron-up.has-scroll{
		margin-bottom: 40px;
	}
	.sliderGabarit{
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 1010px;
	}
	.section--wide-1,.section--wide-2{
		overflow: visible;
	}
	.skiptocontent{
		// padding-bottom: 100px;
		background-color: #FFF;
		// overflow: auto;
	}
	h2{
		font-family: $font-lovelo-black;
		font-size: 28px;
		font-size: 2.8rem;
		margin-top: 40px;
		@media(max-width: 639px){
			font-size: 14px;
			font-size: 1.4rem;
			margin-top: 20px;
		}
	}
	h3{
		font-family: $font-biko-bold;
		font-size: 20px;
		font-size: 2.0rem;
		margin-bottom: 25px;
		margin-top: 40px;
	}
	.triangle{
		width: 0;
		height: 0;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-top: 10px solid #FFF;
		display: inline-block;
		margin-top: 20px;

		@media(max-width: 639px){
			margin-top: 10px;
			border-width: 5px;
		}
	}
	.title-icon{
		.icon{
			font-size: 72px;
			font-size: 7.2rem;
			@media(max-width: 639px){
				font-size: 36px;
				font-size: 3.6rem;
			}
		}
	}
	.banner--wide-2,.banner--3{
		.title-icon{
			.icon{
				color: white;
			}
		}
	}

	// Textes WYSIWYG
	.rte-contain{
		width: 540px;
		background-color: #FFF;
		text-align: left;
		display: inline-block;
		display: block;
		margin-left: auto;
		margin-right: auto;
		overflow: hidden;

		.yosemite-rte:first-child{
			margin-top: 40px;
			@media(max-width: 639px){
				margin-top: 20px;
			}
			*:first-child{
				margin-top: 0;
			}
		}


		&.col-2{
			width: 900px;
			padding: 45px 40px 0;
		}
		.rte-col-left{
			width: calc(50% - 40px);
			background-color: #FFF;
			display: inline-block;
		}
		.rte-col-right{
			@extend .rte-col-left;
			@media(min-width: 1280px){
				float: right;
			}			
		}

		@media(max-width: 1279px){
			width: 100%;
			padding-left: 55px;
			padding-right: 55px;
			&.col-2{
				width: 100%;
			}
			.rte-col-left{
				width: 100%;
			}
		}
		@media(max-width: 639px){
			width: 100%;
			padding-left: 27.5px;
			padding-right: 27.5px;
			margin-bottom: 30px;
			&.col-2{
				width: 100%;
				margin-bottom: 30px;
			}
			.rte-col-left{
				width: 100%;
			}
		}
	}
	// CALL TO ACTIONS
	.callToAction--dark{
		@include buttonBuilder(500px, 85px, 'dark','callToAction');
		// @include buttonPictoBuilder('agenda', 18px, 'dark');

		@media(max-width: 1279px){
			// display: none;
		}
	}
	// Section 1
	.section--wide-1{
		background-color: #FFF;
		padding-bottom: 40px;

		.yosemite-rte:first-child{
			margin-top: 60px;
			@media(max-width: 639px){
				margin-top: 30px;
			}
			*:first-child{
				margin-top: 0;
			}
		}

		.svg-picto-empty{
			height: 72px;
		}
		.container--wide{
			position: relative;
			top: -130px;
			margin-bottom: -130px;
			padding: 55px 40px 0px 40px;
			background-color: #FFF;
			min-height: 130px;
		}
		.callToAction--dark{
			margin-bottom: 30px;
		}
		// ScrollTo
		.svg-picto-scrollTo{

			.st0{
				stroke: $default-dark-font-color;
			}
		}

		@media(max-width: 1279px){
			.container--wide{
				top: 0;
				margin-bottom: 0;
				padding-left: 0;
				padding-right: 0;
				padding-bottom: 0;
			}
		}
		@media(max-width: 1279px){
			.callToAction--dark{
				margin-bottom: 0;
			}
		}
	}

	// Section 2
	.banner--wide-2{
		@media(max-width: 1279px){
			min-height: 345px;
			//border-bottom: solid 1px #FFF;
		}
		@media(max-width: 639px){
			min-height: 172.5px;
		}
	}
	.container--wide-2{
		position: relative;
		top: 70px;
		h2{
			color: $default-light-font-color;
		}
		@media(max-width: 1279px){
			position: absolute;
			bottom: 60px;
		}
	}
	.section--wide-2{
		background-color: #FFF;
		.has-scroll{
			margin-bottom: 40px;
		}
		h2{
			color: $default-light-font-color;
		}
		.container--wide{
			width: 1090px;
			position: relative;
			top: -295px;
			margin-bottom: -295px;
			padding: 45px 40px 0px 40px;
			background-color: #FFF;
			min-height: 295px;

			@media(max-width:1279px){
				min-height: 0;
			}
		}
		.actu-list--gabarit{
			@include actuBuilder('gabarit');
			overflow: auto;
		}
		.callToAction--picto-agenda{
			@include buttonBuilder(550px, 85px, 'dark',true);
			@include buttonPictoBuilder('archive', 18px, 'dark'); 
			margin-bottom: 30px;
		}
		// ScrollTo
		.svg-picto-scrollTo{
			.st0{
				stroke: $default-dark-font-color;
			}
		}
		@media(max-width: 1279px){
			
			.container--wide{
				width: 100%;
				top: 0;
				margin-bottom: 0;
				padding: 0;
			}
		}
		@media(max-width: 1279px) and (min-width: 640px){
			
		}
		@media(max-width: 639px){
			
		}
	}

	// Section 3
	// .container--wide-3{
	// 	@extend .container--wide-2;
	// }
	// .section--wide-3{
	// 	background-color: #FFF;
	// 	.container--wide{
	// 		width: 1045px;
	// 		position: relative;
	// 		top: -295px;
	// 		margin-bottom: -295px;
	// 		padding: 15px 40px 40px 40px;
	// 		background-color: #FFF;
	// 		min-height: 295px;
	// 	}
	// 	.miniActu{
	// 		@include actuBuilder('miniActu');
	// 	}
	// 	// ScrollTo
	// 	.svg-picto-scrollTo{
	// 		.st0{
	// 			stroke: $default-dark-font-color;
	// 		}
	// 	}
	// }
	.download-title{
		margin-bottom: 40px;
	}
	.download--wide-4{
		margin-bottom: 35px;
		margin-top: 10px;
		@media(max-width: 639px){
			margin-bottom: 17.5px;
		}

		& + .callToAction--dark{
			margin-top: 25px;
		}
	}
	.lock-bar--down{
		@include lockBarBuilder(900px, 28px, 'down', 'dark');
		// margin-top: 80px;
	}
	.acces-rapides{
		width: 825px;
		display: inline-block;
		margin-bottom: 170px;
		margin-top: 65px;
		text-align: center;
		.item{
			display: inline-block;
			text-align: center;
			width: 272px;
			vertical-align: top;
			padding-left: 30px;
			padding-right: 30px; 

			&:first-child{
				padding-left: 0;
			}

			&:nth-child(n+3){
				padding-right: 0;
			}
		}
		.access-title{
			@include transition(all, 0.3s,ease,0s);
			font-size: 16px;
			font-size: 1.6rem;
			.icon{
				position: relative;
				top: -1px;
			}
			&:before{

			}
		}
		.icon-administrable{
			font-size: 47px;
			font-size: 4.7rem;
			margin-bottom: 35px;

			.icon{
				position: relative;
				top: -1px;
			}
		}
	}
	@media(max-width: 1279px){
		.banner--wide.banner--3{
			min-height: 345px;
		}
		.section--wide-3{
			.container--wide{
				top: 0;
				margin-bottom: 0;
				width: 100%;
				padding: 0;
			}
		}
		.article-actu{
			.callToAction--pictoAfter{
				.svg-picto-more{
					width: 17px;
					height: 17px;
				}
			}
		}
	}
	@media(min-width: 640px) and (max-width: 1279px){
		
	}
	@media(max-width: 639px){
		.download-title{
			margin-top: 20px;
			margin-bottom: 20px;
			padding-left: 27.5px;
			padding-right: 27.5px;
		}
	}


	@media(max-width: 1279px){
		.callToAction--picto-agenda{
			display: none !important;
		}
		.sliderGabarit{
			.nextPage,.prevPage{
				display: none;
			}
		}
	}
	@media(min-width: 640px) and (max-width: 1279px){		
	}
	@media(max-width: 639px){
		.svg-picto-scrollTop{
			display: none !important;
		}	
	}
}