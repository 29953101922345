/* Container */
@media(min-width:640px) and (max-width: 1279px){
	.global-container{
		width:100%;
	}
	.container{
		width: 100%;
		padding:0 55px;
		margin: 0 auto;
	}
	.container-padding-40{
		width: 100%;
		padding: 0 40px;
		margin: 0 auto;
	}
	.container--full-mobile{
		width: 100%;
	}
	.container--wide--padding-free{
		width: 100%;
	}
	.container-thin{
		width: 100%;
		padding:0 5%;
		margin: 0 auto;
	}
	.button{
		&--dark{
			@include buttonBuilder(100%, 85px, 'dark', 'basic');
		}
		&--light{
			@include buttonBuilder(100%, 85px, 'light',  'basic');
		}
	}
	.callToAction{
		&--light{
			@include buttonBuilder(100%, 85px, 'light','callToAction');
		}
		&--dark{
			@include buttonBuilder(100%, 85px, 'dark','callToAction');
		}
		&--picto-archive{
			@include buttonBuilder(100%, 85px, 'dark','callToAction');
			@include buttonPictoBuilder('archive', 18px, 'dark');
		}
		&--picto-agenda{
			@include buttonBuilder(100%, 85px, 'light','callToAction');
			@include buttonPictoBuilder('agenda', 18px, 'dark');
		}
		&--pictoAfter{
			@include buttonBuilder(auto, 85px, 'dark','more');
			@include buttonPictoBuilder('more', 18px, 'dark');
		}
	}
	.scrollTo{
		&--agenda{
			@include buttonBuilder(100%, 100px, 'dark', 'scrollTo');
			@include buttonPictoBuilder('agenda', 18px, 'dark');
			.svg-picto-agenda{
				top: 30px;
			}
		}
	}
	.overPicture{
		@include buttonBuilder(100%, 85px, 'light', 'callToAction');
	}
}
@media(max-width: 639px){
	.global-container{
		width:100%;
	}
	.container{
		width: 100%;
		padding:0 27.5px;
		margin: 0 auto;
	}
	.container--full-mobile{
		width: 100%;
	}
	.yosemite-rte h2 {
	    font-size: 16px;
	    font-size: 1.6rem;
	}
	.container-padding-40{
		width: 100%;
		padding: 0 20px;
		margin: 0 auto;
	}
	.container--wide--padding-free{
		width: 100%;
	}
	.container-thin{
		width: 100%;
		padding:0 5%;
		margin: 0 auto;
	}
	.button{
		&--dark{
			@include buttonBuilder(100%, 43px, 'dark', 'basic');
		}
		&--light{
			@include buttonBuilder(100%, 43px, 'light',  'basic');
		}
	}
	.callToAction{
		&--light{
			@include buttonBuilder(100%, 43px, 'light','callToAction');
		}
		&--dark{
			@include buttonBuilder(100%, 43px, 'dark','callToAction');
		}
		&--picto-archive{
			@include buttonBuilder(100%, 43px, 'dark','callToAction');
			@include buttonPictoBuilder('archive', 18px, 'dark');
		}
		&--picto-agenda{
			@include buttonBuilder(100%, 43px, 'light','callToAction');
			@include buttonPictoBuilder('agenda', 18px, 'light');
		}
		&--pictoAfter{
			@include buttonBuilder(100%, 43px, 'dark','basic');
			@include buttonPictoBuilder('more', 18px, 'dark');
		}
	}
	.scrollTo{
		&--agenda{
			@include buttonBuilder(100%, 100px, 'dark', 'scrollTo');
			@include buttonPictoBuilder('agenda', 18px, 'dark');
			.svg-picto-agenda{
				top: 44%;
			}
		}
	}
	.overPicture{
		@include buttonBuilder(100%, 51px, 'light', 'callToAction');
	}
}