.yosemite-rte{
	font-family: $font-opensans-regular;
	font-size: 16px;
	font-size: 1.6rem;
	line-height: 1.2em;

	h3{
		font-family: $font-biko-bold;
		font-size: 20px;
		font-size: 2.0rem;
		margin-bottom: 25px;
		margin-top: 40px;
	}
	p{ 
		margin-bottom: 15px;
	}
	a{
		font-weight: bold;
		&:hover, &:focus, &:active{
			text-decoration: underline;
		}
	}
	strong{
		font-family: $font-opensans-bold;
	}
	li{
		&:before{
			content: '\2022';
			display: inline-block;
			font-family: $font-opensans-bold;
			margin-right: 3px;
		}
		margin-bottom: 23px;
	}
	.mailto{
		color: $default-hover-color;
		display: block;
	}
	.callToAction--pictoAfter{
		margin-top: 5px;
		width: auto;
		padding-left: 20px;
		padding-right: 30px;
		color: inherit;
		&:hover,&:focus,&:active{
			color: $default-hover-color;
		}
	}
	.button--rte{
		@include buttonBuilder(278px, 30px, 'dark', 'rte');
 
		&.more{
			padding-top: 3px;
			.icon{
				position: relative;
				top: -1px;
			}
		}
	}
	@media(max-width: 1279px) and (min-width: 640px){
		font-size: 26px;
		font-size: 2.6rem;
		h2{
			font-size: 37px;
			font-size:3.7rem;
		}
		h3{
			font-size: 26px;
			font-size: 2.6rem;
		}
	}
	@media(max-width: 639px){
		font-size: 13px;
		font-size: 1.3rem;

		p{
			margin-bottom: 7.5px;
		}
		h2{
			font-size: 18.5px;
			font-size:1.85rem;
		}
		h3{
			margin-top: 20px;
			margin-bottom: 12.5px;
		}
		li{
			margin-bottom: 16.5px;
		}
	}
}

.class_identifier_page_wide{
	.yosemite-rte{
		&:first-child{
			margin-top: 60px;
			*:first-child{
				margin-top: 0;
			}

			@media(max-width: 639px){
				margin-top: 30px;
			}
		}
	}
}