.applications{
	background-color:$default-light-font-color;

	h2{
		font-family: $font-lovelo-black;
		font-size:24px;
		font-size:2.4rem;
		margin-bottom: 25px;
	}

	.content-text{
		margin: 40px 75px 65px;

		@include RWD(tablet) {
			margin: 20px 0 0;
		}

		h2, h3, h4, p, li {
			color: #000;
		}
		&.text-light {
			h2, h3, h4, p, li {
				color: #fff;
			}
		}
		p{
			margin-bottom:25px;
			font-family: $font-opensans-light;
			font-size: 15px;
			font-size: 1.5rem;
		}
		li{
			margin-bottom: 25px;
			font-family: $font-opensans-light;
			font-size: 15px;
			font-size: 1.5rem;
			&::before{
				content:"\e940";
				font-family: "pba";
				margin-right: 10px;
			}
		}
		h3{
			text-align: left;
			font-family: $font-lovelo-black;
			font-size:21px;
			font-size:2.1rem;
			margin-bottom: 35px;
			margin-top: 35px;
		}
	}
	
	.section-app{
		width:100%;
		min-height:770px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}

}