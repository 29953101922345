.global-container{
	background-color:#fff;
}
.fakeH1{
	font-family: $font-lovelo-black;
	font-size:37px;
	font-size: 3.7rem;
	line-height: 33px;
	//letter-spacing: 0.1em;
	@media(max-width: 639px){
		font-size: 27px;
		font-size: 2.7rem;
	}
}
.gabarit-1{
	background-color:#fff;
	.back-to{
		max-width: 255px;
	}
	.categorie{
		font-size:29px;
		font-size: 2.9rem;
		margin-bottom:35px;
		position:relative;
		text-transform: uppercase;
		color:$default-hover-color;
		font-family:$font-novecento-widenormal;
	}
	/**CSS VIDEO IFRAME**/
	html,body{
		height:100%;
	}
	.wrapper         {
		width:100%;
		height:100%;
		margin:0 auto 60px;
		background:#CCC;
	}
	.h_iframe        {
		position:relative;
	}
	.h_iframe .ratio {
		display:block;
		width:100%;
		height:auto;
	}
	.h_iframe iframe {
		position:absolute;
		top:0;
		left:0;
		width:100%; 
		height:100%;
	}
	/**FIN**/
	.marginTopBot{
		margin-top:20px;
		margin-bottom:25px;
	}
	.level0{
		font-family: $font-opensans-bold;
		font-size: 14px;
		font-size: 1.4rem;
	}
	.level1,
	.level2{
		font-family: $font-opensans-regular;
		font-size: 14px;
		font-size: 1.4rem;
	}
	.deco-agenda{
		width: 37px;
		height: 37px;
		border-top: 16px solid;
		border-right: 16px solid;
		position: absolute;
		top: 0px;
		right: 0px;
		border-color:$default-hover-color;
	}
	.event-date{
		font-family: "bikobold";
		font-size: 2.4rem;
		text-transform: uppercase;
		letter-spacing: 0.031em;
	}
	.background-1-col{
		height:510px;
		width:100%;
		background-color: #D1D3D4;
	}
	.container-1-col-ext-bg{
		margin:0 auto;
		background-color:$default-light-font-color;
		width:1400px;
		margin-top:-467px;
	}
	.container-1-col-ext{
		margin:0 auto;
		background-color:$default-light-font-color;
		width:1400px;
		margin-top:0px;
		// img{
		// 	width:100%;
		// }
	}
	.container-1-col-int-bg{
		padding:80px 105px 0;
		color:$lighter-dark-font-color;
	}
	.container-1-col-int{
		padding:0px 105px 0;
		color:$lighter-dark-font-color;
	}
	h1{
		font-family: $font-lovelo-black;
		font-size:37px;
		font-size: 3.7rem;
		line-height: 33px;
		margin-bottom: 65px;
		//letter-spacing: 0.1em;

		@media(max-width: 639px){
			font-size: 27px;
			font-size: 2.7rem;
		}
	}
	h3{
		font-family: $font-lovelo-black;
		font-size:28px;
		font-size: 2.8rem;
		letter-spacing: 0em;
	}
	.sous-titre-page{
		font-family: $font-novecento-widenormal;
		font-size:29px;
		font-size: 2.9rem;

		@media(max-width: 639px){
			font-size: 19px;
			font-size: 1.9rem;
		}
	}
	.marginBot{
		margin-bottom:65px;

		@media(max-width: 639px){
			margin-bottom: 32.5px;
		}
	}
	p .chapo{
		font-family: $font-opensans-regular;
		font-size:19px;
		font-size: 1.9rem;
		line-height: 22px;

		@media(max-width: 639px){
			font-size: 15px;
			font-size: 1.5rem;
			line-height: 17px;
		}
	}
	.arrow-down{
		width:100%;
		margin:0 auto;
		text-align:center;
		font-size: 25px;
		font-size: 2.5rem;
	}
	.button-arrow-down{
		width:33px;
		margin-top:65px;
		padding-bottom:35px;
	}
	.lock-bar--down{
		@include lockBarBuilder(500px, 28px, 'down', 'dark');
		margin-top:25px;
	}
	.lock-bar--up{
		@include lockBarBuilder(500px, 28px, 'up', 'dark');
	}
	.download-file{
		p{
			text-align: center;
			margin-top:0px;
			margin-bottom:40px;
		}
		.miniTitre{
			font-family:$font-biko-bold;
			font-size: 20px;
			font-size: 2rem;
		}
	}
/*
	ul{
		list-style-type: disc;
		font-family:$font-opensans-bold;
		font-size: 16px;
		font-size: 1.6rem;
		li{
			margin-left: 16px;
			margin-top: 25px;
		}
	}
*/
	.textePuce{
		display:block;
		font-family:$font-opensans-regular;
		font-size: 16px;
		font-size: 1.6rem;
	}
	.texteSansPuce{
		font-family:$font-opensans-regular;
		font-size: 16px;
		font-size: 1.6rem;
		margin-top: 25px;
	}
	.opensansbold{
		font-family:$font-opensans-bold;
	}
	.littleSeparator{
		margin-top: 0px;
		margin-bottom: 55px;
		border-color:$default-dark-font-color;
	}
	.buttonCall{
		margin-top:0px;
		margin-bottom:60px;
	}
	.callToAction--dark{
		@include buttonPictoBuilder('agenda', 18px, 'dark');
		@include buttonBuilder(500px, 85px, 'dark','callToAction');
		
		/*
		@media(max-width: 1279px){
			display: none;
		}*/
	}
	.sliderGabarit1{
		position:relative;
		.bxSlider{
			margin-bottom:0px;
		}
		.bx-wrapper{
			margin-bottom:0px;
			.bx-viewport{
				height:290px;	
				border: 0px solid #FFF;
				left: 0px;
			}
		}
		#pagerSquare{
			position:absolute;
			bottom:10px;
			right:12px;
			.pager-square{
				width:22px;
				height:22px;
				background-color:transparent;
				border:4px solid $default-light-font-color;
				display: inline-block;

			}
			a.active{
				background-color:$default-light-font-color;
				display: inline-block;
			}
		}
		#sliderGab1{
			ul{
				margin-top: 0px;
				margin-left:0px;
			}
			li{
				height:290px;
				width:510px;
				margin-top: 0px;
				margin-left:0px;
				background-size: cover;
				background-position: center center;
			}
		}
		.arrowSlider{
			position:absolute;
			.bx-prev{

			}
			a{
				width:35.5px;
				height:48.9px;
				display: inline-block;
				position:absolute;
				margin-left: -36px;
			}
		}
		#prevPage{
			left:-110px;
			top:50%;
			font-size:47px;
			font-size:4.7rem;
			@include transition(all,0.3s,ease,0s);
		    &:hover .icon,
			&:active .icon,
			&:focus .icon{
				color:$default-hover-color;
				@include transition(all,0.3s,ease,0s);
			}
		}
		#nextPage{
			right:-110px;
			top:50%;
			font-size:47px;
			font-size:4.7rem;
			@include transition(all,0.3s,ease,0s);
		    &:hover .icon,
			&:active .icon,
			&:focus .icon{
				color:$default-hover-color;
				@include transition(all,0.3s,ease,0s);
			}
		}
	}

	.btn-ticket {
		margin-right: 0;
		font-size: 14px;
		padding: 15px 20px;
		min-height: auto;
		line-height: 20px;

		&:before {
			content: none;
		}

		.icon {
			position: initial;
			margin-left: 25px;
		}
	}

}

.class_identifier_page_thin{
	.yosemite-rte{
		margin-bottom:75px;
	}
	.sliderGabarit{
		margin-bottom:20px;
	}
	.download--thin-2{
		margin-bottom:55px;
	}
	
	.artips{
		.img-artips{
			max-height: 400px;
			padding-bottom: 58.1%;
			background-repeat:no-repeat;
			background-size: cover;
		}/*
		h2{
			font-family:$font-biko-bold;
			font-size: 37px;
			font-size: 3.7rem;
		}
		.sous-titre-page{
			font-family: $font-biko-regular;
			font-size: 29px;
			font-size: 2.9rem;
		}
		h3{
			font-family: $font-biko-regular;
			font-size:39px;
			font-size: 3.9rem;
			margin-bottom:60px;
		}*/
	}
}

.class_group_content.class_identifier_page_thin .rte-contain.bloc_text {
	width: 1400px;
}

.gabarit-1 .sliderGabarit {
	.bx-wrapper{
		.bx-viewport{
			height:580px !important;
		}
	}

	.bxslider{
		li{
			height:580px !important;
			width:1010px !important;

		}
	}
}

@media(min-width:640px) and (max-width:1279px){
	.gabarit-1{
		.container-1-col-ext{
			width:100%;
		}
		.container-1-col-int{
			width:100%;
			padding:0 55px;
		}
		.container-1-col-ext-bg {
		    margin: 0px auto;
		    width:100%;
		}
		.container-1-col-int-bg {
		    color: #2C2C2E;
		    padding: 40px 55px 40px;
		    width:100%;
		}
		.background-1-col {
		    height: 340px;
		    width: 100%;
		}
		.categorie{
			font-size:29px;
			font-size: 2.9rem;
			margin-bottom:35px;
			position:relative;
			text-transform: uppercase;
			color:$default-hover-color;
			font-family:$font-novecento-widenormal;
		}
		.marginTopBot{
			margin-top:20px;
			margin-bottom:25px;
		}
		.lvl0{
			font-family: $font-opensans-bold;
			font-size: 26px;
			font-size: 2.6rem;
		}
		.lvl1,
		.lvl2{
			font-family: $font-opensans-regular;
			font-size: 26px;
			font-size: 2.6rem;
		}
		.deco-agenda{
			width: 37px;
			height: 37px;
			border-top: 16px solid;
			border-right: 16px solid;
			position: absolute;
			top: 0px;
			right: 0px;
			border-color:$default-hover-color;
		}
		.no-contain-mobile{
    		width:100%;
    		padding:0 !important;
    	}
    	p .chapo{
    		font-size: 26px;
    		font-size: 2.6rem;
    		line-height: 31.2px;
    	}
    	.download-file{
			.miniTitre{
				font-family:$font-biko-bold;
				font-size: 36px;
				font-size: 3.6rem;
			}
		}
	}
}

@media(max-width:639px){
	.gabarit-1{
		.container-1-col-ext{
			width:100%;
		}
		.container-1-col-int{
			width:100%;
			padding:0 27.5px;
		}
		.container-1-col-ext-bg {
		    margin: 0px auto;
		    width:100%;
		}
		.container-1-col-int-bg {
		    color: #2C2C2E;
		    padding: 20px 27.5px 20px;
		    width:100%;
		}
		.background-1-col {
		    height: 170px;
		    width: 100%;
		}
		.lvl0{
			font-family: $font-opensans-bold;
			font-size: 13px;
			font-size: 1.3rem;
		}
		.lvl1,
		.lvl2{
			font-family: $font-opensans-regular;
			font-size: 13px;
			font-size: 1.3rem;
		}
		.no-contain-mobile{
    		width:100%;
    		padding:0 !important;
    	}
    	.download-file{
			.miniTitre{
				font-family:$font-biko-bold;
				font-size: 18px;
				font-size: 1.8rem;
			}
		}
	}
}