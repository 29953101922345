.wi-txt_img {
  width: 100%;
  display: flex;
  margin-bottom: 30px;

  @include RWD(mobile) {
    flex-direction: column;
  }

  .visuel {
    min-width: 50%;
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: start;

    @include RWD(mobile) {
      margin: 0 0 40px;
    }

    img {
      max-width: 100%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .wi-citation blockquote {
      min-width: 100%;
    }
  }

  &.right {
    .visuel {
      order: 1;
      margin-right: 0;
      margin-left: 30px;

      @include RWD(mobile) {
        margin: 0 0 40px;
        order: 0;
      }
    }
  }
}