@font-face {
	font-family: 'pba';
	src:url('fonts/icons-pba/pba.eot?ahco1p');
	src:url('fonts/icons-pba/pba.eot?ahco1p#iefix') format('embedded-opentype'),
		url('fonts/icons-pba/pba.ttf?ahco1p') format('truetype'),
		url('fonts/icons-pba/pba.woff?ahco1p') format('woff'),
		url('fonts/icons-pba/pba.svg?ahco1p#pba') format('svg');
	font-weight: normal;
	font-style: normal;
}
.icon {
	font-family: 'pba';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: inline-block;	
	// @include transition(all, 0.3s,ease,0s);	
	vertical-align: middle;
}

/*
.icon-projects-fix:before {
	content: "\e947";
}
.icon-tickets-fix:before {
	content: "\e948"; 
}
.icon-visio-fix:before {
	content: "\e949";
}
.icon-child-icon-fix:before {
	content: "\e94a";
} 
.icon-mobile-pba-fix:before {
	content: "\e94b";
}
.icon-plan-fix:before {
	content: "\e94c";
}*/

.icon-eiffel:before {
    content: "\e95f";
}
.icon-livre:before {
    content: "\e96d";
}
.icon-bus-8:before { 
    content: "\e96a";
}
.icon-light:before {
    content: "\e96b";
}
.icon-news:before {
    content: "\e96c";
}
.icon-corporation:before {
    content: "\e966";
}
.icon-heart:before {
    content: "\e967";
}
.icon-place:before {
    content: "\e968";
}
.icon-sunlight:before {
    content: "\e969";
}
.icon-artips-text:before {
    content: "\e930";
}
.icon-workshop:before {
    content: "\e95e";
}
.icon-bird:before {
    content: "\e94d";
}
.icon-boat:before {
    content: "\e94e";
}
.icon-book:before {
    content: "\e94f";
}
.icon-crown:before {
    content: "\e950";
}
.icon-frigien:before {
    content: "\e951";
}
.icon-frise-fb:before {
    content: "\e952";
}
.icon-joconde:before {
    content: "\e953";
}
.icon-moon:before {
    content: "\e954";
}
.icon-palais:before {
    content: "\e955";
}
.icon-pantheon:before {
    content: "\e956";
}
.icon-pyramides:before {
    content: "\e957";
}
.icon-rosace:before {
    content: "\e958";
}
.icon-screen:before {
    content: "\e959";
}
.icon-sun:before {
    content: "\e95a";
}
.icon-television:before {
    content: "\e95b";
}
.icon-wall:before {
    content: "\e95c";
}
.icon-world:before {
    content: "\e95d";
}
.icon-projects-fix:before {
    content: "\e947";
}
.icon-tickets-fix:before {
    content: "\e948";
}
.icon-visio-fix:before {
    content: "\e949";
}
.icon-child-icon-fix:before {
    content: "\e94a";
}
.icon-mobile-pba-fix:before {
    content: "\e94b";
}
.icon-plan-fix:before {
    content: "\e94c";
}
.icon-presse-fix:before {
    content: "\e946";
}
.icon-icon-mountain:before {
    content: "\e944";
}
.icon-less-circled:before {
    content: "\e943";
}
.icon-artips-bordered:before {
    content: "\e942";
}
.icon-bigben:before {
    content: "\e910";
}
.icon-cross:before {
    content: "\e911";
}
.icon-cross-circled:before {
    content: "\e912";
}
.icon-less:before {
    content: "\e913";
}
.icon-paint:before {
    content: "\e914";
}
.icon-theater:before {
    content: "\e915";
}
.icon-user:before {
    content: "\e941";
}
.icon-online:before {
    content: "\e90f";
}
.icon-architecture:before {
    content: "\e90d";
}
.icon-blocks:before {
    content: "\e90e";
}
.icon-more-circled:before {
    content: "\e90c";
}
.icon-pdf:before {
    content: "\e90b";
}
.icon-archive:before {
    content: "\e900";
}
.icon-agenda:before {
    content: "\e901";
}
.icon-appli:before {
    content: "\e902";
}
.icon-artips:before {
    content: "\e903";
}
.icon-bed:before {
    content: "\e904";
}
.icon-chef-oeuvre:before {
    content: "\e905";
}
.icon-child:before {
    content: "\e906";
}
.icon-clock:before {
    content: "\e907";
}
.icon-collection:before {
    content: "\e908";
}
.icon-credit-card:before {
    content: "\e909";
}
.icon-download:before {
    content: "\e90a";
}
.icon-b:before {
    content: "\62";
}
.icon-p:before {
    content: "\70";
}
.icon-a:before {
    content: "\61";
}
.icon-l:before {
    content: "\6c";
}
.icon-i:before {
    content: "\69";
}
.icon-r:before {
    content: "\72";
}
.icon-e:before {
    content: "\65";
}
.icon-t:before {
    content: "\74";
}
.icon-s:before {
    content: "\73";
}
.icon-u:before {
    content: "\75";
}
.icon-x:before {
    content: "\78";
}
.icon-empty:before {
    content: "\e916";
}
.icon-files:before {
    content: "\e917";
}
.icon-food:before {
    content: "\e918";
}
.icon-frise:before {
    content: "\e919";
}
.icon-globe:before {
    content: "\e91a";
}
.icon-graduate:before {
    content: "\e91b";
}
.icon-instagram:before {
    content: "\e91c";
}
.icon-ipad:before {
    content: "\e91d";
}
.icon-marker:before {
    content: "\e91e";
}
.icon-mobile-pba:before {
    content: "\e91f";
}
.icon-more:before {
    content: "\e920";
}
.icon-musee-prive:before {
    content: "\e921";
}
.icon-parcours:before {
    content: "\e922";
}
.icon-parent:before {
    content: "\e923";
}
.icon-people:before {
    content: "\e924";
}
.icon-plan:before {
    content: "\e925";
}
.icon-pmr:before {
    content: "\e926";
}
.icon-presse:before {
    content: "\e927";
}
.icon-productions:before {
    content: "\e928";
}
.icon-projects:before {
    content: "\e929";
}
.icon-search:before {
    content: "\e92a";
}
.icon-send:before {
    content: "\e92b";
}
.icon-smile:before {
    content: "\e92c";
}
.icon-tablets:before {
    content: "\e92d";
}
.icon-team:before {
    content: "\e92e";
}
.icon-ticket:before {
    content: "\e92f";
}
.icon-tickets:before {
    content: "\e931";
}
.icon-key:before {
    content: "\e932";
}
.icon-tower:before {
    content: "\e933";
}
.icon-twitter-circled:before {
    content: "\e934";
}
.icon-archive2:before {
    content: "\e935";
}
.icon-vimeo-circled:before {
    content: "\e936";
}
.icon-share:before {
    content: "\e937";
}
.icon-infos:before {
    content: "\e938";
}
.icon-share-circled:before {
    content: "\e939";
}
.icon-facebook-circled:before {
    content: "\e93a";
}
.icon-visio:before {
    content: "\e93b";
}
.icon-youtube-circled:before {
    content: "\e93c";
}
.icon-chevron-down:before {
    content: "\e93d";
}
.icon-chevron-up:before {
    content: "\e93e";
}
.icon-chevron-left:before {
    content: "\e93f";
}
.icon-chevron-right:before {
    content: "\e940";
}
.icon-glossaire:before {
    content: "\e945";
}

