.Home-page{
	background-color:$default-dark-font-color;
	.bg-image{
		height:493px;
		width:100%;
		position:relative;
		overflow:hidden;
		box-shadow: 0px 0px 0px 1000px rgba(0, 0, 0, 0.2) inset;
	}
	.classVideo{
		position:relative;
		width:100%;
		height:100%;
	}
	.classeVideo{
		pointer-events: none;
		position:relative;
		z-index: 1;
		& + .main-container{
			position: relative;
			z-index: 2;
		}
	}
	video { 
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    min-width: 100%;
	    min-height: 100%;
	    width: 100%;
	    height: auto;
	    //z-index: -100;
	    transform: translateX(-50%) translateY(-50%);
	    -ms-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
		background: url('../images/banners/img-hp1.jpg') no-repeat;
	    background-size: cover;
	    transition: 1s opacity;
	}
	iframe {
        position: absolute;
        top: 0; left: 0; width: 100%; height: 100%;
        border: none;
        box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box;
    }
	/*iframe { 
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    min-width: 100%;
	    min-height: 100%;
	    width: 100%;
	    height: auto;
	    //z-index: -100;
	    transform: translateX(-50%) translateY(-50%);
		body { width: 100%; height: auto; }
	}*/
	.html5-video-player, .html5-video-player.video-stream {
	    display: block;
	    width: 100% !important;
	    height: auto !important;
	    position: absolute;
	}
	.stopfade { 
	   opacity: .5;
	}
	/*
	@media (min-aspect-ratio: 16/9) {
	  #bgvid {
	    width: 100%;
	    height: auto;
	  }
	}
	@media (max-aspect-ratio: 16/9) {
	  #bgvid {
	    width: auto;
	    height: 100%;
	  }
	}*/
	/*@media screen and (max-width: 1279px) {
	 	.bg-image { 
	 		background: url('../images/banners/img-hp1.jpg') #000 no-repeat center center fixed; 
	 	}
	    #bgvid { 
	    	display: none; 
	    }
	}*/
	/*
	.overlay-bg{
		width:100%;
		height:100%;
		background:linear-gradient(
	      rgba(44, 44, 46, 0.5), 
	      rgba(44, 44, 46, 0.5)
	    );
		position:absolute;
	}*/
	.lock-bar--down{
		@include lockBarBuilder(1140px, 28px, 'down', 'light');
		margin-top:25px;
		margin-bottom:0px;
	}
	.lock-bar--up{
		@include lockBarBuilder(1140px, 28px, 'up', 'light');
		margin-bottom:0px;
	}
	@media (max-width:639px){
		.lock-bar--down,
		.lock-bar--up{
			margin-top:0px;
			margin-bottom:0px;
			height: 14px;
		}
		.lock-bar--down{
			border-width: medium 8px 8px;
		}
		.lock-bar--up{
			border-width: 8px 8px medium;
		}
	}
	.main-container{
		width:1280px;
		margin:0 auto;
		display:block;
	}
	.deco-desktop-left{
		display: inline-block;
		position: absolute;
		height: 340px;
		width: 100%;
		left: 0px;
		top:0px;
		border-top: 16px solid $default-light-font-color;
	    border-right: 16px solid $default-light-font-color;
	    height: 40px;
	}
	.deco-desktop2-left{
		display: inline-block;
		position: absolute;
		height: 340px;
		width: 100%;
		left: 0px;
		bottom:0px;
		border-bottom: 16px solid $default-light-font-color;
	    border-right: 16px solid $default-light-font-color;
	    height: 40px;
	}
	.deco-desktop-right{
		display: inline-block;
		position: absolute;
		height: 340px;
		width: 100%;
		left: 0px;
		top:0px;
		border-top: 16px solid $default-light-font-color;
	    border-left: 16px solid $default-light-font-color;
	    height: 40px;
	}
	.deco-desktop2-right{
		display: inline-block;
		position: absolute;
		height: 340px;
		width: 100%;
		left: 0px;
		bottom:0px;
		border-bottom: 16px solid $default-light-font-color;
	    border-left: 16px solid $default-light-font-color;
	    height: 40px;
	}
	
	.abs-video{
		position: absolute;
		left: 50%;
		margin-left: -17.5px;
	}
	.deco-tablette-up,
	.deco-tablette-bottom{
		display:none;
	}
	.logo-pba-mobile-hp{
		display:none;
		width: 100%;
		height: 100px;
		padding-top: 50px;
		padding-bottom: 100px;
		width:100%;
		display:block;
	    height:50px;
	    width: 100%;
		font-size: 30px;
		font-size: 5.02rem;
		letter-spacing: 0.13em;
		color:$default-light-font-color;
		text-align: center;
		margin-left: 10px;
	    span{
			&.need-mr{
				margin-right: 12px;
			}
			&.need-tiret{
				margin-right: 20px;
				position: relative;
				&:after{
					display: inline-block;
					content: '';
					width: 10px;
					height: 1.5px;
					background-color: #FFF;
					position: absolute;
					top: 50%;
					right: -12px;
					@include transition(all, 0.3s, ease, 0s);
				}
			}
		}
	}
	h1{
		font-family: $font-lovelo-black;
		font-size:46px;
		font-size:4.6rem;
		color:$default-light-font-color;
	}
	.fakeH1{
		font-family: $font-lovelo-black;
		font-size:46px;
		font-size:4.6rem;
		color:$default-light-font-color;
		line-height: normal;
	}
	a{

	}
	.container-int{
		margin-left:65px;
		margin-right:65px;
		max-height:493px;
		text-align:justify;
		.pictoTitre{
			margin-bottom:25px;
			padding-top: 104px;
			.icon{
				font-size: 41px;
				font-size: 4.1rem;
				color: $default-light-font-color;
				&.icon-parcours{
					font-size: 46px;
					font-size: 4.6rem;
				}
			}
		}
		p{
			font-family:$font-baron-neue-normal50;
			font-size:24px;
			font-size: 2.4rem;
			color:$default-light-font-color;
		}
	}
	hr{
		margin-top: 0px;
		margin-bottom: 0px;

	}
	.left{
		width:500px;
		margin-top:60px;
		height:340px;
		position:relative;
		margin-top:60px;
		display: inline-block;
		text-align: center;
		/*.top-left{
			border:8px solid $default-light-font-color;
			&:after{
				content:"";
				width:16px;
				height: 13px;
				display: inline-block;
				position:absolute;
				right:0px;
				background-color: $default-light-font-color;
			}
		}
		.bottom-left{
			border:8px solid $default-light-font-color;
			position:absolute;
			bottom:0px;
			width: 100%;
			&:after{
				content:"";
				width:16px;
				//height: 13px;
				display: inline-block;
				text-align:right;

				right:0px;
				background-color: $default-light-font-color;
			}
		}*/
	}
	.right{
		width:500px;
		margin-top: 60px;
		height:340px;
		position:relative;
		display: inline-block;
		text-align: center;

		@media(max-width: 639px){
			overflow: hidden;
		}
		/*.top-right{
			border:8px solid $default-light-font-color;
			&:before{
				content:"";
				width:16px;
				height: 13px;
				display: inline-block;
				background-color: $default-light-font-color;
			}
		}
		.bottom-left-right{
			border:8px solid $default-light-font-color;
			position:absolute;
			bottom:0px;
			&:before{
				content:"";
				width:16px;
				height: 13px;
				display: inline-block;
				background-color: $default-light-font-color;
			}
		}*/
	}
	.contenuTitre{
		&:before{
			content:"";
			width:500px;
			height:340px;
		}
	}
	.contenuTitre-left{
		margin: 0;
	    position: absolute;
	    top: 50%;
	    transform: translate(0%, -50%);
	    -ms-transform: translate(0%, -50%);
        -webkit-transform: translate(0%, -50%);
	    width:100%;
	    a{
	    	width: 100%;
			display: block;
			height: 339px;
			vertical-align: middle;
	    	@include transition(all,0.3s,ease,0s);
		    &:hover .icon,
			&:active .icon,
			&:focus .icon{
				@include transition(all,0.3s,ease,0s);
				color:$default-hover-color;
			}
		}
	}
	.contenuTitre-right{
		margin: 0;
	    position: absolute;
	    top: 50%;
	    transform: translate(0%, -50%);
	    -ms-transform: translate(0%, -50%);
        -webkit-transform: translate(0%, -50%);
	    width:100%;
	    a{
	    	width: 100%;
			display: block;
			height: 339px;
			vertical-align: middle;
	    	@include transition(all,0.3s,ease,0s);
		    &:hover .icon,
			&:active .icon,
			&:focus .icon{
				@include transition(all,0.3s,ease,0s);
				color:$default-hover-color;
			}
		}
	}
	.icon-chevron-down{
		color: $default-light-font-color;
		font-size: 26px;
		margin-top: 35px;
		z-index: 10;
	}
	.sliderHome{
		position:relative;
		.bxSlider{
			margin-bottom:0px;
		}

		.bx-wrapper{
			margin-bottom:0px;
			.bx-viewport{
				height:290px;	
				border: 0px solid #FFF;
				left: 0px;
				box-shadow: none;
			}
		}
		.pagerSquare{
			position:absolute;
			bottom: 20px;
			right: 70px;
			z-index: 50;
			a{
				margin-right:6px;
				&:last-child{
					margin-right:0px;
				}
			}
			.pager-square{
				width:24px;
				height:24px;
				background-color:transparent;
				border:4px solid $default-light-font-color;
				display: inline-block;
				vertical-align:top;
			}
			a.active{
				background-color:$default-light-font-color;
				display: inline-block;
				height: 20px;
			}
		}
		#sliderHome{
			ul{
				margin-top: 0px;
				margin-left:0px;
			}
			li{
				height:500px;
				width:100%;
				margin-top: 0px;
				margin-left:0px;
				background-size: cover;
				background-position: center center;
			}
		}
		.bx-prev{
			z-index: 50;
		}
		.bx-next{
			z-index: 50;
		}
		.arrowSlider{
			position:absolute;
			font-size:47px;
			font-size:4.7rem;
			color:$default-light-font-color;
			/*
			.icon{
				color: $default-light-font-color;
				font-size: 5rem;
			}
			a{
				width:35.5px;
				height:48.9px;
				display: inline-block;
				position:absolute;
				margin-left: -36px;
				a:hover,
				a:active,
				a:focus{
					stroke:$default-hover-color;
				}
			}*/
		}
		.slider-arrow{
			position: absolute;
			top: 50%;
			width: 1280px;
			display: block;
			right: 0px;
			left: 0px;
			margin: -18px auto 0px;
			@media(max-width:1279px){
				width:100%;
				margin: 35px auto 0px;
			}
			@media(max-width:639px){
				margin: 19px auto 0px;
			}
		}
		.prevPage{
			left:60px;
			top:50%;
			@include transition(all,0.3s,ease,0s);
			.icon{
				&:hover,
				&:active,
				&:focus{
					@include transition(all,0.3s,ease,0s);
					color: $default-hover-color;
				}
			}
		}
		.nextPage{
			right:60px;
			top:50%;
			@include transition(all,0.3s,ease,0s);
		    .icon{
				&:hover,
				&:active,
				&:focus{
					@include transition(all,0.3s,ease,0s);
					color: $default-hover-color;
				}
			}
		}
		.main-container-slider{
			width:1280px;
			margin:0 auto;
			display:block;
			position:absolute;
			z-index: 1;
			top:85px;
			bottom: 0;
			left: 0;
			right: 0;
		}
		.actu-slider-container{
			width:89%;
			margin: 0 auto;
			height:340px;
			position:relative;
			/*&:before{
				content: '';
			    display: inline-block;
			    height: 100%;
			    vertical-align: middle;
			    margin-right: -0.25em;
			}*/
		}
		.categorie-actu{
			position: absolute;
			top: -50px;
			//right: 46.5%;
			left: 0;
			right: 0;
			p{
				font-family: $font-biko-bold;
				font-size: 20px;
				font-size:2rem;
				color:$default-light-font-color;
				position: relative;
				text-align: center;
			}
		}
		.svg-picto-agenda{
			.st0, .st1{
				stroke: $default-light-font-color;
			}
		}
		.actu-slider{
			text-align: center;
			vertical-align: middle;
			width: 95%;
			margin: 0 auto;
			height:90%;
			display:table;
			.contenu-actu{
				margin-top:4px;
			}
			a{
				display: table-cell;
				vertical-align: middle;
				@include transition(all,0.3s,ease,0s);
			    &:hover .icon,
				&:active .icon,
				&:focus .icon{
					color:$default-hover-color;
					@include transition(all,0.3s,ease,0s);
				}
				img{
					margin:30px auto 0;
				}
				.bigger{
					font-family:$font-lovelo-black;
					font-size:27px;
					font-size: 2.7rem;
				}

				p:has(.bigger){
					margin-bottom:18px;
				}
				/*&:before {
				  content: '';
				  display: inline-block;
				  height: 100%;
				  vertical-align: middle;
				  margin-right: -0.25em;
				}*/
			}
			.pictoTitre{
				//margin-top:30px;
				margin-bottom:30px;
				.icon{
					color:$default-light-font-color;
					font-size: 50px;
					font-size: 5rem;
				}
			}
			p{
				color:$default-light-font-color;
				font-family: $font-biko-bold;
				font-size: 20px;
				font-size: 2rem;
				line-height: 24px;
			}
			.sous-titre-actu{
				font-family: $font-novecento-widebook;
				font-size:31px;
				font-size: 3.1rem;
				line-height: 24px;
			}
		}
		.separator-head{
			border-width: 16px;
			border-color:$default-light-font-color;
			position: relative;
			&:before{
				content:"";
				width:16px;
				height: 13px;
				display: inline-block;
				background-color: $default-light-font-color;
				position: absolute;
				left: 0px;
			}
			&:after{
				content:"";
				width:16px;
				height: 13px;
				display: inline-block;
				position:absolute;
				right:0px;
				background-color: $default-light-font-color;
			}
		}
		.separator-foot-contain{
			position:absolute;
			bottom:-15px;
		}
		.separator-foot{
			width: 1140px;
			border-width: 16px;
			border-color:$default-light-font-color;
			position: relative;
			//margin-bottom:60px;
			//margin-top:110px;
			//padding-bottom: 75px;
			margin-bottom:0px;
			&:before{
				content:"";
				width:16px;
				height: 13px;
				display: inline-block;
				margin-top: -28px;
				background-color: $default-light-font-color;
				position: absolute;
				left: 0px;
			}
			&:after{
				content:"";
				width:16px;
				height: 13px;
				display: inline-block;
				position:absolute;
				right:0px;
				top: -29px;
				background-color: $default-light-font-color;
			}
		}
	}
	.bloc-titre-gallerie{
		height:150px;
		text-align:center;
		color:$default-light-font-color;
		h2{
			font-family:$font-lovelo-black;
			font-size: 34px;
			font-size: 3.4rem;
			vertical-align: middle;
			padding-top: 44px;
			/*&:before{
				content: '';
			    display: inline-block;
			    height: 100%;
			    vertical-align: middle;
			    margin-right: -0.25em;
			}*/
			span{
				display:block;
				font-family: $font-biko-bold;
				font-size:20px;
				font-size:2rem;
				text-align: center;
				text-transform: none;
				//margin-top: -45px;
			}
		}
		.triangle {
		    width: 0px;
		    height: 0px;
		    border-left: 10px solid transparent;
		    border-right: 10px solid transparent;
		    border-top: 10px solid #FFF;
		    display: inline-block;
		    margin-top: 12px;
		}

	}
	.gallery {
	    margin-bottom: 35px;
		display:flex;
		@media (max-width: 1279px){
			display:block;
		}
		.vignette-wrapper{
			width:25%;
			@media (max-width: 1279px){
				width: 100%;
			}
			.vignette{
				width:100%;
				padding-bottom:150%;
				.gallery-actions{
					position: absolute;
					z-index: 2;
				}
				a{
					display: block;
					position: absolute;
					z-index: 1;
					width: 100%;
					height: 100%;
				}
				&:hover{
					.favorites-actions button,
					a:before{
						opacity: 1;
					}
				}
			}
		}
	}
	.bloc-button-gallerie{
		height:121px;
		margin:0 auto;
		width:100%;
		text-align:center;
		margin-top: 6px;
		/*&:before{
			content: '';
		    display: inline-block;
		    height: 100%;
		    vertical-align: middle;
		    margin-right: -0.25em;
		}*/
		.callToAction--picto-archive{
			vertical-align: middle;
			margin-bottom: 0px;
			color:$default-light-font-color;
			border-color:$default-light-font-color;
			.svg-picto-archive .st0{
				fill:$default-light-font-color;
			}
			&:hover,
			&:active,
			&:focus,
			&:hover .svg-picto-archive .st0,
			&:active .svg-picto-archive .st0,
			&:focus .svg-picto-archive .st0{
				color:$default-hover-color;
				border-color:$default-hover-color;
				fill:$default-hover-color;
			}
		}
	}
	.icon-archive, .icon-chef-oeuvre {
		margin-right: 40px;
		font-size: 27px;
		font-size: 2.7rem;
	}
	.icon-eye{
		margin-right: 15px;
		height: 25px;
		width: 37px;
		&:before, &:after{ 	height: 25px; width: 37px; }
		@media (max-width: 639px) {
			&:before, &:after{
				top: -2px;
				background-size: 20px;
				background-repeat: no-repeat;
				background-position: center;
			}
		}
	}

	.expander-light{
		position: relative;
		height: 150px;
		width: 100%;
		background-color:$default-light-font-color;
		.englobeur{
			display:inline-block;
			vertical-align: middle;
			@include transition(all,0.3s,ease,0s);
			&:before{
				content: '';
			    display: inline-block;
			    height: 150px;
			    vertical-align: middle;
			    margin-right: -0.25em;
			}

			&:hover .icon,
			&:active .icon,
			&:focus .icon{
				@include transition(all,0.3s,ease,0s);
				color:$default-hover-color;
			}
			
			a{
				@include transition(all,0.3s,ease,0s);
			}
			a:hover .picto-before1 .st0,
			a:hover .picto-before1 .st1,
			a:active .picto-before1 .st0,
			a:active .picto-before1 .st1,
			a:focus .picto-before1 .st0,
			a:focus .picto-before1 .st1{
				stroke:$default-hover-color;
				@include transition(all,0.3s,ease,0s);
			}
			&:hover,
			&:hover a,
			&:hover .picto-before2,
			&:hover .text-headband-light,
			&:active,
			&:active a,
			&:active .picto-before2,
			&:active .text-headband-light,
			&:focus,
			&:focus a,
			&:focus .picto-before2,
			&:focus .text-headband-light{
				color:$default-hover-color;
				border-color:$default-hover-color;
				fill:$default-hover-color;
				@include transition(all,0.3s,ease,0s);
			}
		}
		.englobeur1{
			margin-left:120px;
			.icon{
				color: $default-dark-font-color;
				font-size: 70px;
				font-size: 7rem;
			}
		}
		.englobeur2{
			margin-left:160px;
			.icon{
				color: $default-dark-font-color;
				font-size: 42px;
				font-size: 4.2rem;
			}
		}
		.picto-before{
			vertical-align: middle;
			display:inline-block;
		}
		.text-headband-light{
			font-family:$font-lovelo-black;
			color:$default-dark-font-color;
			font-size: 24px;
			font-size: 2.4rem;
			display:inline-block;
			margin-left: 40px;
			vertical-align: middle;
		}
		.hidden-mobile{
			display:inline-block;
		}
	}
}
@media(max-width: 1279px){
	.Home-page{
		.sliderHome{
			.pagerSquare{
				width:100%;
				text-align: center;
				right:0px;
			}
		}
	}
}
@media (min-width:640px) and (max-width:1279px){
	.Home-page{
		.bg-image{
			height:810px;
			/*video{
				display:none;
			}*/
		}
		.logo-pba-mobile-hp{
			display:block;
		}
		.center-line{
			position: absolute;
			bottom: 27px;
		}
		.deco-desktop{
			display:none;
		}
		.deco-tablette-bottom{
		    display: inline-block;
		    width: 505px;
		    height: 42px;
		    position: absolute;
		    left: 0px;
		    top: 200px;
		    background-position: -20px -27px; 
		    background-size: 109% auto; 
		    background-repeat: no-repeat;
		}
		@media screen\0 {
			.deco-tablette-bottom{
			    background-position: -344px -27px; 
			    background-size: 234% auto; 
			    background-repeat: no-repeat;
			}
		}
		@media screen and (min-width:0\0) {
			.deco-tablette-bottom{
			    background-position: -344px -27px; 
			    background-size: 234% auto; 
			    background-repeat: no-repeat;
			}
		}
		@media all and (-ms-high-contrast:none){
			.deco-tablette-bottom{
			    background-position: -344px -27px; 
			    background-size: 234% auto; 
			    background-repeat: no-repeat;
			}
		}
		.deco-tablette-up{
			display: inline-block;
			width: 505px;
			height: 310px;
			position: absolute;
			left: 47%;
			top: 0px;
			margin-left: -235px;
		}
		.contenuTitre-right {
		    top: 24%;
		}
		.contenuTitre-left {
		    top: 40%;
		}
		.main-container{
			width:100%;
		}
		.container-int{
			text-align:center;
		}
		.right{
			margin-top:-20px;
			height: 340px;
			display:block;
			margin:0 auto;
			padding-bottom: 97px;
		}
		.left{
			margin-top:0px;
			height: 340px;
			display:block;
			margin:0 auto;
		}
		h1{
			font-size: 56px;
			font-size: 5.6rem;
			line-height: 67.2px;
		}
		.sliderHome {
			.main-container-slider {
    			width: 100%;
    			top:200px;
    		}
    		.categorie-actu{
    			top:-110px;
    			//right: 44.5%;
    			p{
    				font-size: 24px;
    				font-size: 2.4rem;
    			}
    		}
    		.separator-foot-contain{
	    		width: 100%;
	    		bottom:-7.5px;
	    	}
    		.separator-foot{
    			width:100%;
    		}
    		#prevPage{
    			top: 9.5%;
    		}
    		#nextPage{
    			top: 9.5%;
    		}
    		.actu-slider-container{
    			height:510px;
    		}
    		.actu-slider{
    			.pictoTitre {
				    //margin-top: 70px;
				    margin-bottom: 25px;
				}
				.sous-titre-actu {
					font-size:46px;
				    font-size: 4.6rem;
				    line-height: 55.2px;
				}
				p{
					font-size: 34px;
					font-size: 3.4rem;
					line-height:40.8px;
				}
    		}
    		#sliderHome{
				li{
					height:800px;
				}
			}
			.bx-wrapper .bx-viewport {
			    height: 800px;
			}
			.prevPage {
			    left: 55px;
			    top: 10%;
			}
			.nextPage {
			    right:55px;
			    top: 10%;
			}
    	}
    	.gallery {
    		margin-bottom: 80px;
    	}
    	.bloc-button-gallerie{
    		padding:0 55px;
    		height: 185px;
    		.callToAction--picto-archive, .callToAction--picto-chef-oeuvre {
	    		margin-top: 0px;
	    	}
    	}
    	.expander-light{
    		height:470px;
    		/*.container-int{
    			padding:0 60px;
    		}*/
    		.englobeur{
				display:inline-block;
				vertical-align: middle;
				border: 5px solid #000;
				line-height:115px;
				width:100%;
				text-align: center;
				margin-top:75px;
				@include transition(all,0.3s,ease,0s);
				&:before{
					content: '';
				    display: inline-block;
				    height: 0%;
				    vertical-align: middle;
				    margin-right: -0.25em;
				}
				a{
					&:hover,
					&:active,
					&:focus{
						color:$default-hover-color;
					}
				}
			}
			.englobeur1{
				margin-left:0px;
			}
			.englobeur2{
				margin-left:0px;
			}
			.hidden-mobile{
				display:none;
			}
    	}
	}
}
@media (max-width:639px){
	.Home-page{
		.container-int{
			margin-left: 22.5px;
			margin-right: 22.5px;
			.pictoTitre {
				margin-bottom:12.5px;
				.icon {
		    		font-size: 2.05rem !important;
		    	}
			}
		}
		.bg-image{
			height:405px;
			/*video{
				display:none;
			}*/
		}
		h1 {
		    font-size: 1.4rem;
		    line-height: 17px;
		}

		.fakeH1{
			font-size:23px;
			font-size:2.3rem;
		}
		.left{
			width:250px;
			margin-top: 30px;
			height:170px;
			.deco-tablette-up{
				background-position: -9px -9px !important;
			}
		}

		.right{
			width:250px;
			margin-top: 30px;
			height:170px;
			.deco-tablette-bottom{
				background-position: -11px -17px !important;
				background-size: 110%;
			}
		}
		.logo-pba-mobile-hp{
			display:block;
			width: 100%;
			height: 50px;
			padding-top: 25px;
			padding-bottom: 50px;
			width:100%;
			display:block;
		    height:25px;
		    width: 100%;
			font-size: 25px;
			font-size: 2.5rem;
			letter-spacing: 0.07em;
			color:$default-light-font-color;
			text-align: center;
			margin-left: 4px;
		    /*background:url("../images/logo-pba.svg") 0px 1px no-repeat;
		    background-size:508.5px;*/
		    span{
				&.need-mr{
					margin-right: 12px;
				}
				&.need-tiret{
					margin-right: 20px;
					position: relative;
					&:after{
						display: inline-block;
						content: '';
						width: 10px;
						height: 1.5px;
						background-color: #FFF;
						position: absolute;
						top: 50%;
						right: -12px;
						@include transition(all, 0.3s, ease, 0s);
					}
				}
			}
		}
		.center-line{
			position: absolute;
			bottom: 13.5px;
		}
		.deco-desktop{
			display:none;
		}
		.deco-tablette-bottom{
		    display: inline-block;
		    width: 252.5px;
		    height: 30px;
		    position: absolute;
		    left: 0px;
		    top: 100px;
		}
		.deco-tablette-up{
			display: inline-block;
			width: 252.5px;
			height: 155px;
			position: absolute;
			left: 47%;
			top: 0px;
			margin-left: -117.5px;
		}
		.contenuTitre-right {
		    top: 40%;
		}
		.contenuTitre-left {
		    top: 55%;
		}
		.main-container{
			width:100%;
		}
		.container-int{
			text-align:center;
			p{
				font-size: 1.2rem;
			}
		}
		.right{
			margin-top:-10px;
			height: 170px;
			display:block;
			margin:0 auto;
		}
		.left{
			margin-top:0px;
			height: 170px;
			display:block;
			margin:0 auto;
		}
		h1{
			font-size: 28px;
			font-size: 2.8rem;
			line-height: 33.6px;
		}
		.sliderHome {
			.main-container-slider {
    			width: 100%;
    			top:100px;
    		}
    		.arrowSlider {
			    font-size: 2.35rem;
			}
    		/**/
    		.pagerSquare{
				position:absolute;
				bottom: 10px;
				right: 0px;
				z-index: 50;
				a{
					margin-right:3px;
					&:last-child{
						margin-right:0px;
					}
				}
				.pager-square{
					width:12px;
					height:12px;
					background-color:transparent;
					border:2px solid $default-light-font-color;
					display: inline-block;
					vertical-align: top;
				}
				a.active{
					height: 10px;
    				margin-top: 0px;
    				vertical-align:top;
				}
			}
			/**/
			.separator-head{
				border-width: 8px;
				&:before{
					content:"";
					width:8px;
					height: 6.5px;
					left: 0px;
				}
				&:after{
					content:"";
					width:8px;
					height: 6.5px;
					right:0px;
				}
			}
    		.categorie-actu{
    			top:-55px;
    			p{
    				font-size: 12px;
    				font-size: 1.2rem;
    			}
    		}
    		.separator-foot-contain{
	    		width: 100%;
	    		bottom:0px;
	    	}
    		.separator-foot{
				width:100%;
				border-width: 8px;
				margin-bottom:0px;
				&:before{
					content:"";
					width:8px;
					height: 6.5px;
					margin-top: -14px;
				}
				&:after{
					content:"";
					width:8px;
					height: 6.5px;
					top: -14.5px;
				}
			}
    		#prevPage{
    			top: 9.5%;
    		}
    		#nextPage{
    			top: 9.5%;
    		}
    		.actu-slider-container{
    			height:255px;
    		}
    		.actu-slider{
    			.pictoTitre {
				    //margin-top: 70px;
				    margin-bottom: 12.5px;
				    .icon {
					    font-size: 2.5rem;
					}
				}
				.sous-titre-actu {
					font-size:23px;
				    font-size: 2.3rem;
				    line-height: 27.6px;
				}
				p{
					font-size: 17px;
					font-size: 1.7rem;
					line-height:20.4px;
				}
				 a {
				 	.bigger{
				 		font-size: 1.35rem;
				 	}
				 	img {
					    width: 200px;
					    margin: 15px auto 0px;
					    height: auto;
					}
				}
    		}
    		#sliderHome{
				li{
					height:400px;
				}
			}
			.bx-wrapper .bx-viewport {
			    height: 400px;
			}
			.prevPage {
			    left: 27.5px;
			    top: 10%;
			}
			.nextPage {
			    right:27.5px;
			    top: 10%;
			}
    	}
    	.bloc-titre-gallerie {
    		height: 75px;
    		h2 {
    			font-size: 17px;
			    font-size:1.7rem;
			    padding-top: 22px;
			    span{
			    	font-size: 10px;
			    	font-size: 1rem;
			    }
			}
			.triangle {
			    width: 0px;
			    height: 0px;
			    border-left: 5px solid transparent;
			    border-right: 5px solid transparent;
			    border-top: 5px solid #FFF;
			    display: inline-block;
			    margin-top: 6px;
			}
		}
    	.gallery {
    		margin-bottom: 40px;
    	}
    	.bloc-button-gallerie{
    		padding:0 27.5px;
    		height: 82.5px;
    		.callToAction--picto-archive{
	    		margin-top: 0px;
	    	}
    	}
    	.callToAction--picto-archive {
		    font-size: 14px;
		    height: auto;
		    min-height: 42.5px;
		    padding-top: 9px;
		}
    	.icon-archive, .icon-eye, , .icon-chef-oeuvre{
		    margin-right: 10px;
		    //margin-left: -20px;
		    font-size: 1.35rem;
    	}
		.public-gallery-link{
			padding-left: 0;
			padding-right: 0;
			#eyeSvg{
				width:17px;
				margin-right:5px;
			}
		}
    	.expander-light{
    		height:235px;
    		/*.container-int{
    			padding:0 60px;
    		}*/
    		.englobeur{
				display:inline-block;
				vertical-align: middle;
				border: 3px solid #000;
				line-height:57.5px;
				width:100%;
				text-align: center;
				margin-top:37.5px;
				@include transition(all,0.3s,ease,0s);
				&:before{
					content: '';
				    display: inline-block;
				    height: 0%;
				    vertical-align: middle;
				    margin-right: -0.25em;
				}
			}
			.englobeur1{
				margin-left:0px;
				.icon{
					font-size: 35px;
					font-size: 3.5rem;
				}
			}
			.englobeur2{
				margin-left:0px;
				.icon{
					font-size: 20px;
					font-size: 2rem;
				}
			}
			.text-headband-light{
				font-size: 12px;
				font-size: 1.2rem;
				margin-left: 20px;
			}
			.hidden-mobile{
				display:none;
			}
    	}
	}
}

.bx-default-pager{
	display:none;
}

