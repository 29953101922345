header {
    width: 100%;
    background-color: $default-dark-font-color;
    z-index: 9999;
    .headerDesktop {
        width: 1280px;
        margin: 0 auto;
        height: 90px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
    .headerRWD {
        display: none;
    }
    .blocLogo {
        //height:25px;
        vertical-align: middle;
        .logo {
            width: auto;
            color: white;
            font-size: 30px;
            font-size: 3.0rem;
            letter-spacing: 0.13em;
            @include transition(all, 0.3s, ease, 0s);
            p:first-child {
                letter-spacing: 0.45em;
            }
        }
        .logoHPMobile {
            margin-left: 25px;
            height: 100%;
            //margin-top:13px;
       
        }
        a.logo {
            &:hover,
            &:active,
            &:focus {
                color: $default-hover-color;
                .need-tiret {
                    &:after {
                        background-color: $default-hover-color;
                    }
                }
            }
        }
    }
    .buttonMenu {
        font-family: $font-lovelo-black;
        font-size: 18px;
        font-size: 1.8rem;
        color: $default-light-font-color;
        max-width: 200px;
        vertical-align: middle;
        margin-top: 6px;

       	.mac &{
       		padding-top: 10px;

       		a{
       			padding-top: 5px;
       		}
       	}
        a {
            @include transition(all, 0.3s, ease, 0s);
            &:hover,
            &:focus,
            &:active {
                color: $default-hover-color;
                @include transition(all, 0.3s, ease, 0s);
            }
        }
        &.active {
            color: $default-hover-color;
        }
    }
    .searchInput {
        position: relative;
        vertical-align: middle;
        input {
            background-color: $default-dark-font-color;
            color: $default-light-font-color;
            width: 161px;
            height: 35px;
            border: 4px solid $default-light-font-color;
            font-family: $font-opensans-regular;
            position: relative;
            padding-right: 30px;
            padding-left: 5px;
            @include transition(all, 0.3s, ease, 0s);
            &:hover,
            &:active,
            &:focus {
                border-color: $default-hover-color;
                @include transition(all, 0.3s, ease, 0s);
            }
        }
        .buttonInput {
            position: absolute;
            top: 7px;
            right: 11px;
            //margin-top: 2px;
            font-size: 18px;
            font-size: 1.8rem;
            @include transition(all, 0.3s, ease, 0s);
        }
        @media screen and (-webkit-min-device-pixel-ratio: 0) {
            .buttonInput {
                top: 4px;
            }
        }
    }
    .tickets {
        color: #fff;
        font-size: 3rem;

        &:hover {
            color: $default-hover-color;
        }
    }
    .blocLang {
        vertical-align: middle;
        .lang {
            .langSelect {
                color: $default-light-font-color;
                font-family: $font-opensans-regular;
                font-size: 16px;
                font-size: 1.6rem;

                .fre-FR {
                    background-image: url('../images/flags/fre-FR.gif');
                }

                .eng-GB {
                    background-image: url('../images/flags/eng-GB.gif');
                }

                .dut-NL {
                    background-image: url('../images/flags/dut-NL.gif');
                }
                
                a {
                    width: 15px;
                    height: 12px;
                    display: block;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;

                    @include transition(all, 0.3s, ease, 0s);
                    &:hover,
                    &:active,
                    &:focus {
                        color: $default-hover-color;
                        @include transition(all, 0.3s, ease, 0s);
                    }
                }
                &:hover .triangle-down::before,
                &:active .triangle-down::before,
                &:focus .triangle-down::before {
                    border-color: $default-hover-color transparent transparent transparent;
                    @include transition(all, 0.3s, ease, 0s);
                }
                ul {
                    padding: 0px;
                    li {
                        margin-top: 0px;
                        margin-bottom: 0px;
                        list-style: none;
                    }
                    .triangle-down {
                        position: relative;
                        &::before {
                            content: "";
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 5px 5px 0 5px;
                            border-color: #ffffff transparent transparent transparent;
                            position: absolute;
                            top: 4px;
                            left: -20px;
                            @include transition(all, 0.3s, ease, 0s);
                        }
                        &::after {
                            content: "";
                            width: 50px;
                            height: 53px;
                            position: absolute;
                            left: -23px;
                        }
                    }
                    .langHidden {
                        position: absolute;
                        margin-top: 25px;
                    }
                    .lang2 {
                        margin-top: 7px;
                    }
                }
            }
        }
    }
    .search-icon-mobile {
        display: none;
    }
    .picto-info-mobile {
        display: none;
    }
    .logo2 {
        width: auto;
        /*margin-top:8px;
        height: 82px;*/
        height: 100%;
        img {
            width: 56px;
            vertical-align: middle;
            padding: 1px;
            margin-top: 8px;
            height: 73px;
        }
        a {
            margin-right: 11px;
            &:focus,
            &:active {
                outline: 1px dotted $default-hover-color;
            }
        }
    }

    &.hasAppDLBlock{
        height: auto;
    }
}

#applicationDownloadBlock{
    display: none;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.97);
    color: #FFF;
    font-family: "loveloblack";
    font-size: 1.8rem;
    position:fixed;
    top: 50px;
    bottom: 0;
    left: 0;
    right: 0;

    .content{
        background-image: url('../images/applibg.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        padding: 30px;
    }

    .card{
        padding: 20px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        width: 195px;
        .title{
            color: #1d1d1e;
            margin-bottom: 10px;
        }
    }



    .close{
        position: absolute;
        right: 14px;
        top: 10px;
        opacity: 1;
        
        &:after {
            content: url("../images/burger-icon-cross2.svg");
            height: 15px;
            width: 15px;
            display: block;
        }
    }

}

.fillerCookie {
    height: 45px;
    display: block;
    width: 100%;
}

.filler {
    height: 90px;
    display: block;
    width: 100%;
}

.hidden {
    display: none;
}

.visible {
    display: block;
}

.reallyHidden {
    display: none;
}

@media (max-width: 1279px) {
    .filler {
        height: 100px;
    }
    header {
        width: 100%;
        height: 100px;
        position: relative;
        .headerDesktop {
            display: none;
        }
        .logo2 {
            img {
                margin-top: 0px;
                height: 100%;
            }
        }
        .headerRWD {
            display: block;
            position: relative;
            .headerUp {
                height: 100px;
                background-color: $default-dark-font-color;
                color: $default-light-font-color;
                vertical-align: middle;
                text-align: left;
                padding-right: 35px;
                &::before {
                    content: "";
                    height: 100%;
                }
                .search-icon-mobile {
                    display: inline-block;
                    vertical-align: middle;
                    position: absolute;
                    right: 272px;
                    top: 50%;
                    margin-top: -23px;
                    height: 45px;
                    .icon {
                        font-size: 43px;
                        font-size: 4.3rem;
                        &:hover,
                        &:active,
                        &:focus {
                            color: $default-hover-color;
                        }
                    }
                }
                .triangleMenu {
                    //display:none;
                    position: absolute;
                    &::after {
                        content: "";
                        position: absolute;
                        width: 0;
                        height: 0;
                        //top: -29px;
                        //right: 30px;
                        border-style: solid;
                        border-width: 0 15px 15px 15px;
                        border-color: transparent transparent #fff transparent;
                    }
                }
                .triangleSearch {
                    top: 58px;
                    right: 39px;
                }
                .triangleBurger {
                    left: 50%;
                    margin-left: -15px;
                    bottom: -16px;
                }
                .rechercheRWD {
                    border: 14px solid $default-light-font-color;
                    background-color: $default-dark-font-color;
                    width: 100%;
                    padding: 50px;
                    z-index: 100;
                    color: $default-light-font-color;
                    position: absolute;
                    display: none;
                    left: 0px;
                    top: 100px;
                    .searchText {
                        width: 100%;
                        font-family: $font-opensans-regular;
                        font-size: 26px;
                        font-size: 2.6rem;
                        margin-bottom: 40px;
                        padding-left: 30px;
                        height: 87px;
                        color: $default-dark-font-color;
                    }
                    .searchSubmit {
                        width: 100%;
                        color: $default-light-font-color;
                        background-color: $default-hover-color;
                        font-family: $font-biko-bold;
                        font-size: 36px;
                        font-size: 3.6rem;
                        border: none;
                        height: 87px;
                    }
                }
                .burger-icon {
                    vertical-align: middle;
                    width: 47px;
                    height: 43px;
                    position: absolute;
                    right: 25px;
                    top: 50%;
                    margin-top: -23px;
                }
                .picto-info-mobile {
                    vertical-align: middle;
                    display: inline-block;
                    position: absolute;
                    right: 197px;
                    top: 50%;
                    margin-top: -23.5px;
                    .icon {
                        font-size: 43px;
                        font-size: 4.3rem;
                        &:hover,
                        &:active,
                        &:focus {
                            color: $default-hover-color;
                        }
                    }
                }
                .blocLang {
                    position: absolute;
                    top: calc(50% - 19px);
                    right: 100px;

                    .lang .langSelect a {
                        width: 45px;
                        height: 36px;
                        background-size: 90%;
                        background-position: center;
                        background-repeat: no-repeat;
                    }

                    .lang .langSelect ul .langHidden.lang3 {
                        margin-top: 45px;
                    }
                }
                button {
                    width: 47px;
                    height: 45px;
                    &::before {
                        display: block;
                    }
                }
                .burger-icon-opened {
                    &::after {
                        content: url("../images/burger-icon-cross2.svg");
                    }
                }
                .burger-icon-closed {
                    &::after {
                        content: url("../images/burger-icon.svg");
                    }
                }
            }
            .blocLogo {
                height: 100%;
                vertical-align: middle;
                margin-right: 35px;
                .logo {
                    width: auto;
                    margin-left: 25px;
                    padding-top: 17px;
                    &::before {
                        content: "";
                        height: 100%;
                    }
                    p {
                        &:first-child {
                            font-size: 33px;
                            font-size: 3.3rem;
                            margin-bottom: 5px;
                            letter-spacing: 0.23em;
                        }
                        &:last-child {
                            font-size: 25px;
                            font-size: 2.5rem;
                        }
                    }
                    @media (max-width: 639px) {
                        p {
                            &:first-child {
                                font-size: 16.5px;
                                font-size: 1.65rem;
                                margin-bottom: 2.5px;
                            }
                            &:last-child {
                                font-size: 12.5px;
                                font-size: 1.25rem;
                            }
                        }
                    }
                }
                a.logo {
                    &:hover,
                    &:active,
                    &:focus {
                        color: $default-hover-color;
                    }
                }
                .logoHome {
                    width: auto;
                    margin-left: 25px;
                    padding-top: 17px;
                    &::before {
                        content: "";
                        height: 100%;
                    }
                    a {
                        vertical-align: middle;
                        &::before {
                            content: "";
                            height: 100%;
                        }
                        width: 51px;
                        display: block;
                        //width:80px;
                        height: 67.4px;
                        background: url("../images/logo-lille.svg") 0 0 no-repeat;
                        background-size: 51px;
                        background-position: 0 0px;
                        /*&:hover,
                        &:active,
                        &:focus{
                           	background-position:0 0px;
                        }*/
                    }
                }
            }
            .titrePage {
                vertical-align: middle;
                font-family: $font-lovelo-black;
                font-size: 30px;
                font-size: 3rem;
                width: calc(100% + 35px - 120px - 172px - 70px - 90px);
                width: -moz-calc(100% + 35px - 120px - 172px - 70px - 90px);
                width: -webkit-calc(100% + 35px - 120px - 172px - 70px - 90px);
                line-height: 1em;
                .sousTitrePage {
                    display: block;
                    font-family: $font-opensans-light;
                    font-size: 22px;
                    font-size: 2.2rem;
                }
            }
            .containerMenuRWD {
                border: 14px solid $default-light-font-color;
                background-color: $default-dark-font-color;
                width: 100%;
                padding: 80px 50px 50px;
                z-index: 100;
                color: $default-light-font-color;
                position: fixed;
                display: none;
                top: 100px;
                bottom:0px;
                overflow-y: scroll;
                &::before {
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 0;
                    top: -29px;
                    right: 30px;
                    border-style: solid;
                    border-width: 0 15px 15px 15px;
                    border-color: transparent transparent #ffffff transparent;
                }
                .menuDeroulant {
                    margin-left: 20%;
                    hr {
                        margin-bottom: 45px;
                    }
                    ul {}
                    .lvl1 {
                        font-family: $font-biko-bold;
                        font-size: 36px;
                        font-size: 3.6rem;
                        margin-bottom: 45px;
                        position: relative;
                        @include transition(all, 0.3s, ease, 0s);
                        &:hover,
                        &:active,
                        &:focus {
                            color: $default-hover-color;
                            @include transition(all, 0.3s, ease, 0s);
                        }
                    }
                    .ul_lvl2 {
                        margin-left: 45px;
                        display: none;
                    }
                    .lvl2 {
                        font-family: $font-atcoverlook-regular;
                        font-size: 32px;
                        font-size: 3.2rem;
                        margin-bottom: 45px;
                        @include transition(all, 0.3s, ease, 0s);
                        &:hover,
                        &:active,
                        &:focus {
                            color: $default-hover-color;
                            @include transition(all, 0.3s, ease, 0s);
                        }
                    }
                    .picto-menu-RWD {
                        position: absolute;
                        left: -110px;
                        top: 0px;
                    }
                    .icon-musee-prive {
                        font-size: 57px;
                        font-size: 5.7rem;
                        margin-top: -16px;
                    }
                    .icon-parcours {
                        font-size: 57px;
                        font-size: 5.7rem;
                        margin-top: -11px;
                    }
                    .icon-chef-oeuvre {
                        font-size: 50px;
                        font-size: 5rem;
                        margin-top: -7px;
                    }
                    .icon-agenda {
                        font-size: 59px;
                        font-size: 5.9rem;
                        margin-top: -12px;
                    }
                    .icon-infos {
                        font-size: 48px;
                        font-size: 4.8rem;
                        margin-top: -6px;
                    }
                    .icon-send {
                        font-size: 36px;
                        font-size: 3.6rem;
                    }
                    button {
                        width: 32px;
                        height: 32px;
                        right: 65px;
                        position: absolute;
                        margin-top: 2px;
                        &::before {
                            display: block;
                        }
                    }
                    .less {
                        &::after {
                            content: url("../images/picto-moins-menuRWD.svg");
                        }
                    }
                    .more {
                        &::after {
                            content: url("../images/picto-plus-menuRWD.svg");
                        }
                    }
                    .lineMore {
                        //width: calc(100% + 45px);
                        margin-left: -45px;
                    }
                }
            }
        }
    }
}

.sticky {
    position: fixed;
}

.ui-autocomplete {
    background-color: $default-dark-font-color;
    color: $default-light-font-color;
    padding: 10px;
    width: 200px !important;
    z-index: 9999;
    li {
        cursor: pointer;
        a {
            span {
                // color:$default-hover-color !important;
           
            }
        }
    }
}
@media (min-width: 640px) {
    #applicationDownloadBlock{
        display: none !important;
    }
}
@media (max-width: 639px) {
    .filler {
        height: 50px;
    }
    header {
        width: 100%;
        height: 50px;
        .headerRWD {
            .headerUp {
                height: 50px;
                //padding-top:15px;
                .search-icon-mobile {
                    margin-top: -9px;
                    right: 180px;
                    .icon {
                        font-size: 21.5px;
                        font-size: 2.15rem;
                    }
                }
                .triangleMenu {
                    &::after {
                        content: "";
                        position: absolute;
                        width: 0px;
                        height: 0px;
                        border-style: solid;
                        border-width: 0px 7px 7px;
                        border-color: transparent transparent #FFF;
                        top: -10px;
                        left: 8px;
                    }
                }
                .triangleMenu {
                    right: 20px;
                    top: 35px;
                    &::after {
                        border-width: 0 7.5px 7.5px 7.5px;
                    }
                    /*&.triangleBurger{
                    	margin-left: -7px;
                    }*/
                }
                .triangleSearch {
                    top: 37px;
                    right: 25px;
                }
                @media screen and (-webkit-min-device-pixel-ratio: 0) {
                    .search-icon-mobile {
                        margin-top: -12px;
                    }
                    .triangleMenu {
                        top: 28px;
                        &::after {
                            content: "";
                            top: 0px;
                            left: 8px;
                        }
                    }
                    .triangleSearch {
                        top: 30px;
                        right: 26px;
                    }
                }
                .rechercheRWD {
                    border: 3.5px solid $default-light-font-color;
                    padding: 12.5px;
                    top: 50px;
                    /*&::before{
                    	content:"";
                    	position:absolute;
                    	width: 0;
                    	height: 0;
                    	top: -7.25px;
                    	right: 7.5px;
                    	border-style: solid;
                    	border-width: 0 3.25px 3.25px 3.25px;
                    	border-color: transparent transparent #ffffff transparent;
                    }*/
                    .searchText {
                        font-size: 16px;
                        font-size: 1.6rem;
                        margin-bottom: 22.5px;
                        padding-left: 15px;
                        height: 35px;
                    }
                    .searchSubmit {
                        font-size: 18px;
                        font-size: 1.8rem;
                        height: 35px;
                    }
                }
                .burger-icon {
                    width: 23.5px;
                    height: 19px;
                    margin-top: -7.5px;
                }
                @media screen and (-webkit-min-device-pixel-ratio: 0) {
                    .burger-icon {
                        margin-top: -10.5px;
                    }
                }
                .picto-info-mobile {
                    @include transition(all, 0.3s, ease, 0s);
                    color: $default-light-font-color;
                    margin-top: -10.75px;
                    right: 140px;
                    .icon {
                        font-size: 21.5px;
                        font-size: 2.15rem;
                    }
                    &:hover,
                    &:active,
                    &:focus {
                        @include transition(all, 0.3s, ease, 0s);
                        color: $default-secondary-color;
                    }
                }
                .blocLang {
                    right: 70px;
                    top: calc(50% - 12px);

                    .lang .langSelect a {
                        width: 30px;
                        height: 24px;
                    }

                    .lang .langSelect ul .langHidden.lang3 {
                        margin-top: 35px;
                    }
                }
                button {
                    width: 23.5px;
                    height: 22px;
                }
                .burger-icon-opened {
                    &::after {
                        content: url("../images/burger-icon-cross2.svg");
                    }
                }
                .burger-icon-closed {
                    &::after {
                        content: url("../images/burger-icon.svg");
                    }
                }
            }
            .blocLogo {
                margin-right: 0px;
                .logo {
                    margin-left: 12.5px;
                    padding-top: 8.5px;
                    margin-right: 12.5px;
                    p {
                        &:first-child {
                            font-size: 16.5px;
                            font-size: 1.65rem;
                            margin-bottom: 2.5px;
                            letter-spacing: 0.45em;
                        }
                        &:last-child {
                            font-size: 12.5px;
                            font-size: 1.25rem;
                            letter-spacing: 0.3em;
                        }
                    }
                }
                .logoHPMobile {
                    margin-left: 12.5px;
                    //margin-top:6.5px;
                    height: 100%;
                    .logo2 img {
                        width: 27px;
                        vertical-align: middle;
                        height: 100%;
                    }
                }
                .logoHome {
                    width: auto;
                    margin-left: 12.5px;
                    padding-top: 8.5px;
                    a {
                        width: 27.5px;
                        height: 32.7px;
                        background-size: 25.5px;
                    }
                }
            }
            .titrePage {
                font-size: 15px;
                font-size: 1.5rem;
                min-width: 115px;
                width: calc(100% + 17.5px - 60px - 86px - 35px - 90px);
                width: -moz-calc(100% + 17.5px - 60px - 86px - 35px - 90px);
                width: -webkit-calc(100% + 17.5px - 60px - 86px - 35px - 90px);
                .sousTitrePage {
                    font-size: 11px;
                    font-size: 1.1rem;
                }
            }
            .containerMenuRWD {
                border: 7px solid $default-light-font-color;
                padding: 40px 25px 25px;
                top: 50px;
                bottom: 0px;
                &::before {
                    top: -14.5px;
                    right: 15px;
                    border-width: 0 7.5px 7.5px 7.5px;
                }
                .menuDeroulant {
                    hr {
                        margin-bottom: 22.5px;
                    }
                    ul {}
                    .lvl1 {
                        font-size: 18px;
                        font-size: 1.8rem;
                        margin-bottom: 22.5px;
                    }
                    .ul_lvl2 {
                        margin-left: 22.5px;
                    }
                    .lvl2 {
                        font-size: 16px;
                        font-size: 1.6rem;
                        margin-bottom: 22.5px;
                    }
                    .picto-menu-RWD {
                        left: -55px;
                        top: 0px;
                    }
                    .icon-musee-prive {
                        font-size: 28.5px;
                        font-size: 2.85rem;
                        margin-top: -7px;
                    }
                    .icon-parcours {
                        font-size: 28.5px;
                        font-size: 2.85rem;
                        margin-top: -5.5px;
                    }
                    .icon-chef-oeuvre {
                        font-size: 25px;
                        font-size: 2.5rem;
                        margin-top: -3px;
                    }
                    .icon-agenda {
                        font-size: 29.5px;
                        font-size: 2.95rem;
                        margin-top: -6px;
                    }
                    .icon-infos {
                        font-size: 24px;
                        font-size: 2.4rem;
                        margin-top: -3px;
                    }
                    .icon-send {
                        font-size: 18px;
                        font-size: 1.8rem;
                    }
                    button {
                        width: 16px;
                        height: 16px;
                        right: 32.5px;
                        position: absolute;
                        margin-top: -2px;
                        &::before {
                            display: block;
                        }
                    }
                    .less {
                        &::after {
                            content: url("../images/picto-moins-menuRWD.svg");
                        }
                    }
                    .more {
                        &::after {
                            content: url("../images/picto-plus-menuRWD.svg");
                        }
                    }
                    .lineMore {
                        //width: calc(100% + 45px);
                        margin-left: -22.5px;
                    }
                }
            }
        }
    }
}