#user-connect{
    vertical-align: middle;
    margin-right: 20px;
    margin-left: 20px;
    @media(max-width:1279px){
        margin-left: 20%;
    }
    &.logged{
        display: inline-flex;
        button, a{
            font-family: 'novecento_sans_widebook';
            font-size:16px; font-size:1.6rem;
            padding: 5px 15px 6px 15px;

            @media(max-width:1279px){
                font-size:36px; font-size:3.6rem;
                padding: 10px 40px 7px;
            }
        }
        &:first-child{
            margin-right: 5px;
        }
    }

    button {
        width: 35px;
        height: 35px;
        background-image:url(../images/account/user.svg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    a {
        @extend %bones-btn;
        @extend %skin-btn-white; 
 
        &:hover, &:focus{
            @extend %animation-btn-red;
        }
    }
}
