
@mixin encartFamilleBuilder($color){
	h2{
		color: $color;
	}
	.button--famille{
		background-color: $color;
		border: 2px solid $color;

		&:hover,
		&:focus,
		&:active{
			background-color: transparent;
			color: $color;
		}
	}
}
.class_group_content.class_identifier_page_wide .page--visiter--famille{
	overflow: auto;
	section{
		overflow: visible;
	}
	.banner--wide{
		min-height: 1070px;
		padding-bottom: 0;
		overflow: auto;
	}
	.anchor-contain{
		top: 130px;
	}
	.banner--famille{
		padding-top: 40px;
		padding-bottom: 20px;
		background-color: #FFF;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.section-famille{
		position: relative; 
		margin-top: 245px; 
		margin-bottom: 20px;

		.innerContent{
			line-height: 1;
		}
	}
	// Tablette encart 3
	.tablette-bg{
		margin-bottom: 15px;
	}

	.has-scroll.icon.icon-chevron-up{
		margin-top: 55px;
	}
	// Encarts
	.banner--wide .encart,
	.banner--famille .encart{
		background-color: #FFF;
		@include border-radius(10px);
		width: 650px;
		max-width: 100%;
		display: inline-block;
		padding: 60px 62px 45px;
		text-align: center;
		margin-bottom: 15px;
		h2{
			font-family: $font-biko-bold;
			font-size: 62px;
			font-size: 6.2rem;
			text-transform: none;
			margin-top: 0;
			margin-bottom: 25px;
			text-align: left;
			letter-spacing: 0;
		}
		.yosemite-rte{
			text-align: left;
			font-family: $font-opensans-light !important;
			&>*{
				font-family: $font-opensans-light !important;
			}
		}
		.button--famille{
			@include border-radius(10px);
			@include transition(all, 0.3s, ease, 0s);
			width: 415px;
			height: 60px;
			display: inline-block;
			line-height: 56px;
			font-family: $font-biko-bold;
			font-size: 24px;
			font-size: 2.4rem;
			color: white;
			margin-top: 30px;
			max-width: 100%;

			&:before{
				display: inline-block;
				content: '';
				height: 108%;
				vertical-align: middle;
			}
			.innerContent{
				display: inline-block;
			}
		}
		&.encart-1{
			@include encartFamilleBuilder(#F26522);
		}
		&.encart-2{
			@include encartFamilleBuilder(#7AA507);
		}
		&.encart-3{
			@include encartFamilleBuilder(#1C75BC);
			margin-bottom: 0px;
			// height: 480px;
		}
		&.encart-4{
			@include encartFamilleBuilder(#F7941E);
			h2{
				font-size: 59px;
				font-size: 5.9rem;
			}
		}
	}

	section.chef-oeuvre{
		.h2-builder{
			@include titleBuilder('','picto-top','container-dark',280px,'picto-triangle');
		}
	}

	@media (max-width: 1279px){
		.encart{
			padding: 30px !important;
		}
		.padding-mobile{
			padding-left: 30px;
			padding-right: 30px;
		}
		.banner--wide{
			background-size: auto 1070px;
			background-repeat: no-repeat;
			background-position: top; 
			min-height: auto;
		}
		.section-famille{
			margin-bottom: 0;
			margin-top: 280px;
			padding-bottom: 20px;
		}
		.banner--famille{
			height: 250px;
			margin-bottom: 530px;

			&:nth-child(n){
				margin-bottom: 0;
				background-size: 100% auto;
				height: auto;
			}
		}
		.encart{
			margin-bottom: 0;
			margin-top: 125px;
			padding: 35px 30px 50px 30px;
			width: 100%;
			max-width: 650px;

			.button--famille{
				line-height: 1; 
			}

			&.encart-1{
				margin-top: 0px;
				width: 100%;

			}
			&.encart-3{
				@include border-radius(10px);
				height: auto;
			}
		}
		.tablette-bg{
			background-image: none !important;
			padding: 0;
			width: auto;
		}
	}
	@media(max-width: 639px){
		.anchor-contain{
			top: 35px;
		}
		.section-famille{
			margin-top: 140px;
		}
		.banner--wide{
			background-size: auto 507px;
		}
		.tablette-bg{
			width: 100%;
		}
		.banner--famille .encart{
			width:100%;
			padding:20px 15px 20px
		}
		.encart{
			padding: 17.5px 15px 25px !important;
			h2{
				font-size: 36px !important;
				font-size: 3.6rem !important;
			}
			.button--famille{
				font-size: 16px !important;
				font-size: 1.6rem !important;
			}
		}
	}
}