.class_identifier_oeuvre .page--collections--oeuvre-artist{

	.module--oeuvre{
		height: auto;
	}
	
	.artist-details{
		.line{
			margin-bottom: 15px;
		}
		.artist-label{
			width: 140px;
			display: inline-block;
			font-family: $font-opensans-light-italic;
			font-size: 14px;
			font-size: 1.4rem;
			text-align: right;
			padding-right: 30px;
		}
		.artist-detail{
			display: inline-block;
			font-family: $font-opensans-bold;
			font-size: 14px;
			font-size: 1.4rem;
			&>*{
				display: inline-block;
			}
		}
	}
	.artist-bio{
		margin-bottom: 30px;
		&.yosemite-rte{
			font-family: $font-opensans-light;
			font-size: 14px;
			font-size: 1.4rem;
		}
	}
	.infos-plus{
		padding: 40px 30px 65px 60px;
		width: 1075px;
		background-color: #FFF;
		display: inline-block;
		color: $default-dark-font-color;


		.artist-left{
			width: 555px;
			display: inline-block;
			vertical-align: top;
			padding-right: 120px;
		}
		.artist-right{
			display: inline-block;
		}
		.artist-visu{
			width: 275px;
			height: 400px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}
		.artist-head{
			margin-bottom: 15px;
			.name{
				font-family: $font-biko-bold;
				font-size: 23px;
				font-size: 2.3rem;
				margin-right: 25px;
			}
			.date{
				font-family: $font-opensans-regular;
				font-size: 14px;
				font-size: 1.4rem;
			}			
		}
		.related-title{
			font-family: $font-biko-bold;
			font-size: 23px;
			font-size: 2.3rem;
			margin-bottom: 20px;
		}
		.related-documents{
			display: inline-block;
			width: 145px;
			margin-right: 20px;
			vertical-align: top;
			text-align: center;

			&.pdf{
				.document-visu{
					width: 145px;
					border: solid 1px rgb(29, 29, 30);
					display: inline-flex;
					justify-content: center;
					align-items: center;
					.icon-pdf{
						font-size: 50px ;
						font-size: 5.0rem ;
					}
				}
			}
		}
		.document-visu{
			height: 125px;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			margin-bottom: 10px;
			border-radius: 5px;
			font-size: 100px;
			text-align: center;
		}
		.document-caption{
			font-family: $font-opensans-light;
			font-size: 14px;
			font-size: 1.4rem;
			padding-left: 10px;
		}
	}
	@media(max-width: 1279px){
		// height: calc(100% - 170px);
		// height: -moz-calc(100% - 170px);
		// height: -webkit-calc(100% - 170px);
		margin: 0px;
		padding: 0px;
		position: absolute;
		width: 100%;
		top: 100px;
		bottom: 70px;
		overflow-y: scroll;

		// Contenu artist
		.artist-details{
			display: block;
			margin-left: auto;
			margin-right: auto;
			width: 100%;
			text-align: left;
			padding: 0 55px;
			padding-bottom: 35px;
			margin-bottom: 35px;

			.artist-label{
				font-size: 22px;
				font-size: 2.2rem;
				width: 220px;
				display: inline-block;
				vertical-align: middle;
			}
			.artist-detail{
				font-size: 22px;
				font-size: 2.2rem;
				display: inline-block;
				vertical-align: middle;
				width: calc(100% - 220px);
				width: -moz-calc(100% - 220px);
				width: -webkit-calc(100% - 220px);
			}
			.line{
				&:last-child{
					&:after{
						content: '';
						display: block;
						width: 70%;
						height: 1px;
						background-color: #7B7C7E;
						margin-left: auto;
						margin-right: auto;
						position: relative;
						top: 40px;
					}
				}
			}
		}

		// Arianne
		.fake-arianne{
			color: white;
			margin-top: 0px;
			color: white;
		}
		.infos-content{
			background-color: #FFF;

			.yosemite-rte{
				word-wrap: break-word;
			}
		}
		.oeuvre-menu-mobile{
			display: inline-block;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 25px;

			.menu-item{
				height: 100px;
				text-align: left;
				width: 415px;
				&:before{
					height: 100%;
				}
				&:after{
					display: none;
				}

				.title{
					display: inline-block;
					margin-left: 50px;
					font-family: $font-biko-bold;
					font-size: 30px;
					font-size: 3.0rem;
					margin-bottom: 0;
					vertical-align: middle;
				}
				.icon{
					margin-bottom: 0;
					font-size: 35px;
					font-size: 3.5rem;
				}
				&.item-artist{
					.subtitle{
						font-family: $font-opensans-regular;
						font-size: 22px;
						font-size: 2.2rem;
					}
				}
				&.item-map{
					.title{
					}
					.subtitle{
						display: inline-block;
						font-family: $font-biko-regular;
						font-size: 30px;
						font-size: 3.0rem;
						&:before{
							content: ',';
						}
					}
				}
				&.item-artips{
					text-align: center;
				}
			}
		}
		.close-part{
				text-align: center;
				position: fixed;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 70px;

				&:hover,
				&:focus,
				&:active{
					color: $default-hover-color;
				}


				&:before{
					content: '';
					display: inline-block;
					vertical-align: middle;
					height: 100%;
				}

				.inner-content{
					display: inline-block;
					vertical-align: middle;
					color: white;
				}
				.icon{
					font-size: 27px;
					font-size: 2.7rem;
				}
				span{
					font-size: $font-biko-bold;
					font-size: 30px;
					font-size: 3.0rem;
					display: inline-block;
					vertical-align: middle;
					margin-left: 20px;
				}

				@media(max-width: 639px){
					height: 35px;
					.icon{
						font-size: 13.5px;
						font-size: 1.35rem;
					}
					span{
						font-size: 15px;
						font-size: 1.5rem;
						margin-left: 10px;
					}
				}
			}
	}
	@media(max-width: 639px){
		top: 50px;
		bottom: 35px;
		.oeuvre-menu-mobile{
			margin-bottom: 12.5px;
			.menu-item{
				height: 50px;
				width: 207.5px;
				.title{
					margin-left: 25px;
					font-size: 15px;
					font-size: 1.5rem;
				}
				.subtitle{
					font-size: 15px;
					font-size: 1.5rem;
				}
				.icon{
					font-size: 17.5px;
					font-size: 1.75rem;
				}
				&.item-map{
					.title{
						&:after{
							content: ',';
						}
					}
					.subtitle{
						font-size: 15px;
						font-size: 1.5rem;
					}
				}
				&.item-artist{
					.subtitle{
						font-size: 11px;
						font-size: 1.1rem;
					}
				}
			}
		} 

		// Contenu artist
		.artist-details{
			padding: 0 27.5px;

			.artist-label{
				font-size: 11px;
				font-size: 1.1rem;
				width: 110px;
			}
			.artist-detail{
				font-size: 11px;
				font-size: 1.1rem;
				width: calc(100% - 110px);
				width: -moz-calc(100% - 110px);
				width: -webkit-calc(100% - 110px);
			}
			.line{
				margin-bottom: 10px;
				&:last-child{
					&:after{
						content: '';
						display: block;
						width: 70%;
						height: 1px;
						background-color: #7B7C7E;
						margin-left: auto;
						margin-right: auto;
						position: relative;
						top: 20px;
					}
				}
			}
		}
	}
}