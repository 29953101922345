
input#email::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: inherit;
}
input#email:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color: inherit;
   opacity:  1;
}
input#email::-moz-placeholder { /* Mozilla Firefox 19+ */
   color: inherit;
   opacity:  1;
}
input#email:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: inherit;
}

input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: inherit;
}
input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color: inherit;
   opacity:  1;
}
input::-moz-placeholder { /* Mozilla Firefox 19+ */
   color: inherit;
   opacity:  1;
}
input:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: inherit;
}
.noSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.clear{
	width: 100%;
	clear: both;
}
.floatLeft{
	float: left!important;
}
.floatRight{
	float: right!important;
}
.verticalAligned{
	display: inline-block;
	vertical-align: middle;
	line-height: normal;
}
.dispTable{
	display: table !important;
}
.hasBefore{
	position: relative;

	&:before{
		content: "";
		display: inline-block;
		position: absolute;
	}
}
.hasAfter{
	position: relative;

	&:after{
		content: "";
		display: inline-block;
		position: absolute;
	}
}
.xDistribution{
	text-align: justify;
	-ms-text-justify: distribute-all-lines;
    text-justify: distribute-all-lines;
	font-size: 0;
	line-height: 0;

	&:after{
		content: "";
		width: 100%;
		display: inline-block;
	}
	&>*{
		display: inline-block;
		line-height: normal;
		font-size: 14px;
		font-size: 1.4rem;
		text-align: left;
	}
}
// .valign{
// 	&:before{
// 		content: '';
// 		display: inline-block;
// 		vertical-align: middle;
// 		height: 100%;
// 	}
// }
.center-line{
	width: 100%;
	text-align: center;
}
.visible-mobile{
	display: none!important;
	@media(max-width: 1279px){
		display: block !important;
	}
	@media(max-width: 1279px) and (min-width: 640px){
		display: block !important;
	}
	@media(max-width: 639px){
		display: block !important;
	}
}
.visible-mobile-inline-block{
	display: none !important;
	@media(max-width: 1279px){
		display: inline-block !important;
	}
	@media(max-width: 1279px) and (min-width: 640px){
		display: inline-block !important;
	}
	@media(max-width: 639px){
		display: inline-block !important;
	}
}
.visible-desktop{
	@media(max-width: 1279px){
		display: none !important;
	}
	@media(max-width: 1279px) and (min-width: 640px){
		display: none !important;
	}
	@media(max-width: 639px){
		display: none !important;
	}
}
.visible-desktop-inline-block{
	@media(max-width: 1279px){
		display: none !important;
	}
	@media(max-width: 1279px) and (min-width: 640px){
		display: none !important;
	}
	@media(max-width: 639px){
		display: none !important;
	}
}
sup{
	font-size: 0.6em !important;
}
.no-overflow{
	overflow: hidden !important;
}