.musee-prive{
	width:100%;
	.container-ext{
		width:650px;
		margin:0 auto;
		background-color:$default-hover-color;
	}
	.bg-white{
		background-color:$default-light-font-color;
	}
	.bg-gold{
		background-color:#C49A6C;
	}
	.container-int{
		padding:60px 75px;
	}
	.icon-chevron-down{
		color:$default-light-font-color;
		font-size: 19px;
		font-size: 1.9rem;
	}
	.icon-chevron-up{
		color:$default-light-font-color;
		font-size: 19px;
		font-size: 1.9rem;
	}
	.banner--wide-mp{
		background-position: center center;
		background-size: cover;
		padding-bottom: 80px;
		min-height: 500px;
		position: relative;
		padding-top: 70px;
	}
	.banner--wide {
	    padding-bottom: 0px;
	    min-height: 510px;

	    @media(max-width: 1279px){
	    	width: 100%;
	    	display: inline-block;
	    }
	}
	h2{
		margin-top: 0px !important;
		font-family: $font-biko-bold !important;
		margin-bottom:40px;
		text-transform:none;
		text-align:center;
		font-size: 36px !important;
		font-size: 3.6rem !important;
	}
	.chevron-inner{
		position: absolute;
		bottom: 30px;
	}
	.banner--wide-mp{
		&:last-child{
			.chevron-inner{
				bottom: 1px;
			}
		}
	}
	button{
	    &:hover,
		&:active,
		&:focus{
			color:$default-hover-color;
		}
	}
	.button-mp{
		border:4px solid $default-light-font-color;
		padding:30px;
		font-family: $font-lovelo-black;
		font-size: 24px;
		font-size: 2.4rem;
		display: inline-block;
		min-width: 420px;
		margin:0 auto 40px;
		max-width:100%;
		text-align:center;
		/*@include transition(all,0.3s,ease,0s);
	    &:hover,
		&:active,
		&:focus{
			color:$default-hover-color;
			border-color:$default-hover-color;
			@include transition(all,0.3s,ease,0s);
		}*/
	}
	p{
		font-family: $font-opensans-regular;
		font-size: 16px;
		font-size: 1.6rem;
		margin-bottom: 25px;
		line-height: 19px;
		.bold{
			font-family: $font-opensans-bold;
		}
		&:last-child{
			margin-bottom: 0px;
		}
	}
	.text-white{
		h2,a,p,ul,ol,li{
			color:$default-light-font-color;
			border-color:$default-light-font-color;
		}
		a{
			@include transition(all,0.3s,ease,0s);
			&:hover, &:active, &:focus{
				color: $default-dark-font-color;
				border-color: $default-dark-font-color;
			}
		}
		
	}
	.text-dark{
		h2,a,p,ul,ol,li{
			color:$default-dark-font-color;
			border-color:$default-dark-font-color;
		}
		a{
			@include transition(all,0.3s,ease,0s);
			&:hover, &:active, &:focus{
				color: #D8383F;
				border-color:#D8383F;
			}
		}
	}
}


@media(min-width:640px) and (max-width:1279px){
	.musee-prive{
		width:100%;
		.container-ext{
			width:100%;
			margin:0 auto;
			background-color:$default-hover-color;
		}
		.bg-white{
			background-color:$default-light-font-color;
		}
		.bg-gold{
			background-color:#C49A6C;
		}
		.container-int{
			padding:60px 55px;
		}
		.icon-chevron-down{
			color:$default-light-font-color;
			font-size: 19px;
			font-size: 1.9rem;
		}
		.icon-chevron-up{
			color:$default-light-font-color;
			font-size: 19px;
			font-size: 1.9rem;
		}
		.banner--wide-mp{
			//background-position: center center;
			//background-size: cover;
			padding-bottom: 0px;
			height: auto;
			position: relative;
			padding-top: 0px;
			background:none;
			min-height: 0px;
		}
		.banner--wide {
		    padding-bottom: 0px;
		    min-height: 780px;
		}
		h2{
			margin-top: 0px !important;
			font-family: $font-biko-bold;
			margin-bottom:40px;
			text-transform:none;
			text-align:center;
			font-size: 36px !important;
			font-size: 3.6rem !important;
		}
		.chevron-inner{
			position: absolute;
			bottom: 30px;
		}
		.banner--wide-mp{
			&:last-child{
				.chevron-inner{
					bottom: 1px;
				}
			}
		}
		button{
		    &:hover,
			&:active,
			&:focus{
				color:$default-hover-color;
			}
		}
		.button-mp{
			border:4px solid $default-light-font-color;
			padding:30px;
			font-family: $font-lovelo-black;
			font-size: 24px;
			font-size: 2.4rem;
			display: inline-block;
			min-width: 420px;
			margin:0 auto 40px;
			max-width:100%;
			text-align:center;
		}
		p{
			font-family: $font-opensans-regular;
			font-size: 22px;
			font-size: 2.2rem;
			margin-bottom: 33px;
			line-height: 26px;
			.bold{
				font-family: $font-opensans-bold;
			}
			&:last-child{
				margin-bottom: 0px;
			}
		}
		.text-white{
			h2,a,p{
				color:$default-light-font-color;
				border-color:$default-light-font-color;
			}
		}
		.text-dark{
			h2,a,p{
				color:$default-dark-font-color;
				border-color:$default-dark-font-color;
			}
		}
	}
}

@media(max-width:639px){
	.musee-prive{
		width:100%;
		.container-ext{
			width:100%;
			margin:0 auto;
			background-color:$default-hover-color;
		}
		.bg-white{
			background-color:$default-light-font-color;
		}
		.bg-gold{
			background-color:#C49A6C;
		}
		.container-int{
			padding:30px 22.5px;
		}
		.icon-chevron-down{
			color:$default-light-font-color;
			font-size: 9.5px;
			font-size: 0.95rem;
		}
		.icon-chevron-up{
			color:$default-light-font-color;
			font-size: 9.5px;
			font-size: 0.95rem;
		}
		.banner--wide-mp{
			//background-position: center center;
			//background-size: cover;
			padding-bottom: 0px;
			height: auto;
			position: relative;
			padding-top: 0px;
			background:none;
			min-height: 0px;
		}
		.banner--wide {
		    padding-bottom: 0px;
		    min-height: 390px;
		}
		h2{
			margin-top: 0px !important;
			font-family: $font-biko-bold;
			margin-bottom:20px;
			text-transform:none;
			text-align:center;
			font-size: 18px !important;
			font-size: 1.8rem !important;
		}
		.chevron-inner{
			position: absolute;
			bottom: 15px;
		}
		.banner--wide-mp{
			&:last-child{
				.chevron-inner{
					bottom: 0.5px;
				}
			}
		}
		.button-mp{
			border:2px solid $default-light-font-color;
			padding:15px;
			font-family: $font-lovelo-black;
			font-size: 12px;
			font-size: 1.2rem;
			display: inline-block;
			min-width: 210px;
			margin:0 auto 20px;
			max-width:100%;
			text-align:center;
		}
		p{
			font-family: $font-opensans-regular;
			font-size: 11px;
			font-size: 1.1rem;
			margin-bottom: 16.5px;
			line-height: 13px;
			.bold{
				font-family: $font-opensans-bold;
			}
			&:last-child{
				margin-bottom: 0px;
			}
		}
	}
}