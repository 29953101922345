.public-list-all, .public-album{
    
    background-color:#fff;
    overflow:hidden;

    .arianne.collections-liste-oeuvre{
        .title{
            width: 550px;
            padding-top: 30px;
            position: relative;
            span.eye{
                display: inline-block;
                width:41px;
                height: 28px;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NiIgaGVpZ2h0PSIzMSI+PHN0eWxlPi5leWVfcGFydHtmaWxsOm5vbmU7c3Ryb2tlOiNGRkZGRkY7c3Ryb2tlLXdpZHRoOjQ7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fTwvc3R5bGU+PHBhdGggaWQ9InN2Z18xIiBkPSJNMjIuODk3IDIuMjg1Yy05IDAtMTYuOCA1LjQtMjAuMyAxMy4xIDMuNSA3LjcgMTEuMiAxMy4xIDIwLjMgMTMuMSA5IDAgMTYuOC01LjQgMjAuMy0xMy4xLTMuNi03LjctMTEuMy0xMy4xLTIwLjMtMTMuMXoiIGNsYXNzPSJleWVfcGFydCIvPjxjaXJjbGUgaWQ9InN2Z18yIiBjeD0iMjIuODk3IiBjeT0iMTUuMzg1IiByPSI3IiBjbGFzcz0iZXllX3BhcnQiLz48L3N2Zz4=");
                background-size: cover;
                position: relative;
                top: 5px;
            }
            .triangle{
                width: 0px;
                height: 0px;
                display: inline-block;
                margin-top: 12px;
                position: absolute;
                bottom: -23px;
                left: calc(50% - 21px);
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid #FFF;
            }

        } 

        button.create-album-btn{
            @extend %bones-btn;
            @extend %skin-btn-white;
            &:hover, &:focus, &:active{
                @extend %animation-btn-red;
            }
            position: absolute;
            top: 25px;
            right: 0;
            padding:10px 15px;
        }
    }

    .album-infos{
        width: 1102px;
        max-width: 100%;
        margin: 0 auto;
        padding: 45px 0 60px;
        position: relative;

        .album-name{
            font-family: $font-biko-bold;
            font-size: 2rem;
            font-size: 20px;
            text-transform: uppercase;
            margin-bottom: 10px;
        }
        .album-description{
            font-size: 1.4rem;
            font-size: 14px;
            margin-bottom: 5px;
        }
        .album-creator{
            font-size: 1.4rem;
            font-size: 14px;
            font-weight: bold;
            a:hover{
                text-decoration:underline;
            }
        }

        button.share{
            @extend %bones-btn;
            @extend %skin-btn-black;
            &:hover, &:focus, &:active{
                @extend %animation-btn-red;
            }
            position: absolute;
            top: 45px;
            right: 0;
            padding:10px 15px;
        }

        @media(max-width:1279px){
            padding-left: 15px;
            padding-right: 85px;

            button.share{
                right: 15px;
            }
        }
    }


    .gallery{
        display:flex;
        flex-flow: row wrap;
        .description{    
            color: #2C2C2E;
            background-color: #FFF;
        }

        .vignette{
            display:block;
            position: relative;
            @media(max-width: 639px){
                width: 100%;
            }

            .gallery-actions{
                position: absolute;
                z-index: 2;
                &.favorites-actions{
                    top: 20px;
                    right: 20px;
                    
                    button{ 
                        opacity: 0;
                        background-color:#404;
                        display: block;
                        height:28px;
                        width: 32px;

                        background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMzEgMjAwIj48c3R5bGU+LnN0MHtmaWxsOm5vbmU7c3Ryb2tlOiNGRkZGRkY7c3Ryb2tlLXdpZHRoOjE1O3N0cm9rZS1taXRlcmxpbWl0OjEwO308L3N0eWxlPjxwYXRoIGQ9Ik0xMTUuOCAxOTNDODYuNyAxNzYuMSA5LjkgMTI0LjkgOS45IDU1LjkgOS45IDMwIDMxLjggOSA1OC44IDljNDIuMSAwIDU3IDMyLjMgNTcgMzIuM1MxMzQuNiA5IDE3Mi43IDljMjYuOSAwIDQ4LjggMjEgNDguOCA0Ni45LjEgNjktNzYuNyAxMjAuMi0xMDUuNyAxMzcuMXoiIGNsYXNzPSJzdDAiLz48L3N2Zz4=') no-repeat 50%;
                        background-size: cover;

                        transition: scale, 0.1s;
                        &.active, &:hover{
                            
                            transform: scale(1.2);
                            background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMzUuMSAyMDkuNSI+PHN0eWxlPi5zdDB7ZmlsbDpub25lO3N0cm9rZTojRkZGRkZGO3N0cm9rZS13aWR0aDoxNTtzdHJva2UtbWl0ZXJsaW1pdDoxMDt9IC5zdDF7ZmlsbDojRkZGRkZGO3N0cm9rZTojRkZGRkZGO3N0cm9rZS13aWR0aDo0O3N0cm9rZS1taXRlcmxpbWl0OjEwO308L3N0eWxlPjxwYXRoIGQ9Ik0xMTcuMiAyMDEuNWMtMjktMTYuOS0xMDUuOC02OC4xLTEwNS44LTEzNy4xIDAtMjUuOSAyMS45LTQ2LjkgNDguOC00Ni45IDQyLjEgMCA1NyAzMi4zIDU3IDMyLjNzMTguOC0zMi4zIDU3LTMyLjNjMjYuOSAwIDQ4LjggMjEgNDguOCA0Ni45IDAgNjktNzYuOCAxMjAuMi0xMDUuOCAxMzcuMXoiIGNsYXNzPSJzdDAiLz48cGF0aCBkPSJNMTE4IDE5Ni40Qzg5IDE3OS41IDEyLjIgMTI4LjMgMTIuMiA1OS4zIDEyLjEgMzMuNCAzNCAxMi40IDYxIDEyLjRjNDIuMSAwIDU3IDMyLjMgNTcgMzIuM3MxOC44LTMyLjMgNTctMzIuM2MyNi45IDAgNDguOCAyMSA0OC44IDQ2LjkgMCA2OS03Ni44IDEyMC4yLTEwNS44IDEzNy4xeiIgY2xhc3M9InN0MSIvPjwvc3ZnPgo=');
                        }
                    }
                }
            }

            a{
                position:relative;
                display:block;
                z-index:1;

                .visu{
                    position: relative;
                    &:after{
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        content: ' ';
                        background-color: rgba(0,0,0,0.29);
                        top: 0;
                        left:0;
                        opacity:0;
                        transition: opacity, 0.5s;
                    }
                }

                .description{
                    background: #fff;
                    color: $lighter-dark-font-color;
                }
            }


            &:hover, &:focus, &:active{
                .gallery-actions, 
                .visu:after, 
                .favorites-actions button,
                a:before{opacity:1;}

                .description{@extend %animation-btn-red;}
            }
        }
    }
}