.succes_password {
  position: fixed;
  z-index: 999;
  bottom: 20px;
  right: 20px;
  width: 350px;
  background-color: black;
  color: white;
  padding: 10px 15px;
  font-size: 12px;
  line-height: 20px;

  .close {
    opacity: 1;

    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      top: 15px;
      right: 0px;
      height: 3px;
      background-color: #fff;
      width: 15px;
      transition: all, 0.5s;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:hover {
      opacity: 0.5;
    }
  }

  strong {
    font-size: 14px;
    line-height: 30px;
  }
}