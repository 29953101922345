.overlay{
    position:fixed;
    top:0; left:0;
    width:100%; height:100%;
    background-color:rgba(0, 0, 0, 0.4);
    z-index: 9999;
    transition: opacity 0.4s;
    overflow: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    &.hidden{
        display: none;
    }
}