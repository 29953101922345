.class_identifier_oeuvre{
	height: 100%;
	.page--collections--oeuvre-infos-plus{
		.module--oeuvre{
			height: auto;
		}
		.infos-plus{
			display: inline-block;
			width: 1075px;
			padding: 40px 30px 40px 35px;
			background-color: #FFF;
			height: auto;
			min-height: 575px;
			color: $default-dark-font-color;
		}
		.infos-controls{
			display: none;
		}
		.yosemite-rte{
			font-family: $font-opensans-light;
			font-size: 16px;
			font-size: 1.6rem;

			a{
				font-weight: bold;
				&:hover, &:focus, &:active{
					text-decoration: underline;
				}
			}

			h3{
				margin-top: 0;
				font-family: $font-biko-bold;
				font-size: 26px;
				font-size: 2.6rem;
				line-height: 27.6px;
				padding-left: 20px;
			}

			@media(max-width: 639px){
				font-size: 13px;
				font-size: 1.3rem;

				h3{
					font-size: 20px;
					font-size: 2.0rem;
					padding-left: 0;
				}
			}
		}
		h3{
			font-family: $font-biko-bold;
			font-size: 26px;
			font-size: 2.6rem;
			line-height: 27.6px;
			margin-bottom: 10px;
			margin-top: 20px;
		}
		.infos-left{
			display: inline-block;
			width: 625px;
			.columned-content{
				padding-right: 35px;
				vertical-align: top;
				-webkit-columns: 2;
				-moz-columns: 2;
				columns: 2;
			}
		}
		.infos-right{
			display: inline-block;
			width: 325px;
			padding-right: 55px;
			vertical-align: top;
		}
		.infos-visus{
			display: inline-block;
			width: 366px;
			position: relative;
			text-align: center;
			vertical-align: top;
		}
		.big-visu{
			height: 290px;
			background-size: cover;
		}
		.image-full{
			text-align:center;
			img{
				width:100%;
				height:auto;
			}
		}
		.related-documents{
			display: inline-block;
			width: 145px;
			margin-right: 20px;
			vertical-align: top;

			&.pdf{
				.document-visu{
					width: 145px;
					border: solid 1px rgb(29, 29, 30);
					display: inline-flex;
					justify-content: center;
					align-items: center;
					.icon-pdf{
						font-size: 50px ;
						font-size: 5.0rem ;
					}
				}
			}
		}
		.document-visu{
			height: 125px;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			margin-bottom: 10px;
			border-radius: 5px;
		}
		.document-caption{
			font-family: $font-opensans-light;
			font-size: 14px;
			font-size: 1.4rem;
			padding-left: 10px;
		}
		.oeuvre-infos-mobile{
		}
		.close-part{
			text-align: center;
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 70px;

			&:hover,
			&:focus,
			&:active{
				color: $default-hover-color;
			}
			&:before{
				content: '';
				display: inline-block;
				vertical-align: middle;
				height: 100%;
			}

			.inner-content{
				display: inline-block;
				vertical-align: middle;
				color: white;
			}
			.icon{
				font-size: 27px;
				font-size: 2.7rem;
			}
			span{
				font-size: $font-biko-bold;
				font-size: 30px;
				font-size: 3.0rem;
				display: inline-block;
				vertical-align: middle;
				margin-left: 20px;
			}

			@media(max-width: 639px){
				height: 35px;
				.icon{
					font-size: 13.5px;
					font-size: 1.35rem;
				}
				span{
					font-size: 15px;
					font-size: 1.5rem;
					margin-left: 10px;
				}
			}
		}
		@media(max-width: 1279px){
			// height: calc(100% - 170px);
			// height: -moz-calc(100% - 170px);
			// height: -webkit-calc(100% - 170px);
			margin: 0px;
			padding: 0px;
			position: absolute;
			width: 100%;
			top: 100px;
			bottom: 70px;
			overflow-y: scroll;

			.fake-arianne{
				color: white;
				margin-top: 0px;
				color: white;
			}
			.infos-content{
				background-color: #FFF;

				.yosemite-rte{
					word-wrap: break-word;
				}
			}
			.oeuvre-menu-mobile{
				display: inline-block;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 25px;

				.menu-item{
					height: 100px;
					text-align: left;
					width: 415px;
					&:before{
						height: 100%;
					}
					&:after{
						display: none;
					}

					.title{
						display: inline-block;
						margin-left: 50px;
						font-family: $font-biko-bold;
						font-size: 30px;
						font-size: 3.0rem;
						margin-bottom: 0;
						vertical-align: middle;
						max-width: 313px;
						@media(max-width: 639px){
							max-width: 154px;
						}
					}
					.icon{
						display: inline-block;
						vertical-align: middle;
						margin-bottom: 0;
						font-size: 35px;
						font-size: 3.5rem;
					}
					&.item-artist{
						.subtitle{
							font-family: $font-opensans-regular;
							font-size: 22px;
							font-size: 2.2rem;
						}
					}
					&.item-map{
						.title{
						}
						.subtitle{
							display: inline-block;
							font-family: $font-biko-regular;
							font-size: 30px;
							font-size: 3.0rem;
							&:before{
								content: ',';
							}
						}
					}
					&.item-artips{
						text-align: center;
					}
				}
			}
		}
		@media(max-width: 639px){
			top: 50px;
			bottom: 35px;
			.oeuvre-menu-mobile{
				margin-bottom: 12.5px;
				.menu-item{
					height: 50px;
					width: 207.5px;
					.title{
						margin-left: 25px;
						font-size: 15px;
						font-size: 1.5rem;
					}
					.subtitle{
						font-size: 15px;
						font-size: 1.5rem;
					}
					.icon{
						font-size: 17.5px;
						font-size: 1.75rem;
					}
					&.item-map{
						.title{
							&:after{
								content: ',';
							}
						}
						.subtitle{
							font-size: 15px;
							font-size: 1.5rem;
						}
					}
					&.item-artist{
						.subtitle{
							font-size: 11px;
							font-size: 1.1rem;
						}
					}
				}
			} 
		}
	}
}

// Fix Columns
.no-csscolumns .class_identifier_oeuvre .page--collections--oeuvre-infos-plus{
	.infos-left{
		width: 310px;
	}
	.infos-right{
		display: inline-block;
	}
}
