.class_group_content.class_identifier_page_wide{
	.page--archive{
		padding-bottom: 45px;
		background-color: #FFF;
		h1{
			color: #FFF;
			font-family: "loveloblack";
			font-size: 2.8rem;
			margin-top: 40px;
			text-transform: uppercase;
			letter-spacing: 0.031em;
		}
		@media(max-width: 1279px){
			padding-bottom: 45px;
		}
		@media(max-width: 639px){
			padding-bottom: 22.5px;
		}
		.contain--learn-more{
			@media(min-width: 1280px){
				float: right;
			}
		}
		.right{
			@media(min-width: 1280px){
				border-bottom: solid 1px $default-dark-font-color;
			}
		}

		.actu-list--gabarit:last-child{
			.right{
				border-bottom: none;
			}
		}
		.pagination--light{
			margin-bottom: 0 !important;
		}
	} 
}