.class_group_content.class_identifier_page_wide .gabarit_3 {

  .section--wide-1 .container--wide {
    width: 1090px;
  }

  .rte-contain {
    width: 1010px;
    padding: 0 30px;

    h4 {
      font-family: "bikobold";
    }

    /* a[target="_self"]:before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      background-image: url(svg-bg-uri($svg-arrow, #000));
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      transform: rotate(-90deg);
      margin-right: 5px;
    } */
  }

  .media.file .media-heading {
    a {
      display: flex;
    }

    img {
      order: -1;
      margin-right: 5px;
    }
  }

  .title-bloc {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .h3-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      padding: 10px;
      border: 3px solid #000;
      width: 50px;
      height: 50px;
    }

    h3 {
      text-transform: uppercase;
      margin: 0;
    }

  }

  .banner--wide-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    padding-bottom: 0;

    .container--wide-2 {
      top: 0;
    }

    h2 {
      display: inline-block;
      font-size: 3.5rem;
      padding: 20px 10px 15px;
      background-color: rgba(0, 0, 0, 0.3);
      margin: 0;

      .icon {
        font-size: 3.5rem;
      }
    }
  }

  .section--wide-2 .center-line {
    position: relative;
    top: -60px;
  }

}

.sommaire_g3 {
  display: none;
  width: 300px;
  color: #fff;
  background-color: #000;
  position: fixed;
  bottom: 30px;
  left: 30px;
  z-index: 999;
  padding: 30px 0;

  ul {
    position: relative;
    margin-left: 50px;

    &:before {
      content: "";
      position: absolute;
      display: block;
      height: calc(100% - 56px);
      border-left: 1px solid #fff;
      top: 28px;
      left: 4px;
    }

    li {
      font-family: "bikobold";
      font-size: 2rem;
      padding: 20px 0;

      &.active:before {
        width: 12px;
        height: 12px;
        background-color: #D83840;
        border: 3px solid #fff;
        left: -1px;
      }
      
      &:before {
        content: '';
        position: relative;
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-radius: 100%;
        margin-right: 25px;
      }
    }
  }
}

.table {
  background-color: #F7F7F7;
  padding: 30px;

  p {
    margin-bottom: 0;
  }

  table {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;

    td {
      border: 1px solid #000;
      padding: 15px;

      &:nth-child(1) {
        width: 30%;
      }
    }
  }
}

.pdfs {
  padding: 30px;
  background-color: #D83840;
  color: #fff;
  margin: 30px 0;

  &__collapse {
    position: relative;
    font-family: "bikobold";
    font-size: 2rem;

    &.active {
      .pdfs__collapse__icon {
        transform: rotate(180deg);
      }
    }

    &__icon {
      cursor: pointer;
      position: absolute;
      display: block;
      width: 40px;
      height: 40px;
      border: 3px solid #fff;
      top: -10px;
      right: 0px;
      background-image: url(svg-bg-uri($svg-arrow, #fff));
      background-size: 50%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__contain {
    display: none;
    flex-wrap: wrap;
    border-top: 3px solid #fff;
    padding-top: 30px;
    margin-top: 30px;

    &.active {
      display: flex;
    }

    .dll_pdf {
      display: flex;
      align-items: center;
      color: black;
      background-color: #fff;
      padding: 10px;
      margin-top: 10px;
      margin-right: 10px;

      span {
        margin-right: 10px;
      }
    }
  }
}

.videos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 30px;

  &__contain {
    width: calc(100% / 2 - 40px);
    margin-bottom: 30px;
    padding-bottom: 12%;

    iframe {
      width: 100%;
      height: calc(100% * 1.72);
    }
  }

  &__more {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;

    &__button {
      display: flex;
      align-items: center;
      font-family: "bikobold";
      font-size: 2.5rem;

      &.active {
        .videos__more__button__icon {
          transform: rotate(180deg);
        }
      }

      &__icon {
        cursor: pointer;
        display: block;
        width: 40px;
        height: 40px;
        border: 3px solid #000;
        background-image: url(svg-bg-uri($svg-arrow, #000));
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center;
        margin-left: 20px;
      }
    }
  }
}