/*#skipnavigation {
    width: 1200px;
    background-color: #fff;
    padding: 5px 0;
    margin:0 auto;
    ul {
        margin: auto;
        overflow: hidden;
        list-style-type: none;
        padding: 0;
        li {
            float: left;
            margin-left: 5px;
            padding: 0 10px;
            margin-bottom:0px;  
            a {
                color: #000;
                text-decoration: none;
                &:hover, &:active, &:focus{
                    text-decoration: underline;
                }
            }                             
        }
    }
}*/

#skipnavigation{
    height: 0px;
    opacity:0;
    text-align:right;
    background-color:$default-dark-font-color;
    color:$default-light-font-color;
    ul {
        margin: auto;
        overflow: hidden;
        list-style-type: none;
        padding: 0;
        height:0px;
    }
    li{
        float:left;
        margin-left: 5px;
        display: inline-block;
        font-size: 12px;
        font-size: 1.2rem;
        &:after{
            content: "|";
            padding: 0px 5px;
        }
        &:nth-last-of-type(1){
            &:after{
                content: "";
                padding: 0px;
            }
        }
        a {
            color: $default-light-font-color;
            text-decoration: none;
            &:hover, &:active, &:focus{
                text-decoration: underline;
            }
        }
    }
    &.hasSkipLinkBanner{
        line-height: 30px;
        height:30px;
        opacity: 1;
        ul {
            height:30px;
        }
    }
}

@media(max-width:1199px){
    #skipnavigation{
        width:100%;
    }
}