.connect_tuto {
  p {
    font-weight: 600;
  }

  ul {
    margin: 20px 0 20px 30px;

    li {
      margin: 10px 0;
      display: flex;
      align-items: center;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    color: #fff;
    background-color: black;
    border-radius: 100%;
    margin-right: 10px;

    &-heart, &-archive {
      &:before {
        margin-top: 2px;
        margin-left: 2px;
        background-image: none;
      }
    }
  }
}