.class_group_content{
	.page--glossaire{
		h1{
			font-family: "loveloblack";
			font-size: 28px;
			font-size: 2.8rem;
			margin-top: 40px;
			color: #fff;
			text-transform: uppercase;
			letter-spacing: 0.031em;

			@media(max-width: 639px){
				font-size: 14px;
				font-size: 1.4rem;
				margin-top: 20px;
			}
		}	
	}
}
@media(max-width: 1279px){
	.class_group_content.class_identifier_page_wide .rte-contain .rte-col-right .yosemite-rte:first-child{
		margin-top: 0;
	}
}