.gigapixels-overlay{
    .pop-up{
        height: calc(100% - 50px);
        width: calc(100% - 50px);
        max-height: unset;
        margin: 25px;
        padding: 0;
        background: transparent;

        .popup-bloc-wrapper{
            display: block;
            overflow: hidden;
            height: 100%;
            width: 100%;

            #popup-gigapixels{
                height: 100%;
                width: 100%;

                iframe{
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
}