html.noscroll{
    overflow: hidden;
}

// * Mandatory notice
.mandatory-notice{
    color:#d8383f;
    font-family:"open_sanslight";
    font-size:1.6rem;
    margin-bottom: 15px;
}


#page_tactile{

    &>*{
        transition: left, 0.5s;
    }

    #entete{
        height:147px;
        line-height: 150px;
        position:fixed;
        top: 0;
        left: 0;
        width: 100%;
        border-bottom: 3px solid #fff;

        button.back{
            position: absolute;
            left: 150px;
            line-height: 150px;
            top: 0;
            font-family: "bikobold";
            font-size: 30px;
            text-transform: uppercase;
            padding: 0 50px;
            color: #fff;
            z-index: 1;
            &:before{
                  content: "\e93f";
                  font-family: 'pba';
                  margin-right: 20px;
            }
        }

        #logo{
            font-family: 'pba';
            font-size: 50px;
            color:#fff;
            letter-spacing: 0.13em;
            text-align:center;
            position: relative;
            top: -10px;

            &:after{
                width: 0px;
                height: 0px;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-top: 15px solid #FFF;
                position: absolute;
                left: calc(50% - 15px);
                bottom: 10px;
                display: block;
                content: '';
            }

            .tiret{
                position: relative;
                top: -10px;
            }
        }

        .retourTimer{
            position: absolute;
            top: 0;
            right: 40px;
            text-align: right;
            color:#fff;
            line-height: 20px;
            padding-top: 55px;
            
            span{
                width: 25px;
                display: inline-block;
                font-size: 20px;
                text-align: center;
            }
        }

    }

    #liens_partenaires{
        z-index: 3;
        position: fixed;
        bottom: 0;
        left: 0;
        width:100%;
        height: calc(100vh - 150px);
        background: url(../images/bg-pba.jpg);
        background-size: cover;

        ul{
            width: 1600px;
            margin: 0 auto;
            padding-top: 50px;
            li{
                width: 33.333333332%;
                float:left;
                padding: 50px 0;

                a{
                    // scoped vars
                    $lnk_mrv: 40px; // vertical margin  (top / bottom)
                    $lnk_mrh: 40px; // horizontal margin (left / right)

                    position:relative;
                    padding: 40px;
                    width: 420px;
                    height: 310px;

                    background-color:rgba(0,0,0,0.8);
                    background-size: cover;
                    color:#fff;
                    font-family: $font-biko-bold;
                    font-size: 30px;
                    line-height: 40px;
                    text-transform:uppercase;
                    display:flex;
                    align-items: center;
                    justify-content: center;
                    transition: all, 0.5s;
                    text-align: center;

                    margin: 0 auto;

                    &:hover, &:focus{
                        @extend %animation-btn-red;

                        &:before{
                            top: calc(#{$lnk_mrv} + 10px);
                        }
                        &:after{
                            bottom: calc(#{$lnk_mrv} + 10px);
                        }
                    }

                    &:before, &:after{
                        content: "";
                        display: block;
                        position: absolute;
                        left: $lnk_mrh;
                        width: calc(100% - (2 * #{$lnk_mrh}) - 40px);
                        height: 32px;
                        border: 18px solid #fff;
                        transition: all, 0.5s;
                    }
                    &:before{
                        border-bottom: none;
                        top: $lnk_mrv;
                    }
                    &:after{
                        border-top: none;
                        bottom: $lnk_mrv;
                    }
                }
            }
        }

    }


    iframe[name="PBAIframe"],
    .iframeOverlay,
    #invisibleLayer{
        position: fixed;
        bottom: 0;
        left: 100vw;
        width:100%;
        height: calc(100vh - 150px);
    }
    iframe[name="PBAIframe"]{
        z-index: 1;
    }
    .iframeOverlay{
        z-index: 2;
        background-color: rgba(0,0,0,0.9);
        left: 0;
        .svg-refresh{
            width: 200px;
            height: 200px;
            position: absolute;
            top: calc(50% - 100px);
            right: calc(50% - 100px);
            &:before{
                content:'';
                width: 200px;
                height: 200px;
                display: inline-block;
                background: transparent url(svg-bg-uri($svg-refresh, #fff)) no-repeat 50%;
                background-size: 150px;
                animation: spinSvgRefresh 2s infinite linear;
            }
        }

        .sk-folding-cube {
            margin: 20px auto;
            width: 70px;
            height: 70px;
            position: absolute;
            transform: rotateZ(45deg);
            top: calc(50% - 35px);
            left: calc(50% - 35px);
        }

        .sk-folding-cube .sk-cube {
            float: left;
            width: 50%;
            height: 50%;
            position: relative;
            transform: scale(1.1); 
        }
        .sk-folding-cube .sk-cube:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #FFF;
            animation: sk-foldCubeAngle 2.4s infinite linear both;
            transform-origin: 100% 100%;
        }
        .sk-folding-cube .sk-cube2 {
            transform: scale(1.1) rotateZ(90deg);
        }
        .sk-folding-cube .sk-cube3 {
            transform: scale(1.1) rotateZ(180deg);
        }
        .sk-folding-cube .sk-cube4 {
            transform: scale(1.1) rotateZ(270deg);
        }
        .sk-folding-cube .sk-cube2:before {
            animation-delay: 0.3s;
        }
        .sk-folding-cube .sk-cube3:before {
            animation-delay: 0.6s; 
        }
        .sk-folding-cube .sk-cube4:before {
            animation-delay: 0.9s;
        }

    }
    #invisibleLayer{
        background-color:transparent;
        left:0;
    }
    &.inNav{
        #liens_partenaires, iframe[name="PBAIframe"]{
            transform: translate3D(-100vw, 0, 0);
        }
    }
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  }
}