@mixin svgBuilder($picto-name,$fill, $stroke,$st-number){
	
	@for $i from 0 through $st-number{
		.st#{$i} {
			@include transition(all,0.3s,ease,0s);
		}
	}
	// Cas particuliers
	@if $picto-name == "picto-visiter"{
		.svg-picto-visiter{
			.st0{
				stroke: $stroke;
				fill: none;
			}
			.st1{
				fill: $fill;
			}
		}
		&.has-self-hover{
			.svg-picto-visiter{
				&:hover,
				&:focus,
				&:active{
					.st0{
						stroke: $default-hover-color;
					}
					.st1{
						fill: $default-hover-color;
					}
				}
			}
		}
		&.has-content-hover{
			&:hover,
			&:focus,
			&:active{
				.svg-picto-visiter{
					.st0{
						stroke: $default-hover-color;
					}
					.st1{
						fill: $default-hover-color;
					}
				}
			}
		}
	}@else if $picto-name == "picto-"{
	}@else{
		/* CAS GENERAUX */
		// Gestion du hover sur lui même
		
		&.has-self-hover{
			.svg-#{$picto-name}{
				&:hover,
				&:focus,
				&:active{
					@if $fill != "none"{
						@for $i from 0 through $st-number{
							.st#{$i} {
								fill: $default-hover-color;
							}
						}
					}
					@if $stroke != "none"{
						@for $i from 0 through $st-number{
							.st#{$i} {
								stroke: $default-hover-color;
							}
						}
					}
				}
			}
		}
		// Gestion du hover sur le parent
		&.has-content-hover{
			&:hover,
			&:focus,
			&:active{
				.svg-#{$picto-name}{
					@if $fill != "none"{
						@for $i from 0 through $st-number{
							.st#{$i} {
								fill: $default-hover-color;
							}
						}
					}
					@if $stroke != "none"{
						@for $i from 0 through $st-number{
							.st#{$i} {
								stroke: $default-hover-color;
							}
						}
					}
				}
			}
		}
		.svg-#{$picto-name}{ 
			// Fill color
			@for $i from 0 through $st-number{
				.st#{$i} {
					fill: $fill;

				}
			}

			@for $i from 0 through $st-number{
				.st#{$i} {
					stroke: $stroke;
				}
			}
		}
	}
	
}


// SVG BUILDER FROM "FACHES ThUMESNIL"
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}


$encoding-reference: (('<','%3C'),('>','%3E'),('"','\''),('#','%23'),('&','%26'));
@function svg-encode($svg) {
  @each $char, $encoded in $encoding-reference {
    $svg: str-replace($svg, $char, $encoded);
  }
  @return 'data:image/svg+xml,' + $svg;
}


$svgFillPH: '##FILL##';
@function svg-bg-uri($svg, $fill-color) {
    $returnSvg: str-replace($svg, $svgFillPH, $fill-color);
  @return svg-encode($returnSvg);
}

