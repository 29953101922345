
@mixin fancySelect($selectBackgroundColor, $selectFontColor, $selectArrowBackgroundColor, $selectArrowColor,$border-color,$font-options-color){
	.fancy-select {
		position: relative;
		font-weight: inherit;
		text-transform: uppercase;
		font-size: 16px;
		font-size: 1.6rem;
		border:4px solid $border-color;
		height: 65px;
		margin-bottom: 10px;
		padding-left:30px;
		font-family: $font-opensans-light;
		color: $font-options-color;
	}

	.fancy-select.disabled {
		opacity: 0.5;
	}

	.fancy-select select:focus + .trigger {
	}

	.fancy-select select:focus + .trigger.open {
		box-shadow: none;
	}
	/*
	.fancy-select{
		.selected{
			font-family:$font-atcoverlook-regular;
			font-size:22px;
			font-size:2.2rem;
			color: $lighter-bright-grey;
		}
	}*/
	.fancy-select .trigger {
		cursor: pointer;
		padding-top: 15px;
		padding-right:40px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		position: relative;
		background: $selectBackgroundColor;
		//border: 1px solid $selectBackgroundColor;
		//color: $selectFontColor;
		width: 100%;
		text-transform: none;
		height: 50px;
		//font-family: $HPRegular;

		transition: all 240ms ease-out;
		-webkit-transition: all 240ms ease-out;
		-moz-transition: all 240ms ease-out;
		-ms-transition: all 240ms ease-out;
		-o-transition: all 240ms ease-out;

		font-family: $font-atcoverlook-regular;
		font-size:22px;
		font-size:2.2rem;
		color: $selectFontColor;
	}

	.fancy-select .trigger:after {
		top: 0;
		right: 0px;
		//background-color: $selectArrowBackgroundColor;
		color: white;
		width: 35px;
		height: 100%;
		content: "\e93d";
		display: inline-block;
		font-family: 'pba';
		font-style: normal;
		font-weight: normal;
		line-height: 1;
		font-size-adjust: none;
		font-stretch: normal;
		font-feature-settings: normal;
		font-language-override: normal;
		font-kerning: auto;
		font-synthesis: weight style;
		font-variant: normal;
		font-size: inherit;
		text-rendering: auto;
		padding-top: 5px;
		padding-left: 10px;
		font-size: 25px;
		font-size: 2.5rem;
		border: none;
		position: absolute;
	}

	.fancy-select .trigger.open {
		//background: $selectBackgroundColor;
		//border: 1px solid $selectBackgroundColor;
		//color: $selectFontColor;
		box-shadow: none;
	}

	.fancy-select .trigger.open:after {
	}

	.fancy-select ul.options {
		list-style: none;
		margin: 0;
		margin-top:22px;
		border:4px solid $border-color;
		position: absolute;
		top: 40px;
		border-top: medium none transparent;
		left: -4px;
		visibility: hidden;
		opacity: 0;
		z-index: 50;
		overflow: auto;
		//background: $selectBackgroundColor;
		border-radius: 0px;
		//border-top: 1px solid $selectBackgroundColor;
		width: 102%;
		background-color: $selectBackgroundColor;
		transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
		-webkit-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
		-moz-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
		-ms-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
		-o-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
	}

	.fancy-select ul.options.open {
		visibility: visible;
		top: 34px;
		opacity: 1;

		/* have to use a non-visibility transition to prevent this iOS issue (bug?): */
		/*http://stackoverflow.com/questions/10736478/css-animation-visibility-visible-works-on-chrome-and-safari-but-not-on-ios*/
		transition: opacity 300ms ease-out, top 300ms ease-out;
		-webkit-transition: opacity 300ms ease-out, top 300ms ease-out;
		-moz-transition: opacity 300ms ease-out, top 300ms ease-out;
		-ms-transition: opacity 300ms ease-out, top 300ms ease-out;
		-o-transition: opacity 300ms ease-out, top 300ms ease-out;
	}

	.fancy-select ul.options.overflowing {
		top: auto;
		bottom: 40px;

		transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
		-webkit-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
		-moz-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
		-ms-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
		-o-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
	}

	.fancy-select ul.options.overflowing.open {
		top: auto;
		bottom: 50px;

		transition: opacity 300ms ease-out, bottom 300ms ease-out;
		-webkit-transition: opacity 300ms ease-out, bottom 300ms ease-out;
		-moz-transition: opacity 300ms ease-out, bottom 300ms ease-out;
		-ms-transition: opacity 300ms ease-out, bottom 300ms ease-out;
		-o-transition: opacity 300ms ease-out, bottom 300ms ease-out;
	}

	.fancy-select ul.options li {
		//padding: 8px 12px;
		//color: $selectFontColor;
		padding-left:45px;
		cursor: pointer;
		white-space: nowrap;
		text-transform: none;
		transition: all 150ms ease-out;
		-webkit-transition: all 150ms ease-out;
		-moz-transition: all 150ms ease-out;
		-ms-transition: all 150ms ease-out;
		-o-transition: all 150ms ease-out;
		padding-top:12px;
		padding-bottom:12px;
		position:relative;
		&::after{
			content:"";
			width:80%;
			border:1px solid $grey-line;
			position: absolute;
			left: 40px;
			top:0px;
		}
		&:first-child::after{
			content:"";
			border: 0px solid #1D1D1E;
		}
		/*
		&:last-child::after{
			content:"";
			border: 0px solid #1D1D1E;
		}*/
	}

	.fancy-select ul.options li.selected {
		//background: $selectBackgroundColor;
		//color: $selectFontColor;
		text-transform: none;
	}

	.fancy-select ul.options li.hover {
		color: #888888;
	}
}

// @include fancySelect(#F0F0F0, #454545, #868789, #FFF);
.arianne{
	@include fancySelect(#434143,#FFF,#FFF,#FFF,transparent,#FFF);
	.select-contain{
		position: absolute;
		right: 80px;
		top: 50%;
		margin-top: -16px;

	}
	.fancy-select{
		width: 240px;
		height: auto;
		padding-left: 0px;
		margin-bottom: 0;
		select{

			&:hover,
			&:focus{
				& + .trigger:after{
					color: $default-hover-color;
				}
			}
		}
		.trigger{
			font-family: $font-lovelo-black;
			font-size: 14px;
			font-size: 1.4rem;
			padding: 5px 0px 5px 15px;
			height: auto;
			text-align: left;

			&:after{
				font-size: 14px;
				font-size: 1.4rem;
			}
		}
		ul.options{
			margin: 0;
			width: 240px;

			&.open{
				top: 24px;
			}
			li{
				padding-left: 15px;
				text-align: left;
			}
		}
	}
}
.selectContact-container{
	@include fancySelect(#FFF,$lighter-bright-grey,#FFF,$lighter-bright-grey,$main-darker-grey,$default-dark-font-color);
}