.contact-us{
	background-color:#fff;
	width:100%;
	padding-top:40px;
	p{
		text-align:center;
		font-family:$font-opensans-light;
		color:$lighter-dark-font-color;
		font-size: 16px;
		font-size:1.6rem;
		margin-bottom:30px;
	}
	.fyi p{
		color: $default-hover-color;
		margin-bottom: 10px;
		text-align: left;
	}
	.sous-titre{
		font-family:$font-biko-bold;
		font-size: 24px;
		font-size: 2.4rem;
		margin-bottom:30px;
		color:$default-dark-font-color;
	}
	.inner-container{
		width:430px;
		margin:0 auto;
	}
	.inner-container-thin{
		width:425px;
		margin:0 auto;
	}
	input, textarea{
		height:65px;
		width:100%;
		margin-bottom:10px;
		font-family:$font-atcoverlook-regular;
		font-size:22px;
		font-size:2.2rem;
		border:4px solid $lighter-bright-grey;
		color: $lighter-bright-grey;
		padding-left:30px;
	}
	textarea{
		padding-top:15px;
		resize: none;
		height: 250px;
	}
	#newsletterCheckbox{
		width:30px;
		height:30px;
	}
	.selectContact-container{
		position:relative;
		.fancy-select{
			border-radius: 4px;
			border-color: $lighter-bright-grey;
			&.focus-fancy-select{
				border-color: $main-darker-grey;
			}
		}
		& .trigger:after{
			content:"\e93d";
			font-family: "pba";
			font-size:18px;
			font-size: 1.8rem;
			position:absolute;
			right:20px;
			top:16px;
			color:$lighter-bright-grey;
		}
	}
	.mailText{
		min-height:250px;
	}
	label{
		display: inline-block;
		font-family: $font-opensans-light;
		color:$lighter-dark-font-color;
		font-size:16px;
		font-size:1.6rem;
		height:30px;
		vertical-align: middle;
		margin-bottom: 10px;
	}
	button{
		border:4px solid $lighter-dark-font-color;
		color:$lighter-dark-font-color;
		height:85px;
		width:100%;
		font-family: $font-lovelo-black;
		font-size:24px;
		font-size:2.4rem;
		text-transform: uppercase;
		margin-bottom:30px;
		@include transition(all,0.3s,ease,0s);
	    &:hover,
		&:active,
		&:focus{
			color:$default-hover-color;
			border-color:$default-hover-color;
			@include transition(all,0.3s,ease,0s);
		}
	}
	.small{

		p {
			font-size: 85%;
		}

	}
}

.checkbox{

	[type="checkbox"]:not(:checked),
	[type="checkbox"]:checked {
		position: absolute;
		left: -9999px;
	}

	/* on prépare le label */
	[type="checkbox"]:not(:checked) + label,
	[type="checkbox"]:checked + label {
		position: relative; /* permet de positionner les pseudo-éléments */
		padding-left: 45px;
		cursor: pointer;    /* affiche un curseur adapté */
		padding-top: 6px;
	}
	/* Aspect des checkboxes */
	/* :before sert à créer la case à cocher */
	[type="checkbox"]:not(:checked) + label:before,
	[type="checkbox"]:checked + label:before {
		content: "";
		position: absolute;
		left: 0px;
		top: 0px;
		width: 30px;
		height: 30px;
		border:4px solid $main-darker-grey;
		background: #FFF none repeat scroll 0% 0%;
	}

	/* Aspect général de la coche */

	[type="checkbox"]:not(:checked) + label:after,
	[type="checkbox"]:checked + label:after {
		content: '\e920';
		position: absolute;
		font-family:'pba';
		top: -1px;
		left: 0px;
		font-size: 32px;
		font-size:3.2rem;
		color:$main-darker-grey;
		//transition: all .2s; /* on prévoit une animation */
		-ms-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}
	/* Aspect si "pas cochée" */
	[type="checkbox"]:not(:checked) + label:after {
		opacity: 0; /* coche invisible */
		transform: scale(0); /* mise à l'échelle à 0 */
	}
	/* Aspect si "cochée" */
	[type="checkbox"]:checked + label:after {
		opacity: 1; /* coche opaque */
		//transform: scale(1); /* mise à l'échelle 1:1 */
	}

	/* aspect désactivée */
	[type="checkbox"]:disabled:not(:checked) + label:before,
	[type="checkbox"]:disabled:checked + label:before {
		box-shadow: none;
		border-color: #bbb;
		background-color: #ddd;
	}
	/* styles de la coche (si cochée/désactivée) */
	[type="checkbox"]:disabled:checked + label:after {
		color: #999;
	}
	/* on style aussi le label quand désactivé */
	[type="checkbox"]:disabled + label {
		color: #aaa;
	}

	/* aspect au focus de l'élément */
	[type="checkbox"]:checked:focus + label:before,
	[type="checkbox"]:not(:checked):focus + label:before {
		border:4px solid $main-darker-grey;
	}
}

.form1{
	.help-block{
	    font-family: $font-opensans-light;
	    font-size:12px;
	    font-size: 1.2rem;
	    margin-left: 22px;
	    color: $default-dark-font-color;
	    margin-top: -4px;
	    position: absolute;
	    display: inline;
	    bottom: -35px;
	    height: 40px;
	    width: 100%;
	    //padding: 8px 10px;
	    left: 0px;
	    text-align:left;
	    -moz-hyphens: none !important;
	    z-index:5;
	    display:none !important;
	}
	.form-control:focus {
		border-color:$main-darker-grey;
		box-shadow:none;
	}
	.form-group2 .help-block{
		width:190px;
		&::after {
			content:"";
			width: 0px;
			height: 0px;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-top: 10px solid #E0001A;
			position: absolute;
			z-index: 20;
			right: 85px;
			top: 40px;
		}
	}
	.form-group3 .help-block{
	    text-align:left;
	    border: medium none;
	    position: absolute;
	    background-color: transparent;
	    color: #E2001a;
	    margin-left: 17px;
	    margin-top: 20px;
	    top: 23px;
	    padding-right: 0px;
	    left: 0px;
	    width: 200px;
	    margin-top:0px;
	    &::after {
			content:"";
			width: 0px;
			height: 0px;
			border-left: 0px solid transparent;
			border-right: 0px solid transparent;
			border-top: 0px solid #E2001A;
			z-index: 0;
		}
	}
	.help-block .check{
		font-family: latoregular;
	    font-size:14px;
	    font-size: 1.2rem;
	    margin-left: 22px;
	    color: #fff;
	    margin-top: -4px;
	}
	/***Couleurs erreur et succès****/
	.has-error .form-control {
	    border-color: #e2001a;
	    background-color:#fce5e8;
	}
	.has-success .form-control {
		border-color:$lighter-bright-grey ;
	    background-color:inherit;
	}
	/*******************************/
	.has-error .trigger {
	    border-color: #e2001a;
	    background-color:#fce5e8;
	}
	.has-error .fancy-select {
	    border-color: #e2001a;
	    background-color:#fce5e8;
	}
	.has-error [type="checkbox"]:not(:checked) + label::before{
		border:1px solid #e2001a;
	}
}


@media (max-width:639px){
	.contact-us{
		background-color:#fff;
		width:100%;
		padding-top:40px;
		p{
			text-align:center;
			font-family:$font-opensans-light;
			color:$lighter-dark-font-color;
			font-size: 16px;
			font-size:1.6rem;
			margin-bottom:30px;
		}
		.fyi p{
			color: $default-hover-color;
			margin-bottom: 10px;
			text-align: left;
		}
		.sous-titre{
			font-family:$font-biko-bold;
			font-size: 24px;
			font-size: 2.4rem;
			margin-bottom:30px;
			color:$default-dark-font-color;
		}
		.inner-container{
			width:100%;
			margin:0 auto;
		}
		.inner-container-thin{
			width:100%;
			margin:0 auto;
		}
		input, textarea{
			height:65px;
			width:100%;
			margin-bottom:10px;
			font-family:$font-atcoverlook-regular;
			font-size:22px;
			font-size:2.2rem;
			border:4px solid $lighter-bright-grey;
			color: $lighter-bright-grey;
			padding-left:30px;
		}
		textarea{
			padding-top:15px;
			resize: none;
			height: 250px;
		}
		#newsletterCheckbox{
			width:30px;
			height:30px;
		}
		.selectContact-container{
			position:relative;
			.fancy-select{
				border-color: $lighter-bright-grey;
				&.focus-fancy-select{
					border-color: $main-darker-grey;
				}
			}
		}
		.mailText{
			min-height:250px;
		}
		label{
			display: inline-block;
			font-family: $font-opensans-light;
			color:$lighter-dark-font-color;
			font-size:16px;
			font-size:1.6rem;
			height:30px;
			vertical-align: middle;
			margin-bottom: 10px;
		}
		button{
			border:4px solid $lighter-dark-font-color;
			color:$lighter-dark-font-color;
			height:65px;
			width:100%;
			font-family: $font-lovelo-black;
			font-size:24px;
			font-size:2.4rem;
			text-transform: uppercase;
			margin-bottom:30px;
			@include transition(all,0.3s,ease,0s);
		    &:hover,
			&:active,
			&:focus{
				color:$default-hover-color;
				border-color:$default-hover-color;
				@include transition(all,0.3s,ease,0s);
			}
		}
		.small{
			font-size: 12px;
			font-size: 1.2rem;
		}
	}
}