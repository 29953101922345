%bones-btn{
    border-style: solid;
    border-width: 4px;
    font-family: $font-lovelo-black;
    font-size: 16px;
    padding: 7px 15px 4px 15px;
    transition: all, 0.5s;
    @media(max-width:1279px){
        font-size:36px; font-size:3.6rem;
        padding: 10px 40px 7px;
        margin-bottom:40px;
    }
    @media(max-width: 767px){
        font-size:20px; font-size:2rem;
    }
}
%input{
    height: 55px; 
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
    font-family: "atc_overlookregular";
    font-size: 24px; font-size: 2.4rem;
    border: 4px solid #BCBEC0;
    color: #BCBEC0;
    padding: 0 30px;
    text-align: left;
    border-radius:0px;
}
%skin-btn-white{ 
    border-color: #FFFFFF;
    color: #FFFFFF;
}
%skin-btn-black{ 
    border-color: #2c2c2e;
    color: #2c2c2e;
}
%animation-btn-red{
    border-color: $default-hover-color;
    color: $default-hover-color;  
}


%cadre-btn{
    a, button{
        // scoped vars
        $lnk_mrv: 30px; // vertical margin  (top / bottom)
        $lnk_mrh: 35px; // horizontal margin (left / right)

        position:relative;
        padding: $lnk_mrv $lnk_mrh;
        width: 370px;
        height: 270px;

        @media(max-width:1279px){
            width: 100%;
            margin-bottom: 25px;
            font-size: 23px;
        }

        background-color:#777;
        background-size: cover;
        color:#fff;
        font-family: $font-biko-bold;
        font-size: 26px;
        text-transform:uppercase;
        display:flex;
        align-items: center;
        justify-content: center;
        transition: all, 0.5s;
        text-align: center;

        &:hover, &:focus{
            @extend %animation-btn-red;

            &:before{
                top: calc(#{$lnk_mrv} + 5px);
            }
            &:after{
                bottom: calc(#{$lnk_mrv} + 5px);
            }
        }

        &:before, &:after{
            content: "";
            display: block;
            position: absolute;
            left: $lnk_mrh;
            width: calc(100% - (2 * #{$lnk_mrh}));
            height: 32px;
            border: 18px solid #fff;
            transition: all, 0.5s;
        }
        &:before{
            border-bottom: none;
            top: $lnk_mrv;
        }
        &:after{
            border-top: none;
            bottom: $lnk_mrv;
        }
    }
}