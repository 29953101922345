#instagram{
	.title{
		margin-top: 60px;
		margin-bottom: 40px;
		text-align: center;
		.icon{
			font-size: 45px;
			color: #ffffff;
			display: inline-block;
			vertical-align: top;
			margin-right: 25px;
		}
		.text{			
			display: inline-block;
			vertical-align: top;
			h2{
				color: #f7f7f7;
				font-family: 'loveloblack', sans-serif;
				text-align: left;
			}
			.subtitle{
				font-family: "novecento_widebook", sans-serif;
				font-size: 24px;
				font-size: 2.4rem;
				color: #f7f7f7;
				text-align: left;
			}
		}
	}
	.insta-gallery{
		display: flex;
		
		li{
			display: inline-block;
			vertical-align: top;
			width: 20%;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;


			a{
				display: inline-block;
				vertical-align: top;
				width: 100%;
				height: 100%;
				padding-bottom: 100%;
				position: relative;
				&:after{					
					content: "";
					display: inline-block;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0; 
					left: 0;
					@include transition(all, 0.3s, ease, 0s);
				}

				&:hover, &:focus, &:active{
					&:after{
						background-color: rgba(#d8383f, 0.78);
					}
				}
			}
		}
	}
	@media(max-width: 1279px) and (min-width: 640px){

		.insta-gallery{
			li{
				width: 50%;

				a{
					padding-bottom: 100%;
				}

				&:last-child{
					display: none;
				} 
			}
		}
	}
	@media(max-width: 639px){
		.insta-gallery{
			display: block;

			li{
				width: 100%;

				a{
					padding-bottom: 100%;
				}
			}
		}
	}
}