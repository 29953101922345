/*------------------------------------*\
    			OBJECTS
\*------------------------------------*/

/************* Constructeurs
 * $BOUTONS........................... Corps de texte
 * $BOUTONS PICTO
 * LOCKBAR
 * ACTUS
 * TITRES
 * LOCKED PICTURES
 * PAGINATION

/* BOUTONS */
@mixin buttonBuilder($width, $height, $theme, $type) {
  background-color: transparent;
  border: solid $default-button-border-width;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0 10px;
  @include transition(all, 0.3s, ease, 0s);

  min-height: $height;
  width: $width;
  @if $theme == "light" {
    color: $default-light-font-color;
    border-color: $default-light-font-color;
  }
  @if $theme == "dark" {
    color: $default-dark-font-color;
    border-color: $default-dark-font-color;
  }

  .innerContent {
    display: inline-block;
    vertical-align: middle;
    max-width: 450px;
  }
  &:before {
    content: "";
    height: $height;
    display: inline-block;
    vertical-align: middle;
  }
  // Boutons WISIWYG
  @if $type== "rte" {
    text-transform: none;
    font-family: $font-biko-bold;
    font-size: 18px;
    letter-spacing: 0.064em;
    display: inline-block;
    @media (max-width: 1279px) {
      border-width: 2px;
    }
    @media (min-width: 640px) and (max-width: 1279px) {
      font-size: 28px;
      min-height: 45px;
      &:before {
        height: 45px;
      }
    }
    @media (max-width: 639px) {
      font-size: 14px;
      min-height: 22.5px;
      &:before {
        height: 22.5px;
      }
    }
    border: solid 1px $default-dark-font-color;
  }
  // Boutons Call to action
  @if $type== "callToAction" {
    text-transform: uppercase;
    font-family: $font-lovelo-black;
    font-size: 24px;
    max-width: 100%;
    margin-bottom: 45px;
    @media (min-width: 640px) and (max-width: 1279px) {
      font-size: 28px;
      border-width: 5px;
      // padding-left: 60px;
      .icon {
      }
    }
    @media (max-width: 639px) {
      font-size: 14px;
      // padding-left: 30px;
      height: auto;
      min-height: $height/2;
      margin-bottom: 22.5px;
      &:before {
        height: ($height/2)-2;
      }
    }
  }
  // Boutons More
  @if $type== "more" {
    font-family: $font-biko-bold;
    font-size: 24px;
    width: 130px;
    max-width: 100%;
    border-width: 2px;
    margin-left: auto;
    margin-right: 0;
    text-transform: none;

    @media (min-width: 640px) and (max-width: 1279px) {
      font-size: 36px;
      width: auto;
    }
    @media (max-width: 639px) {
      font-size: 18px;
      width: auto;
    }
  }
  // Boutons scrollTo
  @if $type == "scrollTo" {
    display: inline-block;
    border: none;
    // Biko Bold
    font-size: 24px;
    //font-size: 2.4rem;
    background-color: rgba(255, 255, 255, 0.9);
    .innerContent {
      letter-spacing: 0.08em;
    }
    .subtitle {
      letter-spacing: normal;
      margin-top: 3px;
    }
    @media (max-width: 1279px) {
      display: block;
      width: 100%;
      padding-left: 55px;
      min-height: 1px;
      &:before {
        height: 100%;
      }
    }
    font-family: $font-biko-bold;
    @media (min-width: 640px) and (max-width: 1279px) {
      font-size: 28px;
      height: 126px;
      margin-bottom: 46px;
    }
    @media (max-width: 639px) {
      font-size: 14px;
      height: 63px;
      margin-bottom: 43px;
    }
  }

  // Boutons overpicture
  @if $type == "overPicture" {
    background-color: $default-light-font-color;
    border-color: $default-light-font-color;
    color: $default-dark-font-color;
    font-family: $font-biko-bold;
    font-size: 24px;
    @media (min-width: 640px) and (max-width: 1279px) {
      font-size: 36px;
    }
    @media (max-width: 639px) {
      font-size: 18px;
    }
    &:hover,
    &:active,
    a:focus {
      background-color: transparent;
      color: $default-light-font-color;
    }
  }
  @if $type == "basic" {
    text-transform: none;
    font-family: $font-biko-bold;
    font-size: 16px;
    @media (min-width: 640px) and (max-width: 1279px) {
      font-size: 28px;
    }
    @media (max-width: 639px) {
      font-size: 14px;
    }
  } @else {
    &:hover,
    &:focus,
    &:active {
      color: $default-hover-color;
      border-color: $default-hover-color;
    }
  }
}
.mac.class_group_content.class_identifier_page_wide
  .callToAction--dark
  .innerContent {
  padding-top: 10px;
}
/* BOUTONS PICTO */
@mixin buttonPictoBuilder($picto, $width, $theme) {
  position: relative;

  .st0 {
    @include transition(all, 0.3s, ease, 0s);
  }
  // Picto Archive
  @if $picto == "archive" {
    font-size: 24px;
    font-size: 2.4rem;
    .svg-picto-archive {
      content: "";
      display: inline-block;
      position: absolute;
      background-repeat: no-repeat;
      top: 25px;
      @media (max-width: 639px) {
        height: 13.451px;
        top: 33%;
      }
      left: $default-button-picto-padding;
      .st0 {
        @if $theme == "dark" {
          fill: $default-dark-font-color;
        }
        @if $theme == "light" {
          fill: $default-light-font-color;
        }
      }
    }
    &:hover,
    &:active,
    &:focus {
      .svg-picto-archive .st0 {
        fill: $default-hover-color;
      }
    }
  }
  //Picto Search
  @if $picto == "search" {
    font-size: 16px;
    font-size: 1.6rem;
    .svg-picto-search {
      content: "";
      display: inline-block;
      position: absolute;
      background-repeat: no-repeat;
      top: 25px;
      .st0 {
        @if $theme == "hover" {
          stroke: $default-hover-color;
        }
        @if $theme == "light" {
          stroke: $default-light-font-color;
        }
      }
    }
  }
  // Picto Agenda
  @if $picto == "agenda" {
    font-size: 24px;
    font-size: 2.4rem;
    padding-left: 60px;

    @media (max-width: 639px) {
      padding-left: 30px;
    }
    .icon {
      content: "";
      display: inline-block;
      position: absolute;
      background-repeat: no-repeat;
      top: 25px;
      left: $default-button-picto-padding;
      font-size: 31px;
      font-size: 3.1rem;
      @media (max-width: 639px) {
        top: 33%;
        font-size: 15px;
        font-size: 1.5rem;
        left: 17.5px;
      }
    }
  }
  // Picto en savoir plus
  @if $picto == "more" {
    display: inline-block;
    width: auto;
    padding-left: 15px;
    padding-right: 10px;
    font-size: 18px;
    font-size: 1.8rem;
    .icon {
      margin-left: 10px;
    }
    @media (min-width: 640px) and (max-width: 1279px) {
      font-size: 36px;
      border-width: 4px;
    }
    @media (max-width: 639px) {
      font-size: 18px;
    }
  }
}
/* LOCKBAR */
@mixin lockBarBuilder($width, $height, $orientation, $theme) {
  display: inline-block;
  border-style: solid;
  border-width: 16px;
  width: $width;
  height: $height;
  margin-bottom: 60px;

  @if $theme == "dark" {
    border-color: $default-dark-font-color;
  } @else if $theme == "light" {
    border-color: $default-light-font-color;
  }
  @if $orientation == "down" {
    border-top: none;
  } @else if $orientation== "up" {
    border-bottom: none;
  } @else if $orientation== "left" {
    border-right: none;
  } @else if $orientation== "right" {
    border-left: none;
  }

  @media (max-width: 1279px) {
    width: 100%;
  }
  @media (max-width: 639px) {
    margin-top: 40px;
    margin-bottom: 30px;
  }
}
/* ACTUS */
@mixin actuBuilder($type) {
  .visu {
    background-size: cover;
    background-position: center, center;
    display: inline-block;
    content: "";
    vertical-align: top;
  }
  .right {
    display: inline-block;
    vertical-align: top;
  }
  .head {
    .date {
      font-size: 33px;
      font-size: 3.3rem;
      font-family: $font-lovelo-black;
      display: inline-block;
    }
    .categorie {
      margin-left: 10px;
      font-size: 30px;
      font-size: 3rem;
      font-family: $font-novecentowide-regular;
      display: inline-block;
    }
    .title {
      display: block;
      font-size: 30px;
      font-size: 3rem;
      font-family: $font-novecentowide-regular;
      margin-bottom: 15px;
      line-height: 30px;
    }
    .tiret {
      font-size: 30px;
      font-size: 3rem;
      font-family: $font-novecentowide-regular;
      display: inline-block;
    }
  }
  .text {
    display: inline-block;
    padding-right: 20px;
    .location {
      font-size: 14px;
      font-size: 1.4rem;
      .place {
        font-family: $font-opensans-bold;
        display: inline-block;
      }
      .details {
        font-family: $font-opensans-regular;
        display: inline-block;
      }
      margin-bottom: 15px;
    }
  }
  .contain--learn-more {
    display: inline-block;
    vertical-align: top;

    // &:before{
    // 	height: 235px;
    // 	content: '';
    // 	display: inline-block;
    // 	vertical-align: middle;
    // }
    .callToAction--pictoAfter {
      vertical-align: middle;
      display: inline-block;
    }
  }
  @media (max-width: 1279px) {
    .visu {
      width: 100%;
      position: relative;
      &:after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-left: 17.5px solid transparent;
        border-right: 17.5px solid transparent;
        border-bottom: 18px solid #fff;
        position: absolute;
        left: 50%;
        margin-left: -17.5px;
        bottom: 0;
      }
    }
    .right {
      height: auto;
      width: 100%;
    }
    .text {
      padding-right: 0;
      .date {
      }
      .categorie {
      }
      .title {
      }
      .location {
        .place {
          .divider {
            display: none;
          }
        }
        .details {
        }
      }
    }
    .contain--learn-more {
      display: block;

      &:before {
        display: none;
      }
      .callToAction--pictoAfter {
      }
    }
  }
  @media (min-width: 640px) and (max-width: 1279px) {
    .visu {
      margin-bottom: 50px;
    }
    .right {
      padding-left: 55px;
      padding-right: 55px;
    }
    .text {
      .date {
      }
      .categorie {
      }
      .title {
      }
      .location {
        font-size: 26px;
        font-size: 2.6rem;
        .place {
        }
        .details {
        }
        margin-bottom: 50px;
      }
    }
    .contain--learn-more {
      &:before {
        // display: none;
      }
      .callToAction--pictoAfter {
      }
    }
  }
  @media (max-width: 639px) {
    .visu {
      margin-bottom: 25px;
    }
    .right {
      padding-left: 27.5px;
      padding-right: 27.5px;
    }
    .head {
      .date {
        font-size: 1635px;
        font-size: 1.65rem;
      }
      .categorie {
        font-size: 15px;
        font-size: 1.5rem;
      }
      .title {
        font-size: 15px;
        font-size: 1.5rem;
        line-height: 15px;
      }
      .tiret {
        font-size: 15px;
        font-size: 1.5rem;
      }
      .categorie {
        margin-left: 5px;
      }
    }
    .text {
      .location {
        font-size: 13px;
        font-size: 1.3rem;
        .place {
        }
        .details {
        }
        margin-bottom: 25px;
      }
    }
    .contain--learn-more {
      &:before {
      }
      .callToAction--pictoAfter {
      }
    }
  }

  @if $type== "gabarit" {
    @media (min-width: 1280px) {
      width: 1000px;
      .callToAction--pictoAfter {
        border-width: 2px;
      }
    }
    text-align: left;
    .actu--item {
      margin-bottom: 45px;

      .yosemite-rte {
        p {
          font-family: $font-opensans-light;
        }
      }
    }
    .visu {
      @media (min-width: 1280px) {
        width: 180px;
        margin-right: 45px;
      }
      height: 235px;
    }
    .right {
      @media (min-width: 1280px) {
        height: auto;
        min-height: 235px;
      }
    }
    .head {
      @media (min-width: 1280px) {
        width: 775px;
      }
    }
    .text {
      @media (min-width: 1280px) {
        width: 600px;
      }
    }
    @media (max-width: 639px) {
      .actu--item {
        margin-bottom: 22.5px;
      }
    }
  } @else if $type== "agenda" {
    // text-align: left;
    // display: block;
    // margin-left: auto;
    // margin-right: auto;
    // @media(min-width: 1280px){
    // 	width: 1185px;
    // 	.callToAction--pictoAfter{
    // 		border-width: 2px;
    // 	}
    // }

    // .actu--item{
    // 	margin-top: 30px;
    // }
    // .visu{
    // 	width: 180px;
    // 	height: 274px;
    // }
    // .right{
    // 	width: 695px;
    // }
    // .actu--item{
    // 	margin-bottom: 45px;

    // 	.yosemite-rte{
    // 		p{
    // 			font-family: $font-opensans-light;
    // 		}
    // 	}
    // }
    // .text{
    // 	@media(min-width: 1280px){
    // 		width: 600px;
    // 	}
    // }
    // @media(max-width: 639px){
    // 	.actu--item{
    // 		margin-bottom: 22.5px;
    // 	}
    // }
    display: block;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 1280px) {
      width: 1185px;
      .callToAction--pictoAfter {
        border-width: 2px;
      }
    }
    text-align: left;
    .actu--item {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 45px;

      .yosemite-rte {
        p {
          font-family: $font-opensans-light;
        }
      }
    }
    .lock {
      width: 37px;
      height: 37px;
      border-top: solid 16px;
      border-right: solid 16px;
      position: absolute;
      top: 0;
      right: 0;

      @media (max-width: 1279px) {
        display: none;
      }
    }
    .visu {
      @media (min-width: 1280px) {
        width: 185px;
        margin-right: 45px;
      }
      height: 274px;
    }
    .right {
      border-top: solid 1px;
      position: relative;
      @media (min-width: 1280px) {
        height: auto;
        min-height: 235px;
        padding-top: 40px;
      }
      @media (max-width: 1279px) {
        border-top: none;
      }
    }
    .head {
      @media (min-width: 1280px) {
        width: 955px;
      }
    }
    .text {
      @media (min-width: 1280px) {
        width: 575px;
      }
    }
    .contain--learn-more {
      width: 380px;
      text-align: left;
      @media (max-width: 639px) {
        width: 100%;
      }
    }
    @media (max-width: 1279px) {
      .actu--item {
        padding-top: 0;
      }
    }
    @media (max-width: 639px) {
      .actu--item {
        margin-bottom: 22.5px;
      }
    }
  } @else if $type== "archives" {
  } @else if $type== "miniActu" {
    @media (min-width: 0px) {
      .text {
        display: inline-block;
        padding-right: 20px;
        .date {
          font-size: 33px;
          font-size: 3.3rem;
          font-family: $font-lovelo-black;
          display: inline-block;
          @media (max-width: 639px) {
            font-size: 16.5px;
            font-size: 1.65rem;
          }
        }
        .categorie {
          font-size: 30px;
          font-size: 3rem;
          font-family: $font-novecentowide-regular;
          display: inline-block;
          @media (max-width: 639px) {
            font-size: 15px;
            font-size: 1.5rem;
          }
        }
        .title {
          display: block;
          font-size: 30px;
          font-size: 3rem;
          font-family: $font-novecentowide-regular;
          margin-bottom: 15px;
          @media (max-width: 639px) {
            font-size: 15px;
            font-size: 1.5rem;
          }
        }
      }
      .callToAction--pictoAfter {
        margin-top: 5px;
      }
      h3 {
        font-family: $font-biko-bold;
        font-size: 20px;
        font-size: 2rem;
        margin-bottom: 25px;
        margin-top: 40px;
      }
    }
    @media (max-width: 1279px) and (min-width: 640px) {
      h3 {
        font-size: 26px;
        font-size: 2.6rem;
      }
    }
    @media (max-width: 639px) {
      .callToAction--pictoAfter {
        border-width: 4px;
        height: 46px;
        font-size: 18px;
        font-size: 1.8rem;
        &:before {
          height: 38px;
        }
      }
    }
  }
}
/* TITRES */
@mixin titleBuilder(
  $picto-left,
  $picto-top,
  $container-color,
  $container-height,
  $picto-triangle
) {
  display: block;
  margin-right: auto;
  margin-left: auto;
  height: $container-height;
  text-align: center;
  .innerContent {
    display: inline-block;
    vertical-align: bottom;
    @media (min-width: 1280px) {
      margin-bottom: 25px;
    }
  }
  &:before {
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    content: "";
  }
  h2 {
    display: inline-block;
    vertical-align: middle;
  }
  @media (max-width: 1279px) {
    height: auto;
    &:before {
      display: none;
    }
  }
  @media (max-width: 1279px) and (min-width: 640px) {
    padding: 70px 0 40px;
    h2 {
      font-size: 28px;
      font-size: 2.8rem;
    }
  }
  @media (max-width: 639px) {
    padding: 35px 0 20px;
    h2 {
      font-size: 14px;
      font-size: 1.4rem;
    }
    .triangle {
      margin-top: 12.25px !important;
      border-width: 5px !important;
      margin-left: -5px !important;
    }
  }
  @media (max-width: 1279px) {
    .icon {
      display: block;
      width: 100%;
      margin-bottom: 35px;
      font-size: 75px;
      font-size: 7.5rem;
    }
  }
  @media (max-width: 639px) {
    margin-bottom: 17.5px;
    .icon {
      font-size: 37px;
      font-size: 3.7rem;
      margin-bottom: 17.5px;
    }
  }
  // Gestion Conteneur
  @if $container-color == "container-dark" {
    background-color: $default-dark-font-color;
    h2,
    .icon {
      color: #fff;
    }
  }
  @if $container-color == "container-light" {
    background-color: #fff;
    h2,
    .icon {
      color: $default-dark-font-color;
    }
    .triangle {
      display: block;
      position: static;
      border-top-color: $default-dark-font-color !important;
    }
  }
  // Gestion Picto
  @if $picto-left == "picto-left" {
    position: relative;
    .innerContent {
      position: relative;
    }
    @media (min-width: 1280px) {
      .icon {
        position: absolute;
        left: -100px;
        top: -15px;
        font-size: 50px;
        font-size: 5rem;
      }
    }
    // @media(max-width: 1279px){

    // 	.icon{
    // 		display: block;
    // 		width: 100%;
    // 		margin-bottom: 35px;
    // 		font-size: 75px;
    // 		font-size: 7.5rem;
    // 	}
    // }
    // @media(max-width: 639px){
    // 	margin-bottom: 17.5px;
    // 	.icon{
    // 		font-size: 37px;
    // 		font-size: 3.7rem;
    // 	}
    // }
    .triangle {
      margin-top: 20px;
    }
  }
  @if $picto-top == "picto-top" {
    position: relative;

    h2 {
      font-family: $font-lovelo-black;
      // font-size: 24px;
      // font-size: 2.4rem;
    }
    .icon {
      display: block;
      font-size: 75px;
      font-size: 7.5rem;
      margin-bottom: 35px;
      &.icon-parcours {
        font-size: 83px;
        font-size: 8.3rem;
      }
      &.icon-musee-prive {
        font-size: 83px;
        font-size: 8.3rem;
      }
      &.icon-key {
        font-size: 60px;
        font-size: 6rem;
      }
      &.icon-collection {
        font-size: 85px;
        font-size: 8.5rem;
      }
      &.icon-artips-text {
        display: inline-block;
        font-size: 20px;
        font-size: 2rem;
        margin-bottom: 0px;
        position: relative;
        left: -4px;
        top: -1px;
      }
    }
    .triangle {
      display: block;
      position: static;
    }
    @media (max-width: 639px) {
      .icon {
        font-size: 37.5px;
        font-size: 3.75rem;
        margin-bottom: 17.5px;
      }
    }
  }
  @if $picto-triangle == "picto-triangle" {
    .triangle {
      width: 0px;
      height: 0px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #fff;
      position: relative;
      left: 50%;
      margin-left: -10px;
      margin-top: 25px;
    }
    // @media(min-width: 1280px){
    // 	.triangle{
    // 		display: inline-block;
    // 		position: absolute;
    // 		top: 50%;
    // 		left: 50%;
    // 		margin-top: 30px;
    // 		margin-left: -10px;
    // 	}
    // }
    // @media(max-width: 1279px){
    // 	.triangle{
    // 		display: block;
    // 		position: relative;
    // 		left: 50%;
    // 		margin-left: -10px;
    // 		margin-top: 30px;
    // 	}
    // }
    // @media(max-width: 639px){
    // 	.triangle{
    // 		margin-top: 15px;
    // 	}
    // }
  }
}
/* LOCKED PICTURES */
@mixin lockedPictureBuilder($picture-height, $container-width) {
  $bar-height: 75 * $picture-height/100;
  height: $picture-height;
  width: 100%;
  background-position: center center;
  background-size: cover;
  text-align: center;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);

  &.stand-alone {
    margin-bottom: 45px;

    @media (max-width: 639px) {
      margin-bottom: 22.5px;
    }
  }

  &:before {
    height: 100%;
    display: inline-block;
    content: "";
    vertical-align: middle;
  }

  .locked-content {
    position: relative;
    display: inline-block;
    width: $container-width + 160;
    text-align: center;
    height: $bar-height;
    vertical-align: middle;
    &:before {
      height: 100%;
      display: inline-block;
      content: "";
      vertical-align: middle;
    }
  }
  .lock-bar-left {
    @include lockBarBuilder(30px, $bar-height, "left", "light");
    position: absolute;
    left: 0;
    // top: 17.5%;
  }
  .lock-bar-right {
    @include lockBarBuilder(30px, $bar-height, "right", "light");
    position: absolute;
    right: 0;
    // top: 17.5%;
  }
  .innerContent {
    display: inline-block;
    vertical-align: middle;
    width: $container-width;
    color: white;
  }
  .title {
    font-family: $font-lovelo-black;
    font-size: 46px;
    font-size: 4.6rem;
  }
  .subtitle {
    font-family: $font-novecento-widenormal;
    font-size: 31px;
    font-size: 3.1rem;
  }
  .description {
    font-family: $font-biko-bold;
    font-size: 25px;
    font-size: 2.5rem;
  }

  @media (max-width: 1279px) {
    .locked-content {
      width: 95%;
    }
    .innerContent {
      width: 100%;
    }
    .lock-bar-left {
      display: none;
    }
    .lock-bar-right {
      display: none;
    }
  }

  @media (max-width: 639px) {
    .title {
      font-size: 23px;
      font-size: 2.3rem;
    }
    .subtitle {
      font-size: 15px;
      font-size: 1.5rem;
    }
    .description {
      font-size: 12.5px;
      font-size: 1.25rem;
    }
  }
}
/* PAGINATION */
@mixin paginationBuilder($theme) {
  $pagination-color: #fff;
  @if $theme == "light" {
    $pagination-color: $default-dark-font-color;
  }
  text-align: center;
  height: 35px;
  margin-bottom: 45px !important;
  color: $pagination-color;
  &:before {
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    content: "";
  }
  %pagination-controls {
    border: solid 4px $pagination-color;
    padding: 5px 13px;
    @include transition(all, 0.3s, ease, 0s);
    // width: 155px;
    font-size: $font-biko-bold;
    font-size: 18px;
    font-size: 1.8rem;

    &:focus,
    &:active,
    &:hover,
    .active {
      border-color: $default-hover-color;
      color: $default-hover-color;
      .icon {
        color: $default-hover-color;
      }
    }
    @media (max-width: 1279px) {
      margin: 0 !important;
    }

    @media (max-width: 639px) {
      // width: 60px;
      font-size: 11px;
      font-size: 1.1rem;

      .icon {
        font-size: 11px;
        font-size: 1.1rem;
      }
    }
  }
  .pagination-previous {
    @extend %pagination-controls;
    float: left;
    text-align: left;
    margin-left: 20px;
    .icon {
      padding-right: 15px;
    }
  }
  .pagination-next {
    @extend %pagination-controls;
    float: right;
    text-align: right;
    margin-right: 20px;
    .icon {
      padding-left: 15px;
    }
  }

  .index {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    @media (max-width: 1279px) {
      display: none;
    }

    .index-item {
      display: inline-block;
      width: 45px;
      text-align: center;
      @include transition(all, 0.3s, ease, 0s);
      border-left: solid 2px $pagination-color;
      font-size: 18px;
      font-size: 1.8rem;
      font-family: $font-biko-bold;

      a {
        width: 100%;
        display: inline-block;
        &:hover,
        &:active,
        &:focus {
          color: $default-hover-color;
        }
      }

      &.active {
        color: $default-hover-color;
      }

      &:first-child {
        border-left: none;
      }
      .pagination-last {
        border-left: none;
      }
      &.dotted {
        border: none;
        width: 20px;
      }
      & + .dotted {
        border: none;
      }
    }
  }

  @if $theme == "dark" {
    background-color: $default-dark-font-color;
  } @else if $theme == "light" {
    background-color: #fff;
  }
}
