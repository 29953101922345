.class_identifier_musee_master.class_group_content.class_identifier_page_wide .section--wide-1.altered{
    background-color: #1D1D1E;
    .container--wide{
        background-color: #1D1D1E;
    }
    .title-icon{
        .icon{
            color: #ffffff;
        }
        h2{
            color: #ffffff;
        }
        .innerContent{
            color: #ffffff;
        }

    }
}
.class_identifier_musee_master.class_group_content.class_identifier_page_wide{

    @media screen and (max-width: 1279px){
        .banner--anchor-4 .scrollTo--picto[data-target-scroll="#scroll1"]{
            display: none;
        }
        .banner--wide{
            min-height: 725px;
            &.banner--wide-2{
                min-height: 345px;
            }
        }
        .section--wide-1.altered{
            display: none;
        }
    }

    @media screen and (max-width: 639px){
        .banner--anchor-4 .scrollTo--picto[data-target-scroll="#scroll1"]{
            display: none;
        }
        .banner--wide{
            min-height: 455px;
            &.banner--wide-2{
                min-height: 270px;
            }
        }
        .section--wide-1.altered{
            display: none;
        }
    }
}