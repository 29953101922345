.class_identifier_emotions{

    $facebookAccountName:   '@PBALILLE';
    $instagramAccountName:  '@PBALILLE';
    $twitterAccountName:    '@PBALILLE';

    // Generic placeholders
    %wrapper{
        max-width: 1168px;
        width:100%;
        margin: 0 auto;
        @media(max-width:1279px){
            padding: 0 27px;
        }
    }

    .arianne-emotions{
        height: 130px;
        .icon-team{
            font-size: 50px;
            position: relative;
            top: -5px;
            left: -20px;
        }
        .triangle{
            width: 0px;
            height: 0px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #FFF;
            display: block;
            margin-top: 12px;
            position: relative;
            left: calc(50% - 10px);
        }
    }

    .page--emotions{
        background-color: #f7f7f7;
    }

    .grid{
        display: flex; 
        flex-flow: row wrap;
        padding-top:5px;
    }

    .grid-col, .grid-sub-col{
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        align-content: flex-start;
        width: 50%;
    }

    .grid-block{
        overflow:hidden;
        position: relative;

        display: block;
        width: 100%;
        margin: 0 5px 10px;
        background-color: #fff;
        background-size: cover;
        background-position: 50% 50%;

        &.gb-insta{
            a:before{
                background: transparent url(svg-bg-uri($svg-instagram, #fff)) no-repeat 50%;
                background-size: 27px;
            }
            p:last-of-type:before{
                content: $instagramAccountName;
            }
        }
        &.gb-facebook{
            a:before{
                background: transparent url(svg-bg-uri($svg-facebook, #fff)) no-repeat 50%;
                background-size: 27px;
            }
            p:last-of-type:before{
                content: $facebookAccountName;
            }
        }
        &.gb-twitter{
            a:before{
                background: transparent url(svg-bg-uri($svg-twitter, #fff)) no-repeat 50%;
                background-size: 23px;
            }
            p:last-of-type:before{
                content: $twitterAccountName;
            }
        }

        &.gb-insta, &.gb-facebook, &.gb-twitter{
            padding: 0;
            a{
                display: block;
                width: 100%;
                height: 100%;
                padding:40px 20px 90px;
                &:before{
                    content: '';
                    width: 46px;
                    height: 46px;
                    border-radius: 25px;
                    border: 2px solid #fff;
                    position: absolute;
                    bottom: 20px;
                    left: 20px;
                }
                p{
                    color: #fff;
                    font-family: $font-biko-regular;
                    font-size: 2rem;
                    font-size: 20px;
                    max-height: 100%;
                    &:last-of-type{
                        position: absolute;
                        bottom: 20px;
                        right: 20px;
                        font-size: 1.6rem;
                        font-size: 16px;
                        font-family: $font-biko-bold;
                        line-height: 19px;
                        text-align: right;
                        &:before{
                            display: block;
                        }
                    }
                }
            }
        }
        &.gb-facebook, &.gb-twitter{
            background-color:#fff;
            a{
                background-color: rgba(29, 29, 29, 0.6);
            }
        }
        &.gb-comment{
            padding: 0;
          
            .comment-wrapper{
                width: 100%;
                height: 100%;
                font-family: $font-biko-bold;
                padding:30px 15px;
            }            

            .commentContent{
                position: relative;
                font-size: 2rem;
                font-size: 20px;
                line-height: 24px;
                z-index: 1;

                &:not(.is-truncated) a.readmore{ display: none;}
                a.readmore{
                    text-align: right;
                    &:before{
                        display: inline-block;
                        content: "\e940";
                        margin-right: 10px;
                        font-family: "pba";
                        font-size: 16px;
                        font-size: 1.6rem;
                        transition: all 0.3s;
                    }

                    &:hover:before{
                        transform: translate3D(0, 0, 0) translateX(5px);
                    }
                }

            }

            .commentAuthor{
                font-size: 2.4rem;
                font-size: 24px;
                text-transform: uppercase;
                text-align:right;
                height: 87px;
                padding: 60px 20px 0 110px;
                line-height: 27px;
                position: absolute;
                bottom: 40px;
                width: calc( 100% - 14px);
            }
          
            &--lo{
                background-color: $default-secondary-color;
                color:#fff;
                .commentContent{
                    border: 4px solid #fff;
                    padding: 15px;
                    &:after{
                        content: url(svg-bg-uri($svg-comment-arrow, $default-secondary-color));
                        position: absolute;
                        width:42px;
                        height:38px;
                        bottom: -38px;
                        left: 90px;
                    }
                }
                .commentAuthor{
                    background: transparent url(svg-bg-uri($svg-comment-author, #fff)) no-repeat 30px 0;
                    background-size:62px 87px;
                    
                }
            }
            &--oe{
                background-color:#f5f5f5;
                color: #1d1d1d;

                .commentContent{
                    max-height: 280px;
                }
                a.visu{
                    display: block;
                    position: absolute;
                    bottom: 20px;
                    left: 20px;
                    height:163px;
                    width:110px;
                    background-size: cover;
                }
                .commentAuthor{
                    height: auto;
                    padding: 0;
                    right: 20px;
                    width: calc(100% - 150px);
                }

            }

            &--h{
                .commentContent{
                    max-height: 135px;
                    min-height: 90px;
                    width: calc(100% - 150px);
                    float: right;
                    &:after{
                        bottom: 20px;
                        left: -40px;
                        transform: rotateY(180deg) rotateZ(-90deg);
                    }
                }
                .commentAuthor{
                    bottom: 30px;
                }
            }
            &--v{
                &.gb-comment--lo{
                    .commentContent{
                        max-height: 300px;
                    }
                }
            }
  
        }

        &.gb-favorites{
            a{
                background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 71%, rgba(0,0,0,0.6) 100%);
                display: block;
                width: 100%;
                height: 100%;
                &:before{
                    content: '';
                    width: 43px;
                    height: 36px;
                    position: absolute;
                    bottom: 20px;
                    left: 20px;
                    background: transparent url(svg-bg-uri($svg-heart, #fff)) no-repeat 50%;
                    background-size: cover;
                }
                p{
                    position: absolute;
                    bottom: 20px;
                    right: 20px;
                    text-align: right;
                    color: #fff;
                    font-family: $font-biko-bold;
                    font-size: 1.6rem;
                    font-size: 16px;
                    line-height: 19px;
                    &:before{
                        content: 'Ajouté en favori par';
                        display: block;
                    }
                }
            }

        }

        &.gb-ecard, &.gb-goldenbook{
            @extend %cadre-btn;
            a, button{
                width: 100%;
                height: 100%;
                &:hover{
                    &:before{ top: calc(100px + 5px); }
                    &:after{ bottom: calc(100px + 5px); }
                }
                &:before{ top: 100px; }
                &:after{ bottom: 100px; }

            }
        }

        &.gb-count{
            display: flex;
            flex-flow: row wrap;
            padding:0;

            &>*{ 
                width: calc(50% - 5px);
                display:block;
                text-align: center;
                padding-top:25px;
                font-family: $font-biko-bold;
                font-size: 2.4rem;
                font-size: 24px;

                span{
                    display: block;
                    font-size:0;
                    width: 80px;
                    height: 80px;
                    margin: 10px auto;
                    border: 3px solid #fff;
                    border-radius: 50%;
                }

                &:nth-child(2n-1){
                    margin-right: 10px;
                }
                &:nth-child(1), &:nth-child(2){
                    margin-bottom: 10px;
                }
                &:nth-child(1), &:nth-child(4){
                    background-color: #000;
                    color: #fff;
                }
            }

            #gb-twitter-count{
                span{
                    background: transparent url(svg-bg-uri($svg-twitter, #fff)) no-repeat 50% 50%;
                    background-size: 39px;
                }
            }
            #gb-snapcode{
                span{
                    font-size: 2.4rem;
                    font-size: 24px;
                    background: transparent url('../images/snapcode_PBALille.png') no-repeat 50% 50%;
                    background-size: cover;
                    border-radius: 0;
                    border: none;
                }
            }
            #gb-facebook-count{
                span{
                    border-color: #000;
                    background: transparent url(svg-bg-uri($svg-facebook, #000)) no-repeat 50% 50%;
                    background-size: 45px;
                }
            }
            #gb-instagram-count{
                span{
                    background: transparent url(svg-bg-uri($svg-instagram, #fff)) no-repeat 50% 50%;
                    background-size: 49px;
                }
            }
        }

        
        
    }

    .page--emotions-web .grid-block{
        &#grid-block-1 ,
        &#grid-block-3 ,
        &#grid-block-4 ,
        &#grid-block-6 ,
        &#grid-block-7 ,
        &#grid-block-8 ,
        &#grid-block-9 ,
        &#grid-block-10,
        &#grid-block-11,
        &#grid-block-16{
            height: 506px
        }

        &#grid-block-2 ,
        &#grid-block-5 ,
        &#grid-block-15,
        &#grid-block-17,
        &#grid-block-20{
            height: 248px;
        }

        &#grid-block-12,
        &#grid-block-13,
        &#grid-block-14,
        &#grid-block-18,
        &#grid-block-19{
            height: 334px;
        }

        &#grid-block-21,
        &#grid-block-22{
            height: 420px;
        }


        &#grid-block-3, 
        &#grid-block-4,
        &#grid-block-7,
        &#grid-block-8,
        &#grid-block-18,
        &#grid-block-19,
        &#grid-block-21,
        &#grid-block-22{
            width: calc(50% - 10px);
        }
    }

    @media(max-width: 1279px){

        .grid-col{
            width: 100%;
        }
    }
    @media(max-width: 639px){
        .grid-col, .grid-sub-col{
            width: 100%;
        }
        .page--emotions-web .grid-block{
            &#grid-block-3, 
            &#grid-block-4,
            &#grid-block-7,
            &#grid-block-8,
            &#grid-block-18,
            &#grid-block-19,
            &#grid-block-21,
            &#grid-block-22{
                width: 100%;
            }
        }
    }

    
    .page--emotions-4k{
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        padding: 0;
 
        .grid-block{

            &.gb-comment{

                .comment-wrapper{
                    padding: 10px;
                }
                .comment-content{
                    font-size: 1.8rem;
                    font-size: 18px;
                    line-height: 20px;
                }
                &--lo{ 
                    .commentContent{
                        padding: 10px;
                        font-size: 19px;
                        line-height: 21px;
                    }
                    .commentAuthor{
                        background-position: 10px 0;
                        background-size: 42px;
                        font-size: 1.6rem;
                        font-size: 16px;
                        height: 60px;
                        padding: 10px 20px 0 65px;
                        bottom: 10px;
                    }
                    &#grid-block-6{
                        .commentContent{
                            
                            &:after{
                                
                            }
                        }
                    }
                    &#grid-block-2{
                        .commentContent{
                            
                        }
                    }
                } 
                &--oe{
                    a.visu{
                        bottom: 10px;
                        left: 10px;
                        height:152px;
                        width:110px;
                        background-size: cover;
                        background-color: #ccc;
                    }
                    &#grid-block-4{
                        a.visu{
                            height:172.5px;
                            width: 120px;
                            top: 0;
                            left: 0;
                        }
                        .commentContent{
                            padding-left: 120px;
                        }
                        .commentAuthor{
                            width: calc(100% - 140px);
                            font-size: 16px;
                            line-height: 20px;
                            bottom:10px;
                        }
                    }
                    &#grid-block-7{
                        a.visu{
                            height:90px;
                            width:70px;
                        }
                        .commentAuthor{
                            width: calc(100% - 90px);
                            right: 10px;
                            font-size: 16px;
                            line-height: 20px;
                            bottom: 10px;
                        }
                    }
                    &#grid-block-12{
                        a.visu{
                            height:80px;
                            width:60px;
                        }
                        .commentAuthor{
                            width: calc(100% - 80px);
                            right: 10px;
                            font-size: 14px;
                            line-height: 16px;
                            bottom: 10px;
                        }
                    }
                }
            }
            
            &.gb-insta, &.gb-facebook, &.gb-twitter{
                a{
                    padding: 20px 20px 80px;
                    &:before{
                        width: 36px;
                        height: 36px;
                        border-radius: 50%;
                        bottom: 20px;
                        left: 20px;
                    }
                    p{
                        font-size: 1.8rem;
                        font-size: 18px;

                    }
                    p:last-of-type{
                        font-size: 1.5rem;
                        font-size: 15px;
                        bottom: 18px;
                        right: 20px;
                    }
                }
                
            }
            &.gb-insta a:before{
                background-size: 18px;
            }
            &.gb-facebook a:before{
                background-size: 20px;
            }
            &.gb-twitter a{
                padding: 20px 20px 80px;
                &:before{
                    background-size: 16px;
                }

            }

            &.gb-count{
                display: flex;
                flex-flow: row wrap;
                padding:0;

                &>*{ 
                    width: 105px;
                    display:block;
                    text-align: center;
                    padding-top:20px;
                    font-family: $font-biko-bold;
                    font-size: 1.6rem;
                    font-size: 16px;

                    span{
                        display: block;
                        font-size:0;
                        width: 56px;
                        height: 56px;
                        margin: 5px auto;
                        border: 3px solid #fff;
                        border-radius: 50%;
                    }

                    &:nth-child(2n-1){
                        margin-right: 0;
                    }
                    &:nth-child(1), &:nth-child(2){
                        margin-bottom: 0;
                    }
                    &:nth-child(1), &:nth-child(3){
                        background-color: #000;
                        color: #fff;
                    }
                    &:nth-child(4){
                        background-color: #FFF;
                        color: #000;
                    }
                }

                #gb-twitter-count{
                    span{
                        background-size: 29px;
                    }
                }
                #gb-snapcode{
                    span{
                        font-size: 1.6rem;
                        font-size: 16px;
                        background: transparent url('../images/snapcode_PBALille.png') no-repeat 50% 50%;
                        background-size: cover;
                        border-radius: 0;
                        border: none;
                    }
                }
                #gb-facebook-count{
                    span{
                        border-color: #FFF;
                        background: transparent url(svg-bg-uri($svg-facebook, #FFF)) no-repeat 50% 50%;
                        background-size: 25px;
                    }
                }
                #gb-instagram-count{
                    span{
                        border-color: #000;
                        background: transparent url(svg-bg-uri($svg-instagram, #000)) no-repeat 50% 50%;
                        background-size: 29px;
                    }
                }
            }
            
        }


        .grid{
            height:100%;
            width: 100%;
            padding: 0;
        }

        #grid-wrapper-1,
        #grid-wrapper-2,
        #grid-wrapper-3,
//        #grid-wrapper-4,
        #grid-wrapper-5{
            //outline:1px solid red;
            //background-color: #BADA55;
            display:block;
            height:355px;
            overflow: hidden;
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
            align-content: flex-start;
            position:relative;
        }

        #grid-wrapper-1{
            width:850px;
            margin:0 10px 10px 0;
            
        }
        #grid-wrapper-2{
            width:420px;
        }
        #grid-wrapper-3{
            margin-right: 10px;
        }
        #grid-wrapper-3,
        #grid-wrapper-5{
            width: 635px;
        }
        #grid-wrapper-4{
            width:205px;
            height: 100%;
            margin-right: 10px;
        }
        

        .grid-block{
            //background-color: #ABD;
            margin: 0;
            height: 100%;

            &#grid-block-1,
            &#grid-block-4,
            &#grid-block-15{
                width:420px;
            }
            &#grid-block-4,
            &#grid-block-8{
                margin-bottom:10px;
            }
            &#grid-block-4,
            &#grid-block-5,
            &#grid-block-6,
            &#grid-block-8,
            &#grid-block-9{
                height: 172.5px;
            }
            &#grid-block-5,
            &#grid-block-6,
            &#grid-block-7,
            &#grid-block-8,
            &#grid-block-9,
            &#grid-block-2,
            &#grid-block-3,
            &#grid-block-10,
            &#grid-block-14{
                width:205px;
            }

            &#grid-block-11{
                width:307px;
            }
            &#grid-block-12,
            &#grid-block-13{
                width: 154px;
            }
            &#grid-block-11,
            &#grid-block-12,
            &#grid-block-13{
                height: 228px;
                margin-bottom: 10px;
            }

            &#grid-block-14,
            &#grid-block-15{
                height: 117px;
            }

            &#grid-block-1,
            &#grid-block-2,
            &#grid-block-5,
            &#grid-block-7,
            &#grid-block-11,
            &#grid-block-12,
            &#grid-block-14{
                margin-right:10px;
            }
            
            &.clone{
                position:absolute;
                opacity: 1;
                z-index:1;
            
                &#grid-block-1{
                    height:355px;
                    width:420px;
                    top:0;
                    left:0;
                }
                &#grid-block-2{
                    height: 355px;
                    width:205px;
                    left:430px;
                    top:0;
                }
                &#grid-block-3{
                    height: 355px;
                    width:205px;
                    left:645px;
                    top:0;
                }
                &#grid-block-4{
                    height: 172.5px;
                    width:420px;
                    left:0;
                    top:0;
                }
                &#grid-block-5{
                    height: 172.5px;
                    width:205px;
                    left:0;
                    top:182.5px;
                }
                &#grid-block-6{
                    height: 172.5px;
                    width:205px;
                    left:215px;
                    top:182.5px;
                }
                &#grid-block-7{
                    height: 355px;
                    width:205px;
                    left:0;
                    top:0;
                }
                &#grid-block-8{
                    height: 172.5px;
                    width:205px;
                    left:215px;
                    top:0;
                }
                &#grid-block-9{
                    height: 172.5px;
                    width:205px;
                    left:215px;
                    top:182.5px;
                }
                &#grid-block-10{
                    height: 355px;
                    width:205px;
                    left:430px;
                    top:0;
                }
                &#grid-block-11{
                    height: 228px;
                    width:307px;
                    left:0;
                    top:0;
                }
                &#grid-block-12{
                    height: 228px;
                    width:154px;
                    left:317px;
                    top:0;
                }
                &#grid-block-13{
                    height: 228px;
                    width:154px;
                    left:481px;
                    top:0;
                }
                &#grid-block-14{
                    height: 117px;
                    width:205px;
                    left:0;
                    top:238px;
                }
            }


        }

    }
    &.error-4k:after{
        content: '';
        display:block;
        position: absolute;
        width: 5px;
        height: 5px;
        bottom: 0;
        right: 0;
        background-color:#000;
    }
    .loader{
        position: absolute;
        top: 0;
        left: 0;
        width:200px;
        height:200px;
        transform: rotate(45deg) translateX(-155px);
        background-color: #000;
        border:10px solid #FFF;
        z-index: 10000;
        .svg-refresh{
            width: 45px;
            height: 45px;
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            &:before{
                content:'';
                width: 50px;
                height: 50px;
                display: inline-block;
                background: transparent url(svg-bg-uri($svg-refresh, #fff)) no-repeat 50%;
                background-size: 30px;
                animation: spinSvgRefresh 2s infinite linear;
            }
        }
    }
}



