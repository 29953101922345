.class_group_content{
	.arianne.plan-musee{
		margin-bottom: 30px;
		.title{
			width: 500px;
		}
	}
	.page--infos-plan-musee{
		background-color: #FFF;
		section{
			padding-top: 45px;
			overflow: hidden;
			img{
				margin-bottom: 45px;
				max-width: 100%;
				display: block;
				margin-left: auto;
				margin-right: auto;
			}
		}
		.plan--level{
			text-align: center;
			font-family: $font-lovelo-black;
			font-size: 20px;
			font-size: 2.0rem;
			text-transform: uppercase;
			margin-bottom: 45px;
			.circled{
				display: inline-block;
				height: 40px;
				width: 40px;
				border: solid 4px $default-dark-font-color;
				@include border-radius(50px);
				padding-top: 7px;
				text-align: center;
			}
		}
		.callToAction--picto-dark-wide{
			margin-bottom: 30px;
		}
		.gallery-plan--head{
			font-family: $font-biko-bold;
			font-size: 20px;
			font-size: 2.0rem;
			margin-bottom: 30px;
			text-align: center;

		}
		.plan-1{
		}
		.plan-2{
		}
		.plan-3{
		}

		@media(max-width: 1279px){
			padding-bottom: 40px;
		}
		@media(max-width: 639px){
			padding-bottom: 20px;
		}
	}
}
.mac.class_group_content{
	.page--infos-plan-musee{
		.plan--level{
			.circled{
				padding-top:9px;
			}
		}
	}
}
