@mixin colorActu($color) {
  .categorie {
    color: $color;
  }
  .right {
    border-color: $color;
  }
  .lock {
    border-color: $color !important;
  }
  .border {
    &::before,
    &::after {
      border-color: $color;
    }
  }
  .titled {
    color: $color;
  }
}

.actu-expositions {
  @include colorActu($color-expositions);
}
.actu-visite {
  @include colorActu($color-visite);
}
.actu-invite {
  @include colorActu($color-invite);
}
.actu-evenements {
  @include colorActu($color-evenements);
}
.actu-actualite {
  @include colorActu($color-actu);
}
.actu-conference {
  @include colorActu($color-conference);
}
.actu-jeune {
  @include colorActu($color-jeune);
}
.actu-nocturne {
  @include colorActu($color-nocturne);
}
.actu-atelier {
  @include colorActu($color-atelier);
}
