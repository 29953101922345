.arianne-inscription{
	h1{
		display: inline;
	}
	.back-to{
		display:none;
	}
	.title{
		width:425px;
		text-transform: uppercase;
		font-size:24px;
		font-size: 2.4rem;
		.icon{
			margin-left:-30px;
			margin-right:30px;
		}
		.text{
			vertical-align: middle;
		}
	}
}
.inscription{
	button{
		margin-top: 30px;
	}
}