%bones-popup{
    // position: fixed;
    // top: 7vh; left: 50%;
    // margin-left: -363px;  
    width: 726px; 
    position: relative;
    max-width: 100%;
    padding: 25px;
    z-index: 99999;
    max-height: 90vh;
    overflow: auto;

    display: none;

    &.last{
        display:block; 
    }

    @media (max-width:767px){
        padding-top:45px;
    }


    .flexbox{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; 
    }
    h2{
        font-family: $font-biko-bold;
        font-size: 24px;
        font-size: 2.4rem;
        margin-bottom: 28px; 
        text-transform: none;
        letter-spacing: unset; 
        text-align: center;
         
    }
    .close{
        width: 30px;
        height: 30px;
        content: '';
        display: block;
        position: absolute;
        right: 25px;
        top: 25px;
        opacity: 1;
        z-index:999991;
        padding: 0 0 20px 20px;
        background-color: #fff;
        &:after, &:before{
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            height: 3px;
            background-color: #535353;
            width: 30px;
            transition: all, 0.5s;
        }
        &:after{
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &:before{
            transform: translate(-50%, -50%) rotate(-45deg);
        }

        &:hover, &:focus{
            &:after, &:before{
                background-color: #000;
            }
        }
        @media (max-width:767px){
            right: 10px;
            top: 10px;
        }
    }
    p{
        font-family: $font-opensans-light;
        font-size: 16px;
        font-size: 1.6rem;

        strong{
            font-family: $font-opensans-bold;

            button{
                &:hover, &:focus{
                    text-decoration: underline; 
                }
            }
        }
    }
    form{
        margin-top: 10px;
        width: 425px;
        @media (max-width:767px){
            width:100%;
        }
        
        & > * {
            display: block;
        } 
        a{
            text-decoration: underline;
        }
        .errors{
            color: #e2001a;
            margin-bottom: 10px;
        }
        [type="text"], [type="password"], [type="email"], [type="date"]{
            @extend %input;
        }
        .label_checkbox{
            padding-left: 25px;
            position: relative;
            line-height: normal;
            &:before{
                content:'';
                top: 11px;
                left: 0px;
                width: 14px;
                height: 14px;
                border: 2px solid #bcbec0;
                position: absolute;
                background: #fff;
            }   
            &:after{
                content:'';
                position: absolute;
                font-family:none;
                width: 14px; height: 14px;
                top: 11px;
                left: 0px;
                background: url(../images/icons/check.png) center no-repeat;
                transform: none;
            }                    
        }
        .radio{
            margin-bottom:20px;
            margin-right:20px;
            margin-top:0px;
            display: inline-block;
            vertical-align: middle;
            position:relative;
            &:nth-last-of-type(1){
                margin-right:0px;
            } 
            [type="radio"]:not(:checked),
            [type="radio"]:checked {
                display: none;
            }  
            label{
                margin: 0;
                min-height: auto;
                padding: 0;
                padding-left:22px;
                color:#000;
                font-family: "atc_overlookregular";
                &:before{
                    content: "";
                    position: absolute;
                    left: 0px; top: 2px;
                    width: 12px;
                    height: 12px;
                    border-radius:50%;
                    border: 2px solid #77787B;
                    background: transparent;
                    z-index:2;
                }
            }      
            [type="radio"]:checked + label{
                &:after{
                    content: "";
                    position: absolute;
                    left: 0px; top: 2px;
                    width: 12px;
                    height: 12px;
                    border-radius:50%;
                    background: #d8383f;
                    z-index:1;
                }
            }
        }
        [type="date"]{
            padding-right:55px;
            margin:0px;
        }
        [type="submit"]{
            @extend %bones-btn;
            @extend %skin-btn-black; 
            height: 55px;
            width: 100%;
            max-width: 100%; 
            margin-bottom:25px;
            text-align:center;
    
            &:hover, &:focus{
                @extend %animation-btn-red;
            }  
        }
        .fancy-select{
            position: relative;
            border: 4px solid #BCBEC0;
            height: 55px;
            margin-bottom: 10px;
            padding-left: 30px;
            &.focus-fancy-select{
                border-color:#77787b;      
            }
            .trigger{
                cursor: pointer;
                padding-top: 12px;
                padding-right: 40px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                position: relative;
                background: #FFF;
                width: 100%;
                height: 40px;
                transition: all 240ms ease-out;
                font-family: "atc_overlookregular";
                font-size: 22px; font-size: 2.2rem;
                color: #BCBEC0;
                top: 1px;
                &:after{
                    width: 35px;
                    height: 100%;
                    display: block;
                    padding-top: 1px;
                    padding-left: 10px;
                    content: "\e93d";
                    font-family: "pba";
                    font-size: 18px; font-size: 1.8rem;
                    position: absolute;
                    right: 20px;
                    top: 16px;
                    color: #BCBEC0;
                }
            }
            ul.options{
                list-style: none;
                margin: 0;
                margin-top: 17px;
                border: 4px solid #77787B;
                position: absolute;
                border-top: medium none transparent;
                left: -4px;
                visibility: hidden;
                opacity: 0;
                z-index: 50;
                overflow: auto;
                border-radius: 0px;
                width: 102%;
                background-color: #FFF;
                transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
                max-height:115px;
                li{
                    padding-left: 45px;
                    cursor: pointer;
                    white-space: nowrap;
                    transition: all 150ms ease-out;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    position: relative;
                    &:after{
                        content: none;
                    }
                }
                &.open{
                    visibility: visible;
                    top: 34px;
                    opacity: 1;
                    transition: opacity 300ms ease-out, top 300ms ease-out;
                }
            }
        }
        .forgot-mdp{
            margin-bottom: 24px;
            font-size: 14px;
            font-size: 1.4rem;
            text-decoration: underline;
        }
        .remember-me{
            display: flex;
            margin-bottom: 17px;
            label{
                display: block;
            }
        }
        .input-file{
            position: relative;
            width:235px;
            display: inline-block;
            vertical-align: middle;
            margin-bottom:10px;
            &:hover{
                label{
                    color:#D8383F;
                    border-color:#D8383F;
                }
            }
            label{
                display: block;
                padding:15px 20px 13px;
                border:3px solid #000;
                color:#000;
                font-size:16px; font-size:1.6rem;
                font-family: "loveloblack";
                transition: all 0.3s;
                margin-bottom:0px;
            }
            input{
                position:absolute;
                top:0; left:0px;
                width:235px;
                padding:14px 0;
                opacity:0;
                cursor:pointer;
            }
        }
        .file-return {
            margin: 0;
            padding-left:20px;
            display: inline-block;
            vertical-align: middle;
            font-family: 'open_sanslight';
            font-size:12px; font-size:1.2rem;
            line-height: 1.5em;
            max-height:36px;
            width:165px;
            overflow: hidden;
        }
        .file-return:not(:empty) {
            
        }
        .error{
            border-color:#e2001a;
            background-color:#fce5e8;
        }
        .control, .accept-cgu{
            position:relative;
        }
        span.error{
            // position:absolute;
            position: relative;
            // right:-20px;
            top:0px;
            // transform: translateX(100%);
            background-color:#1d1d1e;
            color:#ffffff;
            font-family:'open_sanslight';
            font-size:14px; font-size:1.4rem;
            min-height:55px;
            padding:0px 20px;
            display:flex;
            align-items: center;
            margin-bottom: 10px;

            &:before{
                content:'';
                position:absolute;
                top:0;
                transform: translate(-50%, -98%);
                left:50%;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 11px 9px 11px;
                border-color: transparent transparent #1d1d1e transparent;
            }
            @media(max-width:1279px){
                position:relative;
                right:0px;
                transform:none;
                margin-bottom:10px;
                &:before{
                    content:none;
                }
            }
        }
    }
    .fb-connect{
        @extend %bones-btn;
        @extend %skin-btn-black; 
        min-height: 55px;
        display: flex;
        align-items: center;
        text-align: center;
        width: 425px;
        max-width: 100%; 
        justify-content: center;
        font-size:24px; font-size:2.4rem;
 
        &:hover, &:focus{
            @extend %animation-btn-red;
        }  
        @media(max-width:767px){
            width:100%;
        }

    }
    .no-account-yet{
        & > p{
            margin: 0px 0px 17px;
            text-align: center;
        }
        .fb-connect{
            margin-bottom: 20px;
        }
        hr{
            color: #bcbec0;
        }
        @media(max-width:767px){
            width: 100%;
        }
    }
}