.vignette.has-actions{
    .gallery-actions{
        position: absolute;
        z-index: 2;
        &.favorites-actions{
            top: 20px;
            right: 20px;
            
            button{ 
                opacity: 0;
                background-color:#404;
                display: block;
                height:28px;
                width: 32px;
                transform: translate3D(0,0,0);
                background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMzEgMjAwIj48c3R5bGU+LnN0MHtmaWxsOm5vbmU7c3Ryb2tlOiNGRkZGRkY7c3Ryb2tlLXdpZHRoOjE1O3N0cm9rZS1taXRlcmxpbWl0OjEwO308L3N0eWxlPjxwYXRoIGQ9Ik0xMTUuOCAxOTNDODYuNyAxNzYuMSA5LjkgMTI0LjkgOS45IDU1LjkgOS45IDMwIDMxLjggOSA1OC44IDljNDIuMSAwIDU3IDMyLjMgNTcgMzIuM1MxMzQuNiA5IDE3Mi43IDljMjYuOSAwIDQ4LjggMjEgNDguOCA0Ni45LjEgNjktNzYuNyAxMjAuMi0xMDUuNyAxMzcuMXoiIGNsYXNzPSJzdDAiLz48L3N2Zz4=') no-repeat 50%;
                background-size: cover;

                transition: scale, 0.1s;
                &.active, &:hover{
                    
                    transform: scale(1.2);
                    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMzUuMSAyMDkuNSI+PHN0eWxlPi5zdDB7ZmlsbDpub25lO3N0cm9rZTojRkZGRkZGO3N0cm9rZS13aWR0aDoxNTtzdHJva2UtbWl0ZXJsaW1pdDoxMDt9IC5zdDF7ZmlsbDojRkZGRkZGO3N0cm9rZTojRkZGRkZGO3N0cm9rZS13aWR0aDo0O3N0cm9rZS1taXRlcmxpbWl0OjEwO308L3N0eWxlPjxwYXRoIGQ9Ik0xMTcuMiAyMDEuNWMtMjktMTYuOS0xMDUuOC02OC4xLTEwNS44LTEzNy4xIDAtMjUuOSAyMS45LTQ2LjkgNDguOC00Ni45IDQyLjEgMCA1NyAzMi4zIDU3IDMyLjNzMTguOC0zMi4zIDU3LTMyLjNjMjYuOSAwIDQ4LjggMjEgNDguOCA0Ni45IDAgNjktNzYuOCAxMjAuMi0xMDUuOCAxMzcuMXoiIGNsYXNzPSJzdDAiLz48cGF0aCBkPSJNMTE4IDE5Ni40Qzg5IDE3OS41IDEyLjIgMTI4LjMgMTIuMiA1OS4zIDEyLjEgMzMuNCAzNCAxMi40IDYxIDEyLjRjNDIuMSAwIDU3IDMyLjMgNTcgMzIuM3MxOC44LTMyLjMgNTctMzIuM2MyNi45IDAgNDguOCAyMSA0OC44IDQ2LjkgMCA2OS03Ni44IDEyMC4yLTEwNS44IDEzNy4xeiIgY2xhc3M9InN0MSIvPjwvc3ZnPgo=');
                }
            }
        }
    }

    a{
        position:relative;
        display:block;
        z-index:1;

        .visu{
            position: relative;
            &:after{
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                content: ' ';
                background-color: rgba(0,0,0,0.29);
                top: 0;
                left:0;
                opacity:0;
                transition: opacity, 0.5s;
            }
        }

        .description{
            background: #fff;
            color: $lighter-dark-font-color;
        }
    }


    &:hover, &:focus, &:active{
        .gallery-actions, 
        .visu:after, 
        .favorites-actions button,
        a:before{opacity:1;}

        .description{@extend %animation-btn-red;}
    }
}

.favorites-counter{
    @extend %bones-btn;
    @extend %skin-btn-white;

    position: absolute;
    right: 80px;
    margin-top: 37px;

    &:hover, &:active, &:focus{
        @extend %animation-btn-red;
    }

    .icon-heart{
        &:before, &:after{
            background-size: 19px 17px;
            background-repeat: no-repeat;
            content: '';
        }
    }

    @media(max-width: 1279px){
        margin: 0;
        right: auto;
        left: 12px;
        font-size: 20px;
        position: relative;
        margin-top: 27px;
    }
    
}
