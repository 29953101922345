//Cookies
#cnil_banner_consent{
	//position:fixed;
	top:0px;
	right:0px;
	left:0px;
	background-color:$default-light-font-color;
	z-index: 950;
	width:100%;
	.alert{
		text-align: center;
		text-shadow:none;
		box-shadow:none;
		padding:0px;
		border:none;
		border-radius:0px;
		font-family: 'arial';
		font-size:15px;
		font-size:1.5rem;
		color:$default-dark-font-color;
		line-height: 1em;
		margin-bottom:0px;
		padding: 15px 0;
		position: relative;
	}
	.alert-info{
		background-image:none;
		background-color:transparent;
		text-align: center;
	}
	.close{
		position: absolute;
		top: 50%;
		margin-top: -14px;
		right:15px;
		background:url(../images/cookie-croix.png) no-repeat center center;
		height:28px;
		width:40px;
		opacity: 1;
		@include transition(all, 0.4s, ease, 0s);
		&:hover, &:active, &:focus{
			opacity: 0.5;
		}
	}
	a{
		font-family:'arial';
		font-size:15px;
		font-size:1.5rem;
		color:$default-hover-color;
		text-decoration: underline;
		&:hover, &:active, &:focus{
			text-decoration: underline;
		}
	}
}

@media(max-width:1279px){
	#cnil_banner_consent{
	    bottom: 0px;
	    top:inherit;
	    position: fixed;
	    background-color:rgba(255,255,255,0.8);
		.container{
			padding: 0px 60px 0px 30px;
		}
		.alert{
			text-align:left;
			line-height: normal;
			width: 80%;
			//margin: 0 auto;
			position:relative;
			margin-left: 20px;
		}
		.close{
			right: -15%;
		}
	}
	.fillerCookie{
		display: none !important;
	}
}

@media(max-width:639px){
	#cnil_banner_consent{
		.alert{
			font-size:12px;
			font-size:1.2rem;
		}
		a{
			font-size:12px;
			font-size:1.2rem;
		}
		.container{
			padding: 0px 70px 0px 10px;
		}
	}
	.fillerCookie{
		display: none !important;
	}
}