@mixin border-radius($radius){
	 -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
@mixin transition($target, $duration, $easing, $delay){
	 -webkit-transition: $target $duration $easing $delay;
     -moz-transition: $target $duration $easing $delay;
      -ms-transition: $target $duration $easing $delay;
          transition: $target $duration $easing $delay;
}

@mixin RWD($media) {
  @if $media==mobile {
    @media only screen and (max-width: $break-small + "px") {
      @content;
    }
  }

  @else if $media==tablet_only {
    @media only screen and (min-width: $break-small + "px") and (max-width: $break-large - 1 + "px") {
      @content;
    }
  }

  @else if $media==tablet {
    @media only screen and (max-width: $break-large - 1 + "px") {
      @content;
    }
  }

  @else if $media==tablet_desktop {
    @media only screen and (min-width: $break-small + "px") {
      @content;
    }
  }

  @else if $media==desktop {
    @media only screen and (min-width: $break-large + "px") {
      @content;
    }
  }

  @else if $media==vsmall {
    @media only screen and (max-height: $break-vetical + "px") {
      @content;
    }
  }
}